import classNames from 'classnames';
import React from 'react';

interface CounterLabelProps {
  children?: React.ReactNode;
  counterSize?: 'sm' | 'lg' | 'xl' | 'date' | 'timedetail' | 'time';
  counterType?: 'route' | 'Warn' | 'info';
  bgColor?: string;
  textColor?: string;
  className?: string;
  classNameIcon?: string;
  IconItem?: React.ComponentType<{ className: string }>;
}

const CounterLabel: React.FC<CounterLabelProps> = ({
  children,
  counterSize = 'sm',
  counterType = 'route',
  bgColor,
  textColor,
  className,
  classNameIcon,
  IconItem,
}) => (
  <span
    aria-label="counterLabel"
    className={classNames(
      'z-50',
      'absolute',
      'flex items-center justify-center',
      'text-center',
      'p-0',
      'text-xs text-bold',
      'rounded-full',
      { 'w-12 h-12 mx-1 my-2 self-start': counterSize === 'xl' },
      { 'w-10 h-10 mx-1 my-2 self-start': counterSize === 'lg' },
      { 'w-8 h-8 mx-2 my-2 self-start': counterSize === 'sm' },
      { 'w-9 h-9 mx-2 my-2 self-start': counterSize === 'date' },
      { 'w-8 h-8 mx-2 my-2 self-start': counterSize === 'time' },
      { 'w-12 h-6 mx-1 mt-3 self-start': counterSize === 'timedetail' },
      {
        'bg-route-line_full text-menu-active-text':
          !bgColor && counterType === 'route',
      },
      {
        'bg-menu-active text-menu-active-text':
          !bgColor && counterType === 'Warn',
      },
      '',
      className,
    )}
    style={{ background: bgColor, color: textColor }}
  >
    {IconItem && (
      <IconItem
        className={classNames(
          { 'w-6 h-6': counterSize === 'sm' },
          'fill-current',
          classNameIcon,
        )}
      />
    )}
    {children}
  </span>
);

export default CounterLabel;
