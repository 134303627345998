import React from 'react';
import { useTranslation } from 'react-i18next';
import { HelperMessageAction } from '../../components/HelperMessage';
import * as Icon from '../../components/icons';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import InputText from '../../components/inputs/InputText';
import InputTextArea from '../../components/inputs/InputTextArea';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { Spacer } from '../../components/layout/Spacer';
import Callout from '../../helpContext/Callout';

export type PanelSimulationMetaInfo = {
  title: string;
  description: string;
  isDisabled?: boolean;
  onTitleChange: (title: string) => void;
  onDescriptionChange: (description: string) => void;
};

const PanelSimulationMetaInfo: React.FC<PanelSimulationMetaInfo> = props => {
  const { t } = useTranslation('simulation');
  return (
    <>
      <ScreenTitle
        title={t`Simulation Setup`}
        subtitle={t`Getting Started`}
        isSticky
        helpNavTo={'simulation/simulation-getting-started'}
        icon={Icon.SimulationAddLayout}
      />

      <Callout panelMode type="suggestion" iconAfter icon={Icon.ArrowBottom}>
        {t`Let's get started with the new Warehouse Simulation.`}
      </Callout>

      <InputGroupList hasPadding hasSpacing>
        <InputText
          required
          placeholder={t`Simulation Name`}
          label={t`Simulation Name`}
          name={t`Simulation Name`}
          value={props.title ?? ''}
          onChange={props.onTitleChange}
          isDisabled={props.isDisabled}
          animate={
            !props.isDisabled &&
            props.title === '' &&
            props.title?.trim().length === 0
            // && props.title == null
          }
        />

        <InputTextArea
          optional
          label={t`Simulation Description`}
          placeholder={t`About the simulation...`}
          value={props.description ?? ''}
          onChange={props.onDescriptionChange}
          isDisabled={props.isDisabled}
          animate={
            !props.isDisabled &&
            props.description === '' &&
            props.description?.trim().length === 0
            // && props.description == null
          }
        />
      </InputGroupList>
      <Callout panelMode type="tip" iconBefore icon={Icon.ArrowTop}>
        {t`We suggest to give the simulation a name and a short description, helping you better identify it in the future.`}
      </Callout>
      <Spacer flexspace />
      <Callout panelMode type="action" iconAfter icon={Icon.ArrowRightBottom}>
        {t`Click`}
        <HelperMessageAction>{t`Select Data Set`}</HelperMessageAction>
        {t`button to continue to the next step.`}
      </Callout>
    </>
  );
};

export default PanelSimulationMetaInfo;
