import {
  SkipLimitPageSpec,
  SortDirection,
  StringSearchFilter,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import { InferResult } from 'kysely';
import _ from 'lodash';
import { KeysMatching } from '../../../common/utility.types';
import { AnalyzeEventsQueryBuilderParams } from './queryBuilder/analyzedEventsQueryBuilder';
import { analyzedFeedJobLinesQueryBuilder } from './queryBuilder/analyzedFeedJobLinesQueryBuilder';
import { getActivityFeedJobsBuilder } from './queryBuilder/feedJobsQueryBuilders';
import { postProcessDefault } from './queryBuilder/feedQueryBuilder';

export type ActualityAnalyzedEventsQueryBuilderParams =
  AnalyzeEventsQueryBuilderParams & {
    jobId: string;
    filterBy?: ActualityAnalyzedEventsFilterBy;
  };

export type ActualityAnalyzedEventSortBy = {
  direction?: SortDirection;
  field: ActualityAnalyzedEventsColumn;
};

export type ActualityAnalyzedEventsFieldWithStringFilter = KeysMatching<
  ActualityAnalyzedEvent,
  string
>;

export type ActualityAnalyzedEventsFilterBy = Partial<
  Record<ActualityAnalyzedEventsFieldWithStringFilter, StringSearchFilter>
>;

export type ActualityAnalyzedEvents = InferResult<
  ReturnType<typeof getActualityAnalyzedEventSelectPart>
>;
export type ActualityAnalyzedEvent = ActualityAnalyzedEvents[number];
export type ActualityAnalyzedEventsColumn = keyof ActualityAnalyzedEvent;

export type ActualityAnalyzedEventsQueryBuilderPagedClientParams = {
  sortBy?: ActualityAnalyzedEventSortBy;
  page?: SkipLimitPageSpec;
};

export type ActualityAnalyzedEventsQueryBuilderPagedParams =
  ActualityAnalyzedEventsQueryBuilderParams &
    ActualityAnalyzedEventsQueryBuilderPagedClientParams;

export function getActualityAnalyzedEventSelectPart(
  params: ActualityAnalyzedEventsQueryBuilderParams,
) {
  let query = analyzedFeedJobLinesQueryBuilder(
    params,
    getActivityFeedJobsBuilder(params),
  )
    .selectFrom('af')
    .leftJoin('ajl', join => join.onRef('af.eventId', '=', 'ajl.sourceEventId'))
    .where('af.jobId', '=', params.jobId)
    .select([
      'af.eventId',
      'af.eventProcessType',
      'af.eventType',
      'af.consignee',
      'af.sku',
      'af.uom',
      'af.quantity',
      'af.scanCode',
      'af.warehouseAreaSource',
      'af.locationIdSource',
      'af.warehouseArea',
      'af.locationId',
      'af.agentType',
      'af.agentId',
      'af.agentUser',
      'af.agentEnergy',
      'af.agentSignalStrength',
      'af.eventStartTime',
      'af.eventEndTime',
      'af.jobId',
      'af.jobLine',
      'af.jobGroupId',
      'af.jobLineSequence',

      'ajl.jobDate',
      'ajl.volume',
      'ajl.weight',
      'ajl.cost',
      'ajl.duration',
      'ajl.durationTravellingStartEnd',
      'ajl.distanceStartEnd',
      'ajl.durationHandling',
      'ajl.durationPicking',
      'ajl.durationTravellingVertical',
      'ajl.durationTravelling',
      'ajl.durationReordering',
      'ajl.distance',
      'ajl.route',
      'ajl.reasons',
    ]);

  if (params.filterBy) {
    query = _.reduce(
      params.filterBy,
      (q, value, key) => {
        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          value?.value === 'undefined'
        ) {
          return q.where(key as any, 'is', null);
        }

        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          !_.isEmpty(value?.value)
        ) {
          return q.where(key as any, 'like', `%${value.value}%`);
        }
        return q;
      },
      query,
    );
  }

  return query;
}

const isDateField = (f: string): boolean => {
  const fName = _.camelCase(f as string);
  return (
    fName === 'eventStartTime' ||
    fName === 'eventEndTime' ||
    fName === 'jobDate'
  );
};

export function postProcessActualityAnalyzedEvents(
  data: Record<string, any>[],
) {
  return postProcessDefault<ActualityAnalyzedEvent>(data, isDateField);
}

function getActualityAnalyzedEventsTotalPart(
  params: ActualityAnalyzedEventsQueryBuilderParams,
) {
  let query = analyzedFeedJobLinesQueryBuilder(
    params,
    getActivityFeedJobsBuilder(params),
  )
    .selectFrom('af')
    .leftJoin('ajl', join => join.onRef('af.eventId', '=', 'ajl.sourceEventId'))
    .select(({ fn }) => [fn.count('jobKey').distinct().as('totalCount')]);

  if (params.filterBy) {
    query = _.reduce(
      params.filterBy,
      (q, value, key) => {
        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          value?.value === 'undefined'
        ) {
          return q.where(key as any, 'is', null);
        }

        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          !_.isEmpty(value?.value)
        ) {
          return q.where(key as any, 'like', `%${value.value}%`);
        }
        return q;
      },
      query,
    );
  }
  return query;
}

export function getActualityAnalyzedEventsQuery(
  params: ActualityAnalyzedEventsQueryBuilderPagedParams,
) {
  let query = getActualityAnalyzedEventSelectPart(params)
    .orderBy(
      params.sortBy?.field ?? 'eventEndTime',
      params.sortBy?.direction === SortDirection.ASC ? 'asc' : 'desc',
    )
    .$if(!_.isNil(params.page), db => db.offset(params.page.skip ?? 0))
    .$if(!_.isNil(params.page), db => db.limit(params.page.limit ?? 100));

  return query;
}

export function getActualityAnalyzedEventsDataTotalQuery(
  params: ActualityAnalyzedEventsQueryBuilderParams,
) {
  let query = getActualityAnalyzedEventsTotalPart(params);

  return query;
}

export function postProcessTotalCount(events: Record<string, any>[]): {
  totalCount: number;
} {
  return {
    totalCount: _.head(events)?.['total_count'] ?? 0,
  };
}
