import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import AllocationRequirementSummary from '../../../allocation/AllocationRequirementSummary';
import Tabs, { TabItem } from '../../../components/actions/Tabs';
import * as Icon from '../../../components/icons';
import PickingPolicy from '../../sidebar/PickingPolicy';
import { PoliciesTabId } from '../../store/simulation.types';
import { simulationPolicyTabKey } from '../../store/simulation.wizard.state';

const PolicyPickingPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [selectedTab, setSelectedTab] = useRecoilState(simulationPolicyTabKey);
  const tabIconStyle = classNames(
    'fill-current w-7 lg:w-8 xl:w-10 2xl:w-12 h-auto',
  );

  const tabItems: TabItem<PoliciesTabId>[] = [
    {
      id: 'tab-policy-picking',
      title: t`Picking Policy`,
      icon: <Icon.PolicyComplianceAssignmentArrow className={tabIconStyle} />,
      content: <PickingPolicy />,
    },
    {
      id: 'tab-policy-allocate',
      title: t`Allocate Report`,
      icon: <Icon.PolicyAllocate className={tabIconStyle} />,
      content: <AllocationRequirementSummary />,
    },
  ];

  return (
    <Tabs
      componentName="PolicyPickingPanel"
      items={tabItems}
      selectedIndex={_.findIndex(tabItems, i => i.id === selectedTab)}
      onChange={index => setSelectedTab(tabItems[index].id)}
      hasScroll
      fullHeight
    />
  );
};

export default PolicyPickingPanel;
