import classNames from 'classnames';
import _ from 'lodash';
import React, { HTMLAttributes, MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsAgent } from '../../AnalyticTracker';
import { FormattedValueWithUnit } from '../../common/formatHelper';
import { Button } from '../actions/Button';
import ButtonToggle from '../actions/ButtonToggle';
import CopyToClipboardButton from '../actions/CopyToClipboardButton';
import * as Icon from '../icons';
import { ContainerTag } from '../layout/ContainerFlex';
import Loader from '../loaders/Loader';
import StatDiffIcon from './StatDiffIcon';
import { UnitOfMeasure } from './UnitOfMeasure';

type ValueDiffType = 'after' | 'before';

const ValueDiffComponent: React.FC<{
  value: any;
  valueBefore?: any;
  isUnitPrefix?: boolean;
  unitOfMeasure?: string;
  isPrimaryResult?: boolean;
  noDiff?: boolean;
  type: ValueDiffType;
}> = ({
  value,
  valueBefore,
  isUnitPrefix,
  unitOfMeasure,
  isPrimaryResult,
  noDiff,
  type,
}) => {
  const { t } = useTranslation('app');

  return (
    <>
      <div
        data-component="iconDiff"
        className={classNames('flex items-end', 'pb-1')}
      >
        <StatDiffIcon
          className={classNames(
            'h-4 w-4 xl:h-6 xl:w-6',
            'fill-current ltr:mr-2 rtl:ml-2',
            isPrimaryResult ? 'text-menu-text/80' : 'text-menu-text',
          )}
          before={valueBefore}
          after={value}
        />
      </div>

      <div
        data-component={`StatValue-${type}`}
        className={classNames(
          'flex flex-1',
          'text-lg md:text-xl xl:text-3xl',
          'text-menu-text',
          type === 'after'
            ? 'items-end justify-end'
            : 'items-end justify-start',
        )}
      >
        {!noDiff && isUnitPrefix && (
          <UnitOfMeasure unitOfMeasure={unitOfMeasure} />
        )}
        <div
          className={classNames(
            'flex flex-col',
            type === 'after' ? 'items-end' : 'items-start',
          )}
        >
          <label
            className={classNames(
              'text-xxs mb-0.5 pt-1 uppercase',
              isPrimaryResult ? 'text-menu-text/75' : 'opacity-30',
            )}
          >
            {type === 'after' ? t`After` : t`Before`}
          </label>

          {noDiff ? (
            <div
              className={classNames('mb-1 pt-1 text-xl opacity-50')}
            >{t`No Diff`}</div>
          ) : (
            <div className={classNames('flex-1')}>{value.value}</div>
          )}
        </div>
        {!noDiff && !isUnitPrefix && (
          <UnitOfMeasure unitOfMeasure={unitOfMeasure} />
        )}
      </div>
    </>
  );
};

const StatToggleView = ({
  isSelected,
  inFilterStat,
  isCheckLoading,
  hasIssue,
  valuePercent,
  children,
  toggleTable,
  toggleIconOpen,
  toggleIconClosed,
  subStats,
  className,
}: StatProps) => {
  const { t } = useTranslation('app');
  const IconToggleOpen = toggleIconOpen;
  const IconToggleClosed = toggleIconClosed;

  const IconSizeStyle =
    'w-5 h-5 md:w-6 md:h-6 lg:w-7 lg:h-7 xl:w-8 xl:h-8 opacity-80';
  const IconColorStyle = ''; //'text-menu-text group-hover:text-menu-active-text';

  const iconToggleOpenStyle = `${IconSizeStyle} ${IconColorStyle} fill-current group-hover:opacity-50`;

  const iconToggleClosedStyle = `${IconSizeStyle} ${
    inFilterStat
      ? hasIssue
        ? IconColorStyle
        : ''
      : 'group-hover:text-menu-text/75 hover:text-menu-text/75' // 'text-menu-text/40'
  } fill-current group-hover:block`;

  return (
    <ButtonToggle
      title={t`Show on Map`}
      classNameLabel={classNames(
        // 'border border-app-panel-dark', // Debug
        isSelected ? 'text-menu-active-text' : '',
        'h-full',
        'flex',
        'absolute',
        'ltr:right-1 rtl:left-1 xl:ltr:right-2 xl:rtl:left-2',
        hasIssue || children ? 'top-0' : 'top-0',
        subStats || valuePercent || toggleIconOpen || toggleIconClosed
          ? '!items-start'
          : 'items-center justify-center',
      )}
      className={classNames(
        hasIssue || valuePercent || children
          ? 'mt-2'
          : subStats
            ? 'mt-8'
            : 'top-0',
      )}
    >
      {isSelected && (
        <>
          {isCheckLoading && (
            <Loader
              type={'loading-original'}
              size={30}
              className={classNames(
                'fill-current',
                'h-3 w-3 md:h-4 md:w-4 lg:h-4 lg:w-4 xl:h-5 xl:w-5',
                inFilterStat ? 'text-menu-text/60' : 'text-menu-active',
              )}
            />
          )}
          {!isCheckLoading && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {IconToggleOpen ? (
                <IconToggleOpen className={`${iconToggleOpenStyle}`} />
              ) : toggleTable ? (
                <Icon.HideTable className={`${iconToggleOpenStyle}`} />
              ) : (
                <Icon.Hide className={`${iconToggleOpenStyle}`} />
              )}
            </>
          )}
        </>
      )}

      {!isSelected &&
        (isCheckLoading ? (
          <Loader
            type={'loading-original'}
            size={30}
            className={classNames(
              'flex-1 fill-current',
              'h-3 w-3 md:h-4 md:w-4 lg:h-4 lg:w-4 xl:h-5 xl:w-5',
              inFilterStat ? 'text-menu-text/60' : 'text-menu-active',
            )}
          />
        ) : IconToggleClosed ? (
          <IconToggleClosed className={`${iconToggleClosedStyle}`} />
        ) : toggleTable ? (
          <Icon.ShowTable className={`${iconToggleClosedStyle}`} />
        ) : (
          <Icon.Show className={`${iconToggleClosedStyle}`} />
        ))}
    </ButtonToggle>
  );
};

export type StatProps = {
  children?: React.ReactNode;
  subStats?: React.ReactNode;
  value?: string | number;
  valuePercent?: FormattedValueWithUnit;
  valueFormatted?: FormattedValueWithUnit;
  title?: string;
  titleElement?: React.ReactNode;
  titleAlt?: string;
  tag?: string;
  counter?: string;
  className?: string;
  titleClassName?: string;
  icon?: React.FC<HTMLAttributes<Element>>;
  isPreview?: boolean;
  isActionable?: boolean;
  classActionable?: string;
  isSelected?: boolean;
  classSelected?: string;
  classChildren?: string;
  classIcon?: string;
  isFullWidth?: boolean;
  hasLoadMore?: boolean;
  hasMargin?: boolean;
  hasHelper?: boolean;
  hasTooltip?: string;
  isComparable?: boolean;
  isSectionable?: boolean;
  isPrimaryResult?: boolean;
  isBigValue?: boolean;
  isMetaValue?: boolean;
  valueAfter?: FormattedValueWithUnit;
  valueBefore?: FormattedValueWithUnit;
  valueNone?: string;
  valueTotal?: string | number;
  valueDiff?: string | number;
  valueDiffPercent?: string | number;
  valueNegative?: boolean;
  valueIsBig?: boolean;
  inPanelMode?: boolean;
  inFilterStat?: boolean;
  isCheckLoading?: boolean;
  isLoading?: boolean;
  hasListDecimals?: boolean;
  hasIssue?: boolean;
  switchUnitOfMeasure?: React.ReactNode;
  hasEvents?: React.ReactNode;
  hidden?: boolean;
  cardView?: boolean;
  dashboardView?: boolean;
  dashboardSimulationView?: boolean;
  unitOfMeasure?: string;
  status?: string;
  classTitle?: string;
  threshold?: number;
  isUnitPrefix?: boolean;
  isSelectable?: boolean;
  truncate?: boolean;
  toggleTable?: boolean;
  toggleIconOpen?: React.FC<HTMLAttributes<Element>>;
  toggleIconClosed?: React.FC<HTMLAttributes<Element>>;
  toggleMap?: boolean;
  hasNoPadding?: boolean;
  transparent?: boolean;
  canCopy?: boolean;
  legendColor?: [string, string];
  color?: [string, string];
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const Stat = ({
  children,
  subStats,
  value,
  valuePercent,
  valueFormatted,
  title,
  titleAlt,
  titleElement,
  titleClassName,
  tag,
  counter,
  className,
  icon,
  isPreview,
  isPrimaryResult,
  classActionable,
  classSelected,
  classChildren,
  classIcon,
  classTitle,
  isActionable,
  isSelected,
  isFullWidth,
  hasLoadMore,
  hasHelper,
  hasTooltip,
  isComparable,
  isSectionable,
  valueAfter,
  valueBefore,
  valueNone,
  valueTotal,
  valueDiff,
  valueDiffPercent,
  valueNegative,
  valueIsBig,
  inPanelMode,
  inFilterStat,
  isCheckLoading,
  isLoading,
  unitOfMeasure,
  status,
  threshold,
  hasListDecimals,
  hasMargin,
  hasIssue,
  switchUnitOfMeasure,
  isMetaValue,
  hasEvents,
  cardView,
  dashboardView,
  dashboardSimulationView,
  hidden,
  isSelectable,
  isUnitPrefix,
  toggleTable,
  toggleIconOpen,
  toggleIconClosed,
  hasNoPadding,
  truncate,
  legendColor,
  color,
  transparent,
  canCopy,
  onClick,
}: StatProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  const [zoomable, setActive] = useState(false);

  const [bgColor, textColor] = color ?? [];
  const [bgLegendColor, textLegendColor] = legendColor ?? [];

  const StatIcon = icon;

  const issueIcon = (
    <Icon.TriangleInfo
      data-component="issueIcon"
      className={classNames(
        'h-5 w-5 xl:h-6 xl:w-6',
        'mb-1 ltr:mr-1 rtl:ml-1',
        'fill-current',
        isSelected && isActionable
          ? 'text-menu-active-text group-hover:opacity-75'
          : isActionable
            ? 'text-alerts-alert'
            : 'text-menu-text/50',
      )}
    />
  );

  const ValueCell: React.FC<{
    valueFormatted: FormattedValueWithUnit;
    className?: string;
    classNameValue?: string;
  }> = ({ valueFormatted, className, classNameValue }) => {
    const { t } = useTranslation('app');
    return (
      <div
        data-component="StatValueCell"
        className={classNames(
          'flex items-baseline ltr:justify-end rtl:justify-start',
          className,
        )}
      >
        {valueFormatted.prefixUnit && (
          <UnitOfMeasure unitOfMeasure={valueFormatted.unit} />
        )}
        <div
          aria-label={t`Stat Value`}
          className={classNames(
            'text-end text-xs font-bold md:text-sm',
            classNameValue,
          )}
        >
          {valueFormatted.value}
        </div>
        {!valueFormatted.prefixUnit && (
          <UnitOfMeasure unitOfMeasure={valueFormatted.unit} />
        )}
      </div>
    );
  };

  const zoomButton = (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {zoomable ? (
        <Icon.CircleX
          className={`absolute mt-0.5 h-3 w-3 ltr:ml-0.5 rtl:mr-0.5 ${
            zoomable ? 'zoomable' : 'not_zoomable'
          } text-menu-100 top-0 cursor-default fill-current ltr:left-0 rtl:right-0`}
          onClick={() => setActive(!zoomable)}
        />
      ) : (
        <Icon.CircleArrowFullScreen
          className={`absolute mt-0.5 h-3 w-3 ltr:ml-0.5 rtl:mr-0.5 ${
            zoomable ? 'zoomable' : 'not_zoomable'
          } any-hover-none:opacity-0 text-menu-300 top-0 cursor-default fill-current transition delay-700 duration-500 ease-in-out hover:opacity-100 ltr:left-0 rtl:right-0`}
          onClick={() => setActive(!zoomable)}
        />
      )}
    </>
  );

  const styleBlur =
    'backdrop-filter backdrop-blur-lg backdrop-saturate-110 hover:backdrop-filter hover:backdrop-blur-lg hover:backdrop-saturate-110 ';

  const StatLoader = IconSizeStyle => {
    return (
      <Loader
        type={'loading-original'}
        size={30}
        stroke={7}
        className={classNames(
          'h-6 w-6 md:h-8 md:w-8 lg:h-10 lg:w-10 xl:h-12 xl:w-12',
          'flex-1 fill-current',
        )}
      />
    );
  };

  const symbolCount = _.isNil(value)
    ? 0
    : value.toString().length +
      (_.isNil(valueTotal) ? 0 : valueTotal.toString().length);

  const useSmallValueFont = symbolCount > 4;
  const useMillionValue = symbolCount > 6;

  // Your component's render method or functional component body
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Convert HEX to RGBA
  function hexToRGBA(hex, opacity) {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  // Convert RGB to RGBA
  function rgbToRGBA(rgb, opacity) {
    return rgb.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
  }

  // Convert HSL to HSLA
  function hslToHSLA(hsl, opacity) {
    return hsl.replace('hsl', 'hsla').replace(')', `, ${opacity})`);
  }

  // Identify color format and convert to RGBA or HSLA
  function colorWithOpacity(color, opacity) {
    if (color.startsWith('#')) {
      return hexToRGBA(color, opacity);
    } else if (color.startsWith('rgb')) {
      return rgbToRGBA(color, opacity);
    } else if (color.startsWith('hsl')) {
      return hslToHSLA(color, opacity);
    } else {
      return color; // Unknown or unsupported format
    }
  }

  // Basic classes for actionable items
  const basicActionableClasses = classNames({
    isActionable: isActionable,
    isSelected: isActionable && isSelected,
    isNotSelected: isActionable && !isSelected,
    isNotActionable: !isActionable,
  });

  // Classes for various action states,
  const stateActionableClasses = classNames({
    'text-menu-active-text':
      !isActionable && !cardView && !dashboardView && !legendColor,
    'text-menu-text hover:bg-menu-active/50 hover:text-menu-active-text':
      isActionable && !isSelected && !legendColor,
    'text-menu-active-text group-hover:text-menu-active-text':
      isActionable && isSelected && !legendColor && !classSelected,
    [classSelected + ' classSelected']:
      isActionable && isSelected && classSelected,
    'text-menu-active-text hover:bg-opacity-50':
      isActionable && isSelected && isPrimaryResult && !legendColor,
    'text-menu-active-text hover:bg-opacity-90':
      isActionable && isSelected && inFilterStat && !legendColor,
    // Additional condition to remove bg-* classes if props.transparent is true
    ...(transparent
      ? {}
      : {
          'bg-app-panel-stat':
            !isActionable && !cardView && !dashboardView && !legendColor,
          'bg-app-panel hover:bg-menu-active/50 hover:text-menu-active-text':
            isActionable && !isSelected && !legendColor,
          'bg-menu-active text-menu-active-text':
            isActionable && isSelected && !legendColor && !classSelected,
          'bg-menu-active/75 text-menu-active-text':
            isActionable && isSelected && isPrimaryResult && !legendColor,
          'bg-menu-active/90 text-menu-active-text':
            isActionable && isSelected && inFilterStat && !legendColor,
        }),
  });

  return (
    <div
      role="button"
      data-component="Stat"
      data-label={`stat-${title || ''}`}
      aria-label={`stat-${title || ''}`}
      id={id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classNames(
        'group relative',
        { 'overflow-hidden': truncate },
        { 'text-menu-stat': !bgColor && !isSelected },
        'text-xxs lg:text-xs xl:text-base',
        hasMargin && 'm-0.5',
        hasIssue && 'p-2 sm:p-2 lg:p-3 xl:p-4',
        hidden && 'hidden',
        dashboardView && 'bg-app-stat/50 text-menu-text',
        cardView
          ? 'mt-4'
          : isMetaValue
            ? ''
            : hasNoPadding
              ? ''
              : toggleIconOpen || toggleIconClosed
                ? '!lg:ltr:pr-8 !xl:ltr:pr-10 p-2 sm:p-2 lg:p-3 xl:p-4 ltr:pr-6 '
                : 'p-2 sm:p-2 lg:p-3 xl:p-4',
        stateActionableClasses,
        isFullWidth ? 'flex w-full flex-col overflow-hidden' : 'flex-1',
        isPreview
          ? inPanelMode
            ? 'w-full'
            : 'max-w-1/4 min-w-full'
          : isPrimaryResult
            ? 'isPrimaryResult w-full'
            : '',
        dashboardSimulationView || dashboardView || isActionable
          ? 'cursor-pointer'
          : 'cursor-default',
        className,
      )}
      style={
        isActionable && bgLegendColor
          ? {
              background: isSelected
                ? isHovered
                  ? colorWithOpacity(bgLegendColor, 0.5) // Selected & Hovered
                  : bgLegendColor // Selected & Not Hovered
                : isHovered
                  ? colorWithOpacity(bgLegendColor, 0.9) // Not Selected & Hovered
                  : colorWithOpacity(bgLegendColor, 0.1), // Not Selected & Not Hovered
              color: isSelected
                ? isHovered
                  ? textLegendColor // Selected & Hovered
                  : null // Selected & Not Hovered
                : isHovered
                  ? textLegendColor // Not Selected & Hovered
                  : null, // Not Selected & Not Hovered
            }
          : bgColor
            ? {
                background: bgColor,
                color: textColor,
              }
            : null
      }
      onClick={e => {
        if (onClick && isActionable) {
          e.stopPropagation();
          onClick && onClick(e);
          try {
            analyticsAgent?.track(`Stat ${title}: (Click)`, {
              title,
            });
          } catch (ex) {
            console.debug('analyticsAgent Error:', ex);
          }
        }
      }}
    >
      {/* {!cardView && <zoomButton />} */}

      {legendColor && (
        <div
          data-component="StatLegend"
          className={classNames(
            'absolute top-0 h-full w-1 ltr:left-0 rtl:right-0',
          )}
          style={{ background: bgLegendColor, color: textLegendColor }}
        />
      )}

      {hasIssue && (
        <span
          data-component="thresholdIssue"
          className={classNames(
            'text-alerts-warning group-hover:text-alerts-alert',
            'mb-1',
          )}
          title={t`threshold:` + threshold ?? ''}
        >
          <span
            className={classNames(
              'bg-alerts-alert',
              'w-1',
              'h-full',
              'ltr:left-0 rtl:right-0',
              'absolute top-0',
            )}
          ></span>
          {!icon && issueIcon}
        </span>
      )}
      {isActionable && (
        <StatToggleView
          toggleIconOpen={toggleIconOpen}
          toggleIconClosed={toggleIconClosed}
          toggleTable={toggleTable}
          isCheckLoading={!icon && isCheckLoading}
          inFilterStat={inFilterStat}
          isSelected={isSelected}
          valuePercent={valuePercent}
          subStats={subStats}
        >
          {children}
        </StatToggleView>
      )}

      <div
        className={classNames(
          'items-left flex flex-col md:flex-row md:items-center',
          {
            'w-full flex-1': isMetaValue,
          },
          {
            'ltr:ml-2 rtl:mr-2': legendColor && value && !icon && !hasIssue,
          },
          {
            '': legendColor && value && icon,
          },
          {
            'ltr:ml-0.5 rtl:mr-0.5': legendColor && !value,
          },
        )}
      >
        {StatIcon && (
          <div data-component="StatIcon" className={classNames('p-1')}>
            {isCheckLoading ? (
              <StatLoader />
            ) : (
              <StatIcon
                className={classNames(
                  'fill-current',
                  'ltr:mr-1 rtl:ml-1',
                  'h-4 w-4 md:h-5 md:w-5 lg:h-7 lg:w-7 xl:h-8 xl:w-8',
                  classIcon,
                  // 'text-opacity-50',
                )}
              />
            )}
          </div>
        )}

        <div
          data-component="StatItemHeaderTitleToolbarContainer"
          className={classNames(
            { truncate: truncate },
            'relative flex w-full flex-1',
            {
              'flex-col': !isMetaValue,
            },
            {
              'text-menu-active-text group-hover:text-menu-active-text':
                isSelected && !textColor,
            },
            {
              'border-menu h-full ltr:pl-1 ltr:sm:pl-2 ltr:md:border-l ltr:md:pl-3 ltr:xl:pl-4 rtl:pr-1 rtl:sm:pr-2 rtl:md:border-r rtl:md:pr-3 rtl:xl:pr-4':
                StatIcon,
            },
          )}
        >
          {(title || titleElement) && (
            <div
              data-component="StatItemHeaderTitle"
              title={titleAlt ? titleAlt : title}
              role="button"
              className={classNames(
                'flex',
                inFilterStat || counter || isMetaValue || !value ? '' : 'mb-2',
                classTitle,
              )}
            >
              <div
                aria-label="Title"
                className={classNames(
                  'opacity-80',
                  { truncate: title || truncate },
                  inFilterStat
                    ? 'text-minimal sm:text-xs lg:text-sm xl:text-lg'
                    : isMetaValue
                      ? 'text-minimal text-opacity-50 sm:text-xs lg:text-xs xl:text-sm'
                      : cardView
                        ? 'text-minimal xxl:text-sm uppercase sm:text-xs lg:text-xs xl:text-xs'
                        : 'text-minimal xxl:text-sm uppercase sm:text-xs lg:text-xs xl:text-xs',
                  isActionable
                    ? isSelected
                      ? 'isSelected '
                      : isPrimaryResult
                        ? ''
                        : inFilterStat
                          ? 'inFilterStat flex items-center'
                          : ''
                    : isPrimaryResult
                      ? 'isPrimaryResult'
                      : inFilterStat
                        ? ''
                        : isMetaValue
                          ? 'isMetaValue text-xxs lg:text-xs'
                          : '',
                  tag
                    ? 'flex flex-1 flex-col items-start lg:flex-row'
                    : 'flex flex-1 items-center',
                )}
              >
                {titleElement}
                {title && (
                  <span
                    className={classNames(
                      titleClassName ? titleClassName : 'whitespace-normal',
                    )}
                  >
                    {title}
                  </span>
                )}

                {tag && (
                  <ContainerTag
                    className={classNames(
                      'border-menu-active border',
                      'text-menu-active',
                      'text-minimal uppercase',
                      'my-2 lg:my-0',
                      'ltr:ml-0',
                      'ltr:lg:ml-2 rtl:lg:mr-2',
                      'leading-2',
                    )}
                  >
                    {tag}
                  </ContainerTag>
                )}
              </div>

              {switchUnitOfMeasure &&
                !cardView &&
                !dashboardView &&
                switchUnitOfMeasure}
            </div>
          )}
          {isComparable && (
            <>
              {(valueDiffPercent || valueDiff) && (
                <div
                  className={classNames(
                    'flex flex-1 flex-col items-start',
                    'border-menu mb-2 border-b',
                    'bg-app-panel-stat/50',
                    // 'px-3',
                    'pb-0 pt-1',
                    'mt-2',
                    'rounded',
                  )}
                >
                  {(valueDiffPercent || valueDiff) === 0 ||
                  (valueDiffPercent || valueDiff) === '0' ? null : (
                    <>
                      <label
                        className={classNames(
                          'text-xxs mb-0.5 pt-1 uppercase',
                          isPrimaryResult ? 'text-menu-text/75' : 'opacity-30',
                        )}
                      >{t`Difference`}</label>
                      <div
                        className={classNames(
                          'flex items-end xl:flex-row',
                          'w-full flex-1',
                        )}
                      >
                        {valueDiffPercent && (
                          <div
                            data-component="valueDiffPercent"
                            className={classNames(
                              'text-xl md:text-3xl xl:text-6xl',
                              'flex-1',
                              'truncate',
                            )}
                          >
                            {valueDiffPercent}
                            <UnitOfMeasure
                              className="text-base"
                              unitOfMeasure={`%`}
                            />
                          </div>
                        )}
                        {valueDiff && (
                          <div
                            data-component="valueDiff"
                            className={classNames(
                              'text-lg md:text-xl xl:text-2xl',
                              'mb-2',
                              'ltr:ml-2 rtl:mr-2',
                            )}
                          >
                            <span className="opacity-30">{`(`}</span>

                            {isUnitPrefix && (
                              <UnitOfMeasure unitOfMeasure={unitOfMeasure} />
                            )}
                            {valueDiff}
                            {!isUnitPrefix && (
                              <UnitOfMeasure unitOfMeasure={unitOfMeasure} />
                            )}
                            <span className="opacity-30">{`)`}</span>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}

              <div
                data-component="isComparable"
                className={classNames(
                  'flex flex-1',
                  valueIsBig ? 'flex-row' : 'items-end',
                  isActionable
                    ? ' ' +
                        (isSelected
                          ? 'isSelected'
                          : 'text-menu-active group-hover:text-menu-text')
                    : isPrimaryResult
                      ? 'text-menu-text/75'
                      : 'text-menu/70',
                  isPreview ? 'text-2xl' : 'h-16',
                  { truncate: truncate },
                )}
              >
                {valueBefore && (
                  <ValueDiffComponent
                    type="before"
                    value={valueBefore}
                    isUnitPrefix={isUnitPrefix}
                    unitOfMeasure={valueBefore.unit}
                    isPrimaryResult={isPrimaryResult}
                  />
                )}

                {valueAfter &&
                  ((valueDiffPercent || valueDiff) === 0 ||
                  (valueDiffPercent || valueDiff) === '0' ? (
                    <ValueDiffComponent
                      type="after"
                      value={valueAfter}
                      valueBefore={valueBefore}
                      isUnitPrefix={isUnitPrefix}
                      unitOfMeasure={valueBefore.unit}
                      isPrimaryResult={isPrimaryResult}
                      noDiff
                    />
                  ) : (
                    <ValueDiffComponent
                      type="after"
                      value={valueAfter}
                      valueBefore={valueBefore}
                      isUnitPrefix={isUnitPrefix}
                      unitOfMeasure={valueAfter.unit}
                      isPrimaryResult={isPrimaryResult}
                    />
                  ))}
              </div>
            </>
          )}

          {!icon && isCheckLoading && <StatLoader />}

          {!isCheckLoading && value !== null && value !== undefined && (
            <div
              data-component="valueContainer"
              className={classNames(
                'flex flex-1 items-center xl:items-baseline',
                isActionable
                  ? 'isActionable text-xl lg:text-2xl xl:text-4xl' +
                      ' ' +
                      (isSelected
                        ? 'isSelected'
                        : isPrimaryResult
                          ? 'isPrimaryResult'
                          : inFilterStat
                            ? 'inFilterStat'
                            : 'h-16 text-xl lg:text-2xl xl:text-4xl')
                  : isPrimaryResult
                    ? 'isPrimaryResult font-black'
                    : cardView
                      ? 'cardView text-3xl'
                      : isPreview
                        ? 'isPreview min-h-[2rem] text-xl lg:text-4xl'
                        : inFilterStat
                          ? 'inFilterStat text-xl lg:text-4xl'
                          : isMetaValue
                            ? 'isMetaValue text-end text-xs ltr:ml-2 rtl:mr-2'
                            : ' text-xl lg:text-2xl',
              )}
            >
              <div
                data-component="statValueContainer"
                className={classNames(
                  'flex flex-1 items-center xl:items-baseline',
                  !isMetaValue && useSmallValueFont
                    ? isSectionable || useMillionValue
                      ? 'text-base xl:text-xl'
                      : 'text-xl xl:text-2xl'
                    : '',
                )}
              >
                {isUnitPrefix &&
                  (_.isString(value) ? (
                    value === '0' ? null : (
                      <UnitOfMeasure unitOfMeasure={unitOfMeasure} />
                    )
                  ) : // ifNumbers...
                  value === 0 ? null : (
                    <UnitOfMeasure unitOfMeasure={unitOfMeasure} />
                  ))}

                {!isCheckLoading && value === 0 ? (
                  <div
                    data-component="valueNone"
                    className={classNames(
                      'p-0.5 pb-0',
                      'text-xs',
                      'rounded',
                      'border-alerts-warning border border-opacity-50',
                      'text-alerts-warning',
                      'flex items-center',
                    )}
                  >
                    <Icon.TriangleInfo
                      className={classNames(
                        'h-3 w-3',
                        'ltr:mr-1 rtl:ml-1',
                        'fill-current',
                      )}
                    />
                    <span
                      className={classNames(
                        'flex-1',
                        'ltr:pr-1 rtl:pl-1',
                        'uppercase',
                      )}
                    >
                      {valueNone || t`No Items`}
                    </span>
                  </div>
                ) : (
                  <div
                    data-component={`statValue${
                      _.isString(value) ? 'String' : 'Number'
                    }`}
                    className={classNames(
                      'relative',
                      isSelectable && 'select-text',
                      isMetaValue && 'flex-1',
                    )}
                  >
                    {canCopy && (
                      <CopyToClipboardButton
                        hasIconSmall
                        className={classNames(
                          'hidden group-hover:flex',
                          'absolute',
                          '-top-4',
                          '-right-[1.5rem]',
                          'opacity-0 transition-opacity delay-[4000] group-hover:opacity-100 group-hover:delay-[4000]',
                        )}
                        value={`${value}`}
                      />
                    )}
                    {
                      _.isString(value)
                        ? value === '0'
                          ? '—'
                          : value // ifString
                        : typeof value === 'number'
                          ? value === 0
                            ? '——'
                            : value < 0.01
                              ? '< 0.01'
                              : value < 0.1
                                ? '< 0.1'
                                : value < 1
                                  ? value.toFixed(2)
                                  : value < 10
                                    ? value.toFixed(1)
                                    : value.toFixed(0)
                          : _.isObject(value) || _.isArray(value)
                            ? JSON.stringify(value)
                            : value // handle other cases
                    }
                  </div>
                )}

                {!isUnitPrefix &&
                  (_.isString(value) ? (
                    value === '0' ? null : (
                      <UnitOfMeasure unitOfMeasure={unitOfMeasure} />
                    )
                  ) : // ifNumbers...
                  value === 0 ? null : (
                    <UnitOfMeasure unitOfMeasure={unitOfMeasure} />
                  ))}

                {isSectionable && valueTotal !== 0 && valueTotal && (
                  <div className={`mx-1`}>
                    <span
                      className={classNames(
                        'opacity-50',
                        'text-sm xl:text-base',
                        'ltr:ml-1 rtl:mr-1',
                      )}
                    >{t`of`}</span>
                    <span className={classNames('ltr:ml-2 rtl:mr-2')}>
                      {valueTotal}
                    </span>
                  </div>
                )}
              </div>
              {valuePercent && (
                <div
                  data-component="statValuePercent"
                  className={classNames(
                    'opacity-75',
                    'text-sm xl:text-base ltr:ml-2 rtl:mr-2',
                    { 'select-text': isSelectable },
                  )}
                >
                  {valuePercent.raw > 0.01
                    ? valuePercent.fullString
                    : valuePercent.raw === 0
                      ? ''
                      : `< 1%`}
                </div>
              )}
            </div>
          )}

          {counter && isLoading ? (
            <div className={classNames('flex items-center', 'text-xs', 'mb-2')}>
              <Loader
                type={'loading-original'}
                size={35}
                className={classNames('ltr:mr-1 rtl:ml-1')}
              />
            </div>
          ) : (
            counter && (
              <div data-component="counterContainer" className="">
                {counter}
              </div>
            )
          )}

          {valueFormatted && <ValueCell valueFormatted={valueFormatted} />}

          {children && hasListDecimals ? (
            <ul
              className={classNames(
                'ltr:pl-6 rtl:pr-6',
                'text-xs',
                'list-outside list-decimal',
                'marker:text-menu-text/30',
              )}
            >
              {children}
            </ul>
          ) : (
            children && (
              <div
                data-component="statChildren"
                className={classNames(classChildren)}
                // className="divide-y divide-solid divide-menu-400/50"
              >
                {children}
              </div>
            )
          )}

          {subStats && <div className="">{subStats}</div>}
        </div>
      </div>

      {hasEvents}

      {isLoading && (
        <Loader
          type={'loading-original'}
          size={35}
          className={classNames('ltr:mr-1 rtl:ml-1')}
        />
      )}
      {hasLoadMore && (
        <Button
          buttonSize={'sm'}
          full
          label={t`Load More`}
          className={classNames('mt-2')}
        />
      )}
    </div>
  );
};
