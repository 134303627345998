import {
  LoadAssignmentComplianceSummaryDocument,
  LoadAssignmentComplianceSummaryQuery,
  LoadAssignmentComplianceSummaryQueryVariables,
  RunCalculateAssignmentComplianceDocument,
  RunCalculateAssignmentComplianceMutation,
  RunCalculateAssignmentComplianceMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../../common/types';
import { secureClient } from '../../GraphQLClient';
import { errorAppender } from '../../store/error.state';
import {
  assignmentComplianceSummary,
  assignmentComplianceSummaryLoadStatus,
} from '../store/assignmentCompliance.state';
export type LoadAssignmentComplianceSummaryParams = {
  simulationId: string;
};

function useLoadAssignmentComplianceSummary() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load size compliance metadata`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ snapshot, set }) => async () => {
    set(assignmentComplianceSummaryLoadStatus, AsyncLoadStatus.Loading);
  });

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAssignmentComplianceSummaryParams) => {
        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(assignmentComplianceSummaryLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const query = secureClient.watchQuery<
          LoadAssignmentComplianceSummaryQuery,
          LoadAssignmentComplianceSummaryQueryVariables
        >({
          query: LoadAssignmentComplianceSummaryDocument,
          variables: {
            simulationId: params.simulationId,
          },
        });

        const queryObservable = query.subscribe(
          async result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            let compliance = data?.simulation?.assignmentCompliance;
            if (_.isNil(compliance)) {
              try {
                const response = await secureClient.mutate<
                  RunCalculateAssignmentComplianceMutation,
                  RunCalculateAssignmentComplianceMutationVariables
                >({
                  mutation: RunCalculateAssignmentComplianceDocument,
                  variables: {
                    simulationId: params.simulationId,
                  },
                });

                compliance =
                  response.data?.calculateSimulationAssignmentCompliance;
              } catch (ex) {
                console.error(errorTitle, ex);
                handleError(ex.message || ex, ex.stack || null);
              }
            }

            set(assignmentComplianceSummary, compliance);
            set(assignmentComplianceSummaryLoadStatus, AsyncLoadStatus.Ok);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAssignmentComplianceSummaryParams) {
    await initLoading();
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadAssignmentComplianceSummary;
