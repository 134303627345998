import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  actualityJobsTableData,
  actualityJobsTableDataLoadStatus,
  actualityJobsTableDataState,
  actualityJobsTableTotalCount,
} from '../store/actuality.jobs.state';
import {
  ActualityAnalyzedJobQueryBuilderPagedParams,
  getActualityAnalyzedJobDataTotalQuery,
  getActualityAnalyzedJobsQuery,
} from '../store/datasetQueries/actualityAnalyzedJobs';
import { executeDatasetQuery } from '../store/feed.helper';

export type LoadActualityJobsTableDataParams =
  ActualityAnalyzedJobQueryBuilderPagedParams & {
    isAppend?: boolean;
  };

function useLoadActualityJobsTableData() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Actuality Jobs`;
  //const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const resetCallback = useRecoilCallback(({ reset }) => async () => {
    reset(actualityJobsTableDataLoadStatus);
    reset(actualityJobsTableData);
    reset(actualityJobsTableDataState);
    reset(actualityJobsTableTotalCount);
  });

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(actualityJobsTableDataLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadActualityJobsTableDataParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const current = await snapshot.getPromise(actualityJobsTableData);
        let totalCount = await snapshot.getPromise(
          actualityJobsTableTotalCount,
        );

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(actualityJobsTableDataLoadStatus, AsyncLoadStatus.Error);
        }

        if (!params.isAppend) {
          const compiledQueryTotal =
            getActualityAnalyzedJobDataTotalQuery(params).compile();

          const totalResult = await executeDatasetQuery({
            warehouseId,
            compiledQuery: compiledQueryTotal,
            comment: '[actuality] Analyze Jobs total rows',
          });

          totalCount = totalResult[0].totalCount;
        }

        let rows = [];
        if (totalCount > 0) {
          const compiledQuery = getActualityAnalyzedJobsQuery(params).compile();

          rows = await executeDatasetQuery({
            warehouseId,
            compiledQuery,
            comment: '[actuality] Analyze Jobs total rows',
          });
        }

        if (params.isAppend) {
          rows = [...current, ...rows];
        }
        set(actualityJobsTableData, rows);
        set(actualityJobsTableTotalCount, totalCount);
        set(actualityJobsTableDataLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  async function call(params: LoadActualityJobsTableDataParams) {
    await initLoading();
    await loadDataCallback(params);
  }

  async function reset() {
    await resetCallback();
  }

  return [call, reset] as const;
}

export default useLoadActualityJobsTableData;
