import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../common/utils';
import * as Icon from '../icons';
import { Button } from './Button';

export type CopyToClipboardButtonProps = {
  className?: string;
  classNameContainer?: string;
  value?: string;
  title?: string;
  truncate?: boolean;
  hasIcon?: boolean;
  hasIconSmall?: boolean;
};

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = props => {
  const { t } = useTranslation('app');
  const [showPopover, setShowPopover] = useState(false);

  function onClick() {
    navigator.clipboard.writeText(props.value);
    setShowPopover(true);
    setTimeout(() => {
      setShowPopover(false);
    }, 1000);
  }
  return (
    <Popover
      data-component="CopyToClipboardButton"
      className={classNames('', props.classNameContainer)}
      aria-details={`${props.title ? props.title + ':\n' : ''}${props.value}`}
    >
      {(props.hasIconSmall || props.hasIcon || props.title) && (
        <Button
          buttonType="secondary"
          buttonSize={props.hasIconSmall ? 'xs' : 'sm'}
          hasIconBefore={
            <Icon.ActionCopyPaste
              className={classNames(
                'fill-current',
                props.hasIcon ? 'h-6 w-6' : 'h-4 w-4',
              )}
            />
          }
          hasIcon
          onPress={onClick}
          label={props.title ?? ''}
          className={cn(
            'z-alert',
            'backdrop backdrop-blur backdrop-saturate-150',
            'shadow-xl',
            // 'border border-menu',
            'rounded',
            'text-xs',
            {
              'max-w-ch-20 truncate': props.truncate,
              '!p-2': props.hasIcon,
              '!p-1': props.hasIconSmall,
            },
            props.className,
          )}
          titleAlt={props.title}
        />
      )}

      <Transition
        show={showPopover}
        enter="transition-all ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition-all ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className={'relative'}
      >
        <Popover.Panel
          data-component="CopyToClipboardButtonPopover"
          className={classNames(
            'flex items-center',
            'absolute -left-10 -top-6',
            'z-alert',
            // 'mb-8',
            'bg-menu-active/80 text-menu-active-text',
            'rounded',
            'p-1',
            'backdrop backdrop-blur backdrop-saturate-150',
            'whitespace-nowrap',
          )}
        >
          <Icon.CircleOk
            className={classNames(
              'h-4 w-4',
              'fill-current',
              'ltr:mr-2 rtl:ml-2',
            )}
          />
          <span className={classNames('flex-1 text-xs')}>{t`Copied`}</span>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
export default CopyToClipboardButton;
