import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import { actualityHeatmapLevelData } from '../store/actuality.heatmap.state';
import useActualityLocationColor from './useActualityLocationColor';

const ActivityLocationLayer: React.FC = () => {
  const dataLoadable = useRecoilValueLoadable(actualityHeatmapLevelData);
  const [getHeatmapColor, getGradientHeatmapColors] = useActualityLocationColor(
    { dataLoadable },
  );

  return (
    <LocationLayer
      getColor={getHeatmapColor}
      getGradientColors={getGradientHeatmapColors}
    />
  );
};

export default ActivityLocationLayer;
