import { useApolloClient } from '@apollo/client';
import {
  AllocateJobEngine,
  AnalyzeResultStatus,
  BatchJobStatus,
  LoadAllocationRunStatusDocument,
  LoadAllocationRunStatusQuery,
  LoadAllocationRunStatusQueryVariables,
  useCreateAllocationRunMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { GEN_REPORT_JOB_REFRESH_INTERVAL } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { cloneWithoutTypename } from '../../common/utils';
import { errorAppender } from '../../store/error.state';
import { useLoadAnalyzeResult } from '../../warehouse/hooks/useLoadAnalyzeResult';
import {
  allocationRunSummary,
  allocationRunSummaryLoadStatus,
} from '../store/allocation/allocation.state';
import { simulationCurrent } from '../store/simulation.state';

function useRunAllocation() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Allocation Run`;
  const [runJob] = useCreateAllocationRunMutation();
  const loadAnalyzeResult = useLoadAnalyzeResult();
  const [observable, setObservable] = useState<ZenObservable.Subscription>();
  let timeoutId = null;

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(allocationRunSummaryLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadJob = useRecoilCallback(
    ({ snapshot, set }) =>
      async (simulationId: string) => {
        const query = client.watchQuery<
          LoadAllocationRunStatusQuery,
          LoadAllocationRunStatusQueryVariables
        >({
          query: LoadAllocationRunStatusDocument,
          variables: { simulationId },
        });

        function handleError(details?, stack?) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(allocationRunSummaryLoadStatus, AsyncLoadStatus.Error);
        }

        const queryObservable = query.subscribe(
          async ({ data, errors }) => {
            if (errors) {
              const stack = errors.map(e => e.message).join('. ');
              handleError(null, stack);
              return;
            }

            const report = data?.simulation?.latestAllocationRun;
            set(allocationRunSummary, report);

            if (
              report?.status === BatchJobStatus.FAILED ||
              report?.status === BatchJobStatus.TERMINATED
            ) {
              handleError();
            }

            if (
              report.status === BatchJobStatus.CALCULATING ||
              report.status === BatchJobStatus.CREATED
            ) {
              timeoutId = setTimeout(() => {
                loadJob(simulationId);
              }, GEN_REPORT_JOB_REFRESH_INTERVAL);
            }

            if (report.status === BatchJobStatus.READY) {
              set(allocationRunSummaryLoadStatus, AsyncLoadStatus.Ok);

              if (
                report?.analyzeResult?.status === AnalyzeResultStatus.COMPLETED
              ) {
                await loadAnalyzeResult({
                  analyzeId: report?.analyzeResult?.id,
                  analyzeType: 'allocate',
                });
              }
            }
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  const startCheck = useRecoilCallback(
    ({ snapshot, set }) =>
      async (engine: AllocateJobEngine) => {
        const simulation = await snapshot.getPromise(simulationCurrent);

        function handleError(details?, stack?) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(allocationRunSummaryLoadStatus, AsyncLoadStatus.Error);
        }

        try {
          const { data: jobData, errors: jobErrors } = await runJob({
            variables: {
              input: {
                simulationId: simulation.id,
                allocationSettings: cloneWithoutTypename(
                  simulation.allocationSettings,
                ),
                engine: engine,
              },
            },
          });

          if (
            !_.isEmpty(jobErrors) ||
            _.isNil(jobData.createAllocationRun?.id)
          ) {
            const details = _(jobErrors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }

          loadJob(simulation.id);
        } catch (ex) {
          handleError(ex?.message ?? ex);
        }
      },
  );

  async function call(engine: AllocateJobEngine) {
    await initLoading();
    await startCheck(engine);
  }

  function cancel() {
    observable?.unsubscribe();
    clearTimeout(timeoutId);
  }

  return [call, cancel] as const;
}

export default useRunAllocation;
