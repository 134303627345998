import classNames from 'classnames';
import { t } from 'i18next';
import _ from 'lodash';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cctvEnabled, cctvShow } from '../../cctv/cctv.state';
import useFormatter from '../../common/useFormatter';
import LoadingIndicator from '../../components/LoadingIndicator';
import ButtonToolbar from '../../components/actions/ButtonToolbar';
import { ButtonToolbarGroup } from '../../components/actions/ButtonToolbarGroup';
import { ButtonToolbarWrapper } from '../../components/actions/ButtonToolbarWrapper';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import TitleToolbarContainer from '../../containers/TitleToolbarContainer';
import {
  viewerShowLocationTitles,
  viewerShowPortals,
} from '../../layout/viewer/store/viewer.state';
import { getAnalyzeProductMetricDescriptorsMap } from '../../metrics/analyzeProduct/analyzeProductMetric.default';
import { analyzeProductMetricSelected } from '../../metrics/analyzeProduct/analyzeProductMetric.state';
import { simulationLayoutShowCongestion } from '../store/simulation.layout.state';
import { simulationMaxLocationVisits } from '../store/simulation.state';
import { showAnalyseData } from '../store/simulation.wizard.state';
import HeatmapLegend from './HeatmapLegend';

const usedMetrics = [
  {
    groupMetrics: 'velocity',
    groupTitle: t`Velocity`,
    metrics: ['abc', 'visits'],
  },
  {
    groupMetrics: 'reorder',
    groupTitle: t`Re-Ordering`,
    metrics: ['triggered-reorder', 'applied-reorder'],
  },
  {
    groupMetrics: 'heatmaps',
    groupTitle: t`Heatmaps`,
    metrics: ['congestion-zone', 'consignee'],
  },
];

const SimulationHeatmapToolbar: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [heatmapType, setHeatmapType] = useRecoilState(
    analyzeProductMetricSelected,
  );
  const [showFeatureTitles, setShowFeatureTitles] = useRecoilState(
    viewerShowLocationTitles,
  );
  const [showPortals, setShowPortals] = useRecoilState(viewerShowPortals);
  const [showCongestion, setShowCongestion] = useRecoilState(
    simulationLayoutShowCongestion,
  );
  const maxHitCount = useRecoilValue(simulationMaxLocationVisits);
  const CCTVEnabled = useRecoilValue(cctvEnabled);
  const [showCCTV, setShowCCTV] = useRecoilState(cctvShow);
  const formatter = useFormatter();

  const applyAnalyse = useRecoilValue(showAnalyseData);

  if (!applyAnalyse || maxHitCount === 0) return null;

  const actionOptions = _.keyBy(
    [
      {
        id: 'portals',
        title: showPortals ? t`Hide Portals` : t`Show Portals`,
      },
      {
        id: 'titles',
        title: showFeatureTitles ? t`Hide Titles` : t`Show Titles`,
      },
    ],
    'id',
  );

  if (CCTVEnabled) {
    actionOptions['cctv'] = {
      id: 'cctv',
      title: showCCTV ? t`Hide CCTV` : t`Show CCTV`,
    };
  }

  const heatmapDescriptors = getAnalyzeProductMetricDescriptorsMap(
    t,
    formatter,
  );

  return (
    <ButtonToolbarGroup
      className={classNames(
        'absolute ltr:left-1 rtl:right-1 top-1 flex flex-col z-toolbar',
      )}
    >
      <TitleToolbarContainer
        id="panel-toolbar-analytics"
        title={t`Analytics`}
        collapsible
      >
        <div className="flex">
          <ButtonToolbarWrapper className="w-10">
            <DropdownSelector
              className={classNames('text-sm text-menu-text')}
              DropAlignLeft
              buttonTransparent
              vertical
              w_sm
              value={'...'}
              values={_.keys(actionOptions)}
              renderValue={v => actionOptions[v]?.title ?? '...'}
              onChange={v => {
                switch (v) {
                  case 'portals':
                    setShowPortals(!showPortals);
                    break;
                  case 'titles':
                    setShowFeatureTitles(!showFeatureTitles);
                    break;
                  case 'cctv':
                    setShowCCTV(!showCCTV);
                    break;
                }
              }}
            />
          </ButtonToolbarWrapper>
          <ButtonToolbar
            titleTrace={`Toggle: Congestion`}
            isSelected={showCongestion}
            onChange={setShowCongestion}
          >
            <Icon.CongestionMapVariable
              className={classNames('w-8 h-8')}
              alt={t`Show Congestion`}
              title={t`Show Congestion`}
            />
          </ButtonToolbar>
        </div>
      </TitleToolbarContainer>

      {usedMetrics.map(group => {
        return (
          <TitleToolbarContainer
            key={`group-${group.groupMetrics}`}
            id={group.groupMetrics}
            title={group.groupTitle}
            collapsible
            classNameContainerBodyChildren="flex"
          >
            {group.metrics.map(m => {
              const metricDescriptor = heatmapDescriptors[m];
              if (!metricDescriptor || !metricDescriptor.icon) {
                console.error(
                  `Missing icon for metric: ${m}`,
                  metricDescriptor,
                );
              }
              const Icon = metricDescriptor?.icon as any;

              return (
                <ButtonToolbar
                  key={`heatmap-select-${metricDescriptor.type}`}
                  isSelected={heatmapType === metricDescriptor.type}
                  onChange={() => setHeatmapType(metricDescriptor.type)}
                  titleTrace={`Toggle: ${metricDescriptor.title}`}
                >
                  {!_.isNil(Icon) && (
                    <Icon
                      className={classNames('w-8 h-8')}
                      alt={metricDescriptor.actionTitle}
                      title={metricDescriptor.actionTitle}
                    />
                  )}
                </ButtonToolbar>
              );
            })}
          </TitleToolbarContainer>
        );
      })}
      <Suspense
        fallback={
          <LoadingIndicator
            noColor
            transparent
            className={classNames('text-minimal', 'opacity-50', 'mb-2')}
            message={`${t`Loading`}...`}
            circleSize={32}
          />
        }
      >
        <HeatmapLegend />
      </Suspense>
    </ButtonToolbarGroup>
  );
};

export default SimulationHeatmapToolbar;
