import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formatInteger } from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import useFormatter from '../common/useFormatter';
import { PolicyFilter } from '../components/policies/PolicyFilter';
import PolicyFilterGroupList from '../components/policies/PolicyFilterGroupList';
import PolicyFilterItem from '../components/policies/PolicyFilterItem';
import { PolicyFilterTag } from '../components/policies/PolicyFilterTag';
import { PolicyStatContainer } from '../components/policies/PolicyStatContainer';
import RuleContainer, {
  RuleContainerProps,
} from '../components/policies/RuleContainer';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { Stat } from '../components/stats/Stat';
import {
  AP_FALLBACK_RULE_ID,
  getLocationFilterConfigCommon,
} from '../simulation/store/assignmentPolicy.default';
import {
  assignmentPolicyCheckLoadStatus,
  assignmentPolicyCheckResult,
  assignmentPolicyDocument,
  assignmentPolicyRule,
  assignmentPolicySelectedIdentity,
} from '../simulation/store/assignmentPolicy.state';
import {
  simulationIsEditable,
  simulationShowDatasetAsTable,
} from '../simulation/store/simulation.state';
import { sidebarStateByType } from '../store/sidebar.state';

export type AssignmentPolicyFallbackRuleProps = {
  className?: string;
  hasIssue?: boolean;
};

const AssignmentPolicyFallbackRule: React.FC<
  AssignmentPolicyFallbackRuleProps
> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const policy = useRecoilValue(assignmentPolicyDocument);
  const [rule, updateRule] = useRecoilState(
    assignmentPolicyRule(AP_FALLBACK_RULE_ID),
  );
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    assignmentPolicySelectedIdentity,
  );
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-storage-editor'),
  );
  const setShowDatasetTable = useSetRecoilState(simulationShowDatasetAsTable);
  const checkResult = useRecoilValue(assignmentPolicyCheckResult);
  const checkResultStatus = useRecoilValue(assignmentPolicyCheckLoadStatus);
  const isDisabled = !useRecoilValue(simulationIsEditable);

  function selectLocationFilterIntersection(
    filterIntersectionId: string,
    shouldSelect: boolean,
  ) {
    if (!shouldSelect) {
      // deselect intersection only
      setSelectedIdentity(null);
    } else {
      // select intersection and rule details
      setSelectedIdentity({
        ruleId: rule.id,
        locationFilterId: filterIntersectionId,
        filterType: 'locations',
      });
    }

    if (!policyEditPanelState.isPinned) {
      setPolicyEditPanelState({
        ...policyEditPanelState,
        isCollapsed: !shouldSelect,
      });
    }
  }

  function addLocationIntersection() {
    const id = nanoid();
    updateRule({
      ...rule,
      locationsMatch: {
        anyOf: [...(rule.locationsMatch?.anyOf ?? []), { id, allOf: [] }],
      },
    });
    selectLocationFilterIntersection(id, true);
  }

  const removeField = (groupId: string, fieldId: string) => {
    updateRule({
      ...rule,
      locationsMatch: {
        anyOf: _.map(rule.locationsMatch?.anyOf, match => {
          if (match.id !== groupId) return match;
          return {
            id: groupId,
            allOf: match.allOf.filter(filter => filter.type !== fieldId),
          };
        }),
      },
    });
  };

  const removeIntersection = (groupId: string) => {
    updateRule({
      ...rule,
      locationsMatch: {
        anyOf: _.filter(rule.locationsMatch?.anyOf, fg => fg.id !== groupId),
      },
    });
  };

  const isLoading = checkResultStatus === AsyncLoadStatus.Loading;

  const totalLocationCount =
    checkResult?.fallbackLocationFilterMatch?.exclusive ?? 0;
  const totalProductCount =
    checkResult?.fallbackItemFilterMatch?.exclusive ?? 0;

  const hasIssueLocations =
    checkResult?.fallbackLocationFilterMatch?.exclusive === 0;
  const hasIssueProducts =
    checkResult?.fallbackItemFilterMatch?.exclusive === 0;
  const hasIssue = hasIssueLocations || hasIssueProducts;

  const isActive = selectedIdentity?.ruleId === rule.id;

  const isSelectedItemsMode =
    isActive && selectedIdentity?.filterType === 'items-compliance-mode';

  const isSelectedLocationsMode =
    isActive && selectedIdentity?.filterType === 'locations-compliance-mode';

  const containerParams: RuleContainerProps = {
    id: rule.id,
    title: rule.title,
    isCollapsible: true,
    isRemovable: false,
    isActive,
    isDisabled,
    canRename: false,
    namedColorKey: rule.title,
    hasColorMode: false,
    hasIcon: true,
  };

  const isSelectedLocation =
    isActive && selectedIdentity?.filterType === 'locations';
  const isSelectedItems = isActive && selectedIdentity?.filterType === 'items';

  return (
    <RuleContainer
      dataComponent="AssignmentPolicyFallbackRule"
      {...containerParams}
      classNameInner={classNames(
        isActive ? '' : 'bg-sidebar-header/70',
        'p-1 xl:p-2',
      )}
    >
      <PolicyFilterTag className={'space-y-3'}>
        <RuleSectionContainer>
          <PolicyFilter
            label={t`Items Compliance Mode`}
            isActive={isSelectedItemsMode}
            isDisabled={isDisabled}
            onClick={() => {
              setSelectedIdentity(
                isSelectedItemsMode
                  ? null
                  : {
                      ruleId: rule.id,
                      filterType: 'items-compliance-mode',
                    },
              );
            }}
          >
            <PolicyFilterItem
              // name={t`Mode`}
              value={
                policy.fallbackItemsAllowAnyLocations
                  ? t`Ignore Complinace`
                  : t`Enforce (default)`
              }
              isActive={isSelectedItemsMode}
              isDisabled={isDisabled}
              isRemovable={false}
            />
          </PolicyFilter>
          <PolicyStatContainer>
            <Stat
              title={t`Items (Catch all)`}
              value={
                hasIssueProducts ? t`None` : formatInteger(totalProductCount)
              }
              toggleTable
              inPanelMode
              isActionable
              inFilterStat
              hasHelper
              transparent={!isSelectedItems}
              isCheckLoading={isLoading}
              isSelected={isSelectedItems}
              isLoading={isLoading}
              onClick={() => {
                setSelectedIdentity(
                  isSelectedItems
                    ? null
                    : {
                        ruleId: rule.id,
                        filterType: 'items',
                      },
                );
              }}
            />
          </PolicyStatContainer>
        </RuleSectionContainer>

        <RuleSectionContainer
          isSelected={isSelectedLocation}
          namedColorKey={isSelectedLocation ? rule.title : null}
        >
          <PolicyFilter
            label={t`Locations Compliance Mode`}
            isActive={isSelectedLocationsMode}
            isDisabled={isDisabled}
            onClick={() => {
              setSelectedIdentity(
                isSelectedLocationsMode
                  ? null
                  : {
                      ruleId: rule.id,
                      filterType: 'locations-compliance-mode',
                    },
              );
            }}
          >
            <PolicyFilterItem
              // name={t`Mode`}
              value={
                policy.fallbackLocationsAllowAnyItems
                  ? t`Ignore Complinace`
                  : t`Enforce (default)`
              }
              isActive={isSelectedLocationsMode}
              isDisabled={isDisabled}
              isRemovable={false}
            />
          </PolicyFilter>
          <PolicyStatContainer>
            <Stat
              title={t`Locations (Catch all)`}
              value={
                hasIssueLocations ? t`None` : formatInteger(totalLocationCount)
              }
              toggleTable
              inPanelMode
              isActionable
              inFilterStat
              hasHelper
              transparent={!isSelectedLocation}
              isCheckLoading={isLoading}
              isSelected={isSelectedLocation}
              isLoading={isLoading}
              onClick={() => {
                setShowDatasetTable(
                  isSelectedLocation ? null : 'locations-stats-by-rule',
                );
                setSelectedIdentity(
                  isSelectedLocation
                    ? null
                    : {
                        ruleId: rule.id,
                        filterType: 'locations',
                      },
                );
              }}
            />
          </PolicyStatContainer>

          <PolicyFilterGroupList
            isDisabled={isDisabled}
            filterSets={_.map(rule.locationsMatch?.anyOf, filterGroup => ({
              id: filterGroup.id,
              allOf: [...filterGroup.allOf],
            }))}
            config={getLocationFilterConfigCommon(t)}
            selectedId={selectedIdentity?.locationFilterId}
            onAddGroup={addLocationIntersection}
            onDeleteField={removeField}
            onSelectGroup={selectLocationFilterIntersection}
            onDeleteGroup={removeIntersection}
          />
        </RuleSectionContainer>
      </PolicyFilterTag>
    </RuleContainer>
  );
};
export default AssignmentPolicyFallbackRule;
