import {
  LayoutBayLocationFragment,
  MeasurementSystem,
} from '@warebee/frontend/data-access-api-graphql';
import Konva from 'konva';
import _ from 'lodash';
import React, { useState } from 'react';
import { Circle, Rect, RegularPolygon, Text } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { TwTheme } from '../../../Tw';
import { analyticsAgent } from '../../AnalyticTracker';
import {
  getBestContrastColor,
  getColorDarker,
} from '../../common/color.helper';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import AnimatedBorderFeature from './AnimatedBorderFeature';
import StaticBorderFeature from './StaticBorderFeature';

const locationColors = TwTheme.extend.colors.location;

export type BayLocationFeatureProps = {
  location: LayoutBayLocationFragment;
  positionX: number;
  positionY: number;
  isSelected: boolean;
  setSelected?;
  onMouseOver?;
  onMouseOut?;
  color?: [string, string];
  gradientColors?: string[];
  inaccessibleFront?: boolean;
  label?: string;
  labelColor?: [string, string];
  isCompliance?: boolean;
};

const BayLocationFeature: React.FC<BayLocationFeatureProps> = props => {
  const { location } = props;
  const [hovered, setHovered] = useState(false);
  const measureSystem = useRecoilValue(warehouseMeasurementSystem);

  function onLocationClick(evt: Konva.KonvaEventObject<MouseEvent>) {
    if (!props.isSelected) {
      analyticsAgent?.track('Bay: Selected (Click)', {
        id: props.location.locationId,
        viewer: 'bay',
      });
    }
    props.setSelected && props.setSelected(!props.isSelected);
  }

  let fillColor = props.color?.[0] ?? locationColors.DEFAULT;

  if (!location.locationStatus) {
    fillColor = getColorDarker(fillColor);
  }

  const titleColorLocation = getBestContrastColor(fillColor);

  let colorBase = locationColors.DEFAULT; // lowest priority
  if (props.inaccessibleFront) {
    colorBase = locationColors.inaccessible;
  }
  if (location.locationStatus === false) {
    colorBase = locationColors.disabled;
  }

  let gradientConfig: Partial<Konva.RectConfig> = {};
  if (!_.isEmpty(props.gradientColors)) {
    const total = _.size(props.gradientColors);
    const stopColors = _(props.gradientColors)
      .map((c, index) => [index / total, c, (index + 1) / total, c])
      .flatten()
      .value();
    gradientConfig = {
      fillLinearGradientStartPoint: {
        x: 0,
        y: 0,
      },
      fillLinearGradientEndPoint: {
        x: location.locationWidth,
        y: location.locationHeight,
      },
      fillLinearGradientColorStops: stopColors,
    };
  }

  const config: Konva.RectConfig = {
    ...gradientConfig,
    id: location.locationId,
    x: props.positionX,
    y: props.positionY,
    width: location.locationWidth,
    height: location.locationHeight,
    draggable: false,
    fill: _.isNil(props.gradientColors) ? props.color?.[0] ?? colorBase : null,
    strokeWidth: 1,
    perfectDrawEnabled: false,
    opacity: !_.isNil(props.color) || location.locationStatus ? 1 : 0.5,
  };

  const points = [
    props.positionX,
    props.positionY,
    props.positionX,
    props.positionY + location.locationHeight,
    props.positionX + location.locationWidth,
    props.positionY + location.locationHeight,
    props.positionX + location.locationWidth,
    props.positionY,
  ];

  // const locationShape = getBox(location.shape);

  const titleFontSize =
    location.locationWidth < 30
      ? 3
      : location.locationWidth < 50
        ? 5
        : location.locationWidth < 100
          ? 10
          : location.locationWidth < 150
            ? 15
            : location.locationWidth < 200
              ? 20
              : 10;

  const scaleOffsetFactorY =
    measureSystem === MeasurementSystem.IMPERIAL ? 10 : 20;
  const scaleOffsetFactorX =
    measureSystem === MeasurementSystem.IMPERIAL ? -2 : -5;

  const showLabels = !_.isNil(props.label);
  const centerX = props.positionX + location.locationWidth / 2;
  const centerY = props.positionY + location.locationHeight / 2;

  const labelBackgroundColor = props.labelColor?.[0] ?? 'rgba(0,0,0,0.7)';
  const labelFontColor = props.labelColor?.[1] ?? 'rgba(255,255,255,1)';

  const labelSizeRadius =
    location.locationWidth < 24
      ? 8
      : location.locationWidth < 79
        ? 12
        : location.locationWidth > 1000 // Huge Locations
          ? 55
          : 25;

  const labelFontSize =
    location.locationWidth < 24
      ? 8
      : location.locationWidth < 79
        ? 12
        : location.locationWidth > 1000 // Huge Locations
          ? 55
          : 26;
  return (
    <>
      <Rect
        {...config}
        onTap={onLocationClick}
        onClick={onLocationClick}
        onMouseEnter={e => {
          const container = e.target.getStage().container();
          container.style.cursor = 'pointer';
          setHovered(true);
          props.onMouseOver && props.onMouseOver();
        }}
        onMouseLeave={e => {
          const container = e.target.getStage().container();
          container.style.cursor = 'default';
          setHovered(false);
          props.onMouseOut && props.onMouseOut();
        }}
      />
      <Text
        x={props.positionX}
        y={props.positionY}
        width={location.locationWidth}
        height={location.locationHeight}
        fontSize={titleFontSize}
        align="left"
        // verticalAlign="top"
        offsetY={scaleOffsetFactorY}
        offsetX={scaleOffsetFactorX}
        // padding={2}
        // margin={1}
        fill={titleColorLocation}
        scaleY={-1}
        text={location.locationId}
        fontFamily={'NeoSans-Regular'}
        listening={false}
        strokeEnabled={false}
      />
      {hovered && <StaticBorderFeature points={points} />}
      {props.isSelected && <AnimatedBorderFeature points={points} />}
      {showLabels && (
        <>
          {props.isCompliance ? (
            <RegularPolygon
              x={centerX}
              y={centerY}
              fill={labelBackgroundColor}
              stroke={'#000000'}
              strokeWidth={6}
              radius={labelSizeRadius}
              sides={3}
              rotation={180}
              listening={false}
            />
          ) : (
            <Circle
              x={centerX}
              y={centerY}
              radius={labelSizeRadius}
              fill={labelBackgroundColor}
              listening={false}
            />
          )}

          <Text
            x={props.positionX}
            y={props.positionY}
            width={location.locationWidth}
            height={-location.locationHeight}
            fontSize={labelFontSize}
            align="center"
            verticalAlign="middle"
            fill={labelFontColor}
            scaleY={-1}
            text={props.label}
            listening={false}
            strokeEnabled={false}
          />
        </>
      )}
    </>
  );
};

export default BayLocationFeature; //React.memo(BayLocationFeature);
