import { PicklistLinesSortDirection } from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InboxZero from '../../components/InboxZero';
import { Button } from '../../components/actions/Button';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import { ActionBar } from '../../components/nav/ActionBar';
import OrderedItemsTable from '../../import/layout/converter/panels/OrderedItemsTable';

const menuOptions = [
  //'Sort (ASC)',
  //'Sort (DESC)',
  'Delete',
] as const;
type PopupOptionsTuple = typeof menuOptions;
export type MenuOption = PopupOptionsTuple[number];
export type MenuAction = 'SORT' | 'DELETE';

function getActionTitle(action: MenuAction, t: TFunction<'components'>) {
  switch (action) {
    case 'SORT':
      return t`Sort (ASC)`;
    case 'DELETE':
      return t`Delete`;
  }
  return action;
}

export type OrderedListItem<T extends string> = {
  type: T;
  direction?: 'ASC' | 'DESC' | 'DEFAULT';
};

export type OrderedListProps<T extends string> = {
  current: OrderedListItem<T>[];
  options: Record<T, string>;
  onChange: (value: OrderedListItem<T>[]) => void;
};

function OrderedList<T extends string>(props: OrderedListProps<T>) {
  const { t } = useTranslation('app');
  const { current } = props;
  const [selectedType, setSelectedType] = useState<T>();
  const usedForSort = _.keyBy(current, s => s.type);
  const availableOptions = _.filter(
    _.keys(props.options) as T[],
    type => !_.has(usedForSort, type),
  );

  useEffect(() => {
    setSelectedType(_.head(availableOptions));
  }, [current]);

  function addSortOption(type: T) {
    const newSorting: OrderedListItem<T>[] = [
      ...current,
      {
        type,
        direction: PicklistLinesSortDirection.ASC,
      },
    ];
    props.onChange(newSorting);
  }

  function removeSortOption(type: T) {
    const newSorting: OrderedListItem<T>[] = _.filter(
      current,
      so => so.type !== type,
    );
    props.onChange(newSorting);
  }

  //   function updateSortOption(
  //     type: PicklistLinesOrderOptions,
  //     direction,
  //     nullsFirst,
  //   ) {
  //     const newSorting: PicklistLinesSortByFragment[] = _.map(current, so =>
  //       so.type !== type
  //         ? so
  //         : {
  //             ...so,
  //             direction: _.isNil(direction) ? so.direction : direction,
  //             nullsFirst: _.isNil(nullsFirst) ? so.nullsFirst : nullsFirst,
  //           },
  //     );
  //     saveSorting(newSorting);
  //   }

  function onSortChange(newSortedTypes: T[]) {
    props.onChange(_.map(newSortedTypes, t => usedForSort[t]));
  }

  //   function SortIcon({ type }: { type: PicklistLinesOrderOptions }) {
  //     const value = usedForSort[type]?.direction;

  //     function onClick() {
  //       const newValue =
  //         value === PicklistLinesSortDirection.ASC
  //           ? PicklistLinesSortDirection.DESC
  //           : PicklistLinesSortDirection.ASC;
  //       updateSortOption(type, newValue, null);
  //     }

  //     const IconCpm = props => {
  //       switch (value) {
  //         case PicklistLinesSortDirection.ASC:
  //           return <Icon.SortAscending className="text-menu-text" {...props} />;

  //         case PicklistLinesSortDirection.DESC:
  //           return <Icon.SortDescending className="text-menu-text" {...props} />;

  //         default:
  //           return <Icon.SortNone className="text-menu-400" {...props} />;
  //       }
  //     };

  //     return (
  //       <div
  //         data-component="SortIcon"
  //         className={classNames(
  //           `flex cursor-pointer p-1 hover:text-white ltr:ml-2 rtl:mr-2`,
  //         )}
  //         onClick={onClick}
  //       >
  //         <IconCpm className={`h-5 w-5 fill-current`} />
  //       </div>
  //     );
  //   }

  const drawItem = (type: T) => {
    return (
      <div className="flex flex-1 items-center">
        <div className="flex-1 truncate text-xs xl:text-sm">
          {props.options[type]}
        </div>
        {/* <SortIcon type={type} /> */}
        <DropdownSelector
          DropAlignRight
          buttonTransparent
          vertical
          value={'...'}
          values={[...menuOptions]}
          onChange={(option: MenuOption) => {
            switch (option) {
              // case 'Sort':
              //   // updateSortOption(type);
              //   break;
              case 'Delete':
                removeSortOption(type);
                break;
            }
          }}
        />

        {/* <Button
            onPress={() => removeSortOption(type)}
            hasIconAfter
            buttonIcon={<Icon.CircleX className={`w-5 h-5 fill-current`} />}
            buttonType="transparent"
            buttonSize="sm"
            // buttonType="delete"
          /> */}
      </div>
    );
  };

  return (
    <div data-component="OrderedList" className="min-h-5">
      <div className={`bg-app-panel-dark/60 flex flex-col p-1`}>
        {!_.isEmpty(availableOptions) && (
          <ActionBar className="px-1 py-2">
            <DropdownSelector
              classNameDropdown="flex-1 text-sm mr-2"
              classNameLabel="text-xs"
              classNameItem="text-sm"
              dark
              DropAlignRight
              // panelMode
              widthFull
              // buttonTransparent
              value={selectedType}
              renderValue={v => props.options[v]}
              values={availableOptions}
              onChange={(v: T) => setSelectedType(v)}
            />
            <Button
              className="rounded"
              buttonType="primary"
              buttonSize="xs"
              hasIconAfter
              buttonIcon={<Icon.Plus className={`h-4 w-4 fill-current`} />}
              // label={t`Add`}
              onPress={() => addSortOption(selectedType)}
            />
          </ActionBar>
        )}

        <div className={`relative flex h-full min-h-[3rem] flex-1 flex-col`}>
          {!_.isEmpty(current) && (
            <OrderedItemsTable
              // itemColumnTitle={t`Sort by`}
              items={_.map(current, i => i.type)}
              onChange={onSortChange}
              render={(v: T) => drawItem(v)}
            />
          )}

          {_.isEmpty(current) && (
            <InboxZero message={t`No custom order is set.`} />
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderedList;
