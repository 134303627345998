import {
  CreateLayoutImportLocationInput,
  ImportItemSetLineInput,
} from '@warebee/shared/data-access-api-dto';
import { ImportJobImportType } from '@warebee/shared/engine-model';
import { MappingSchema } from './mapping';
import {
  ACTIVITY_FEED_MAPPING_SCHEMA,
  ActivityFeedEvent,
} from './schema/activityFeedSchema';
import { AnalyzedJobEvent } from './schema/analyzed-job-event.schema';
import { ASSIGNMENT_POLICY_MAPPING_SCHEMA } from './schema/assignmentPolicySchema';
import { ASSIGNMENT_MAPPING_SCHEMA } from './schema/assignmentSchema';
import { ITEM_SET_MAPPING_SCHEMA } from './schema/itemSetSchema';
import { LayoutLocationAthena } from './schema/layout.schema';
import { LAYOUT_IMPORT_MAPPING_SCHEMA } from './schema/layoutImportSchema';
import { ORDER_SET_MAPPING_SCHEMA } from './schema/orderSetSchema';

export type DatasetObject<T extends Object> = T & {
  datasetObjectId: string;
  importJobId: string;
};

export type ActivityFeedEventDataset = DatasetObject<ActivityFeedEvent>;
export type ItemsDataset = DatasetObject<ImportItemSetLineInput>;
export type LayoutImportDataset =
  DatasetObject<CreateLayoutImportLocationInput>;
export type AnalyzedEventsDataset = AnalyzedJobEvent;
export type LayoutLocationDataset = DatasetObject<LayoutLocationAthena>;

export const DATASET_OBJECT_SCHEMA_BY_TYPE: Partial<
  Record<ImportJobImportType, MappingSchema<any>>
> = {
  [ImportJobImportType.ACTIVITY_FEED]: ACTIVITY_FEED_MAPPING_SCHEMA,
  [ImportJobImportType.LAYOUT]: LAYOUT_IMPORT_MAPPING_SCHEMA,
  [ImportJobImportType.ASSIGNMENT]: ASSIGNMENT_MAPPING_SCHEMA,
  [ImportJobImportType.ITEM_SET]: ITEM_SET_MAPPING_SCHEMA,
  [ImportJobImportType.ORDER_SET]: ORDER_SET_MAPPING_SCHEMA,
  [ImportJobImportType.ASSIGNMENT_POLICY]: ASSIGNMENT_POLICY_MAPPING_SCHEMA,
};
