import {
  OrderLineFilterType,
  PickingWavePickByDateMode,
  useFindOrderLineFilterValuesQuery,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toDateFromLocaleStringTime } from '../../common/dateTimeHelper';
import { formatTime } from '../../common/formatHelper';
import { Button } from '../../components/actions/Button';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import * as Icon from '../../components/icons';
import InputTime from '../../components/inputs/calendar/InputTime';
import TitleSection from '../../components/layout/TitleSection';
import { ActionBar } from '../../components/nav/ActionBar';
import { Table } from '../../components/table/Table';
import { TableBody } from '../../components/table/TableBody';
import { TableHead } from '../../components/table/TableHead';
import { TableRow } from '../../components/table/TableRow';
import { TableTd } from '../../components/table/TableTd';
import { TableTh } from '../../components/table/TableTh';
import Callout from '../../helpContext/Callout';
import { pickingRequirementDefault } from '../../simulation/store/pickingPolicy/pickingPolicy.default';
import { getWaveByDateDescriptors } from '../../simulation/store/pickingPolicy/pickingPolicy.helper';
import {
  pickingPolicyRule,
  pickingPolicySelectedIdentity,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';
import { simulationCurrentId } from '../../simulation/store/simulation.state';

const PickingPolicyWaveByDateEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const simId = useRecoilValue(simulationCurrentId);
  const selectedIdentity = useRecoilValue(pickingPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    pickingPolicyRule(selectedIdentity?.ruleId),
  );
  const [time, setTime] = useState('12:00');
  const { data, loading } = useFindOrderLineFilterValuesQuery({
    variables: {
      input: {
        simulationId: simId,
        filterType: OrderLineFilterType.ORDER_TIME_MATCH,
      },
      page: null,
    },
  });
  if (!selectedIdentity || !rule) {
    console.warn('PickingPolicyWaveByDateEditor should not render ');
    return null;
  }

  const wave = rule?.pickingRequirementsSettings?.wave;

  const byDateDescriptors = getWaveByDateDescriptors(t);
  const selectedMode =
    wave?.pickByDateSettings?.mode ?? PickingWavePickByDateMode.DATE;

  const selectedIndex = _.findIndex(
    byDateDescriptors,
    o => o.id === selectedMode,
  );

  const fixedDeadlines = _.sortBy(
    wave?.pickByDateSettings?.fixedDeadlines ?? [],
  ) as string[];

  const currentOrderTimes = _(
    data?.findSimulationOrderLineFilterValues?.content,
  )
    .map(f => f.title)
    .value();

  function addFixedTime(timeString: string, isDelete = false) {
    const newFixedDeadlines = _.filter(fixedDeadlines, v => v !== timeString);
    if (!isDelete) {
      newFixedDeadlines.push(timeString);
    }

    updateRule({
      ...rule,
      pickingRequirementsSettings: {
        ...(rule.pickingRequirementsSettings ?? pickingRequirementDefault),
        wave: {
          ...(rule.pickingRequirementsSettings?.wave ?? {}),
          pickByDateSettings: {
            mode: PickingWavePickByDateMode.FIXED_FRAMES,
            fixedDeadlines: newFixedDeadlines,
          },
        },
      },
    });
  }

  function selectDeliveryOption(index: number) {
    const option = byDateDescriptors[index];
    updateRule({
      ...rule,
      pickingRequirementsSettings: {
        ...(rule.pickingRequirementsSettings ?? pickingRequirementDefault),
        wave: {
          ...(rule.pickingRequirementsSettings?.wave ?? {}),
          pickByDateSettings: {
            ...(rule.pickingRequirementsSettings?.wave?.pickByDateSettings ??
              {}),
            mode: option.id,
          },
        },
      },
    });
  }

  return (
    <div data-component="PickingPolicyDeliveryScopeEditor">
      <TitleSection
        id={`policy-editor-wave-pick-by`}
        title={t`Pick By Time`}
        inSidebarView
        hasScreenTitle
      >
        <ActionBar>
          <ButtonSwitchMulti
            buttonType="minimal"
            autoSize
            className="text-xs mx-2 my-2 flex-1"
            selectedIndex={selectedIndex}
            onClick={selectDeliveryOption}
            options={_.map(byDateDescriptors, o => ({ ...o, label: o.title }))}
          />
        </ActionBar>

        {selectedMode === PickingWavePickByDateMode.DATE && (
          <Callout panelMode type="suggestion">
            {t`Order date will be used as by End-of-the-Day`}
          </Callout>
        )}

        {selectedMode === PickingWavePickByDateMode.DATETIME && (
          <>
            <Callout panelMode type="suggestion">
              {t`Order Date will be used for Wave picking.`}
            </Callout>

            <ul className="my-4 divide-y divide-solid divide-menu-400/50 p-default">
              {_.map(currentOrderTimes, v => (
                <li key={v}>{v}</li>
              ))}
            </ul>
          </>
        )}

        {selectedMode === PickingWavePickByDateMode.FIXED_FRAMES && (
          <>
            <Callout panelMode type="suggestion">
              {t`Manually set the Order Date for Wave picking.`}
            </Callout>
            <ActionBar className="p-1">
              <InputTime value={time} onChange={setTime} />
              <Button
                label={t`Add`}
                buttonType="primary"
                buttonSize="xxs"
                className="rounded"
                hasIconBefore
                buttonIcon={
                  <Icon.CirclePlus className={`w-4 h-4 fill-current`} />
                }
                onPress={() => addFixedTime(time)}
              />
            </ActionBar>

            <Table isSticky isSortable isHoverable>
              <TableHead>
                <TableRow>
                  <TableTh isWide value={t`Time`} selectable={true} />
                  <TableTh />
                </TableRow>
              </TableHead>

              <TableBody>
                {_.map(fixedDeadlines, v => {
                  const dateTimeObject = toDateFromLocaleStringTime(v);
                  return (
                    <TableRow key={`row-${v}`} rowLineList rowHover>
                      <TableTd>{formatTime(dateTimeObject)}</TableTd>
                      <TableTd>
                        <Button
                          label={t`Delete`}
                          buttonSize="xs"
                          buttonType={'delete'}
                          hasIconAfter
                          buttonIcon={
                            <Icon.CircleX className={`w-5 h-5 fill-current`} />
                          }
                          onPress={() => addFixedTime(v, true)}
                        />
                      </TableTd>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        )}
      </TitleSection>
    </div>
  );
};

export default PickingPolicyWaveByDateEditor;
