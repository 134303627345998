import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../common/formatHelper';
import classNames from 'classnames';

export type ImportFileFooterStatsProps = {
  children?: React.ReactNode;
  className?: string;
  importSource?: string;
  dataSource?: string;
  importTime?: number;
  createdDate?: number;
  updatedDate?: number;
};

export const ImportFileFooterStats = ({
  className,
  children,
  importSource,
  dataSource,
  importTime,
  createdDate,
  updatedDate,
}: ImportFileFooterStatsProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('designer');

  return (
    <div
      aria-label="ImportFileFooterStats"
      id={id}
      className={classNames(
        'w-full p-2 text-xs text-start text-menu-text/50',
        className,
      )}
    >
      {importTime && (
        <div className="mt-2">
          <span className={classNames('text-menu-text/75 text-bold')}>
            {importSource || ''}
          </span>{' '}
          <span>{t`imported in`} </span>
          <span className={classNames('text-menu-text/75 text-bold')}>
            {formatDateTime(new Date(importTime))}
          </span>
        </div>
      )}

      {createdDate && (
        <div className="mt-2">
          <span>{t`Created`}</span>
          <span>{': '}</span>
          <span className={classNames('text-menu-text/75 text-bold')}>
            {formatDateTime(new Date(createdDate))}
          </span>
        </div>
      )}

      {updatedDate && (
        <div className="mt-2">
          <span>{t`Updated`}</span>
          <span>{': '}</span>
          <span className={classNames('text-menu-text/75 text-bold')}>
            {formatDateTime(new Date(updatedDate))}
          </span>
        </div>
      )}

      {importSource && (
        <div
          title={importSource}
          className={classNames(
            'mt-4 pt-4 border-t border-menu/50 truncate select-text',
          )}
        >
          <span>{t`Source`}</span>
          <span>{': '}</span>
          <span className={classNames('text-menu-text/75 text-bold')}>
            {importSource}
          </span>
        </div>
      )}

      {dataSource && (
        <div
          title={dataSource}
          className={classNames(
            'mt-4 pt-4 border-t border-menu/50 truncate select-text',
          )}
        >
          <span>{t`From`}</span>
          <span>{': '}</span>
          <span className={classNames('text-menu-text/75 text-bold')}>
            {dataSource}
          </span>
        </div>
      )}

      {children}
    </div>
  );
};
