import { ApolloQueryResult } from '@apollo/client';
import { BillableOperationType } from '@warebee/frontend/app-billing-graphql-api';
import {
  Action,
  AssignmentMetaFragment,
  ItemSetMetaFragment,
  LayoutMetaFragment,
  LoadCurrentUserBaseSettingDocument,
  LoadCurrentUserBaseSettingQuery,
  MeasurementSystem,
  OrderSetMetaFragment,
  UserSettingsBaseFragment,
  UserSettingsFragment,
  WarehouseMetaFragment,
  WarehouseType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { secureClient } from '../GraphQLClient';
import { appBillingWarehouseState } from '../appBilling/store/appBilling.state';
import { AsyncLoadStatus } from '../common/types';
import { loggedInUser } from '../store/auth.state';
import { LoadableCollection } from './warehouse.types';

const getKey = (postfix: string) => `warebee-warehouse-${postfix}`;

const warehouseSelectedIdByUser = atom<Record<string, string>>({
  key: getKey('selected-id-all'),
  default: null,
  effects: [
    recoilPersist({
      key: getKey('selected-id-all'),
      storage: localStorage,
    }).persistAtom,
  ],
});

export const warehouseSelectedId = selector<string>({
  key: getKey('selected-id'),
  get: ({ get }) => {
    const user = get(loggedInUser);
    if (!user) return null;
    const all = get(warehouseSelectedIdByUser);
    return all?.[user.email];
  },

  set: ({ get, set }, value: string) => {
    const user = get(loggedInUser);
    if (!user) return null;
    const all = get(warehouseSelectedIdByUser);
    set(warehouseSelectedIdByUser, { ...all, [user.email]: value });
  },
});

export const warehouseList = atom<WarehouseMetaFragment[]>({
  key: getKey('all-meta'),
  default: null,
});

export const warehouseListLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('list-load-status'),
  default: AsyncLoadStatus.None,
});

export const warehouseSelected = selector<WarehouseMetaFragment>({
  key: getKey('selected-meta'),
  get: ({ get }) => {
    const selectedId = get(warehouseSelectedId);
    const list = get(warehouseList);
    return _.find(list, wh => wh.id === selectedId);
  },
  set: ({ get, set }, value) => {
    const selectedId = get(warehouseSelectedId);
    const list = get(warehouseList);
    set(
      warehouseList,
      _.map(list, w => (w.id === selectedId ? value : w)),
    );
  },
});

export const warehouseUpdateLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('update-load-status'),
  default: AsyncLoadStatus.None,
});

export const warehouseMeasurementSystem = selector<MeasurementSystem>({
  key: getKey('selected-measurement-system'),
  get: ({ get }) => {
    return get(warehouseSelected)?.measurementSystem;
  },
});

// export const warehouseCurrencySystem = selector<CurrencySystem>({
//   key: getKey('selected-currency-system'),
//   get: ({ get }) => {
//     return get(warehouseSelected)?.currency;
//   },
// });

export const warehouseCanUpdate = selector<boolean>({
  key: getKey('is-can-update'),
  get: ({ get }) => {
    return _.includes(
      get(warehouseSelected)?.permissions?.allowed,
      Action.UPDATE,
    );
  },
});

export const warehouseIsDemo = selector<boolean>({
  key: getKey('is-is-demo'),
  get: ({ get }) => get(warehouseSelected)?.type === WarehouseType.DEMO,
});

export const warehouseAllAssignmentsMeta = atom<
  LoadableCollection<AssignmentMetaFragment>
>({
  key: getKey('all-assignments-meta'),
  default: {
    status: AsyncLoadStatus.None,
    items: [],
  },
});

export const warehouseAllLayoutsMeta = atom<
  LoadableCollection<LayoutMetaFragment>
>({
  key: getKey('all-layouts-meta'),
  default: {
    status: AsyncLoadStatus.None,
    items: [],
  },
});

export const warehouseAllOrderSetsMeta = atom<
  LoadableCollection<OrderSetMetaFragment>
>({
  key: getKey('all-order-set-meta'),
  default: {
    status: AsyncLoadStatus.None,
    items: [],
  },
});

export const warehouseAllItemSetsMeta = atom<
  LoadableCollection<ItemSetMetaFragment>
>({
  key: getKey('all-item-set-meta'),
  default: {
    status: AsyncLoadStatus.None,
    items: [],
  },
});

export const userSettingsBase = selector<UserSettingsBaseFragment>({
  key: getKey('user-settings-base'),
  get: async ({ get }) => {
    const user = get(loggedInUser);
    if (!user) return null;

    let response: ApolloQueryResult<LoadCurrentUserBaseSettingQuery>;
    try {
      response = await secureClient.query<LoadCurrentUserBaseSettingQuery>({
        query: LoadCurrentUserBaseSettingDocument,
      });
    } catch (ex) {
      console.error(ex);
      throw new Error('Cannot load user settings');
    }

    if (response.errors) {
      console.error(response.errors);
      throw new Error('Cannot get user settings');
    }
    return response.data.currentUserSettings;
  },
});

export const userSettings = atom<UserSettingsFragment>({
  key: getKey('user-settings'),
  default: null,
});

export const userSettingsLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('user-settings-load-status'),
  default: AsyncLoadStatus.None,
});

export const userHasAnalyse = selector<boolean>({
  key: getKey('user-has-analyse'),
  get: ({ get }) => {
    const billingState = get(appBillingWarehouseState);
    return _.includes(
      billingState?.allowedOperations,
      BillableOperationType.ANALYZE,
    );
  },
});

export const userHasOptimise = selector<boolean>({
  key: getKey('user-has-optimise'),
  get: ({ get }) => {
    const billingState = get(appBillingWarehouseState);
    return _.includes(
      billingState?.allowedOperations,
      BillableOperationType.OPTIMIZE,
    );
  },
});

export const warehouseDataDashPath = selector<string>({
  key: getKey('data-dashboardPath'),
  get: ({ get }) => `/wh/i/${get(warehouseSelectedId)}/data/`,
});

export const warehouseShowSmallHeader = atom<boolean>({
  key: getKey('show-small-header'),
  default: false,
});

// Warehouse routes
export const routeIds = ['simulations', 'feed'];
export type RouteId = (typeof routeIds)[number];

export const warehouseDefaultRoute = selector<string>({
  key: getKey('default-route'),
  get: ({ get }) => {
    const userData = get(userSettings)?.featureFlags?.defaultService;
    switch (userData) {
      case 'actuality':
        return 'feed';
      default:
        return 'simulations';
    }
  },
});

export const applyWarehouseRoutePreference = (route: RouteId) => {
  const root = window.document.documentElement;
  _.map(routeIds, id => root.classList.remove(id));
  !_.isNil(route) && root.classList.add(route);
};
