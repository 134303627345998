import { InferResult } from 'kysely';
import _ from 'lodash';
import { ActivityFeedQueryBuilderParams } from './queryBuilder/feedEventsQueryBuilders';
import { getActivityFeedJobsBuilder } from './queryBuilder/feedJobsQueryBuilders';
import { postProcessDefault } from './queryBuilder/feedQueryBuilder';

export type FeedPicklists = InferResult<
  ReturnType<typeof getFeedPicklistQuery>
>;
export type FeedPicklist = FeedPicklists[number];

export function getFeedPicklistQuery(params: ActivityFeedQueryBuilderParams) {
  const builder = getActivityFeedJobsBuilder(params)
    .selectFrom('afj')
    .select([
      'jobKey',
      'jobId',
      'agentId',
      'agentType',
      'eventProcessType',
      'lines',
      'uomCount',
      'minEventTime',
      'minPreviousEndTime',
      'maxEventTime',
      'timeFramedEventsCount',
      'timeFramedUomCount',
    ]);
  return builder;
}

const isDateField = (f: keyof FeedPicklist): boolean => {
  const fName = _.camelCase(f);
  return (
    fName === 'minEventTime' ||
    fName === 'maxEventTime' ||
    fName === 'minPreviousEndTime'
  );
};

export function postProcessFeedPicklist(
  events: Record<string, any>[],
): FeedPicklists {
  return postProcessDefault<FeedPicklist>(events, isDateField);
}
