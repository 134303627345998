import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import {
  LoadLayoutViewDocument,
  LoadLayoutViewQuery,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import {
  viewerBayModels,
  viewerConnectivityGraph,
  viewerLayoutId,
  viewerLocationTypes,
  viewerPlanes,
  viewerSelectedAisleIdAtom,
  viewerSelectedBayIdAtom,
  viewerSelectedLayout,
  viewerSelectedLevel,
  viewerSelectedLevelInner,
  viewerSelectedLocationIdAtom,
  viewerSelectedPlaneIdAtom,
} from '../../layout/viewer/store/viewer.state';
import { errorAppender } from '../../store/error.state';
import { getLayoutPlanes } from '../store/layout.helper';
import {
  layoutDocument,
  layoutDocumentId,
  layoutDocumentLoadStatus,
} from '../store/layout.state';

export function useLoadLayout() {
  const client = useApolloClient();

  const cleanupState = useRecoilCallback(({ set, reset }) => async () => {
    set(viewerLayoutId, null);
    set(viewerSelectedLayout, null);
    set(layoutDocumentLoadStatus, AsyncLoadStatus.Loading);
    reset(viewerPlanes);
    reset(viewerBayModels);
    reset(viewerSelectedAisleIdAtom);
    reset(viewerSelectedBayIdAtom);
    reset(viewerSelectedLocationIdAtom);
    reset(viewerSelectedLevel);
    reset(viewerConnectivityGraph);
  });

  const loadViewerLayoutCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (layoutId: string) => {
        console.time('Load layout data');
        if (_.isNil(layoutId)) return;
        let layoutDataResponse: ApolloQueryResult<LoadLayoutViewQuery>;
        try {
          layoutDataResponse = await client.query<LoadLayoutViewQuery>({
            query: LoadLayoutViewDocument,
            variables: {
              id: layoutId,
            },
          });

          if (layoutDataResponse.error) {
            console.error(layoutDataResponse.error);
            set(errorAppender, {
              id: nanoid(),
              title: 'Cannot load layout',
              details: layoutDataResponse.error.message,
              callStack: layoutDataResponse.error.stack || null,
            });
            return;
          }
          const layoutData = layoutDataResponse.data.layout;
          console.timeEnd('Load layout data');

          console.time('Load layout postprocess');
          const bayTypesMap = _.keyBy(
            layoutData?.layoutMap?.bayTypes,
            bt => bt.bayType,
          );
          const locationTypes = _.keyBy(
            layoutData?.layoutMap?.locationTypes,
            type => type.locationRackingType,
          );

          const planes = getLayoutPlanes(layoutData?.planes);
          const defaultPlaneId = _.first(layoutData?.planes)?.id;
          const planeLevelsMap: Record<string, number[]> = _.reduce(
            layoutData.planes,
            (acc, pl) => ({
              ...acc,
              [pl.id]: pl.levels,
            }),
            {},
          );

          const defaultLevel =
            _(planeLevelsMap[defaultPlaneId]).sort().first() ?? 1;
          console.timeEnd('Load layout postprocess');

          // Set Data
          console.time('Load layout set state');

          set(viewerPlanes, planes);
          set(viewerBayModels, bayTypesMap);
          set(viewerLocationTypes, locationTypes);
          set(viewerSelectedLevelInner, defaultLevel);
          set(viewerConnectivityGraph, layoutData.connectivityGraph);
          set(viewerSelectedPlaneIdAtom, defaultPlaneId);

          // set viewer layout id after selectedPlane / selectedLevel
          // to prevent Recoil to update layout several times
          set(viewerLayoutId, layoutId);
          set(
            viewerSelectedLayout,
            _.omit(layoutData, ['layoutMap', 'connectivityGraph', 'planes']),
          );

          set(layoutDocumentId, layoutId);
          set(layoutDocument, layoutData);
          set(layoutDocumentLoadStatus, AsyncLoadStatus.Ok);
          console.timeEnd('Load layout set state');
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: 'Cannot load layout',
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          return;
        }
      },
  );

  const loadLayout = async (importLayoutId: string) => {
    await cleanupState();
    await loadViewerLayoutCallback(importLayoutId);
  };
  const cleanupLayout = cleanupState;

  return [loadLayout, cleanupLayout] as [
    (importLayoutId: string) => Promise<void>,
    () => Promise<void>,
  ];
}
