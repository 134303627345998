import { useUpdateWarehouseMutation } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import { errorAppender } from '../store/error.state';
import { warehouseSelected } from '../store/warehouse.state';
import { schemaVersionKey } from './store/import.default';
import { createMappingSettingMutableInput } from './store/import.helper';
import {
  importMappingSettingsByType,
  importSettingsAtomAll,
  importTypeCurrent,
  importUploadStatus,
} from './store/import.state';
import { MappingSettings } from './store/import.types';

export type ApplyMappingSettingsParams = {
  mappingSettings: MappingSettings<Object>;
  skipValidation?: boolean;
};

function useApplyMappingSettings() {
  const { t } = useTranslation('errors');
  const [updateWarehouse] = useUpdateWarehouseMutation();
  const errorTitleSave = t`Cannot save mapping settings`;

  const save = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (params: ApplyMappingSettingsParams) => {
        const type = await snapshot.getPromise(importTypeCurrent);
        const warehouse = await snapshot.getPromise(warehouseSelected);
        const settingsAll = await snapshot.getPromise(importSettingsAtomAll);

        if (_.isNil(type) || _.isNil(warehouse)) {
          console.log('Import type and warehouse id in not provided');
        }

        set(importMappingSettingsByType(type), params.mappingSettings);

        function handleError(details?: string, stack?: string) {
          console.error(errorTitleSave, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitleSave,
            details: details,
            callStack: stack,
          });
          set(importUploadStatus, AsyncLoadStatus.Error);
        }

        const whImportSettings = settingsAll[warehouse.id] ?? {};

        const extraSettings = {
          ...(warehouse.extraSettings ?? {}),
          importSettings: {
            ...(warehouse.extraSettings?.importSettings ?? {}),
            [schemaVersionKey]: {
              ...whImportSettings,
              [type]: createMappingSettingMutableInput(params.mappingSettings),
            },
          },
        };

        try {
          const { data: jobData, errors: updateErrors } = await updateWarehouse(
            {
              variables: {
                input: {
                  warehouseId: warehouse.id,
                  extraSettings,
                },
              },
            },
          );

          if (!_.isEmpty(updateErrors)) {
            const details = _(updateErrors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }
          // await useUpdateImportJob();
        } catch (ex) {
          handleError(ex?.message ?? ex);
        }
      },
  );

  return async (params: ApplyMappingSettingsParams) => {
    await save(params);
  };
}

export default useApplyMappingSettings;
