import { AssignmentOccupancySummaryFragment } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TwTheme } from '../../../../../Tw';
import { getBestContrastColor } from '../../../../common/color.helper';
import useFormatter from '../../../../common/useFormatter';
import { Stat } from '../../../../components/stats/Stat';
import { StatCenterPie } from '../../../../components/stats/StatCenterPie';
import { StatGroup } from '../../../../components/stats/StatGroup';
import { StatListItem } from '../../../../components/stats/StatListItem';
import LayoutStats from '../../../../layout/LayoutStats';
import ContainerChart from '../../ContainerChart';
import SimulationPieChart, { PieData } from '../../SimulationPieChart';

const colors = TwTheme.extend.colors.dimensionsColors;
const colorsOccupancy = TwTheme.extend.colors.categoryOccupancy;

export type OccupancySummaryPanelProps = {
  summary: AssignmentOccupancySummaryFragment;
  cardView?: boolean;
};

const OccupancySummaryPanel: React.FC<OccupancySummaryPanelProps> = props => {
  const formatter = useFormatter();
  const { t } = useTranslation('simulation');
  const { summary } = props;
  const maxOccupancy = summary.maxOccupancy;
  const totalVolume = formatter.formatVolume(summary.totalStorageVolume);
  const occupiedVolume = formatter.formatVolume(summary.totalOccupiedVolume);
  const unknownVolume = formatter.formatVolume(summary.totalUnknownVolume);
  const occupiedShare = formatter.formatShare(summary.totalOccupancy);
  const freeShare = formatter.formatShare(1 - summary.totalOccupancy);
  const freeVolume = formatter.formatVolume(
    summary.totalStorageVolume -
      (summary.totalOccupiedVolume ?? 0) -
      (summary.totalUnknownVolume ?? 0),
  );

  const chartData: PieData[] = [
    {
      id: 'occupied',
      label: t`Occupied Volume`,
      color: [colorsOccupancy[90], getBestContrastColor(colorsOccupancy[90])],
      value: occupiedVolume.raw,
      formatted: occupiedVolume,
    },
    {
      id: 'unknownVolume',
      label: t`Unknown Volume`,
      color: [
        colorsOccupancy['unknown'],
        getBestContrastColor(colorsOccupancy['unknown']),
      ],
      value: unknownVolume.raw,
      formatted: unknownVolume,
    },
    {
      id: 'free',
      label: t`Free Volume`,
      color: [colorsOccupancy[20], getBestContrastColor(colorsOccupancy[20])],
      value: freeVolume.raw,
      formatted: freeVolume,
    },
  ];

  return (
    <>
      <StatGroup
        data-component="OccupancySummaryPanel"
        title={t`Occupancy`}
        subTitle={t`Stats`}
        cardView={props.cardView}
        helpNavTo={'simulation/compliance/simulation-compliance-volume'}
      >
        <Stat
          cardView={props.cardView}
          hasHelper
          isPreview
          title={t`Storage Capacity`}
          inPanelMode
          value={totalVolume.value}
          unitOfMeasure={totalVolume.unit}
        >
          <ContainerChart>
            <StatCenterPie
              statLabelTop={t`Occupied`}
              statValueTop={occupiedShare.fullString}
              statLabelBottom={t`Free`}
              statValueBottom={freeShare.fullString}
            />
            <SimulationPieChart data={chartData} />
          </ContainerChart>

          {/* <StatListItem
          title={t`Total Volume`}
          value={totalVolume.value}
          unitOfMeasure={totalVolume.unit}
          isUnitPrefix={totalVolume.prefixUnit}
        /> */}
          <StatListItem
            title={t`Occupied Volume`}
            unitOfMeasure={occupiedVolume.unit}
            value={occupiedVolume.value}
            legendColor={colorsOccupancy[90]}
            legendType={'circle'}
          />
          <StatListItem
            title={t`Free Volume`}
            unitOfMeasure={occupiedVolume.unit}
            value={freeVolume.value}
            legendColor={colorsOccupancy[20]}
            legendType={'circle'}
          />
          <StatListItem
            title={t`Unknown Location Volume`}
            value={unknownVolume.value}
            unitOfMeasure={unknownVolume.unit}
            legendColor={colorsOccupancy['unknown']}
            legendType={'circle'}
          />
        </Stat>
      </StatGroup>
      <LayoutStats />
    </>
  );
};

export default OccupancySummaryPanel;
