import { LayoutImportConverterLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../../common/measureHelper';
import useFormatter from '../../../../common/useFormatter';
import InboxZero from '../../../../components/InboxZero';
import { WarehouseNavigator } from '../../../../components/WarehouseNavigator';
import { WarehouseNavigatorItem } from '../../../../components/WarehouseNavigatorItem';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import * as Icon from '../../../../components/icons';
import InputNumber from '../../../../components/inputs/InputNumber';
import { ContainerScroll } from '../../../../components/layout/ContainerScroll';
import TitleSection from '../../../../components/layout/TitleSection';
import { StatListItem } from '../../../../components/stats/StatListItem';
import PanelContainer from '../../../../containers/PanelContainer';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../../../containers/SidebarContainer';
import { warehouseSelected } from '../../../../store/warehouse.state';
import {
  converterEditableArea,
  converterSelectedArea,
} from '../store/converter.area.state';
import {
  converterLayoutSelectedBay,
  converterLayoutSelectedLocation,
} from '../store/converter.layout.state';
import { converterLocationPatchSetter } from '../store/converter.state';
import AreaSummary from './AreaSummary';

const ConverterLocationSidebarCmp: React.FC = () => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('designer');
  const formatter = useFormatter();

  const wh = useRecoilValue(warehouseSelected);

  const area = useRecoilValue(converterEditableArea);
  const location = useRecoilValue(converterLayoutSelectedLocation);
  const bay = useRecoilValue(converterLayoutSelectedBay);
  const setPatches = useSetRecoilState(converterLocationPatchSetter);
  const areaSelected = useRecoilValue(converterSelectedArea);

  if (!location) {
    if (areaSelected) {
      return <AreaSummary />;
    }
    return (
      <InboxZero
        message={t`No Location selected`}
        message_helper={t`Click on bay map to select`}
        hasIcon
        selfCenter
      />
    );
  }
  function patch(p: Partial<LayoutImportConverterLocationFragment>) {
    setPatches({
      areaId: area.id,
      bayId: location.locationBayId,
      patch: { ...p, locationId: location.locationId },
    });
  }
  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'size'),
    tMeasures,
  );

  // Volume - Calculated
  const volumeCalculatedRaw =
    location.locationLength * location.locationWidth * location.locationHeight;
  const volumeCalculated = formatter.formatVolume(volumeCalculatedRaw);

  return (
    <>
      <WarehouseNavigator>
        <WarehouseNavigatorItem
          name={t`Level`}
          value={location.locationLevel}
          active
        />
        <WarehouseNavigatorItem
          name={t`Location`}
          value={location.locationId}
          active
        />
      </WarehouseNavigator>
      <ContainerScroll
        className="flex flex-col"
        key={`location-props-${location.locationId}`}
      >
        <PanelContainer
          id="panel-object-property-location"
          title={t`Location Properties`}
          // collapsible
          hasPadding
        >
          <TitleSection inPanelView title={t`Status`} className="mb-2 mt-2" />
          <DropdownSelector
            className="my-1 text-xs"
            panelMode
            label={t`Status`}
            value={location.locationStatus ? 'Enabled' : 'Disabled'}
            values={['Disabled', 'Enabled']}
            onChange={value => {
              const newStatus = value === 'Enabled';
              if (location.locationStatus !== newStatus) {
                patch({ locationStatus: newStatus });
              }
            }}
          />
          <TitleSection
            inPanelView
            title={t`Properties`}
            className="mb-2 mt-4"
          />
          <StatListItem
            title={t`Racking Type (Bay)`}
            value={bay?.details?.bayType ?? '—'}
            titleClass={'text-xs'}
            valueClass={'text-xxs'}
          />
          <StatListItem
            title={t`Bay Order`}
            value={bay?.details?.locationOrder ?? '—'}
            titleClass={'text-xs'}
            valueClass={'text-xxs'}
          />
          <StatListItem
            title={t`Location Type`}
            value={location.locationRackingType ?? '—'}
            titleClass={'text-xs'}
            valueClass={'text-xxs'}
          />
          <StatListItem
            title={t`Picking Order`}
            value={location.locationOrder ?? '—'}
            titleClass={'text-xs'}
            valueClass={'text-xxs'}
          />
          <StatListItem
            title={t`Work Zone`}
            value={location.congestionZone ?? '—'}
            titleClass={'text-xs'}
            valueClass={'text-xxs'}
          />
          <StatListItem
            title={t`Position in Bay`}
            value={location.locationBayPosition.toFixed(0) ?? '—'}
            titleClass={'text-xs'}
            valueClass={'text-xxs'}
          />
          <StatListItem
            title={t`Side`}
            value={location.locationSide}
            titleClass={'text-xs'}
            valueClass={'text-xxs'}
          />
          {/* {location.locationIndexFromFront && (
            <StatListItem
              title={t`Position Index`}
              value={location.locationIndexFromFront.toFixed(0) ?? '—'}
              titleClass={'text-xs'}
              valueClass={'text-xxs'}
            />
          )} */}
          {location.locationDepthPosition && (
            <StatListItem
              title={t`Position Depth`}
              value={location.locationDepthPosition.toFixed(0) ?? '—'}
              titleClass={'text-xs'}
              valueClass={'text-xxs'}
            />
          )}
          {location.locationUsageType && (
            <StatListItem
              title={t`Usage Type`}
              value={location.locationUsageType ?? '—'}
              titleClass={'text-xs'}
              valueClass={'text-xxs'}
            />
          )}
          <TitleSection inPanelView title={t`Size`} className="mb-2 mt-4" />
          <InputNumber
            title={t`Width`}
            icon={<Icon.ObjectBoxSideX className="h-8 w-8 fill-current" />}
            value={location.locationWidth}
            unitOfMeasure={sizeMeasureValue}
            onChange={v => patch({ locationWidth: v })}
            range={[1, null]}
          />
          <InputNumber
            title={t`Height`}
            icon={<Icon.ObjectBoxSideY className="h-8 w-8 fill-current" />}
            value={location.locationHeight}
            unitOfMeasure={sizeMeasureValue}
            onChange={v => patch({ locationHeight: v })}
            range={[1, null]}
          />
          <InputNumber
            title={t`Length`}
            icon={<Icon.ObjectBoxSideZ className="h-8 w-8 fill-current" />}
            value={location.locationLength}
            unitOfMeasure={sizeMeasureValue}
            onChange={v => patch({ locationLength: v })}
            range={[1, null]}
          />
          <StatListItem
            titleSelectable
            title={
              <>
                <div
                  className={classNames(
                    'text-menu-text m-1 ml-0 flex h-10 w-10 items-center p-1 shadow-md ltr:mr-2 rtl:ml-2',
                    'bg-menu-100 rounded bg-opacity-50',
                    // 'hover:bg-brand',
                  )}
                >
                  <Icon.DesignerLocationsSize className="h-8 w-8 fill-current" />
                </div>
                <span className="ml-1">{t`Volume`}</span>
              </>
            }
            value={volumeCalculated.value}
            valueRaw={volumeCalculated.raw}
            unitOfMeasure={volumeCalculated.unit}
            styleInput
          />
          {/* <InputNumber
            title={t`Volume`}
            icon={<Icon.AxisXyz className="w-8 h-8 fill-current" />}
            value={locationVolume}
            unitOfMeasure={`cm3`}
            disabled
            // onChange={v => patch({ locationLength: v })}
            range={[1, null]}
          /> */}
          {location.locationDepthFromFront > 0 && (
            <StatListItem
              title={
                <>
                  <div
                    className={classNames(
                      'text-menu-text m-1 ml-0 flex h-10 w-10 items-center p-1 shadow-md ltr:mr-2 rtl:ml-2',
                      'bg-menu-100 rounded bg-opacity-50',
                      // 'hover:bg-brand',
                    )}
                  >
                    <Icon.AxisXyz className="h-8 w-8 fill-current" />
                  </div>
                  <span className="ml-1">{t`Depth`}</span>
                </>
              }
              styleInput
              value={location.locationDepthFromFront ?? '—'}
              unitOfMeasure={sizeMeasureValue}
            />
          )}
        </PanelContainer>
      </ContainerScroll>
    </>
  );
};

export default () => {
  const { t } = useTranslation('designer');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-converter-info-location',
    title: t`Location Info`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <ConverterLocationSidebarCmp />
    </SidebarContainer>
  );
};
