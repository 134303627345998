import {
  ApolloQueryResult,
  FetchResult,
  useApolloClient,
} from '@apollo/client';
import {
  LoadItemSetUomsDocument,
  LoadItemSetUomsQuery,
  LoadItemSetUomsQueryVariables,
  UpdateSimulationDocument,
  UpdateSimulationInput,
  UpdateSimulationMutation,
  UpdateSimulationMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { cloneWithoutTypename } from '../../common/utils';
import { AgentSettingsWithMeta } from '../../resourcePolicy/agentData/agent.types';
import { updateAgentUoms } from '../../resourcePolicy/agentData/agentDataHelper';
import { errorAppender } from '../../store/error.state';
import { warehouseCanUpdate } from '../../store/warehouse.state';
import {
  allocationRequirement,
  allocationRequirementLoadStatus,
} from '../store/allocation/allocationRequirement.state';
import {
  assignmentComplianceSummary,
  assignmentComplianceSummaryLoadStatus,
} from '../store/assignmentCompliance.state';
import { resourcePolicy } from '../store/resourcePolicy.state';
import {
  simulationAssignmentIssues,
  simulationAssignmentIssuesLoadStatus,
  simulationItemSetIssues,
  simulationItemSetIssuesLoadStatus,
  simulationLayoutIssues,
  simulationLayoutIssuesLoadStatus,
  simulationOrderSetIssues,
  simulationOrderSetIssuesLoadStatus,
} from '../store/simulation.issues.state';
import {
  simulationAnalyzeResultAtom,
  simulationCurrentId,
  simulationEffectiveItemSet,
  simulationEffectiveItemSetLoadStatus,
  simulationOrderLineSet,
  simulationOrderLineSetLoadStatus,
  simulationUoms,
  simulationUpdateStatus,
} from '../store/simulation.state';
import {
  orderedQuantityReport,
  orderedQuantityReportLoadStatus,
} from '../store/simulationReport.state';
import {
  sizeComplianceMeta,
  sizeComplianceMetaLoadStatus,
} from '../store/sizeCompliance.state';
import {
  weightComplianceMeta,
  weightComplianceMetaLoadStatus,
} from '../store/weightCompliance.state';
import useLoadAllocationRequirementsMeta from './useLoadAllocationRequirementsMeta';

function useUpdateSimulation() {
  const client = useApolloClient();
  const { t } = useTranslation('simulation');
  const [runAllocationRequirementJob, cancelAllocationRequirementJob] =
    useLoadAllocationRequirementsMeta();

  const init = useRecoilCallback(({ set }) => async () => {
    set(simulationUpdateStatus, AsyncLoadStatus.Loading);
  });

  const callUpdate = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (patch: Partial<UpdateSimulationInput>, simId?: string) => {
        const simulationId = await snapshot.getPromise(simulationCurrentId);
        const canUpdate = await snapshot.getPromise(warehouseCanUpdate);
        const currentUoms = await snapshot.getPromise(simulationUoms);
        const resPolicy = await snapshot.getPromise(resourcePolicy);
        if (!canUpdate) return null;

        let simulationResponse: FetchResult<UpdateSimulationMutation>;
        let itemSetUomResponse: ApolloQueryResult<LoadItemSetUomsQuery>;
        try {
          const isLayoutWillChange = !_.isNil(patch.layoutId);
          const isAssignmentWillChange = !_.isNil(patch.assignmentId);
          const isItemSetWillChange = !_.isNil(patch.itemSetId);
          const isOrderSetWillChange = !_.isNil(patch.orderSetId);
          const isPickingPolicyChanged = !_.isNil(patch.pickingPolicy);

          const isDatasetChanged =
            isLayoutWillChange ||
            isAssignmentWillChange ||
            isItemSetWillChange ||
            isOrderSetWillChange;

          if (isItemSetWillChange) {
            itemSetUomResponse = await client.query<
              LoadItemSetUomsQuery,
              LoadItemSetUomsQueryVariables
            >({
              query: LoadItemSetUomsDocument,
              variables: {
                itemSetId: patch.itemSetId,
              },
            });

            const newUoms = _.map(
              itemSetUomResponse?.data?.itemSet?.uomTypes,
              ut => ut.uom,
            );
            const isUomsChanged = !_.isEqual(
              [...newUoms].sort(),
              [...currentUoms].sort(),
            );
            if (isUomsChanged) {
              set(simulationUoms, newUoms);

              if (!_.isEmpty(resPolicy.agents)) {
                const updatedPolicy = {
                  agents: _.map(
                    resPolicy.agents,
                    a =>
                      updateAgentUoms(
                        a as AgentSettingsWithMeta,
                        newUoms,
                      ) as AgentSettingsWithMeta,
                  ),
                };

                set(resourcePolicy, updatedPolicy);
                patch.resourcePolicy = updatedPolicy;
              }
            }
          }

          simulationResponse = await client.mutate<
            UpdateSimulationMutation,
            UpdateSimulationMutationVariables
          >({
            mutation: UpdateSimulationDocument,
            variables: {
              input: {
                simulationId: simId || simulationId,
                ...cloneWithoutTypename(patch),
              },
            },
          });

          const simulation = simulationResponse.data.updateSimulation;
          set(simulationUpdateStatus, AsyncLoadStatus.Ok);
          if (isDatasetChanged) {
            //issues
            set(simulationLayoutIssues, simulation.layoutIssues);
            reset(simulationLayoutIssuesLoadStatus);
            set(simulationAssignmentIssues, simulation.assignmentIssues);
            reset(simulationAssignmentIssuesLoadStatus);
            set(simulationItemSetIssues, simulation.itemSetIssues);
            reset(simulationItemSetIssuesLoadStatus);
            set(simulationOrderSetIssues, simulation.orderSetIssues);
            reset(simulationOrderSetIssuesLoadStatus);

            reset(orderedQuantityReport);
            reset(orderedQuantityReportLoadStatus);

            set(simulationOrderLineSet, simulation.orderLineSet);
            set(simulationOrderLineSetLoadStatus, AsyncLoadStatus.Ok);

            set(simulationEffectiveItemSet, simulation.effectiveItemSet);
            set(simulationEffectiveItemSetLoadStatus, AsyncLoadStatus.Ok);

            //allocation
            // compliance summaries
            set(sizeComplianceMeta, simulation.sizeCompliance);
            reset(sizeComplianceMetaLoadStatus);
            set(weightComplianceMeta, simulation.weightCompliance);
            reset(weightComplianceMetaLoadStatus);
            set(assignmentComplianceSummary, simulation.assignmentCompliance);
            reset(assignmentComplianceSummaryLoadStatus);

            // analyze
            reset(simulationAnalyzeResultAtom);
          }

          if (isPickingPolicyChanged || isDatasetChanged) {
            cancelAllocationRequirementJob();
            reset(allocationRequirement);
            reset(allocationRequirementLoadStatus);
          }
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: t`Can not save simulation`,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          return;
        }

        if (!_.isEmpty(simulationResponse.errors)) {
          const details = _.map(simulationResponse.errors, e => e.message).join(
            ' ',
          );

          set(errorAppender, {
            id: nanoid(),
            title: t`Can not save simulation`,
            details: details,
            callStack: null,
          });
          return;
        }
      },
  );

  return async (
    patch: Partial<UpdateSimulationInput>,
    simulationId?: string,
  ) => {
    await init();
    await callUpdate(patch, simulationId);
  };
}
export default useUpdateSimulation;
