import React from 'react';
import { useTranslation } from 'react-i18next';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../../containers/SidebarContainer';
import useSelectSimulationWizardStep from '../../hooks/useSelectSimulationWizardStep';
import ComplianceAssignmentPanel from '../../panels/compliance/ComplianceAssignmentPanel';
import ActionBarNextStep from '../ActionBarNextStep';

const ComplianceAssignmentSidebar: React.FC = () => {
  const { t } = useTranslation('simulation');

  const selectStep = useSelectSimulationWizardStep();

  return (
    <>
      <ComplianceAssignmentPanel />
      <ActionBarNextStep />
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-compliance-assignment',
    title: t`Storage Policy + Compliance`,
    loadingMessage: t`Loading Policy...`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <ComplianceAssignmentSidebar />
    </SidebarContainer>
  );
};
