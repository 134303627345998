import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import Tabs, { TabItem } from '../../components/actions/Tabs';
import * as Icon from '../../components/icons';
import FeedDevicePerformancePanel from '../../feed/panels/FeedDevicePerformancePanel';
import FeedEventsPanel from '../../feed/panels/FeedEventsPanel';
import FeedStockPanel from '../../feed/panels/FeedStockPanel';
import LiveFeedPanel from '../../feed/panels/LiveFeedPanel';
import { feedActivitySelectedTabId } from '../../feed/store/feed.state';
import useUserPermissionFeatures from '../../permissions/roles/hooks/useUserPermissionFeatures';
import { ActivityFeedTabId } from '../store/feed.types';
import ActualityHeatmapPanel from './ActualityHeatmapPanel';

const FeedActivityPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const [selectedTab, setSelectedTab] = useRecoilState(
    feedActivitySelectedTabId,
  );
  const visibleFeatures = useUserPermissionFeatures();

  const tabIconStyle = classNames(
    'fill-current w-7 lg:w-8 xl:w-9 2xl:w-10 h-auto',
  );

  const tabItems: TabItem<ActivityFeedTabId>[] = [
    {
      id: 'tab-feed-activity',
      title: t`Activity`,
      icon: <Icon.AgentWalking className={tabIconStyle} />,
      content: <FeedEventsPanel />,
    },
    {
      id: 'tab-feed-kpi',
      title: t`KPI`,
      icon: <Icon.DataStatsPie className={tabIconStyle} />,
      content: <FeedDevicePerformancePanel />,
    },
    {
      id: 'tab-feed-process',
      title: t`process`,
      icon: <Icon.WorkloadZoneBalance className={tabIconStyle} />,
      content: <LiveFeedPanel />,
    },
    {
      id: 'tab-feed-items',
      title: t`Stock`,
      icon: <Icon.DataItems className={tabIconStyle} />,
      content: <FeedStockPanel />,
    },
    {
      id: 'tab-feed-heatmaps',
      title: t`Stats`,
      icon: <Icon.HeatMap9 className={tabIconStyle} />,
      content: <ActualityHeatmapPanel />,
    },
  ];

  const shouldHaveScroll = selectedTab !== 'tab-feed-activity';

  const allowedTabs = tabItems.filter(
    tab => visibleFeatures.find(f => f.id === tab.id)?.visible,
  );

  console.log('Allowed Tabs:', allowedTabs);

  return (
    <Tabs
      componentName="FeedActivityPanel"
      items={allowedTabs}
      selectedIndex={_.findIndex(tabItems, i => i.id === selectedTab)}
      onChange={index => setSelectedTab(tabItems[index].id)}
      classNameTitle="uppercase"
      hasScroll={shouldHaveScroll}
      fullHeight
      hasActionbar={shouldHaveScroll ? false : true}
    />
  );
};

export default FeedActivityPanel;
