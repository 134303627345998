import {
  AssignmentPolicyComplianceRuleMatchFragment,
  AssignmentPolicyRuleMatchType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formatInteger } from '../common/formatHelper';
import useFormatter from '../common/useFormatter';
import * as Icon from '../components/icons';
import PolicyFilterGroupList from '../components/policies/PolicyFilterGroupList';
import { PolicyFilterTag } from '../components/policies/PolicyFilterTag';
import { PolicyStatContainer } from '../components/policies/PolicyStatContainer';
import RuleContainer, {
  RuleContainerProps,
} from '../components/policies/RuleContainer';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { Stat } from '../components/stats/Stat';
import {
  assignmentComplianceSelectedIdentity,
  assignmentComplianceUseComplianceColors,
} from '../simulation/store/assignmentCompliance.state';
import {
  getLocationFilterConfigCommon,
  getProductFilterConfigCommon,
} from '../simulation/store/assignmentPolicy.default';
import { assignmentPolicyRule } from '../simulation/store/assignmentPolicy.state';
import { AssignmentPolicyFilterType } from '../simulation/store/assignmentPolicy.types';
import {
  simulationShowComplianceTable,
  simulationShowDatasetAsTable,
} from '../simulation/store/simulation.state';

export type AssignmentComplianceRuleBaseProps = {
  index?: number;
  className?: string;
  isDefault?: boolean;
  hasIssue?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  isCheckLoading: boolean;
  productMatch?: AssignmentPolicyComplianceRuleMatchFragment;
  locationMatch?: AssignmentPolicyComplianceRuleMatchFragment;
};

export type AssignmentComplianceRuleProps =
  AssignmentComplianceRuleBaseProps & {
    ruleId: string;
    isDefault?: boolean;
    isRemovable?: boolean;
    isNew?: boolean;

    canDelete?: boolean;
    onDeleteClick?: () => void;

    canMoveUp?: boolean;
    onMoveUp?: () => void;
    canMoveDown?: boolean;
    onMoveDown?: () => void;

    canRename?: boolean;
  };

const AssignmentComplianceRule: React.FC<
  AssignmentComplianceRuleProps
> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const rule = useRecoilValue(assignmentPolicyRule(props.ruleId));
  const [selectedDetails, setSelectedDetails] = useRecoilState(
    assignmentComplianceSelectedIdentity,
  );
  const setShowDatasetTable = useSetRecoilState(simulationShowDatasetAsTable);
  const setShowItemsTable = useSetRecoilState(simulationShowComplianceTable);

  const applyComplianceColor = useRecoilValue(
    assignmentComplianceUseComplianceColors,
  );

  const isCheckLoading = props.isCheckLoading;
  // checkStatus === AsyncLoadStatus.None ||
  // checkStatus === AsyncLoadStatus.Loading;

  function selectWithToggle(type: AssignmentPolicyFilterType) {
    const isReset =
      (isSelectedProduct && type === 'items') ||
      (isSelectedLocation && type === 'locations');

    if (type === 'locations') {
      setShowDatasetTable(isReset ? null : 'locations-stats-by-rule');
    }
    if (type === 'items') {
      setShowItemsTable(!isReset);
    }
    setShowItemsTable;
    setSelectedDetails(
      isReset
        ? null
        : {
            ruleId: rule.id,
            filterType: type,
          },
    );
  }

  function getIcon(isAffect: boolean, hasIssue: boolean) {
    return isAffect ? (hasIssue ? Icon.TriangleInfo : Icon.CircleOk) : null;
  }

  const isSelectedRule = selectedDetails?.ruleId === rule?.id;
  const isSelectedLocation =
    isSelectedRule && selectedDetails?.filterType === 'locations';
  const isSelectedProduct =
    isSelectedRule && selectedDetails?.filterType === 'items';

  const ruleType =
    rule.type ?? AssignmentPolicyRuleMatchType.ITEM_MUST_BE_IN_LOCATION;

  const isItemsAffectsCompliance =
    ruleType === AssignmentPolicyRuleMatchType.ITEM_MUST_BE_IN_LOCATION ||
    ruleType === AssignmentPolicyRuleMatchType.STRICT_MATCH;

  const isLocationsAffectsCompliance =
    ruleType === AssignmentPolicyRuleMatchType.LOCATION_MUST_CONTAIN_ITEM ||
    ruleType === AssignmentPolicyRuleMatchType.STRICT_MATCH;

  const complianceIssuesLabel = t`Issues/Total`;
  const complianceGoodLabel = t`All compliant`;
  const totalLabel = t`Total Occupied`;

  const nonCompliantItemsCount = formatInteger(
    props.productMatch?.nonCompliantCount ?? 0,
  );
  const totalItemsCount = formatInteger(props.productMatch?.totalCount ?? 0);

  const nonCompliantLocationsCount = formatInteger(
    props.locationMatch?.nonCompliantCount ?? 0,
  );
  const totalLocationsCount = formatInteger(
    props.locationMatch?.totalCount ?? 0,
  );

  const hasIssueNonMatchedTotalItems = props.productMatch.totalCount === 0;
  const hasIssueNonMatchedTotalLocations = props.locationMatch.totalCount === 0;

  const hasItemsIssues =
    (props.productMatch?.nonCompliantCount > 0 && isItemsAffectsCompliance) ||
    hasIssueNonMatchedTotalItems;

  const hasLocationsIssues =
    (props.locationMatch?.nonCompliantCount > 0 &&
      isLocationsAffectsCompliance) ||
    hasIssueNonMatchedTotalLocations;

  const hasIssueNonMatched =
    hasIssueNonMatchedTotalItems || hasIssueNonMatchedTotalLocations;
  const hasIssueNonMatchedLabel = t`None Matched`;

  const hasIssue = hasItemsIssues || hasLocationsIssues;

  const isActiveRule = selectedDetails?.ruleId === props.ruleId;

  const containerParams: RuleContainerProps = {
    id: rule.id,
    title: rule.title,
    issueList: [
      {
        title: t`Items:`,
        value: nonCompliantItemsCount,
        count: props.productMatch?.nonCompliantCount,
      },
      {
        title: t`Location:`,
        value: nonCompliantLocationsCount,
        count: props.locationMatch?.nonCompliantCount,
      },
    ],

    isCollapsible: true,
    isDisabled: props.isDisabled,
    isActive: isActiveRule,
    orderCounter: props.index,
    hasIssue,
    namedColorKey: applyComplianceColor ? null : rule.title,
  };

  return (
    <RuleContainer
      dataComponent="AssignmentComplianceRule"
      {...containerParams}
      hasIcon
      hasColorMode
      stickyTop={3}
    >
      <PolicyFilterTag className={'space-y-3'}>
        <RuleSectionContainer
          isSelected={isSelectedProduct}
          onClick={() => selectWithToggle('items')}
        >
          <PolicyStatContainer>
            <Stat
              toggleTable
              transparent
              title={
                t`Items: ` +
                (isItemsAffectsCompliance
                  ? hasItemsIssues
                    ? complianceIssuesLabel
                    : complianceGoodLabel
                  : totalLabel)
              }
              value={
                isItemsAffectsCompliance && hasItemsIssues
                  ? nonCompliantItemsCount
                  : hasIssueNonMatchedTotalItems
                    ? hasIssueNonMatchedLabel
                    : totalItemsCount
              }
              valueTotal={totalItemsCount}
              isSectionable={hasItemsIssues}
              icon={getIcon(isItemsAffectsCompliance, hasItemsIssues)}
              inPanelMode
              isActionable
              inFilterStat
              isCheckLoading={isCheckLoading}
              isSelected={isSelectedProduct}
            />
          </PolicyStatContainer>

          <PolicyFilterGroupList
            isDisabled={true}
            isActive={isSelectedProduct}
            filterSets={_.map(rule.productsMatch?.anyOf, filterGroup => ({
              id: filterGroup.id,
              allOf: [...filterGroup.allOf],
            }))}
            config={getProductFilterConfigCommon(t, formatter)}
            selectedId={null}
            showAddFilter={false}
          />
        </RuleSectionContainer>

        <RuleSectionContainer isSelected={isSelectedLocation}>
          <PolicyStatContainer>
            <Stat
              transparent
              title={
                t`Locations: ` +
                (isLocationsAffectsCompliance
                  ? hasLocationsIssues
                    ? complianceIssuesLabel
                    : complianceGoodLabel
                  : totalLabel)
              }
              value={
                isLocationsAffectsCompliance && hasLocationsIssues
                  ? nonCompliantLocationsCount
                  : totalLocationsCount
              }
              valueTotal={totalLocationsCount}
              isSectionable={hasLocationsIssues}
              icon={getIcon(isLocationsAffectsCompliance, hasLocationsIssues)}
              inPanelMode
              isActionable
              inFilterStat
              isCheckLoading={isCheckLoading}
              isSelected={isSelectedLocation}
              onClick={() => selectWithToggle('locations')}
            />
          </PolicyStatContainer>

          <PolicyFilterGroupList
            isDisabled={props.isDisabled}
            isActive={isSelectedLocation}
            filterSets={_.map(rule.locationsMatch?.anyOf, filterGroup => ({
              id: filterGroup.id,
              allOf: [...filterGroup.allOf],
            }))}
            config={getLocationFilterConfigCommon(t)}
            selectedId={null}
            showAddFilter={false}
          />
        </RuleSectionContainer>
      </PolicyFilterTag>
    </RuleContainer>
  );
};
export default AssignmentComplianceRule;
