import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatInteger } from '../../../common/formatHelper';
import { Stat } from '../../../components/stats/Stat';
import { StatGroup } from '../../../components/stats/StatGroup';
import { allocationRunSummary } from '../../store/allocation/allocation.state';
import AllocationSummaryView from './AllocationSummaryView';

const AllocationSummary: React.FC = () => {
  const { t } = useTranslation('simulation');
  const allocation = useRecoilValue(allocationRunSummary);
  const summary = allocation.resultRequirementsSummary?.[0];
  const totalRequirementItemQty = summary?.required?.totalQuantity;
  const totalStockItemsQty = summary?.initiallyPickable?.totalQuantity;
  const totalAllocatedQty =
    (summary?.allocated?.totalQuantity ?? 0) +
    (summary?.allocatedShared?.totalQuantity ?? 0);
  const totalUnpickableQty = summary?.unpickable?.totalQuantity;

  return (
    <>
      <div className="flex flex-1 space-x-1 my-1">
        <Stat
          title={t`Ordered`}
          // isLoading={isLoading}
          key={`allocated-total-RequirementItemQty`}
          value={formatInteger(totalRequirementItemQty)}
          // value={formatInteger(
          //   totalStockItemsQty > 1000000
          //     ? '1M+'
          //     : formatInteger(totalRequirementItemQty),
          // )}
        ></Stat>
        <Stat
          title={t`In Stock`}
          // isLoading={isLoading}
          key={`allocated-total-StockItemsQty`}
          value={
            totalStockItemsQty > 1000000
              ? '1M+'
              : formatInteger(totalStockItemsQty)
          }
        ></Stat>
        <Stat
          title={t`Allocate (Gap)`}
          // isLoading={isLoading}
          key={`allocated-total-AllocatedQty`}
          value={
            totalStockItemsQty > 1000000
              ? '1M+'
              : formatInteger(totalAllocatedQty)
          }
        ></Stat>
      </div>
      {totalUnpickableQty > 0 && (
        <StatGroup title={`Issues`}>
          <Stat
            hasIssue
            title={t`Unpickable`}
            key={`allocated-totalUnpickableRequiredQuantity`}
            value={
              totalStockItemsQty > 1000000
                ? '1M+'
                : formatInteger(totalUnpickableQty)
            }
          ></Stat>
        </StatGroup>
      )}
      <AllocationSummaryView />
    </>
  );
};
export default AllocationSummary;
