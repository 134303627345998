import { AllocationDeallocateSettingsInput } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getIndexedTitle } from '../../../common/utils';
import InboxZero from '../../../components/InboxZero';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import { Container } from '../../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import SectionOptional from '../../../components/layout/SectionOptional';
import TitleSection from '../../../components/layout/TitleSection';
import { StatusTag } from '../../../components/nav/StatusTag';
import PolicyRuleSectionTitle from '../../../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../../../components/policies/PolicyWell';
import { collapsibleStateAtom } from '../../../store/collapsible.state';
import { sidebarStateByType } from '../../../store/sidebar.state';
import { warehouseSelectedId } from '../../../store/warehouse.state';
import useUpdateSimulation from '../../hooks/useUpdateSimulation';
import {
  deallocationPolicyDocument,
  deallocationPolicySelectedIdentity,
} from '../../store/allocationPolicy/allocationPolicy.state';
import { assignmentComplianceStatus } from '../../store/assignmentCompliance.state';
import { getDeallocatePolicyInput } from '../../store/simulation.helper';
import {
  simulationCurrent,
  simulationIsEditable,
} from '../../store/simulation.state';
import DeallocatePolicyRule from '../DeallocatePolicyRule';

const DeallocatePolicy: React.FC = () => {
  const { t } = useTranslation('simulation');
  const sim = useRecoilValue(simulationCurrent);
  const whId = useRecoilValue(warehouseSelectedId);
  const [policy, setPolicy] = useRecoilState(deallocationPolicyDocument);
  const [savedPolicy, setSavedPolicy] =
    useState<AllocationDeallocateSettingsInput>(null);
  const updateSim = useUpdateSimulation();

  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    deallocationPolicySelectedIdentity,
  );
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-deallocate-editor'),
  );
  const [collapsible, setCollapsible] = useRecoilState(collapsibleStateAtom);
  const canUpdate = useRecoilValue(simulationIsEditable);
  const setComplianceStatus = useSetRecoilState(assignmentComplianceStatus);

  // // trigger policy saving and policy check request when AP changed
  useEffect(() => {
    const policyToSave = getDeallocatePolicyInput(policy);

    const isPolicyChanged = !_.isEqual(policyToSave, savedPolicy);
    const isInitial = savedPolicy === null;
    setSavedPolicy(policyToSave);
    if (!isPolicyChanged) return;

    if (!isInitial) {
      updateSim({
        allocationSettings: {
          ...sim.allocationSettings,
          deallocateSettings: policyToSave,
        },
      });
    }
    //reset assignment compliance state
    setComplianceStatus(null);
  }, [policy]);

  useEffect(() => {
    return () => {
      setPolicyEditPanelState({
        ...policyEditPanelState,
        isCollapsed: true,
        isHidden: true,
      });
    };
  }, []);

  const removeRule = (ruleId: string) => {
    if (selectedIdentity?.ruleId === ruleId) {
      setSelectedIdentity(null);
    }
    setPolicy({
      ...policy,
      rules: _.filter(policy.rules, rule => rule.id !== ruleId),
    });
  };

  const addRule = () => {
    const ruleId = nanoid();

    const ruleTitle = getIndexedTitle(
      new Set(_.map(policy.rules, r => r.title)),
      t`Rule #`,
    );
    setPolicy({
      ...policy,
      rules: [
        ...(policy.rules ?? []),
        {
          id: ruleId,
          title: ruleTitle,
          itemsMatch: { anyOf: [] },
        },
      ],
    });

    setCollapsible({
      ...collapsible,
      [ruleId]: { isCollapsed: false },
    });
  };

  const hasRules = !_.isEmpty(policy?.rules);
  const policyRuleCounter = policy?.rules?.length;

  function getPolicyHeaderContent() {
    if (canUpdate && hasRules) {
      return (
        <Button
          label={t`Add Policy`}
          className={classNames('rounded ltr:ml-4 rtl:mr-4')}
          buttonSize="xs"
          buttonType="primary"
          hasIconAfter={<Icon.CirclePlus className={`h-5 w-5 fill-current`} />}
          onPress={() => addRule()}
        />
      );
    }

    if (!canUpdate) {
      return (
        <StatusTag
          title={t`Policy Locked`}
          type="locked"
          modeStyle="stroke"
          icon={Icon.Lock}
          onPress={null}
        />
      );
    }

    return null;
  }

  return (
    <SectionOptional
      id={'allocation-deallocate'}
      title={t`Deallocate rules`}
      value={sim.allocationSettings?.deallocateSettings?.disabled === false}
      onChange={enabled =>
        setPolicy(current => ({ ...current, disabled: !enabled }))
      }
    >
      <Container col hasOverflowY>
        <ScreenTitle
          title={t`Deallocate policy`}
          subtitle={t`Items`}
          isSticky
          // helpNavTo={'simulation/policies/policy-storage/policy-storage'}
          icon={Icon.PolicyAssignment}
        />
        <TitleSection
          title={
            <PolicyRuleSectionTitle
              isFeasible={true}
              counter={policyRuleCounter}
            />
          }
          inPanelView
          className={classNames('z-400 top-12 xl:top-20')}
          hasScreenTitle
          hasAction={getPolicyHeaderContent()}
        />

        {/* <DeallocatePolicyRuleDetailWatcher /> */}
        <Container col flex1 hasOverflowY>
          <PolicyWell fullHeight isDisabled={!canUpdate} isCentred={!hasRules}>
            {!hasRules && (
              <InboxZero selfCenter hasIcon message={t`No Rules found`}>
                {canUpdate && (
                  <div
                    className={classNames(
                      'mt-4 flex flex-col items-center space-y-2 xl:flex-row xl:space-y-0',
                    )}
                  >
                    <Button
                      full
                      buttonSize="xs"
                      buttonType="primary"
                      label={t`Add Policy`}
                      className="w-full flex-1 rounded"
                      onPress={addRule}
                      hasIconAfter={
                        <Icon.CirclePlus className={`h-5 w-5 fill-current`} />
                      }
                    />
                  </div>
                )}
              </InboxZero>
            )}

            {_.map(policy?.rules, (rule, index) => {
              return (
                <DeallocatePolicyRule
                  isDisabled={!canUpdate}
                  key={`deallocate-rule-${rule.id}`}
                  ruleId={rule.id}
                  index={index + 1}
                  isRemovable={true}
                  canDelete={true}
                  canRename={true}
                  onDeleteClick={() => removeRule(rule.id)}
                />
              );
            })}
          </PolicyWell>
        </Container>
      </Container>
    </SectionOptional>
  );
};

export default DeallocatePolicy;
