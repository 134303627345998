import CodeSnippet from '../../helpContext/CodeSnippet';
import classNames from 'classnames';
import _ from 'lodash';
import QRCodeCanvas from 'qrcode.react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { FormErrorField } from '../../components/FormError';
import { Button } from '../../components/actions/Button';
import * as Icon from '../../components/icons';
import InputText from '../../components/inputs/InputText';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { IconArrowDir } from '../../components/nav/IconArrowDirectional';
import Callout from '../../helpContext/Callout';
import { useErrorTranslator } from '../../public/ErrorTranslator';
import TOTPAppLinks from '../../public/TOTPAppLinks';
import {
  authDisableMFA,
  confirmTOTPSetup,
  getTOTPCode,
} from '../../store/auth.helper';
import {
  authMFAType,
  authUserPoolType,
  loggedInUser,
} from '../../store/auth.state';

export type SettingsMFASetupProps = {};

const SettingsMFASetup: React.FC<SettingsMFASetupProps> = props => {
  const { t } = useTranslation('app');
  const user = useRecoilValue(loggedInUser);
  const [totpToken, setTotpToken] = useState('');
  const [error, setError] = useState<string>(null);
  const [mfaSetupCode, setMfaSetupCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mfaType, setMFAType] = useState(useRecoilValue(authMFAType));
  const { translateError } = useErrorTranslator();
  const userPoolType = useRecoilValue(authUserPoolType);
  const [showManualCode, setShowManualCode] = useState<boolean>(false);

  const issuer = 'WareBee';
  const str = `otpauth://totp/${user.email}?secret=${mfaSetupCode}&issuer=${issuer}`;

  async function requestTOTPCode() {
    setIsLoading(true);
    try {
      const code = await getTOTPCode();
      setMfaSetupCode(code);
    } catch (ex) {
      const errorMessage = translateError(ex);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }

  async function resetMFA() {
    setIsLoading(true);
    setError(null);
    try {
      await authDisableMFA();
      setMFAType('NOMFA');
    } catch (ex) {
      const errorMessage = translateError(ex);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }

  async function confirmTOTPCode() {
    setIsLoading(true);
    setError(null);
    try {
      await confirmTOTPSetup(totpToken);
      setMFAType('TOTP');
      setMfaSetupCode(null);
    } catch (ex) {
      const errorMessage = translateError(ex);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {mfaType === 'NOMFA' && _.isNil(mfaSetupCode) && (
        <fieldset>
          <label className="mt-3 mb-2 text-menu-200">{t`Two Factor Authentication (2FA/MFA)`}</label>
          {!_.isEmpty(error) && <FormErrorField message={error} />}
          <Button
            className="p-2 text-start bg-opacity-80 backdrop-filter backdrop-blur-lg backdrop-saturate-110"
            buttonSize={`lg`}
            label={t`Enable Two Factor Auth. (2FA)`}
            full
            buttonType={`primary`}
            hasIconAfter
            buttonIcon={<IconArrowDir />}
            isLoading={isLoading}
            onPress={() => requestTOTPCode()}
          />
        </fieldset>
      )}

      {mfaType !== 'NOMFA' && _.isNil(mfaSetupCode) && (
        <fieldset>
          <label className="mt-3 mb-2 text-menu-200">{t`Multi Factor Authentication (2FA/MFA)`}</label>
          <Callout panelMode type="suggestion">
            {t`Multi-factor authentication (MFA) is enabled for your account. You can disable it by clicking the button below.`}
          </Callout>
          {!_.isEmpty(error) && <FormErrorField message={error} />}
          <Button
            className="p-2 text-start bg-opacity-80 backdrop-filter backdrop-blur-lg backdrop-saturate-110"
            buttonSize={`lg`}
            label={t`Disable Two Factor Authentication`}
            isDisabled={userPoolType === 'mfa'}
            full
            buttonType={`primary`}
            hasIconAfter
            buttonIcon={<IconArrowDir />}
            isLoading={isLoading}
            onPress={() => resetMFA()}
          />
        </fieldset>
      )}

      {mfaType === 'NOMFA' && !_.isNil(mfaSetupCode) && (
        <>
          <ScreenTitle
            isSticky
            subtitle={t`Security`}
            title={t`Setup multi-factor authentication`}
          />

          <section className="w-full mb-4">
            <div className="flex flex-col w-full items-center justify-center">
              <Callout panelMode type="counter" counter="1">
                <div className="mb-2 flex flex-col">
                  {t`To setup your multi-factor authentication, Scan this QR code below, with your authentication app:`}
                  <p className="text-menu-100">
                    <span className="italic bold">{t`Use Apps like:`}</span>
                    <TOTPAppLinks />
                  </p>
                </div>

                {/* <label className="mt-2 mb-2 text-menu/60">{t`Scan this code:`}</label> */}
                <div className="flex flex-col items-center justify-center mt-4 mb-4">
                  <div className="bg-white rounded p-3 h-full flex flex-col items-center justify-center shadow-app-200xl">
                    {/* style={{ width: 200 + 16 }} */}
                    <QRCodeCanvas
                      className="h-full w-full flex-1"
                      value={str}
                      size={160}
                    />
                    {/* size={200} */}
                  </div>
                  <div
                    className="text-menu-text/60 hover:underline text-xs cursor-pointer mt-4"
                    onClick={() => {
                      setShowManualCode(!showManualCode);
                    }}
                  >
                    {showManualCode
                      ? t`Hide manual code`
                      : t`Having trouble scanning the code?`}
                  </div>
                </div>
                {showManualCode && (
                  <div className="w-full mb-4 p-4">
                    <p className="mt-3 mb-2 text-menu">{t`If you are unable to scan the QR code image, enter the following code in your authentication app.`}</p>
                    <CodeSnippet>{mfaSetupCode}</CodeSnippet>
                  </div>
                )}
              </Callout>
            </div>
            <fieldset>
              <Callout panelMode type="counter" counter="2">
                <div className="mb-4">
                  {t`Enter the verification code from your security app to confirm setup.`}
                </div>

                <InputText
                  value={totpToken}
                  label={t`Verify Token`}
                  placeholder="123456"
                  name="token"
                  onChange={v => setTotpToken(v)}
                />
                {!_.isEmpty(error) && <FormErrorField message={error} />}

                <Button
                  className="p-2 mt-2 text-start bg-opacity-80 backdrop-filter backdrop-blur-lg backdrop-saturate-110"
                  buttonSize={`lg`}
                  label={t`Activate MFA`}
                  full
                  buttonType={`primary`}
                  hasIconAfter
                  buttonIcon={
                    <Icon.CircleOk
                      className={classNames('w-8 h-8 fill-current mx-1')}
                    />
                  }
                  onPress={() => confirmTOTPCode()}
                />
                {/* <span className='text-xs'>{`We're asking for this code based on your two-factor authentication.`}</span> */}
              </Callout>
            </fieldset>
          </section>
        </>
      )}
    </>
  );
};
export default SettingsMFASetup;
