import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const getKey = (postfix: string) => `warebee-cctv-${postfix}`;

export const cctvEnabled = atom<boolean>({
  key: getKey('enabled'),
  default: false,
  effects: [
    recoilPersist({
      key: getKey('enabled'),
      storage: localStorage,
    }).persistAtom,
  ],
});

const cctvShowAtom = atom<boolean>({
  key: getKey('show-atom'),
  default: false,
});

export const cctvShow = selector<boolean>({
  key: getKey('show'),
  get: ({ get }) => get(cctvShowAtom) && get(cctvEnabled),
  set: ({ set }, value) => set(cctvShowAtom, value),
});
