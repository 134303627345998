import {
  QueryDatasetDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  DatasetQueryConverterConfig,
  DatasetQueryExportJobParams,
  datasetQueryExportJobParams,
} from '@warebee/shared/export-converter';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  actualityHeatmapTableData,
  actualityHeatmapTableDataLoadStatus,
  actualityHeatmapTableDataState,
  actualityHeatmapTableTotalCount,
} from '../store/actuality.heatmap.state';
import {
  ActualityHeatmapDataQueryParams,
  getActualityHeatmapDataQuery,
  getActualityHeatmapTotalQuery,
} from '../store/datasetQueries/actualityHeatmapData';
import { executeDatasetQuery } from '../store/feed.helper';

export type LoadActualityHeatmapTableParams =
  ActualityHeatmapDataQueryParams & {
    isAppend?: boolean;
  };

export type ExportActualityHeatmapTableParams =
  ActualityHeatmapDataQueryParams & {
    converterConfig: DatasetQueryConverterConfig;
  };

function useLoadActualityHeatmapTableData() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Actuality Heatmap Data`;
  const [runExportCSV] = useRunExportJobMutation();

  const resetCallback = useRecoilCallback(({ reset }) => async () => {
    reset(actualityHeatmapTableDataLoadStatus);
    reset(actualityHeatmapTableData);
    reset(actualityHeatmapTableDataState);
    reset(actualityHeatmapTableTotalCount);
  });

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(actualityHeatmapTableDataLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadActualityHeatmapTableParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const current = await snapshot.getPromise(actualityHeatmapTableData);
        let totalCount = await snapshot.getPromise(
          actualityHeatmapTableTotalCount,
        );

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(actualityHeatmapTableDataLoadStatus, AsyncLoadStatus.Error);
        }

        if (!params.isAppend) {
          const compiledQueryTotal =
            getActualityHeatmapTotalQuery(params).compile();

          const totalResult = await executeDatasetQuery({
            warehouseId,
            compiledQuery: compiledQueryTotal,

            comment: '[actuality] Heatmap total rows',
          });

          totalCount = totalResult[0].totalCount;
        }

        let rows = [];
        if (totalCount > 0) {
          const compiledQuery = getActualityHeatmapDataQuery(params).compile();

          rows = await executeDatasetQuery({
            warehouseId,
            compiledQuery,

            comment: '[actuality] Heatmap table data',
          });
        }

        if (params.isAppend) {
          rows = [...current, ...rows];
        }
        set(actualityHeatmapTableData, rows);
        set(actualityHeatmapTableTotalCount, totalCount);
        set(actualityHeatmapTableDataLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  const exportCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: ExportActualityHeatmapTableParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const exportParams = { ...params, page: null };
        const query = getActualityHeatmapDataQuery(exportParams).compile();

        const variables: DatasetQueryExportJobParams = {
          ...datasetQueryExportJobParams,
          query: QueryDatasetDocument.loc.source.body,
          config: params.converterConfig,
          variables: {
            warehouseId: warehouseId,
            query: query.sql,
            params: query.parameters,
          },
        };
        const { data, errors } = await runExportCSV({
          variables,
        });

        return {
          errors: errors,
          job: data.createExportJob,
        };
      },
  );
  async function call(params: LoadActualityHeatmapTableParams) {
    await initLoading();
    await loadDataCallback(params);
  }

  async function reset() {
    await resetCallback();
  }

  async function exportCSV(params: ExportActualityHeatmapTableParams) {
    return await exportCallback(params);
  }

  return [call, reset, exportCSV] as const;
}

export default useLoadActualityHeatmapTableData;
