import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import _ from 'lodash';

export const daysOfWeek = [0, 1, 2, 3, 4, 5, 6] as const;
export type DayOfWeek = (typeof daysOfWeek)[number];

export const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function toDateFromLocaleStringDate(v: string | Date): Date {
  return utcToZonedTime(v, tz);
}

export function toLocalDateFromMs(v: number) {
  return new Date(new Date(v).toISOString().slice(0, -1));
}

export function toLocaleDateTimeDBString(v: Date): string {
  return format(zonedTimeToUtc(v, tz), `yyyy-MM-dd HH:mm:ss.SSS`);
}

export function toLocaleDateTimeString(v: Date): string {
  return format(zonedTimeToUtc(v, tz), `yyyy-MM-dd'T'HH:mm:ss.SSS`);
}

export function toLocaleDateString(v: Date): string {
  return format(zonedTimeToUtc(v, tz), `yyyy-MM-dd`);
}

export function toHumanReadableDate(v: Date): string {
  return format(zonedTimeToUtc(v, tz), 'd MMMM, yyyy');
}

export function toHumanReadableTime(v: Date): string {
  return format(v, 'HH:mm');
}

export function toHumanReadableTimeDetailed(v: Date): string {
  return format(v, 'HH:mm:ss');
}

export function toHumanReadableDateDay(v: Date): string {
  return format(v, 'd MMMM');
}

export function toDateFromLocaleStringTime(
  localTime: string,
  localDate?: string,
): Date {
  const parts = _.split(localTime, ':').map(p => _.padStart(p, 2, '0'));
  const fullTimeString = _.assign(_.fill(new Array(3), '00'), parts).join(':');
  const timeString = `1970-01-01T${fullTimeString}.000`;
  const time = utcToZonedTime(timeString, tz);
  if (!_.isNil(localDate)) {
    const d = toDateFromLocaleStringDate(localDate);
    time.setFullYear(d.getFullYear());
    time.setMonth(d.getMonth());
    time.setDate(d.getDate());
  }
  return time;
}

export function toLocaleTimeString(v: Date): string {
  return format(v, 'HH:mm:ss');
}
