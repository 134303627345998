import {
  Field,
  ID,
  InputAndObjectType,
  ObjectType,
} from '@warebee/shared/util-backend-only-types';

@InputAndObjectType()
export class LocationSizeConstraintSettings {
  @Field({ nullable: true })
  disabled?: boolean;

  @Field({ nullable: true })
  unknownAsNoncompliant?: boolean;
}

@InputAndObjectType()
export class BayWidthConstraintSettings {
  @Field({ nullable: true })
  disabled?: boolean;
}

@ObjectType()
export class ConstraintState {
  @Field()
  compliantCount: number;

  @Field()
  nonCompliantCount: number;

  @Field()
  unknownCount: number;
}

@ObjectType()
export class ConstraintSummary {
  @Field(() => ID)
  type: string;

  @Field({ nullable: true })
  enforced?: boolean;

  @Field({ nullable: true })
  makeCompliant?: boolean;

  @Field(() => ConstraintState)
  before: ConstraintState;

  @Field(() => ConstraintState)
  after: ConstraintState;
}
