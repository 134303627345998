import _ from 'lodash';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const getKey = (postfix: string) => `warebee-global-${postfix}`;

export const importTriggeredBySim = atom<string>({
  key: getKey('import-triggered-by'),
  default: null,
});

export const billingStatusVisibilityState = atom({
  key: 'billingStatusVisibility',
  default: true,
  effects: [
    recoilPersist({
      key: 'billingStatusVisibility',
      storage: localStorage,
    }).persistAtom,
  ],
});

export const helperCalloutVisibilityState = atom({
  key: 'helperCalloutVisibility',
  default: true,
  effects: [
    recoilPersist({
      key: 'helperCalloutVisibility',
      storage: localStorage,
    }).persistAtom,
  ],
});

export const themeIds = ['Proglove', 'Devoca', 'WarebeeLight'];
export type ThemeId = (typeof themeIds)[number];

export const applyThemePreference = (theme: ThemeId) => {
  const root = window.document.documentElement;
  _.map(themeIds, id => root.classList.remove(id));
  !_.isNil(theme) && root.classList.add(theme);
};

export const appThemeSelectedId = atom<ThemeId>({
  key: getKey('theme-id'),
  default: null,
  effects: [
    recoilPersist({
      key: getKey('theme-id'),
      storage: localStorage,
    }).persistAtom,
  ],
});

export const appIsEmbedded = atom<boolean>({
  key: getKey('is-embedded'),
  default: null,
});

export const analyticsUserState = atom<boolean>({
  key: getKey('state-analytics'),
  default: true,
  effects: [
    recoilPersist({
      key: getKey('state-analytics'),
      storage: localStorage,
    }).persistAtom,
  ],
});

export const chatState = atom<boolean>({
  key: getKey('state-chat'),
  default: true,
  effects: [
    recoilPersist({
      key: getKey('state-chat'),
      storage: localStorage,
    }).persistAtom,
  ],
});
