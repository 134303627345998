import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRanger } from 'react-ranger';
import { FormattedValueWithUnit } from '../../common/formatHelper';
import { getPrecession, round } from '../../common/numberHelper';
import InputNumber from './InputNumber';

const handleSizes = {
  xs: 'w-[5ch] h-8',
  sm: 'w-10 h-10',
  lg: 'w-16 h-16',
  xl: 'w-20 h-20',
  '2xl': 'w-24 h-24',
  base: 'w-12 h-12',
} as const;

type HandleSizes = keyof typeof handleSizes;

export type InputSliderProps = {
  children?: React.ReactNode;

  className?: string;
  classNameTitle?: string;
  title?: string;
  values: number[];
  unitOfMeasure?: string;
  min: number;
  max: number;
  minValueTitle?: string;
  maxValueTitle?: string;
  stepSize: number;
  handleSize?: HandleSizes;
  valueInputs?: boolean;
  segmentsClassNames?: string[];
  handlesClassNames?: string[];
  hideHandlesValue?: boolean;
  isDisabled?: boolean;
  isMinimal?: boolean;
  hasSwitch?: boolean;
  switchValue?: boolean;
  activeMode?: boolean;
  hideTicks?: boolean;

  format?: (v: number) => FormattedValueWithUnit;
  onChange: (values: number[]) => void;
  onSwitchChange?: (value: boolean) => void;
};

const InputSlider: React.FC<InputSliderProps> = props => {
  const { t } = useTranslation('app');

  const isDisabled = props.isDisabled;
  const isHidden = props.hasSwitch && !props.switchValue;
  const { getTrackProps, ticks, segments, handles } = useRanger({
    min: props.min,
    max: props.max,
    stepSize: props.stepSize,
    values: props.values,
    ticks: [props.min, props.max],
    onChange: values =>
      !isDisabled &&
      props.onChange(values.map(v => parseFloat(v.toPrecision(7)))),
  });

  const segmentsClassNamesDefault = [
    'bg-menu-active/30 text-menu-active-text',
    'bg-app-panel-dark',
  ];
  const segmentsClassNamesDisabled = ['bg-app-panel', 'bg-app-panel-dark'];
  const segmentsClassNamesDefaultActive = [
    'bg-app-panel-dark',
    'bg-app-panel-dark/70',
  ];

  const segmentsClassNames =
    props.segmentsClassNames ??
    (props.activeMode
      ? segmentsClassNamesDefaultActive
      : isDisabled
        ? segmentsClassNamesDisabled
        : segmentsClassNamesDefault
    ).map(v => (isDisabled ? `${v}` : v));

  const precession = getPrecession(props.stepSize);

  /**
   * Get formatted value to display on handles
   */
  function getHandleValueText(value: number): string {
    if (_.isNil(value)) return null;

    if (value === props.min && !_.isNil(props.minValueTitle))
      return props.minValueTitle;

    if (value === props.max && !_.isNil(props.maxValueTitle))
      return props.minValueTitle;

    return round(value, precession).toLocaleString();
  }

  const sliderId = nanoid();

  return (
    <div
      key={sliderId}
      data-component="InputSlider"
      data-label={`input-slider-${props.title || ''}`}
      aria-label={`input-slider-${props.title || ''}`}
      // className={
      //   (classNames(
      //     'relative flex flex-col w-full',
      //     isDisabled
      //       ? 'mb-2 lg:mb-4'
      //       : props.isMinimal
      //       ? 'mb-1 md:mb-2 xl:mb-4 min-h-6'
      //       : 'mb-6 lg:mb-10',
      //   ),
      //   props.className)
      // }
      className={classNames(
        'relative flex w-full flex-col',
        isDisabled
          ? null // 'mb-2 lg:mb-4'
          : props.isMinimal
            ? 'mb-1 min-h-6 md:mb-2 xl:mb-4'
            : isHidden
              ? ''
              : 'mb-6 lg:mb-10',
        props.className,
      )}
    >
      {_.isNil(props.title) ? null : (
        <div
          className={classNames(
            'flex w-full items-center',
            props.isMinimal ? 'mb-3 mt-3' : 'my-4',
          )}
        >
          <div
            data-component={`InputSlider-title`}
            className={classNames(
              props.classNameTitle
                ? props.classNameTitle
                : 'text-xs lg:text-base',
              props.switchValue ? 'text-menu-text' : 'text-menu-text/50',
            )}
          >
            {props.title}
            {props.unitOfMeasure && (
              <span
                data-component="unitOfMeasure"
                className={classNames(
                  'px-1 py-0.5',
                  'ltr:ml-2 rtl:mr-2',
                  'text-xxs',
                  'border',
                  'rounded',
                  props.activeMode
                    ? 'text-menu/80 border-app-panel-dark/50 bg-app-panel-dark/50'
                    : 'text-menu-text/60 border-app-panel-dark/30 bg-app-panel-dark/60',
                )}
              >
                {props.unitOfMeasure}
              </span>
            )}
          </div>

          {props.hasSwitch && (
            <div
              className={classNames(
                'flex flex-1 ltr:justify-end rtl:justify-start',
              )}
            >
              <Switch
                checked={props.switchValue}
                disabled={props.isDisabled}
                onChange={props.onSwitchChange}
                className={classNames(
                  'relative inline-flex items-center rounded-full',
                  'h-4 w-7',
                  props.switchValue
                    ? props.isDisabled
                      ? 'bg-menu-active/50 text-menu-active-text'
                      : 'bg-menu-active text-menu-active-text'
                    : props.isDisabled
                      ? 'bg-app-panel-dark/50'
                      : 'bg-app-panel-dark/75 hover:bg-menu-active/75 hover:text-menu-active-text',
                )}
              >
                <span className="sr-only">{t`Disable`}</span>
                <span
                  className={classNames(
                    'inline-block',
                    'h-3 w-3',
                    'transform',
                    'rounded-full',
                    props.switchValue
                      ? 'bg-app-panel-dark/50'
                      : 'bg-app-panel-light/75',
                    props.switchValue ? 'translate-x-1' : 'translate-x-3',
                  )}
                />
              </Switch>
            </div>
          )}
        </div>
      )}
      {!isHidden && (
        <div
          data-component="trackContainer"
          className={classNames(
            'relative block w-full',
            props.isMinimal ? 'px-1' : 'mb-10 mt-2 p-2',
            { 'px-2': props.handleSize === 'xs' },
            { 'px-6': !props.handleSize },
            // { hidden: isHidden },
          )}
        >
          <div
            data-component="track"
            className={classNames('inline-block h-2 w-full', {
              'border-app-panel border-b': props.children,
            })}
            {...getTrackProps()}
          >
            {!props.hideTicks &&
              ticks.map(({ value, getTickProps }, tickIndex) => {
                const isFirstTick = tickIndex === 0;
                const tickLabel = isFirstTick ? t`min` : t`max`;

                const formatted = props.format ? props.format(value) : null;
                const valueString =
                  formatted?.value ?? round(value, precession).toLocaleString();
                const measureString = formatted?.unit ?? props.unitOfMeasure;

                return (
                  <div
                    data-component="tick"
                    key={`tick-value-${tickIndex}-${value}`}
                    {...getTickProps()}
                  >
                    <div
                      className={classNames('flex items-center', {
                        'mt-5': props.isMinimal,
                        'mt-6': !props.isMinimal,
                        'ltr:justify-start rtl:justify-end': isFirstTick,
                        'ltr:justify-end rtl:justify-start': !isFirstTick,
                      })}
                    >
                      <label
                        className={classNames('leading-tight', {
                          'text-start': isFirstTick,
                          'text-end': !isFirstTick,
                          'mt-0.5': props.isMinimal,
                          'mt-2': props.isMinimal,
                        })}
                      >
                        <div
                          className={classNames('text-xxs uppercase', {
                            'text-menu-text/75': props.activeMode,
                            'text-menu-text/60': !props.activeMode,
                          })}
                        >
                          {tickLabel}
                        </div>
                        <div
                          className={classNames('text-sm', 'mt-0.5', {
                            'text-menu-text': props.activeMode,
                            'text-menu-text/80': !props.activeMode,
                          })}
                        >
                          {valueString}

                          {!_.isNil(measureString) && (
                            <span
                              className={classNames(
                                'text-xxs opacity-75 ltr:ml-0.5 rtl:mr-0.5',
                                {
                                  'text-menu-active': props.activeMode,
                                  'text-menu-text/60': !props.activeMode,
                                },
                              )}
                            >
                              {measureString}
                            </span>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>
                );
              })}
            {segments.map(({ getSegmentProps }, i) => (
              <div
                data-component="segment"
                key={`${sliderId}-segment-${i}`}
                {...getSegmentProps()}
                className={classNames(
                  'h-full rounded',
                  segmentsClassNames?.[i],
                )}
              />
            ))}
            {handles.map(({ value, active, getHandleProps }, i) => (
              <button key={`${sliderId}-handles-${i}`} {...getHandleProps()}>
                <div
                  className={classNames(
                    'flex items-center justify-center whitespace-nowrap rounded-full text-sm',
                    handleSizes?.[props.handleSize] ?? 'h-12 w-12',
                    props.handlesClassNames?.[i]
                      ? props.handlesClassNames?.[i]
                      : !isDisabled &&
                          'bg-menu-active text-menu-text border-menu',

                    {
                      'bg-app-panel-dark backdrop-blur backdrop-saturate-100 backdrop-filter':
                        active,
                      '': !active && !isDisabled,
                    },
                    {
                      'bg-app-panel-dark/50 cursor-default': isDisabled,
                    },
                  )}
                  style={{
                    fontWeight: active ? 'bold' : 'normal',
                    transform: active
                      ? 'translateY(-40%) scale(1.3)'
                      : isDisabled
                        ? ''
                        : 'translateY(0) scale(0.9)',
                    transition: isDisabled
                      ? ''
                      : 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                  }}
                >
                  <InputNumber
                    isTransparent
                    inputClassName={classNames(
                      'bg-menu-active !text-menu-active-text',
                      'rounded-full',
                      'items-center justify-center',
                      'text-center',
                      '!min-w-[3ch]',
                      '!max-w-[6ch]',
                      Number.isInteger(value) ? 'text-sm' : 'text-xs',
                      handleSizes?.[props.handleSize] ?? 'w-12 h-12',
                    )}
                    value={value}
                    disabled={props.isDisabled}
                    onChange={v => {
                      const newValues = [...props.values];

                      // Round the value up to 1 decimal point
                      const roundedValue = Math.round(v * 10) / 10;

                      newValues[i] = roundedValue;
                      props.onChange(
                        newValues.map(v => parseFloat(v.toPrecision(7))),
                      );
                    }}
                  />
                  {/* {!props.hideHandlesValue && getHandleValueText(value)} */}
                </div>
              </button>
            ))}
            {props.children}
          </div>
        </div>
      )}
    </div>
  );
};

export default InputSlider;
