import classNames from 'classnames';
import React, { HTMLAttributes, useState } from 'react';
import { Button } from './actions/Button';

export type ButtonLocateActionProps = {
  selectValue: string;
  className?: string;
  classNameIcon?: string;
  isActive: boolean;
  onClick: () => Promise<void>;
  color?: [string, string];
  colorBorder?: string;
  colorText?: string;
  colorBg?: string;
  colorBgIcon?: string;
  title?: string;
  icon?: React.FC<HTMLAttributes<Element>>;
  children?: React.ReactNode;
};

const ButtonLocate: React.FC<ButtonLocateActionProps> = props => {
  const [isProgress, setInProgress] = useState(false);
  const IconAction = props.icon;

  const cellStyle = {
    background: props.colorBg || props.color?.[0],
    color: props.colorText || props.color?.[1],
    border: `${props.colorBorder}` || null,
  };

  return (
    <Button
      data-component="ButtonLocate"
      className={classNames(
        'text-xs',
        'flex-1 items-start text-start',
        'bg-menu',
        // '!px-1',
        'hover:text-menu-text',
        {
          'text-menu-active': props.isActive,
        },
        'rounded',
        props.isActive
          ? 'border-opacity-100 border-dashed border shadow-xl border-menu-active'
          : 'border-opacity-30 border-px',
        props.className,
      )}
      buttonType="helper"
      buttonSize="xs"
      full
      isDisabled={isProgress}
      isLoading={isProgress}
      onPress={async () => {
        setInProgress(true);
        await props.onClick();
        setInProgress(false);
      }}
      label={props.title ? props.title : props.selectValue}
      hasIconBefore={
        // <div
        //   className={classNames(
        //     'w-5 h-5',
        //     'rounded',
        //     'flex items-center justify-center',
        //     props.classNameIcon,
        //   )}
        //   style={cellStyle}
        // >
        IconAction && (
          <IconAction
            data-component="ResourceIcon"
            className={classNames(
              'w-5 h-5',
              'fill-current',
              'ltr:mr-2 rtl:ml-2',
              props.classNameIcon,
            )}
          />
        )
        // </div>
      }
      style={cellStyle}
    >
      {props.children}
    </Button>
  );
};
export default ButtonLocate;
