import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import useChangeSidebarState from '../../common/useChangeSidebarState';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import ButtonToolbar from '../../components/actions/ButtonToolbar';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { importTriggeredBySim } from '../../store/global.state';
import {
  warehouseAllAssignmentsMeta,
  warehouseCanUpdate,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import { useLoadAllAssignmentsMeta } from '../../warehouse/hooks/useLoadAllAssignmentsMeta';
import {
  HelperWidgetImportInitialSetup,
  HelperWidgetImportLocked,
  HelperWidgetImportSelector,
  HelperWidgetNextStep,
} from '../helper/HelperWidgets';
import useUpdateSimulation from '../hooks/useUpdateSimulation';
import {
  simulationCurrent,
  simulationIsEditable,
  simulationShowDatasetAsTable,
} from '../store/simulation.state';

const SimulationDatasetPanelAssignment: React.FC = () => {
  const whId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const canChange = useRecoilValue(simulationIsEditable);

  const assignmentsMeta = useRecoilValue(warehouseAllAssignmentsMeta);
  const [simulation, setSimulation] = useRecoilState(simulationCurrent);
  const updateSimulation = useUpdateSimulation();
  const changeSidebar = useChangeSidebarState('sidebar-simulation-issues');
  const setImportTriggerSimId = useSetRecoilState(importTriggeredBySim);

  const [showAsTable, setShowAsTable] = useRecoilState(
    simulationShowDatasetAsTable,
  );
  const loadAssignmentList = useLoadAllAssignmentsMeta();
  const { t } = useTranslation('simulation');
  const navigate = useNavigate();

  useEffect(() => {
    loadAssignmentList(whId);
  }, []);

  const changeAssignment = (assignmentId: string) => {
    setSimulation({
      ...simulation,
      assignment: { id: assignmentId },
    });
    changeSidebar({ isCollapsed: false });
    updateSimulation({ assignmentId });
  };
  const proceedWithImport = () => {
    setImportTriggerSimId(simulation.id);
    navigate(`/wh/i/${whId}/import/assignment`);
  };
  const assignments = assignmentsMeta.items;

  const assignmentMap = _.keyBy(assignments, a => a.id);
  const isLoading =
    assignmentsMeta.status === AsyncLoadStatus.None ||
    assignmentsMeta.status === AsyncLoadStatus.Loading;

  const datasetSelectorMenu = () => {
    return (
      <div
        className={classNames(
          'group',
          'flex items-center',
          'py-1 lg:py-2',
          canChange ? 'px-4' : 'px-1',
        )}
      >
        <DropdownSelector
          value={
            simulation?.assignment?.id ||
            t`No Assignment Selected, Click to Select`
          }
          values={_.keys(assignmentMap)}
          renderValue={v =>
            `[${assignmentMap[v]?.status}] ${assignmentMap[v]?.title || v}`
          }
          valueHelper={v =>
            assignmentMap[v]?.updatedAt
              ? new Date(assignmentMap[v]?.updatedAt).toLocaleString('en-GB')
              : ''
          }
          filterValue={(v, searchString) =>
            `[${assignmentMap[v]?.status}] ${assignmentMap[v]?.title || v}`
              .toLowerCase()
              .indexOf(searchString.toLowerCase()) >= 0
          }
          hasSubAction
          subAction={t`Import Assignment`}
          DropAlignRight
          widthFull
          valuePrimary
          multiline
          light
          onChange={changeAssignment}
          onSubActionClick={proceedWithImport}
          disabled={!canChange || !canUpdate}
          border={canChange || canUpdate}
          hasSearch
          hasSearchLabel={t`Stock Assignment`}
          // asyncSearchValue={v =>
          //   `${assignmentMap[v]?.title || v}`
          // }
        />
        {simulation?.assignment?.id && (
          <ButtonToolbar
            titleTrace={`Assignment Datatable`}
            className={classNames(
              'ltr:ml-2 rtl:mr-2',
              showAsTable === 'assignment' ? '' : 'hidden group-hover:block',
            )}
            label={t`Show Data`}
            isBoxed
            isSelected={showAsTable === 'assignment'}
            onChange={() =>
              setShowAsTable(showAsTable === 'assignment' ? null : 'assignment')
            }
          >
            {showAsTable === 'assignment' ? (
              <Icon.ShowTable
                className={classNames('h-9 w-9 lg:h-10 lg:w-10', 'p-0.5')}
              />
            ) : (
              <Icon.HideTable
                className={classNames('h-9 w-9 lg:h-10 lg:w-10', 'p-0.5')}
              />
            )}
          </ButtonToolbar>
        )}
      </div>
    );
  };

  return (
    <>
      <ScreenTitle
        title={t`Assignment`}
        subtitle={t`Simulation Dataset`}
        isSticky
        helpNavTo={'simulation/dataset/simulation-dataset-assignment'}
        icon={Icon.DataAssignment}
      />

      {isLoading ? (
        <LoadingIndicator message={t`Loading assignments`} selfCenter />
      ) : assignmentsMeta.status === AsyncLoadStatus.Error ? (
        <ErrorIndicator selfCenter message={t`Cannot load assignments`} />
      ) : !_.isEmpty(assignments) ? (
        !canChange ? (
          <HelperWidgetImportLocked title={t`Assignment`}>
            {datasetSelectorMenu()}
          </HelperWidgetImportLocked>
        ) : (
          <>
            <HelperWidgetImportSelector title={t`Assignments`} />
            {datasetSelectorMenu()}
          </>
        )
      ) : (
        <HelperWidgetImportInitialSetup
          dataType="assignment"
          title={t`Import Assignment (csv)`}
        />
      )}

      {simulation?.assignment?.id ? (
        <HelperWidgetNextStep title={t`Order Set import`} hasDataIssues />
      ) : null}
    </>
  );
};

export default SimulationDatasetPanelAssignment;
