import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../common/useFormatter';
import { ColumnConfig } from '../../components/DatasetTable';
import { getActualityMetricDescriptorsMap } from '../../metrics/actuality/actualityMetric.default';
import { actualityMetricSelected } from '../store/actuality.heatmap.state';
import { ActualityHeatmapDataRow } from '../store/datasetQueries/actualityHeatmapData';

function useActualityHeatmapDataTableConfig(): ColumnConfig<ActualityHeatmapDataRow>[] {
  const { t } = useTranslation('feed');
  const formatter = useFormatter();

  const heatmapMetricId = useRecoilValue(actualityMetricSelected);
  const metricDescriptors = getActualityMetricDescriptorsMap(t, formatter);
  const metric = metricDescriptors[heatmapMetricId];

  const columnsConfig: ColumnConfig<ActualityHeatmapDataRow>[] = [
    {
      field: 'skuKey',
      title: t`Consignee-SKU`,
    },
    { field: 'locationId', title: t`Location` },
    {
      field: 'dimensionValue',
      title: metric.title,
      render: v => (_.isNumber(v) && metric.format ? metric.format(v) : v),
    },
  ];

  return columnsConfig;
}
export default useActualityHeatmapDataTableConfig;
