import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { containsFilter } from '../../common/filterHelper';
import { AsyncLoadStatus, DatasetDataViewProps } from '../../common/types';
import DatasetTable from '../../components/DatasetTable';
import useLoadActualityHqTableData from '../hooks/useLoadActualityHqTableData';
import {
  actualityHqBuilderParams,
  actualityHqTableData,
  actualityHqTableDataLoadStatus,
  actualityHqTableDataState,
  actualityHqTableTotalCount,
} from '../store/actuality.hq.state';
import { ActualityHqDataRow } from '../store/datasetQueries/actualityHqDataRows';
import { ActualityHqFilterBy } from '../store/datasetQueries/actualityQueryBuilder';
import useActualityHqDataTableConfig from './useActualityHqDataTableConfig';

const ActualityHqDataTable: React.FC<
  DatasetDataViewProps<ActualityHqDataRow>
> = props => {
  const { t } = useTranslation('dataset');
  // const whId = useRecoilValue(warehouseSelectedId);
  // const datasetId = useRecoilValue(datasetObjectDocumentId);
  const data = useRecoilValue(actualityHqTableData);
  const totalCount = useRecoilValue(actualityHqTableTotalCount);
  const status = useRecoilValue(actualityHqTableDataLoadStatus);
  const queryBuilderParams = useRecoilValue(actualityHqBuilderParams);
  const [dataState, setDataState] = useRecoilState(actualityHqTableDataState);
  // const [runExportCSV] = useRunExportJobMutation();

  const [loadCallback, reset] = useLoadActualityHqTableData();
  const columnsConfig = useActualityHqDataTableConfig();

  useEffect(() => {
    callDataLoad(dataState.searchValues);
  }, [props.id, dataState.searchValues, queryBuilderParams]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  function callDataLoad(
    searchValues: Record<string, string>,
    page = { isAppend: false, skip: 0 },
  ) {
    if (_.isNil(props.id)) return;

    const filterBy: ActualityHqFilterBy = _.reduce(
      _.entries(searchValues),
      (acc, [key, value]) => {
        return {
          ...acc,
          [key]: containsFilter(value),
        };
      },
      {} as ActualityHqFilterBy,
    );
    // {
    //   locationId: containsFilter(searchValues['locationId']),
    //   sku: containsFilter(searchValues['sku']),
    //   consignee: containsFilter(searchValues['consignee']),
    //   jobId: containsFilter(searchValues['jobId']),
    //   importJobId: containsFilter(searchValues['importJobId']),
    // }

    loadCallback({
      ...queryBuilderParams,
      isAppend: page.isAppend,
      page: {
        skip: page.skip,
      },
      filterBy,
    });
  }

  function onLoadNext() {
    callDataLoad(dataState.searchValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  // const converterConfig: ActivityFeedConverterConfig = {
  //   columnsConfig,
  // };

  // async function startExportCSV() {
  //   const query = getFeedTotalRowsQuery({
  //     datasetId,
  //   }).compile();
  //   const variables: ActivityFeedExportJobParams = {
  //     ...activityFeedExportJobParams,
  //     query: QueryDatasetDocument.loc.source.body,
  //     config: converterConfig,
  //     variables: {
  //       warehouseId: whId,
  //       query: query.sql,
  //       params: query.parameters,
  //     },
  //   };
  //   const { data, errors } = await runExportCSV({
  //     variables,
  //   });

  //   return {
  //     errors: errors,
  //     job: data.createExportJob,
  //   };
  // }

  // const flattenData = getActivityFeedTableRows(
  //   data?.content as ActivityFeedEventDataset[],
  //   converterConfig,
  // );

  const isLoading = status === AsyncLoadStatus.Loading;
  const itemsCount = data?.length || 0;

  if (props.customRenders) {
    _.forEach(props.customRenders, (renderer, key) => {
      const cfg = _.find(columnsConfig, c => c.field === key);
      if (!_.isNil(cfg)) {
        cfg.render = renderer;
      }
    });
  }

  return (
    <DatasetTable
      id={props.id}
      data={data}
      columnsConfig={columnsConfig}
      keyFields={['eventId']}
      hasCounter
      hideScreenTitle={props.hideScreenTitle}
      subtitle={t`Activities`}
      title={props.title}
      isSticky
      onLoadNext={onLoadNext}
      onSearch={v => {
        setDataState({ ...dataState, searchValues: v });
      }}
      totalCount={totalCount}
      searchValues={dataState.searchValues}
      isLoading={isLoading}
      // onStartExportClick={startExportCSV}
    />
  );
};

export default ActualityHqDataTable;
