import { BillableOperationType } from '@warebee/frontend/app-billing-graphql-api';
import {
  WarehouseType,
  useDemoWarehousesQuery,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import AppBillingWarehouseStatus from '../../appBilling/AppBillingWarehouseStatus';
import {
  appBillingWarehouseLoadStatus,
  appBillingWarehouseState,
  appBillingWarehouseStateSummary,
} from '../../appBilling/store/appBilling.state';
import { cctvEnabled } from '../../cctv/cctv.state';
import {
  APP_VER,
  BUILD_ENV,
  BUILD_HASH,
  BUILD_ID,
  BUILD_TIMESTAMP,
} from '../../common/environmentVariables';
import { formatDateTime } from '../../common/formatHelper';
import { AsyncLoadStatus } from '../../common/types';
import { HelperMessageAction } from '../../components/HelperMessage';
import { InboxZero } from '../../components/InboxZero';
import LanguagePrompt from '../../components/LanguagePrompt';
import LanguageSelector from '../../components/LanguageSelector';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Button } from '../../components/actions/Button';
import DropdownSelector from '../../components/actions/DropdownSelector';
import { MailTo } from '../../components/actions/MailTo';
import BillingTag from '../../components/billing/BillingTag';
import * as Icon from '../../components/icons';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import { ContainerScroll } from '../../components/layout/ContainerScroll';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import LinkTraced from '../../components/nav/LinkTraced';
import PanelContainer from '../../containers/PanelContainer';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import Callout from '../../helpContext/Callout';
import {
  ViewerCachingOption,
  ViewerCachingSizeOption,
  getLayoutCachingOptions,
  getLayoutCachingSizeOptions,
} from '../../layout/viewer/store/viewer.helper';
import { viewerLayoutCachingSettings } from '../../layout/viewer/store/viewer.state';
import ThemeSelector from '../../screens/settings/ThemeSelector';
import { loggedInUser } from '../../store/auth.state';
import {
  analyticsUserState,
  billingStatusVisibilityState,
  chatState,
  helperCalloutVisibilityState,
} from '../../store/global.state';
import { sidebarStateByType } from '../../store/sidebar.state';
import {
  userSettings,
  userSettingsLoadStatus,
  warehouseList,
  warehouseListLoadStatus,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import useAssignDemoWarehouse from '../../warehouse/hooks/useAssignDemoWarehouse';
import useUpdateUserSettings from '../../warehouse/hooks/useUpdateUserSettings';
import SettingsAccount from './SettingsAccount';
import SettingsFeed from './SettingsFeed';

const SidebarSettings: React.FC = props => {
  const currentWhId = useRecoilValue(warehouseSelectedId);
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const whList = useRecoilValue(warehouseList);
  const whListLoadStatus = useRecoilValue(warehouseListLoadStatus);

  const settings = useRecoilValue(userSettings);
  const settingsStatus = useRecoilValue(userSettingsLoadStatus);
  const updateUserSettings = useUpdateUserSettings();

  const [CCTVEnabled, setCCTVEnabled] = useRecoilState(cctvEnabled);
  const [analyticsEnabled, setAnalyticsEnabled] =
    useRecoilState(analyticsUserState);
  const [chatEnabled, setChatEnabled] = useRecoilState(chatState);

  const [isBillingStatusVisible, setBillingStatusVisible] = useRecoilState(
    billingStatusVisibilityState,
  );
  const toggleBillingStatusVisibility = () => {
    setBillingStatusVisible(!isBillingStatusVisible);
  };

  const [isHelperCalloutVisible, setHelperCalloutVisible] = useRecoilState(
    helperCalloutVisibilityState,
  );
  const toggleHelperCalloutVisibility = () => {
    setHelperCalloutVisible(!isHelperCalloutVisible);
  };

  const [sidebarState, setSidebarState] = useRecoilState(
    sidebarStateByType('sidebar-global-settings'),
  );
  const assignDemo = useAssignDemoWarehouse();
  const {
    loading: loadingDemoWh,
    error,
    data: demoWhData,
  } = useDemoWarehousesQuery();

  // const [themeId, setThemeId] = useRecoilState(appThemeSelectedId);
  // const themeOptions: ThemeId[] = [null, ...themeIds];

  const demoWHlist = loadingDemoWh ? [] : demoWhData.demoWarehouses;

  const whId = useRecoilValue(warehouseSelectedId);
  const user = useRecoilValue(loggedInUser);

  const billingState = useRecoilValue(appBillingWarehouseStateSummary);
  const billingStateLoadStatus = useRecoilValue(appBillingWarehouseLoadStatus);
  const whBillingState = useRecoilValue(appBillingWarehouseState);
  const subscription = _.head(whBillingState?.currentSubscriptions);
  const [cachingSettings, setCachingSettings] = useRecoilState(
    viewerLayoutCachingSettings,
  );

  const cachingTypesOptions = _.keyBy(getLayoutCachingOptions(t), i => i.id);
  const cachingSizeOptions = _.keyBy(getLayoutCachingSizeOptions(t), i => i.id);

  let billingSummaryMessage = '';

  if (subscription) {
    billingSummaryMessage = t(`Your subscription is active until {{endDate}}`, {
      endDate: formatDateTime(new Date(subscription.currentPeriodEnd)),
    });
  } else if (
    _.find(
      whBillingState?.balance,
      b => b.operationType === BillableOperationType.ANALYZE,
    )?.balance > 0
  ) {
    billingSummaryMessage = t(
      `You have {{simulationCount}} analyse simulations left.`,
      {
        simulationCount: _.find(
          whBillingState?.balance,
          b => b.operationType === BillableOperationType.ANALYZE,
        )?.balance,
      },
    );
  } else if (
    _.find(
      whBillingState?.balance,
      b => b.operationType === BillableOperationType.OPTIMIZE,
    )?.balance > 0
  ) {
    billingSummaryMessage = t(
      `You have {{simulationCount}} optimize simulations left.`,
      {
        simulationCount: _.find(
          whBillingState?.balance,
          b => b.operationType === BillableOperationType.OPTIMIZE,
        )?.balance,
      },
    );
  }
  // } else if
  //    ( billingState === 'isTrial')
  // {
  //   billingSummaryMessage = t(`You are currently running a trial`);
  // }
  // } else if
  //    ( billingState === 'unpaid')
  // {
  //   billingSummaryMessage = t(`This warehouse, has no active subscription, please purchase one to continue.`);
  // }
  else {
    billingSummaryMessage = t(
      `This warehouse has no active subscription, please subscribe to continue.`,
    );
  }

  const [localLoading, setLocalLoading] = useState(false);

  return (
    <ContainerScroll className="flex flex-col">
      <Callout panelMode type="suggestion">
        <div>{t(`Hello ${user.firstName}`)}</div>
        <div>{t`I am WareBee helper`}</div>
        <div>{t`Here you can change your account and warehouse settings`}</div>
      </Callout>
      <SettingsAccount />
      <PanelContainer
        id="panel-user-warehouses"
        title={t`Warehouse Settings`}
        collapsible
        // hasPadding
      >
        <PanelContainer
          id="panel-my-warehouses"
          // hasPadding
          // collapsible
          transparent
          title={t`My Warehouses`}
          className="flex flex-wrap space-y-1"
        >
          <ScreenTitle
            isSticky
            subtitle={t`System`}
            title={t`Warehouse Settings`}
          />

          <Callout
            panelMode
            type="suggestion"
            iconAfter
            icon={Icon.ArrowRightBottom}
          >
            {t`Select`}
            <HelperMessageAction>{t`the warehouse`}</HelperMessageAction>
            {t`to change it's settings`}
          </Callout>

          {whListLoadStatus === AsyncLoadStatus.Loading ? (
            <LoadingIndicator />
          ) : (
            <div className="divide divide-menu-800 divide-y p-2">
              {_(whList)
                .filter(
                  wh => wh.type !== WarehouseType.DEMO || wh.type !== null,
                )
                .map(wh => (
                  <Button
                    className="backdrop-saturate-110 bg-opacity-80 px-1 text-start backdrop-blur-lg backdrop-filter"
                    key={`wh-${wh.id}`}
                    buttonSize={'sm'}
                    buttonType={'secondary'}
                    // buttonType={'primary'}
                    label={wh.title}
                    full
                    hasIconBefore
                    buttonIcon={
                      <Icon.WarehouseParams1
                        className={`h-10 w-10 fill-current p-0.5`}
                      />
                    }
                    onPress={() => {
                      setSidebarState({ ...sidebarState, isCollapsed: true });
                      navigate(`/wh/i/${wh.id}/edit`);
                    }}
                  />
                ))
                .value()}
            </div>
          )}
        </PanelContainer>
        <PanelContainer
          id="panel-demo-warehouses"
          // hasPadding
          // collapsible
          transparent
          title={t`Demo Warehouses`}
          classNameInner="p-2"
        >
          <ScreenTitle
            isSticky
            subtitle={t`System`}
            title={t`Demo Warehouses`}
          />

          <Callout
            panelMode
            type="suggestion"
            iconAfter
            icon={Icon.ArrowRightBottom}
          >
            {t`Select`}
            <HelperMessageAction>{t`the warehouse`}</HelperMessageAction>
            {t`to add the warehouse as a Demo`}
          </Callout>

          <div className="p-2">
            {loadingDemoWh ? (
              <LoadingIndicator message={t`Loading Demo...`} />
            ) : (
              demoWHlist.map(wh => {
                const isSubscribed = _.some(
                  whList,
                  w => w.id === wh.warehouse.id,
                );
                return (
                  <div
                    key={`${wh.warehouse.id}`}
                    className="bg-app-panel-light/50"
                  >
                    {/* <Button
                    className="px-1 text-start bg-opacity-80 backdrop-filter backdrop-blur-lg backdrop-saturate-110"
                    key={`wh-${wh.warehouse.id}`}
                    buttonSize={'md'}
                    buttonType={`primary`}
                    // buttonType={'primary'}
                    label={wh.warehouse.title}
                    full
                    isDisabled={localLoading}
                    hasIconBefore
                    buttonIcon={
                      <Icon.LayoutWarehouseTemplate
                        className={`w-16 h-16 fill-current p-0.5`}
                      />
                    }
                    onPress={async () => {
                      setLocalLoading(true);
                      await assignDemo(wh.warehouse.id, !isSubscribed);
                      if (currentWhId === wh.warehouse.id && isSubscribed) {
                        navigate('/');
                      }
                      setLocalLoading(false);
                    }}
                  /> */}
                    <InputCheckbox
                      className="p-2"
                      key={`wh-demo-${wh.warehouse.id}`}
                      name="warehouse-demo"
                      isDisabled={localLoading}
                      isSelected={isSubscribed}
                      onChange={async () => {
                        setLocalLoading(true);
                        await assignDemo(wh.warehouse.id, !isSubscribed);
                        if (currentWhId === wh.warehouse.id && isSubscribed) {
                          navigate('/');
                        }
                        setLocalLoading(false);
                      }}
                    >
                      <div className="text-white">{wh.warehouse.title}</div>
                    </InputCheckbox>
                  </div>
                );
              })
            )}
          </div>
        </PanelContainer>
      </PanelContainer>
      <PanelContainer
        id="panel-account-subscription"
        title={t`Subscription & Billing`}
        collapsible
        // hasPadding
      >
        <ScreenTitle
          isSticky
          subtitle={t`Billing`}
          title={t`Subscriptions & Purchases`}
        />

        <div className="mb-4 p-4">
          <label className="text-menu-200 mb-2 mt-3">{t`Status`}</label>

          <LinkTraced
            to={`/wh/i/${whId}/pricing/`}
            data-label={`BillingState-setting-button-${billingState}`}
            aria-label={`BillingState-setting-button-${billingState}`}
            titleTrace={`Billing: ${billingState}`}
          >
            <BillingTag
              billingState={billingState}
              billingStateLoadStatus={billingStateLoadStatus}
            />
          </LinkTraced>
        </div>

        <Callout panelMode type="suggestion">
          {billingSummaryMessage}
          <AppBillingWarehouseStatus />
        </Callout>

        <ScreenTitle isSticky subtitle={t`Billing`} title={t`Invoices`} />
        <Callout panelMode type="suggestion">
          <InboxZero message={t`Please contact support`} />
        </Callout>

        <div className="mb-4 p-4">
          <label className="text-menu-200 mb-2 mt-3">{`Billing Alerts`}</label>
          <InputCheckbox
            name="visibility"
            className="p-2"
            isSelected={isBillingStatusVisible}
            onChange={toggleBillingStatusVisibility}
          >
            {isBillingStatusVisible
              ? 'Hide Billing Alerts'
              : 'Show Billing Alerts'}
          </InputCheckbox>
        </div>
      </PanelContainer>
      <PanelContainer
        id="panel-system-notifications"
        title={t`Notification Settings`}
        collapsible
        // hasPadding
      >
        <ScreenTitle isSticky subtitle={t`System`} title={t`Notifications`} />
        <section className="divide-menu-400/50 mb-4 w-full divide-y divide-solid p-4">
          <InputCheckbox
            name="notification"
            className="p-2"
            isDisabled={settingsStatus === AsyncLoadStatus.Loading}
            isSelected={settings?.notifications?.simulations ?? false}
            onChange={v =>
              updateUserSettings({
                notifications: {
                  simulations: v,
                },
              })
            }
          >
            <div>{t`Simulation Notifications`}</div>
          </InputCheckbox>
          <InputCheckbox
            name="notification"
            className="p-2"
            isDisabled={settingsStatus === AsyncLoadStatus.Loading}
            isSelected={settings?.notifications?.news ?? false}
            onChange={v =>
              updateUserSettings({
                notifications: {
                  news: v,
                },
              })
            }
          >
            <div>{t`WareBee News`}</div>
          </InputCheckbox>
        </section>
      </PanelContainer>
      <PanelContainer
        id="panel-system-language"
        title={t`System Language`}
        collapsible
        // hasPadding
      >
        <ScreenTitle isSticky subtitle={t`System`} title={t`Language`} />

        <div className="p-4">
          <LanguagePrompt />
          <LanguageSelector panelMode />
        </div>
      </PanelContainer>

      <PanelContainer
        id="panel-system-performance"
        title={t`Performance`}
        collapsible
        // hasPadding
      >
        <ScreenTitle
          isSticky
          subtitle={t`System`}
          title={t`Performance and caching`}
        />
        <section className="divide-menu-400/50 mb-4 w-full divide-y divide-solid p-4">
          <DropdownSelector
            className="my-4 text-base"
            classNameLabel="flex-1"
            classNameValue="relative"
            headerMode
            label={t('Layout caching')}
            value={cachingSettings.type}
            values={_.keys(cachingTypesOptions) as ViewerCachingOption[]}
            renderValue={v => cachingTypesOptions[v]?.title || ''}
            onChange={v =>
              setCachingSettings({
                ...cachingSettings,
                type: v,
              })
            }
          />
          <DropdownSelector
            className="my-4 text-base"
            classNameLabel="flex-1"
            headerMode
            label={t('Cache size')}
            disabled={cachingSettings.type === 'off'}
            value={cachingSettings.size}
            values={_.keys(cachingSizeOptions) as ViewerCachingSizeOption[]}
            renderValue={v => cachingSizeOptions[v]?.title || ''}
            onChange={v =>
              setCachingSettings({
                ...cachingSettings,
                size: v,
              })
            }
          />
        </section>
      </PanelContainer>

      <PanelContainer
        id="panel-system-legal"
        title={t`Legal`}
        collapsible
        // hasPadding
      >
        <ScreenTitle
          isSticky
          subtitle={t`Legal`}
          title={t`Terms of Service and Privacy Policy`}
        />

        <Callout
          panelMode
          type="suggestion"
          // iconAfter
          // icon={Icon.ArrowRightBottom}
        >
          <Icon.TriangleInfo className="text-alerts-warn h-4 w-4 fill-current ltr:mr-2 rtl:ml-2" />
          {t`Removing the checkboxes will deactivate your account.`}
        </Callout>

        {/* <HelperMessage isSuggestion hasPadding className={`mt-4 pt-4`}>
          <div className="flex mt-4">
            <Icon.TriangleInfo className="w-4 h-4 ltr:mr-2 rtl:ml-2 fill-current" />
            {t`Removing the checkboxes will deactivate your account.`}
          </div>
        </HelperMessage> */}
        <div className="p-4">
          <fieldset>
            <InputCheckbox
              name="tosConfirmation"
              // isDisabled
              isSelected
              // ref={register}
              className="pt-4 ltr:pl-2 rtl:pr-2"
            >
              <div>
                {t`I agree to`}
                {` `}
                {t`WareBee`}
                {` `}
                <Link to="/legal/terms" className="font-bold underline">
                  {t`Terms of Service`}
                </Link>
              </div>
            </InputCheckbox>
          </fieldset>
          <fieldset>
            <InputCheckbox
              name="privacyPolicyConfirmation"
              // isDisabled
              isSelected
              // ref={register}
              className="ltr:pl-2 rtl:pr-2"
            >
              <div>
                {t`I accept WareBee use of my data for the service and everything else described in the `}
                <Link to="/legal/privacy" className="font-bold underline">
                  {t`Privacy Policy`}
                </Link>
                .
              </div>
            </InputCheckbox>
          </fieldset>
        </div>
      </PanelContainer>
      <PanelContainer
        id="panel-system-gdpr"
        title={t`GDPR`}
        collapsible
        // hasPadding
      >
        <ScreenTitle
          isSticky
          subtitle={t`GDPR Policy`}
          title={t`Deactivating and Deletion`}
        />

        <Callout panelMode type="suggestion">
          {t`Temporarily deactivate or permanently delete your account.`}
          <div className="mt-4">
            <Icon.TriangleInfo className="h-4 w-4 fill-current ltr:mr-2 rtl:ml-2" />
            {t`You can deactivate your account. Or if you want to permanently delete your WareBee account, let us know at `}
            <MailTo
              email="support+gdpr@warebee.com"
              subject="[WAREBEE] GDPR - Delete Account"
              body={`Hello, Please delete my account`}
            >
              <div>{t`support@warebee.com`}</div>
            </MailTo>
          </div>
        </Callout>

        <div className="mt-4 space-y-1 p-4">
          <Button
            className="backdrop-saturate-110 bg-opacity-80 p-2 text-start backdrop-blur-lg backdrop-filter"
            buttonSize={`lg`}
            label={t`Deactivate my Account`}
            full
            hasIconBefore
            buttonType={`primary`}
            // buttonType={`primary`}
            buttonIcon={<Icon.CircleX className={`h-6 w-6 fill-current`} />}
          />

          <Button
            className="backdrop-saturate-110 bg-opacity-80 p-2 text-start backdrop-blur-lg backdrop-filter"
            buttonSize={`lg`}
            label={t`Delete my Account and All Data`}
            full
            hasIconBefore
            buttonType={`primary`}
            // buttonType={`primary`}
            buttonIcon={<Icon.CircleX className={`h-6 w-6 fill-current`} />}
          />
        </div>
      </PanelContainer>
      <PanelContainer
        id="panel-system-integrations-settings"
        title={t`Integration Settings`}
        collapsible
        // hasPadding
      >
        <TitleSection inPanelView title={t`Features`}>
          <SettingsFeed />
        </TitleSection>

        <TitleSection inPanelView title={t`Demo`}>
          {/* <ScreenTitle isSticky subtitle={t`Demo`} title={t`Integration`} /> */}
          <section className="divide-menu-400/50 mb-4 w-full divide-y divide-solid p-4">
            <InputCheckbox
              name="notification"
              className="p-2"
              isSelected={CCTVEnabled}
              onChange={v => setCCTVEnabled(v)}
            >
              <div>{t`CCTV Integration (Demo Warehouse)`}</div>
            </InputCheckbox>
            <Callout panelMode type="suggestion">
              {/* <svg
              id="a"
              data-name="Icons"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 128 153.42"
              className="w-12 h-12 float-left mr-2 p-0.5"
            >
              <path
                d="m60.32,15.51v130.99L5.4,114.86,60.32,15.51m4-15.51L0,116.37l64.32,37.05V0h0Z"
                fill="#ececec"
              />
              <path
                d="m107.9,127.95l-44.22,25.47V0h0l52.92,96.2-6.06,3.35L63.68,14.72v130.19l40.77-22.95m14.21-8.18"
                fill="#ececec"
              />
              <polyline
                points="118.65 113.77 112.94 103.7 119 100.35 128 116.37 111.92 125.63 108.47 119.63 118.65 113.77"
                fill="#00b8d3"
              />
            </svg> */}
              <span>{`Automate Warehouse safety by integrating with existing infrastructure like security cameras (CCTV) and sensors and gives real-time insights around working conditions, unsafe events, and operations.`}</span>
              {/* <div>
              {`Visit`}
              <a
                className="text-menu-active hover:underline mx-1"
                href="https://arvist.ai"
                target="_blank"
                rel="noreferrer"
              >
                {`https://arvist.ai`}
              </a>
              {`to learn more`}
            </div> */}
            </Callout>
          </section>
        </TitleSection>
      </PanelContainer>
      <PanelContainer
        id="panel-system-automation"
        title={t`Automation Settings`}
        collapsible
        // hasPadding
      >
        <Callout panelMode type="suggestion">
          <p>{t`Automation is not enabled for this account`}</p>
          <p>{t`Please contact support`}</p>
          <div className="mt-4">
            <MailTo
              email="support+automation@warebee.com"
              subject="[WAREBEE] Automation"
              body={`WareBee Automation`}
            >
              <div>{t`support@warebee.com`}</div>
            </MailTo>
          </div>
        </Callout>
      </PanelContainer>
      <PanelContainer
        id="panel-system-theme"
        title={t`Display Settings`}
        collapsible
      >
        <div className="p-4">
          <ThemeSelector />
        </div>

        {/* <div className="p-4">
          <RouteSelector />
        </div> */}

        <div className="p-4">
          <InputCheckbox
            name="Chat"
            className="p-2"
            isSelected={chatEnabled}
            onChange={v => setChatEnabled(v)}
          >
            <div>{t`Chat (Support)`}</div>
          </InputCheckbox>
        </div>
      </PanelContainer>
      <PanelContainer
        id="panel-system-about"
        title={t`About`}
        collapsible
        // hasPadding
      >
        <ScreenTitle isSticky subtitle={t`About`} title={t`WareBee`} />
        <Callout panelMode type="suggestion">
          {t`WareBee is running version:`}
          <div className="flex items-center py-4">
            <div className="select-text">{APP_VER}</div>
          </div>
          <div className="divide-menu-50/10 flex flex-col space-y-2 divide-y font-mono text-xs">
            <div className="w-full select-text pt-2">{BUILD_TIMESTAMP}</div>
            <div className="w-full select-text pt-2">{BUILD_HASH}</div>
            <div className="w-full select-text pt-2">{BUILD_ENV}</div>
            <div className="w-full select-text pt-2">{BUILD_ID}</div>
          </div>
        </Callout>
      </PanelContainer>
      <PanelContainer
        id="panel-system-support-data"
        title={t`Support Data`}
        collapsible
        // hasPadding
      >
        <div className="px-4">
          <InputCheckbox
            name="Analytics"
            className="p-2"
            isSelected={analyticsEnabled}
            onChange={v => setAnalyticsEnabled(v)}
          >
            <div>{t`App Analytics`}</div>
          </InputCheckbox>
        </div>

        <div className="border-menu-400 flex items-center border-b border-opacity-50 p-4 py-2">
          <span className="text-menu-text/60 mr-2 text-xs uppercase">{`UID:`}</span>
          <span className="select-text">{user.id}</span>
        </div>
        {_(whList)
          .filter(wh => wh.type !== WarehouseType.DEMO || wh.type !== null)
          .map(wh => (
            <div
              key={wh.id}
              className="border-menu-400 border-b border-opacity-50 px-2 py-2"
            >
              <div className="flex items-center px-2 py-1">
                <span className="text-menu-text/60 mr-2 text-xs uppercase">{`Name:`}</span>
                <span className="select-text">{wh.title}</span>
              </div>
              <div className="flex items-center px-2 py-1">
                <span className="text-menu-text/60 mr-2 text-xs uppercase">{`WHID:`}</span>
                <span className="select-text">{wh.id}</span>
              </div>
            </div>
          ))
          .value()}

        <div className="my-4 px-4">
          <label className="text-menu-200 mb-2 mt-3">{`Help Settings`}</label>
          <InputCheckbox
            name="visibility"
            className="p-2"
            isSelected={isHelperCalloutVisible}
            onChange={toggleHelperCalloutVisibility}
          >
            {isHelperCalloutVisible ? 'Hide Helpers' : 'Show Helpers'}
          </InputCheckbox>
        </div>
      </PanelContainer>

      <div className="h-20 pb-10"></div>
    </ContainerScroll>
  );
};

export default () => {
  const { t } = useTranslation('app');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-global-settings',
    title: t`Settings`,
    loadingMessage: t`Loading Settings`,
    size: 'xl',
    className: 'shadow-2xl',
    isGlobal: true,
    sideRight: true,
  };
  return (
    <SidebarContainer {...containerProps} sideRight isGlobal>
      <SidebarSettings />
    </SidebarContainer>
  );
};
