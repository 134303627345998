import {
  AnalyzeResultStatus,
  BaseEntitySortOption,
  BatchJobStatus,
  OptimizationRunStatus,
  SimulationDashboardItemFragment,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import {
  DashboardItemStatusType,
  DashboardOrder,
  DashboardSimulationItemStatus,
} from '../store/dashboard.types';
import { SortTypeBase } from './dashboard.types';

export function getSimulationStatus(
  sim: SimulationDashboardItemFragment,
  t: TFunction<'simulation'>,
): DashboardSimulationItemStatus {
  let status: DashboardSimulationItemStatus = {
    type: DashboardItemStatusType.draft,
    title: t('Draft', { ns: 'simulation' }),
    simulationStage: 'draft',
  };

  if (sim.analyzeResult) {
    const statusRaw = sim.analyzeResult.status;

    const inProgress =
      statusRaw === AnalyzeResultStatus.ANALYZING ||
      statusRaw === AnalyzeResultStatus.CREATED;

    const hasError = statusRaw === AnalyzeResultStatus.FAILED;

    const title =
      statusRaw === AnalyzeResultStatus.COMPLETED
        ? t(`Analysed`, { ns: 'simulation' })
        : inProgress
          ? t(`Analysing`, { ns: 'simulation' })
          : t('Analyse Error', { ns: 'simulation' });

    let progress: number | null = null;
    if (inProgress) {
      const rawProgress = sim.analyzeResult.progress || 0;

      progress =
        rawProgress < 1 ? parseFloat(rawProgress.toFixed(1)) : rawProgress;
    }

    status = {
      type: inProgress
        ? DashboardItemStatusType.progress
        : hasError
          ? DashboardItemStatusType.error
          : DashboardItemStatusType.complete,
      title,
      progress,
      simulationStage: inProgress
        ? 'analysing'
        : hasError
          ? 'analyseError'
          : 'analyzed',
    };
  }

  if (sim.latestAllocationRun) {
    const run = sim.latestAllocationRun;
    const statusRaw = run.status;
    const inProgress =
      statusRaw === BatchJobStatus.CALCULATING ||
      statusRaw === BatchJobStatus.CREATED ||
      statusRaw === BatchJobStatus.RESTARTED;

    const hasError =
      statusRaw === BatchJobStatus.FAILED ||
      statusRaw === BatchJobStatus.TERMINATED;

    const title =
      statusRaw === BatchJobStatus.READY
        ? t(`Allocated`, { ns: 'simulation' })
        : inProgress
          ? t(`Allocating`, { ns: 'simulation' })
          : hasError
            ? t('Allocate Error', { ns: 'simulation' })
            : t('Allocate (Issue)', { ns: 'simulation' });

    let progress: number | null = null;
    if (inProgress) {
      const rawProgress = run.progress || 0;
      progress =
        rawProgress < 1 ? parseFloat(rawProgress.toFixed(1)) : rawProgress;
    }

    status = {
      type: inProgress
        ? DashboardItemStatusType.progress
        : hasError
          ? DashboardItemStatusType.error
          : DashboardItemStatusType.complete,
      title,
      progress,
      simulationStage: inProgress
        ? 'allocating'
        : hasError
          ? 'allocationError'
          : 'allocated',
    };
  }

  if (sim.latestOptimizationRun) {
    const run = sim.latestOptimizationRun;
    const statusRaw = run.status;
    const inProgress =
      statusRaw === OptimizationRunStatus.IN_PROGRESS ||
      statusRaw === OptimizationRunStatus.CREATED;

    const inProgressStopping = statusRaw === OptimizationRunStatus.STOPPING;

    const hasError = statusRaw === OptimizationRunStatus.FAILED;

    const title =
      statusRaw === OptimizationRunStatus.COMPLETED
        ? t(`Optimised`, { ns: 'simulation' })
        : inProgress
          ? t(`Optimising`, { ns: 'simulation' })
          : inProgressStopping
            ? t(`Finalising`, { ns: 'simulation' })
            : hasError
              ? t('Optimise Error', { ns: 'simulation' })
              : t('Optimise (Issue)', { ns: 'simulation' });

    let progress: number | null = null;
    if (inProgress) {
      const rawProgress = run.progress || 0;
      console.log('rawProgress Optimising', rawProgress);
      console.log('statusRaw Optimising', statusRaw);

      progress =
        rawProgress < 1 ? parseFloat(rawProgress.toFixed(1)) : rawProgress;
    }

    status = {
      type: inProgress
        ? DashboardItemStatusType.progress
        : hasError
          ? DashboardItemStatusType.error
          : DashboardItemStatusType.complete,
      title,
      progress,
      simulationStage: inProgress
        ? 'optimising'
        : hasError
          ? 'optimiseError'
          : 'optimised',
    };
  }

  return status;
}

export function geSortByParams(sort: DashboardOrder): SortTypeBase[] {
  if (_.isNil(sort)) {
    throw new Error(`Sort value can not be empty`);
  }
  switch (sort) {
    case DashboardOrder.updatedDesc:
      return [
        {
          field: BaseEntitySortOption.UPDATED_AT,
          direction: SortDirection.DESC,
        },
      ];
    case DashboardOrder.updatedAsc:
      return [
        {
          field: BaseEntitySortOption.UPDATED_AT,
          direction: SortDirection.ASC,
        },
      ];
    case DashboardOrder.createdDesc:
      return [
        {
          field: BaseEntitySortOption.CREATED_AT,
          direction: SortDirection.DESC,
        },
      ];
    case DashboardOrder.createdAsc:
      return [
        {
          field: BaseEntitySortOption.CREATED_AT,
          direction: SortDirection.ASC,
        },
      ];
    case DashboardOrder.title:
      return [
        {
          field: BaseEntitySortOption.TITLE,
          direction: SortDirection.ASC,
        },
      ];
  }
}
