import { ActualityMetaFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus } from '../../common/types';
import {
  AgentSettingsWithMeta,
  ResourcePolicy,
} from '../../resourcePolicy/agentData/agent.types';
import { resourcePolicySelectedAgentId } from '../../simulation/store/resourcePolicy.state';
import { DataSetTableType } from '../../simulation/store/simulation.types';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  ActualityExtraSettings,
  ActualityMainViewType,
  TimelineFilterByPerformance,
  TimelineFilterByType,
} from './actuality.types';
import {
  ActualityAnalyzedJobSummaries,
  ActualityAnalyzedJobSummary,
  getActualityAnalyzedJobSummaries,
} from './datasetQueries/actualityAnalyzedJobSummaries';
import {
  ActualityAnalyzedEventsSummaryRow,
  getActualityAnalyzedEventsSummary,
} from './datasetQueries/feedAnalyzedEvents';
import { executeDatasetQuery } from './feed.helper';
import { feedQueryBuilderParams } from './feed.state';

const getKey = (postfix: string) => `warebee-actuality-${postfix}`;

export const actualitySelectedId = atom({
  key: getKey('selected-id'),
  default: null,
});

export const actualitySelectedDocument = atom<ActualityMetaFragment>({
  key: getKey('selected-document'),
  default: null,
});

export const actualitySelectedDocumentStatus = atom<AsyncLoadStatus>({
  key: getKey('selected-document-status'),
  default: AsyncLoadStatus.None,
});

export const actualityDocumentUpdateStatus = atom<AsyncLoadStatus>({
  key: getKey('update-status'),
  default: AsyncLoadStatus.None,
});

export const actualityExtraSettings = atom<ActualityExtraSettings>({
  key: getKey('extra-settings'),
  default: null,
});

export const actualityResourcePolicy = selector<ResourcePolicy>({
  key: getKey('resource-policy'),
  get: ({ get }) => {
    const sim = get(actualitySelectedDocument);
    return (sim?.resourcePolicy as ResourcePolicy) ?? { agents: [] };
  },
  set: ({ get, set }, value: ResourcePolicy) => {
    const doc = get(actualitySelectedDocument);
    set(actualitySelectedDocument, { ...doc, resourcePolicy: value });
  },
});

export const actualityPolicyAgentById = selectorFamily<
  AgentSettingsWithMeta,
  string
>({
  key: getKey('resource-policy-agent-by-id'),
  get:
    (agentId: string) =>
    ({ get }) => {
      const policy = get(actualityResourcePolicy);
      return _.find(policy?.agents, agent => agent.id === agentId);
    },
  set:
    (agentId: string) =>
    ({ get, set }, value: AgentSettingsWithMeta) => {
      const policy = get(actualityResourcePolicy);
      set(actualityResourcePolicy, {
        ...policy,
        agents: policy.agents.map(rule => (rule.id === agentId ? value : rule)),
      });
    },
});

export const actualityResourcePolicySelectedAgent =
  selector<AgentSettingsWithMeta>({
    key: getKey('selected-rule'),
    get: ({ get }) => {
      const selectedId = get(resourcePolicySelectedAgentId);
      if (_.isNil(selectedId)) return null;
      return get(actualityPolicyAgentById(selectedId));
    },
    set: ({ get, set }, value: AgentSettingsWithMeta) => {
      const selectedId = get(resourcePolicySelectedAgentId);
      if (_.isNil(selectedId)) return null;
      set(actualityPolicyAgentById(selectedId), value);
    },
  });

export const actualityAnalyzeLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('analyze-load-status'),
  default: AsyncLoadStatus.None,
});

export const actualityAnalyzedEventsSummary = selector<
  ActualityAnalyzedEventsSummaryRow<'eventType'>[]
>({
  key: getKey('analyzed-events-summary'),
  get: async ({ get }) => {
    const actualityId = get(actualitySelectedId);
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getActualityAnalyzedEventsSummary(
      {
        ...get(feedQueryBuilderParams),
        actualityId,
      },
      'eventType',
    ).compile();
    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[actuality] Analyzed Events Summary',
    });
    return result as ActualityAnalyzedEventsSummaryRow<'eventType'>[];
  },
});

export const actualityAnalyzedAgentSummary = selector<
  ActualityAnalyzedEventsSummaryRow<'agentId'>[]
>({
  key: getKey('analyzed-agents-summary'),
  get: async ({ get }) => {
    const actualityId = get(actualitySelectedId);
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getActualityAnalyzedEventsSummary(
      {
        ...get(feedQueryBuilderParams),
        actualityId,
      },
      'agentId',
    ).compile();
    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[actuality] Analyzed Agents Summary',
    });
    return result as any as ActualityAnalyzedEventsSummaryRow<'agentId'>[];
  },
});

export const actualityAnalyzedJobSummaries =
  selector<ActualityAnalyzedJobSummaries>({
    key: getKey('analyzed-job-summaries'),
    get: async ({ get }) => {
      const actualityId = get(actualitySelectedId);
      const warehouseId = get(warehouseSelectedId);
      const compiledQuery = getActualityAnalyzedJobSummaries({
        ...get(feedQueryBuilderParams),
        actualityId,
      }).compile();
      const result = await executeDatasetQuery({
        warehouseId,
        compiledQuery,
        comment: '[actuality] Analyzed Job Summaries',
      });
      return result;
    },
  });

export const actualityAnalyzedJobSummaryById = selector<
  Record<string, ActualityAnalyzedJobSummary>
>({
  key: getKey('analyzed-job-summaries-by-id'),
  get: ({ get }) => {
    const all = get(actualityAnalyzedJobSummaries);
    const grouped = _.keyBy(all, p => p.jobId);
    return grouped;
  },
});

export const actualityTimelineFilterPerformance =
  persistAtom<TimelineFilterByPerformance>({
    key: getKey('timeline-filter-resource-performance'),
    default: 'all',
  });

export const actualityTimelineFilterType = persistAtom<TimelineFilterByType>({
  key: getKey('timeline-filter-resource-type'),
  default: 'user',
});

export const actualityMainViewTypeAtom = persistAtom<ActualityMainViewType>({
  key: getKey('main-view-type-atom'),
  default: 'layout',
});

export const actualityMainViewType = selector<ActualityMainViewType>({
  key: getKey('main-view-type'),
  get: ({ get }) => get(actualityMainViewTypeAtom),
  set: ({ set }, v) => {
    set(actualityMainViewTypeAtom, v);
    console.log('Set actualityMainViewTypeAtom', v);
  },
});

export const actualityShowDatasetAsTable = atom<DataSetTableType>({
  key: getKey('show-dataset-as-table'),
  default: null,
});

export const actualityAgentShiftCutOf = atom<number>({
  key: getKey('agent-shift-cut-of'),
  default: 2,
});
