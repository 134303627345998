import { InferResult } from 'kysely';
import _ from 'lodash';
import { HeatmapAggregationFn } from '../../../common/heatmap.types';
import {
  ActualityHqQueryBuilderParams,
  getHqQueryBuilder,
} from './actualityQueryBuilder';
import { postProcessDefault } from './queryBuilder/feedQueryBuilder';

const actualityHqAllFieldsStub = getHqQueryBuilder({} as any)
  .selectFrom('hq')
  .selectAll();

export type ActualityHqRows = InferResult<typeof actualityHqAllFieldsStub>;
export type ActualityHqRow = ActualityHqRows[number];
export type ActualityHqField = keyof ActualityHqRow;

export type ActualityMetricSummaryRow = {
  dimension: string;
  locationLevel: number;
  totalCount: number;
  totalEvents: number;
  totalLocations: number;
  totalExistedLocations: number;
  totalItems: number;
  totalAssignments: number;
};

export type GetActualityMetricSummaryParams = ActualityHqQueryBuilderParams & {
  groupBy: ActualityHqField;
  aggregationFn: HeatmapAggregationFn;
  aggregateBy: ActualityHqField;
};
export function getActualityMetricSummary(
  params: GetActualityMetricSummaryParams,
) {
  return getHqQueryBuilder(params)
    .selectFrom('hq')
    .select(({ fn }) =>
      _.compact([
        `${params.groupBy} as dimension`,
        'locationLevel',
        params.aggregationFn === 'max'
          ? fn.count(params.aggregateBy).as('totalCount')
          : null,
        params.aggregationFn === 'min'
          ? fn.count(params.aggregateBy).as('totalCount')
          : null,
        params.aggregationFn === 'avg'
          ? fn.count(params.aggregateBy).as('totalCount')
          : null,
        params.aggregationFn === 'sum'
          ? fn.sum(params.aggregateBy).as('totalCount')
          : null,
        params.aggregationFn === 'count'
          ? fn.count(params.aggregateBy).as('totalCount')
          : null,
        fn.countAll<number>().as('totalEvents'),
        fn.count<number>('locationId').distinct().as('totalLocations'),
        fn
          .count<number>('layoutLocationId')
          .distinct()
          .as('totalExistedLocations'),
        fn.count<number>('skuKey').distinct().as('totalItems'),
        fn.count<number>('assignmentItem').distinct().as('totalAssignments'),
      ]),
    )
    .groupBy([params.groupBy, 'locationLevel']);
}

export function postProcessActualityMetricSummary(data: Record<string, any>[]) {
  return postProcessDefault<ActualityMetricSummaryRow>(data);
}
