import classNames from 'classnames';
import _ from 'lodash';
import { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { BrowserRouter, useSearchParams } from 'react-router-dom';
import { RecoilRoot, useRecoilValue, useSetRecoilState } from 'recoil';
import AnalyticTracker from './AnalyticTracker';
import AppRoutes from './AppRoutes';
import AuthManager from './AuthManager';
import ErrorFallback from './ErrorFallback';
import ErrorWatcher from './ErrorWatcher';
import NotificationWatcher from './NotificationWatcher';
import { APP_VER } from './common/environmentVariables';
import { AppFooter } from './components/AppFooter';
import HubspotChat from './components/HubspotChat';
import LoadingIndicator from './components/LoadingIndicator';
import { ContainerApp } from './components/layout/ContainerApp';
import ContactSupport from './helpContext/ContactSupport';
import i18n from './i18n';
import RoleParamWatcher from './permissions/RoleParamWatcher';
import {
  appIsEmbedded,
  appThemeSelectedId,
  applyThemePreference,
  themeIds,
} from './store/global.state';
import {
  applyWarehouseRoutePreference,
  routeIds,
  warehouseDefaultRoute,
} from './store/warehouse.state';

const ThemeParamWatcher = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const themeParam = searchParams.get('theme');
  const embeddedParam = searchParams.get('isEmbedded');
  const themeId = useRecoilValue(appThemeSelectedId);
  const setEmbedded = useSetRecoilState(appIsEmbedded);

  useEffect(() => {
    applyThemePreference(themeId);
  }, [themeId]);

  useEffect(() => {
    if (_.includes(themeIds, themeParam)) {
      applyThemePreference(themeParam);
    }
  }, [themeParam]);

  useEffect(() => {
    if (!!embeddedParam) setEmbedded(true);
  }, [embeddedParam]);

  return null;
};

const RouteParamWatcher = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const routeId = useRecoilValue(warehouseDefaultRoute);
  const routeParam = searchParams.get('route');

  useEffect(() => {
    applyWarehouseRoutePreference(routeId);
  }, [routeId]);

  useEffect(() => {
    if (_.includes(routeIds, routeParam)) {
      applyWarehouseRoutePreference(routeParam);
    }
  }, [routeParam]);

  return null;
};

export default function App() {
  const { t } = useTranslation('app');

  return (
    <RecoilRoot>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Suspense
            fallback={
              <div
                data-component="LoadingApp"
                className={classNames(
                  'flex flex-col items-center',
                  'h-full overflow-hidden',
                  'bg-app-background',
                )}
              >
                <LoadingIndicator selfCenter message={t`WareBee Loading...`} />
                <AppFooter
                  className={classNames('px-6 py-4', 'bg-app-background/50')}
                >
                  <div className={classNames('flex flex-1 text-xs')}>
                    <ContactSupport />
                  </div>
                  <div className={classNames('flex flex-1 text-xs')}>
                    <div>{t`v.`}</div>
                    <div className={classNames('ltr:ml-1 rtl:mr-1')}>
                      {APP_VER}
                    </div>
                  </div>
                </AppFooter>
              </div>
            }
          >
            <RoleParamWatcher />
            <RouteParamWatcher />
            <ThemeParamWatcher />
            <ErrorWatcher />
            <ErrorBoundary
              fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorFallback errorOrigin="unhandledException" />
              )}
            >
              <AuthManager
                errorFallback={<ErrorFallback errorOrigin="notConnected" />}
              >
                <NotificationWatcher />
                <HubspotChat />
                <AnalyticTracker />

                <ContainerApp
                  aria-label="ContainerApp"
                  className={classNames('bg-app-background h-full')}
                >
                  <AppRoutes />
                </ContainerApp>
                <footer />
              </AuthManager>
            </ErrorBoundary>
          </Suspense>
        </BrowserRouter>
      </I18nextProvider>
      {/* </ApolloProvider> */}
    </RecoilRoot>
  );
}
