import { AnalyzeResultStatus } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Container } from '../../components/layout/ContainerFlex';
import { SidebarMenuItem } from '../../components/nav/SidebarMenuItem';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { userHasOptimise } from '../../store/warehouse.state';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import { optimisationStatus } from '../store/optimisation.state';
import { getWizardSteps } from '../store/simulation.helper';
import {
  simulationAnalyzeStatus,
  simulationCurrent,
} from '../store/simulation.state';
import { simulationWizardSelectedStepId } from '../store/simulation.wizard.state';

type SidebarSimulationMenuWizardProps = {
  isMinimised: boolean;
};

const SidebarSimulationMenuWizard: React.FC<
  SidebarSimulationMenuWizardProps
> = props => {
  const { t } = useTranslation('simulation');

  const sim = useRecoilValue(simulationCurrent);
  const activeStepId = useRecoilValue(simulationWizardSelectedStepId);
  const selectStep = useSelectSimulationWizardStep();
  const analyzeStatus = useRecoilValue(simulationAnalyzeStatus);
  const optStatus = useRecoilValue(optimisationStatus);
  const hasOptimise = useRecoilValue(userHasOptimise);

  const steps = getWizardSteps(
    sim,
    analyzeStatus === AnalyzeResultStatus.COMPLETED,
    optStatus,
    hasOptimise,
    t,
  );
  return (
    <Container hasOverflowY className="flex flex-col">
      <ul
        data-component="SidebarSimulationMenuWizard"
        className={`relative flex flex-col text-start px-1`}
      >
        {steps.map(item => {
          return (
            <SidebarMenuItem
              hasWizardArrows={
                !item.treeLineStart || !item.treeLineEnd || item.hasWizardArrows
              }
              isMinimised={props.isMinimised}
              key={item.id}
              menuLevel={item.menuLevel.toString()}
              title={item.title}
              titleMinimised={item.titleMinimized}
              icon={props.isMinimised && item.icon}
              active={item.id === activeStepId}
              treeLine={item?.treeLine}
              treeLineStart={item?.treeLineStart}
              treeLineEnd={item?.treeLineEnd}
              hasMenuItems={item.hasMenuItems}
              inProgress={item?.progress}
              stepCounter={item?.stepCounter}
              disabled={item?.disabled}
              isHidden={item?.isHidden}
              //locked={status?.isLocked}
              onClick={() => selectStep({ stepId: item.id })}
            />
          );
        })}
      </ul>
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('simulation');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-menu',
    title: t('Simulation Wizard Menu'),
    size: 'xs',
  };
  return (
    <SidebarContainer
      key={containerProps.type}
      {...containerProps}
      widthAutoMinimised
      withMenuIcons={<SidebarSimulationMenuWizard isMinimised={true} />}
    >
      <SidebarSimulationMenuWizard isMinimised={false} />
    </SidebarContainer>
  );
};
