import { ActivityEventProcessType } from '@warebee/shared/import-converter';
import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import { Button } from '../../components/actions/Button';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { Spacer } from '../../components/layout/Spacer';
import { ActionBar } from '../../components/nav/ActionBar';
import PanelContainer from '../../containers/PanelContainer';
import useActivityEventProcessTypeDetails from '../../import/activityFeed/schema/useActivityEventProcessTypeDetails';
import { actualityResourcePolicy } from '../store/actuality.state';
import {
  feedDisabledAgentTypes,
  feedDisabledProcessTypes,
} from '../store/feed.state';

const FeedGlobalFilterPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const [disabledAgentTypes, setDisabledAgentTypes] = useRecoilState(
    feedDisabledAgentTypes,
  );
  const [disabledProcessType, setDisabledProcessType] = useRecoilState(
    feedDisabledProcessTypes,
  );
  const policy = useRecoilValue(actualityResourcePolicy);

  const [disabledAgentTypesLocal, setDisabledAgentTypesLocal] =
    useState<string[]>();
  const [disabledProcessTypesLocal, setDisabledProcessTypesLocal] =
    useState<ActivityEventProcessType[]>();

  const disabledAgentsSet = new Set(disabledAgentTypesLocal);
  const disabledProcessSet = new Set(disabledProcessTypesLocal);

  const processTypesDescriptors = useActivityEventProcessTypeDetails();

  useEffect(() => {
    setDisabledAgentTypesLocal(disabledAgentTypes);
  }, [disabledAgentTypes]);

  useEffect(() => {
    setDisabledProcessTypesLocal(disabledProcessType);
  }, [disabledProcessType]);

  function toggleAgentType(id: string, isSelect: boolean) {
    const others = _.filter(disabledAgentTypesLocal, at => at !== id);
    if (!isSelect) {
      others.push(id);
    }
    setDisabledAgentTypesLocal(others);
  }

  function toggleProcessType(id: ActivityEventProcessType, isSelect: boolean) {
    const others = _.filter(disabledProcessTypesLocal, at => at !== id);
    if (!isSelect) {
      others.push(id);
    }
    setDisabledProcessTypesLocal(others);
  }

  return (
    <>
      <PanelContainer
        id="panel-feed-global-filters-agent-type"
        title={t`Agent types filters`}
        collapsible
        hasPadding
      >
        {_.isEmpty(policy?.agents) && (
          <InboxZero message={t`No Agent fount in resource policy`} />
        )}
        <ul className={classNames('space-y-6 mb-6')}>
          {_.map(policy?.agents, agent => {
            const isDisabled =
              disabledAgentsSet.size !== 0 && disabledAgentsSet.has(agent.id);
            return (
              <div
                className={classNames('flex')}
                key={`agent-global-filter-${agent.id}`}
              >
                <label className={classNames('flex-1 opacity-80')}>
                  {agent.title}
                </label>
                <ButtonSwitchMulti
                  autoSize
                  options={[{ label: t('off') }, { label: t('on') }]}
                  selectedIndex={isDisabled ? 0 : 1}
                  onClick={v => toggleAgentType(agent.id, v === 1)}
                />
              </div>
            );
          })}
        </ul>
        <ActionBar>
          <Spacer flexspace />
          <Button
            label={t`Apply agents type filter`}
            buttonType="primary"
            buttonSize="xs"
            onPress={() => setDisabledAgentTypes(disabledAgentTypesLocal)}
          />
        </ActionBar>
      </PanelContainer>
      {/* ACTiVITY  TYPE FILTERS */}
      <PanelContainer
        id="panel-feed-global-filters-process-type"
        title={t`Process types filters`}
        collapsible
        hasPadding
      >
        <ul className={classNames('space-y-6 mb-6')}>
          {_.map(processTypesDescriptors, processType => {
            const isDisabled =
              disabledProcessSet.size !== 0 &&
              disabledProcessSet.has(processType.id);
            return (
              <div
                className={classNames('flex')}
                key={`process-type-global-filter-${processType.id}`}
              >
                <label className={classNames('flex-1 opacity-80')}>
                  {processType.id}
                </label>
                <ButtonSwitchMulti
                  autoSize
                  options={[{ label: t('off') }, { label: t('on') }]}
                  selectedIndex={isDisabled ? 0 : 1}
                  onClick={v => toggleProcessType(processType.id, v === 1)}
                />
              </div>
            );
          })}
        </ul>
        <ActionBar>
          <Spacer flexspace />
          <Button
            label={t`Apply process type filter`}
            buttonType="primary"
            buttonSize="xs"
            onPress={() => setDisabledProcessType(disabledProcessTypesLocal)}
          />
        </ActionBar>
      </PanelContainer>
    </>
  );
};

export default FeedGlobalFilterPanel;
