import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import CopyToClipboardButton from './actions/CopyToClipboardButton';
import * as Icon from './icons';

export type KeyValueItemProps = {
  children?: React.ReactNode;
  className?: string;
  name?: string;
  value: string | React.ReactNode;
  isRemovable?: boolean;
  isCopyable?: boolean;
  isCopyableValueOnly?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  isNot?: boolean;
  hasIssue?: boolean;
  onRemoveClick?: () => void;
  onClick?: () => void;
  icon?: React.FC<HTMLAttributes<Element>>;
  iconClassName?: string;
};

const KeyValueItem: React.FC<KeyValueItemProps> = props => {
  const { t } = useTranslation('app');
  const IconItem = props.icon;

  return (
    <div
      data-component="KeyValueItem"
      data-label={`KeyValueItem-${props.name}`}
      aria-label={`KeyValueItem-${props.name}`}
      className={classNames(
        'group',
        'relative',
        'flex flex-1',
        'bg-app-panel/50',
        { 'cursor-pointer': !props.isDisabled },
        { 'cursor-default': props.isDisabled },
        props.className,
      )}
    >
      <button
        data-component="KeyValueItemAction"
        type="button"
        onClick={e => {
          props.onClick && props.onClick();
        }}
        disabled={props.isDisabled}
        className={classNames([
          'flex flex-1',
          'rounded',
          'text-xs xl:text-base',
          { 'bg-alerts-alert text-menu-text': props.hasIssue },
          {
            'px-1 py-1 shadow': props.name,
            'items-center': props.icon,
            'items-start xl:items-top': !props.icon,
            'bg-app-panel-light/50 text-menu-text hover:bg-menu-active/80 hover:text-menu-active-text':
              !props.isActive && !props.isDisabled && !props.hasIssue,
            'bg-app-panel-dark/60 text-menu-text hover:bg-app-panel-dark/50 hover:text-menu-text':
              props.isActive && !props.hasIssue,
            'border border-app-background/50 group-hover:rounded hover:rounded':
              props.isDisabled,
            'group-hover:rounded-l group-hover:rounded-r-none hover:rounded-r-none':
              props.isRemovable && !props.isDisabled,
          },
        ])}
      >
        {props.icon && (
          <IconItem
            className={classNames(
              'fill-current',
              props.iconClassName
                ? props.iconClassName
                : 'w-5 h-5 xl:w-6 xl:h-6 ltr:mr-2 rtl:ml-2 ltr:ml-1 rtl:mr-1',
            )}
          />
        )}
        <div
          data-component="containerKeyValue"
          className={classNames([
            'flex flex-col flex-1',
            'whitespace-pre-line',
          ])}
        >
          {props.name && (
            <div
              className={classNames([
                'flex items-start',
                'ltr:pr-1 rtl:pl-1',
                'uppercase',
                'opacity-75',
                'text-xs',
                'my-2',
              ])}
            >
              {props.name}
              {`:`}
            </div>
          )}

          {props.value && (
            <div
              className={classNames([
                'flex flex-1 items-center text-start',
                'p-3',
                'rounded',
                'font-bold',
                'text-xs md:text-xs lg:text-sm xl:text-base',
                'bg-app-panel',
              ])}
            >
              {props.value}
            </div>
          )}
        </div>
      </button>

      {props.isRemovable && (
        <button
          data-component="ButtonRemove"
          type="button"
          onClick={e => {
            e.stopPropagation();
            props.onRemoveClick && props.onRemoveClick();
          }}
          className={classNames([
            'relative',
            'px-1 py-1 -ml-px',
            'hover:rounded-r group-hover:rounded-r',
            'hidden group-hover:flex',
            'transition duration-200',
            'rounded-r',
            {
              'bg-app-panel/70 text-menu-text hover:bg-menu-hover hover:text-menu-text group-hover:text-menu-text':
                !props.isActive,
              'bg-app-panel-dark/70 text-menu-active hover:bg-menu/50 hover:text-menu-active':
                props.isActive,
              'hover:bg-app-panel-light hover:text-menu-text':
                !props.isDisabled,
              'bg-opacity-50': props.isDisabled,
            },
          ])}
        >
          <Icon.CircleX className={classNames('w-5 h-5 fill-current')} />
        </button>
      )}

      {props.isCopyable && (
        <div
          className={classNames(
            'p-1',
            'hidden group-hover:flex',
            'absolute',
            '-top-2',
            '-right-2',
          )}
        >
          <CopyToClipboardButton
            hasIcon
            value={`${props.name ? props.name + ':\n' : ''}${props.value}`}
          />
        </div>
      )}

      {props.isCopyableValueOnly && (
        <div
          className={classNames(
            'p-1',
            'hidden group-hover:flex',
            'absolute',
            '-top-2',
            '-right-2',
          )}
        >
          <CopyToClipboardButton hasIcon value={`${props.value}`} />
        </div>
      )}
      {props.children}
    </div>
  );
};

export default KeyValueItem;
