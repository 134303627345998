import {
  Field,
  ID,
  IntersectionType,
  ObjectType,
  PartialType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { ItemUomQuantity } from './item-quantity.model';

@ObjectType()
export class AssignmentItemCapacity {
  @Field(() => [ItemUomQuantity])
  breakdownByUom: ItemUomQuantity[];
}

export enum AssignmentChangeReason {
  ALLOCATE = 'ALLOCATE',
  OPTIMIZE = 'OPTIMIZE',
  ALLOCATE_AND_OPTIMIZE = 'ALLOCATE_AND_OPTIMIZE',
}

registerEnumType(AssignmentChangeReason, {
  name: 'AssignmentChangeReason',
});

@ObjectType()
export class AssignmentChangeItemMove {
  @Field(() => ID)
  itemId: string;

  @Field(() => ID)
  consignee: string;

  @Field(() => ID)
  sku: string;

  @Field(() => ID, { nullable: true })
  srcLocationId?: string;

  @Field(() => AssignmentItemCapacity, { nullable: true })
  srcCapacity?: AssignmentItemCapacity;

  @Field(() => ID, { nullable: true })
  destLocationId?: string;

  @Field(() => AssignmentItemCapacity, { nullable: true })
  destCapacity?: AssignmentItemCapacity;

  @Field({ nullable: true })
  estimatedCostImprovement?: number;

  @Field(() => AssignmentChangeReason, { nullable: true })
  reason?: AssignmentChangeReason;
}

@ObjectType()
export class AssignmentChange {
  @Field(() => ID, { nullable: true })
  prevAssignmentId?: string;

  @Field(() => [AssignmentChangeItemMove])
  moves: AssignmentChangeItemMove[];
}

export enum AssignmentChangeSequenceAction {
  TAKE = 'TAKE',
  PUT = 'PUT',
  EXCHANGE = 'EXCHANGE',
  STASH = 'STASH',
  UNSTASH = 'UNSTASH',
}

registerEnumType(AssignmentChangeSequenceAction, {
  name: 'AssignmentChangeSequenceAction',
});

@ObjectType()
export class AssignmentChangeSequenceMoveInfo {
  @Field(() => ID)
  moveId: string;

  @Field(() => ID)
  groupId: string;

  @Field()
  chainNumber: number;

  @Field()
  sequenceNumber: number;

  @Field(() => AssignmentChangeSequenceAction)
  destAction: AssignmentChangeSequenceAction;
}

@ObjectType()
export class AssignmentChangeSequenceMove extends IntersectionType(
  AssignmentChangeItemMove,
  AssignmentChangeSequenceMoveInfo,
) {}

@ObjectType()
export class ReassignJobMove extends IntersectionType(
  AssignmentChangeItemMove,
  PartialType(AssignmentChangeSequenceMoveInfo),
) {}

@ObjectType()
export class ReassignJobDetails {
  @Field(() => ID, { nullable: true })
  groupId?: string;

  @Field({ nullable: true })
  chainNumber?: number;

  @Field({ nullable: true })
  sequenceNumber?: number;

  @Field({ nullable: true })
  estimatedCostImprovement?: number;

  @Field(() => [ReassignJobMove])
  moves: ReassignJobMove[];

  @Field(() => [AssignmentChangeReason], { nullable: true })
  reasons?: AssignmentChangeReason[];
}
