import { AssignmentItemDataFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import useFormatter from '../common/useFormatter';
import ButtonLocateLocation from '../components/ButtonLocateLocation';
import { ColumnConfig } from '../components/DatasetTable';
import { getAssignmentSchema } from '../import/assignment/schema/assignmentSchema';
import ItemTag from '../simulation/tags/ItemTag';

function useAssignmentDataTableConfig(): ColumnConfig<AssignmentItemDataFragment>[] {
  const { t: tImporter } = useTranslation('importer');
  const { t } = useTranslation('dataset');
  const formatter = useFormatter();
  const schema = _.keyBy(getAssignmentSchema(tImporter).fields, f => f.name);

  const columnsConfigRaw: ColumnConfig<AssignmentItemDataFragment>[] = [
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
      hasFilter: true,
      isHeader: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      hasFilter: true,
      isHeader: true,
      render: (sku: string, row) => (
        <ItemTag
          title={sku}
          filters={{ consignee: row['consignee'], sku }}
          // color={getQualitativeColor(sku, 'dimension')[0]}
        />
      ),
    },
    {
      field: 'locationId',
      title: t`Location ID`,
      hasFilter: true,
      render: (locationId: string, row) => (
        <ButtonLocateLocation locationId={locationId} />
      ),
    },
    { field: 'stockUom', title: t`Stock UOM`, hiddenInExport: true },
    {
      field: 'stockUomQuantity',
      title: t`Stock UOM Qty`,
      hiddenInExport: true,
    },
  ];

  const columnsConfig = columnsConfigRaw.map(f => ({
    ...f,
    title: f.title ?? schema[f.field]?.title,
  }));

  return columnsConfig;
}
export default useAssignmentDataTableConfig;
