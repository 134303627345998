import { InferResult, sql } from 'kysely';
import _ from 'lodash';
import { datasetQueryBuilder } from './queryBuilder/datasetQueryBuilder';
import {
  ActivityFeedQueryBuilderParams,
  getActivityFeedFilteredQueryBuilder,
} from './queryBuilder/feedEventsQueryBuilders';
import { postProcessDefault } from './queryBuilder/feedQueryBuilder';

export type FeedLatestEvents = InferResult<
  ReturnType<typeof getLatestEventsQuery>
>;
export type FeedLatestEvent = FeedLatestEvents[number];

export const getLatestEventsQuery = (
  params: ActivityFeedQueryBuilderParams & {
    agentId?: string;
    locationId?: string;
  },
) => {
  let query = datasetQueryBuilder
    .with(
      cte => cte('af'),
      db => getActivityFeedFilteredQueryBuilder(params, db),
    )
    .with(
      cte => cte('afLimit'),
      db => {
        let query = db
          .selectFrom('af')
          .selectAll()
          .orderBy('eventEndTime', 'desc')
          .limit(1000);

        if (!_.isNil(params.agentId)) {
          query = query.where('agentId', '=', params.agentId);
        }
        if (!_.isNil(params.locationId)) {
          query = query.where('locationId', '=', params.locationId);
        }

        return query;
      },
    )
    .selectFrom('afLimit as t1')
    .select([
      'eventId',
      'agentType',
      'eventProcessType',
      'eventType',
      'agentId',
      'agentEnergy',
      'agentSignalStrength',
      'locationId',
      'locationIdSource',
      'consignee',
      'sku',
      'uom',
      'quantity',
      'jobId',
      'jobGroupId',
      'jobLine',
      sql<Date>` case ${sql.ref('eventStartTime')} 
        when null then ${sql.ref('eventEndTime')} 
        else ${sql.ref('eventStartTime')} end `.as('eventStartTime'),
      'eventEndTime',
      sql<Date>`
        lag(${sql.ref('eventEndTime')}, 1) 
        OVER(
          PARTITION BY ${sql.ref('agentId')}
          ORDER BY ${sql.ref('eventEndTime')} ASC
        )`.as('previousStartTime'),
    ])
    .select(({ fn, selectFrom }) => [
      selectFrom('afLimit as t2')
        .select(({ fn }) => [fn.countAll<number>().as('congestion')])
        .whereRef('t1.locationId', '=', 't2.locationId')
        // .whereRef('t1.partition', '=', 't2.partition')
        .whereRef('t1.agentId', '<>', 't2.agentId')
        .where(
          sql<boolean>`
            abs(to_unixtime(${sql.ref('t2.eventEndTime')}) 
            - to_unixtime(${sql.ref('t1.eventEndTime')})) < 1000*60*5`,
        )
        .as('congestion'),
    ])
    .where('locationId', 'is not', null)
    .orderBy('eventEndTime', 'desc')
    .limit(1000);
  if (!_.isNil(params.agentId)) {
    query = query.where('agentId', '=', params.agentId);
  }
  return query;
};

const isDateField = (f: string): boolean => {
  const fName = _.camelCase(f);
  return (
    fName === 'previousStartTime' ||
    fName === 'eventStartTime' ||
    fName === 'eventEndTime'
  );
};

export function postProcessFeedLatestEvent(
  events: Record<string, any>[],
): FeedLatestEvents {
  return postProcessDefault<FeedLatestEvent>(events, isDateField);
}
