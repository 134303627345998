import { AllocationSummaryDataRow } from '@warebee/shared/export-converter';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { getQualitativeColor } from '../../../common/color.helper';
import { formatInteger } from '../../../common/formatHelper';
import useFormatter from '../../../common/useFormatter';
import { ColumnConfig } from '../../../components/DatasetTable';
import Tag from '../../../helpContext/Tag';
import { getAllocationSummaryGroutByOptions } from '../../store/allocation/allocation.helper';
import { AllocationSummaryGroupByType } from '../../store/allocation/allocation.types';
import EmptyValueTag from '../../tags/EmptyValueTag';
import ItemTag from '../../tags/ItemTag';
import UomTag from '../../tags/UomTag';

export type AllocationSummaryTableConfigParams = {
  key: AllocationSummaryGroupByType;
};

function renderUoms(uoms: Record<string, number>) {
  if (_.isEmpty(uoms)) {
    return <EmptyValueTag />;
  }
  return (
    <>
      {_.map(uoms, (value, uom) => (
        <UomTag key={uom} uom={uom} value={value} />
      ))}
    </>
  );
}

function renderQtyValue(value: number) {
  if (_.isNil(value) || value == 0) {
    return <EmptyValueTag />;
  }
  return (
    <span className="flex-1 text-right" title={`RAW: ${formatInteger(value)}`}>
      {Number(value) > 1000000 ? '1M+' : formatInteger(Number(value))}
    </span>
  );
}

function renderReasons(reasons: string[]) {
  return (
    <>
      {_.map(reasons, (value, uom) => (
        <Tag>{value}</Tag>
      ))}
    </>
  );
}

function useAllocationSummaryTableConfig(
  params: AllocationSummaryTableConfigParams,
): ColumnConfig<AllocationSummaryDataRow>[] {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const keyOptionsAll = getAllocationSummaryGroutByOptions(t);
  const keyOption = keyOptionsAll[params.key];

  const keyFields = new Set(keyOption.keyColumns);
  const keyFieldsAll: ColumnConfig<AllocationSummaryDataRow>[] = [
    {
      field: 'consignee',
      title: t`Consignee`,
      isHeader: true,
      // hasSort: true,
      hasFilter: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      isHeader: true,
      hasSort: true,
      hasFilter: true,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
    { field: 'skuGroup', title: t`Item Group`, hasSort: true, hasFilter: true },
    { field: 'subGroup', title: t`Sub-Group`, hasSort: true, hasFilter: false },
  ];

  const columnsConfig: ColumnConfig<AllocationSummaryDataRow>[] = [
    {
      field: 'key',
      title: '',
      hiddenInExport: true,
      isHeader: true,
      render: (key, row) => {
        const dimension = keyOption.getMetricValue(row);
        const colors = getQualitativeColor(dimension, 'dimension');
        return (
          <div
            className={classNames('flex h-6 w-1 rounded-sm px-1 py-1')}
            style={{
              backgroundColor: colors[0],
              color: colors[1],
            }}
          />
        );
      },
    },
    ..._.filter(keyFieldsAll, f => keyFields.has(f.field as any)),
    {
      field: 'requiredQty',
      title: t`Ordered (qty)`,
      hasSort: true,
      // hasFilter: true,
      render: renderQtyValue,
    },
    {
      field: 'requiredUOM',
      title: t`Ordered UOM`,
      render: renderUoms,
      hiddenInExport: true,
    },
    {
      field: 'requiredUOMString',
      title: t`Ordered UOM`,
      hiddenInBrowser: true,
    },
    {
      field: 'initiallyPickableQty',
      title: t`Stock (Qty)`,
      hasSort: true,
      render: renderQtyValue,
    },
    {
      field: 'initiallyPickableUOM',
      title: t`Stock UOM`,
      render: renderUoms,
    },
    {
      field: 'initiallyPickableUOMString',
      title: t`Stock UOM`,
      hiddenInBrowser: true,
    },
    {
      field: 'allocatedQty',
      title: t`Allocated (Qty)`,
      hasSort: true,
      render: renderQtyValue,
    },
    {
      field: 'allocatedUOM',
      title: t`Allocated UOM`,
      render: renderUoms,
      hiddenInExport: true,
    },
    {
      field: 'allocatedUOMString',
      title: t`Allocated UOM`,
      hiddenInBrowser: true,
    },
    {
      field: 'allocatedSharedQty',
      title: t`Allocated shared (Qty)`,
      render: renderQtyValue,
    },
    {
      field: 'unAllocatedQty',
      title: t`Un-Allocated(Qty)`,
      render: renderQtyValue,
    },
    {
      field: 'unAllocatedUOM',
      title: t`Un-Allocated UOM`,
      render: renderUoms,
      hiddenInExport: true,
    },
    {
      field: 'unAllocatedUOMString',
      title: t`Un-Allocated UOM`,
      hiddenInBrowser: true,
    },
    {
      field: 'unAllocatedReasons',
      title: t`Un-Allocated Reasons`,
      render: renderReasons,
      hiddenInExport: true,
    },
    {
      field: 'unAllocatedReasonsString',
      title: t`Un-Allocated Reasons`,
      hiddenInBrowser: true,
    },

    {
      field: 'deallocatedQty',
      title: t`De-Allocated(Qty)`,
      render: renderQtyValue,
    },
    {
      field: 'deallocatedUOM',
      title: t`De-Allocated UOM`,
      render: renderUoms,
      hiddenInExport: true,
    },
    {
      field: 'deallocatedUOMString',
      title: t`De-Allocated UOM`,
      hiddenInBrowser: true,
    },
    {
      field: 'deallocatedReasons',
      title: t`Deallocated Reasons`,
      render: renderReasons,
      hiddenInExport: true,
    },
    {
      field: 'deallocatedReasonsString',
      title: t`Deallocated Reasons`,
      hiddenInBrowser: true,
    },
    {
      field: 'unpickableQty',
      title: t`Unpickable (Qty)`,
      render: renderQtyValue,
    },
  ];

  return columnsConfig;
}

export default useAllocationSummaryTableConfig;
