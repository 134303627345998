import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import useFormatter from '../common/useFormatter';
import ButtonToggle from '../components/actions/ButtonToggle';
import DropdownSelector from '../components/actions/DropdownSelector';
import HeatmapBucketsList from '../components/heatmap/HeatmapBucketsList';
import * as Icon from '../components/icons';
import { StatGroup } from '../components/stats/StatGroup';
import { simulationLayoutHeatmapFilters } from '../simulation/store/simulation.layout.state';
import AssignmentStatsFilterPanel from './AssignmentStatsFilterPanel';
import AssignmentStatsFilterSummary from './AssignmentStatsFilterSummary';
import { getAssignmentStatItemMetricDescriptorsMap } from './assignmentStats.default';
import {
  assignmentStatsBuckets,
  assignmentStatsEditorViewMode,
  assignmentStatsItemMetric,
} from './assignmentStats.state';

const AssignmentStatsLegend: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const metric = useRecoilValue(assignmentStatsItemMetric);
  const bucketsLoadable = useRecoilValueLoadable(assignmentStatsBuckets);
  const [heatmapFilter, setHeatmapFilter] = useRecoilState(
    simulationLayoutHeatmapFilters,
  );
  const [viewMode, setViewMode] = useRecoilState(assignmentStatsEditorViewMode);
  const [allSelected, setAllSelected] = useState(true);

  // Add a useEffect hook to track if even one bucket is deselected.
  useEffect(() => {
    // Check if any bucket's hidden status is true (i.e., it's deselected).
    const anyDeselected = Object.values(heatmapFilter.hiddenBuckets).some(
      hidden => hidden,
    );

    if (anyDeselected) {
      // If even one bucket is deselected, set allSelected to false.
      setAllSelected(false);
    } else {
      // If all buckets are selected, set allSelected to true.
      setAllSelected(true);
    }
  }, [heatmapFilter.hiddenBuckets]); // Add heatmapFilter.hiddenBuckets as a dependency.

  const toggleSelectAllBuckets = () => {
    const hiddenBuckets = _.reduce(
      buckets,
      (acc, b) => ({
        ...acc,
        [b.id]: allSelected, // If allSelected is true, deselect all. If allSelected is false, select all.
      }),
      {},
    );

    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets,
    });
  };

  const metricDescriptorMap = getAssignmentStatItemMetricDescriptorsMap(
    t,
    formatter,
  );
  const metricDescriptor = metricDescriptorMap[metric];

  const buckets =
    bucketsLoadable?.state === 'hasValue' ? bucketsLoadable.contents : [];

  const debouncedClick = useCallback(
    _.debounce(
      (
        id: string,
        value: boolean,
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        if (evt.detail === 2) {
          forceSelectSingleBucket(id);
        } else {
          updateBucketFilter(id, value);
        }
      },
      400,
    ),
    [heatmapFilter, buckets],
  );

  const updateBucketFilter = (id: string, value: boolean) => {
    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets: {
        ...heatmapFilter.hiddenBuckets,
        [id]: !value,
      },
    });
  };

  const forceSelectSingleBucket = (id: string) => {
    const hiddenBuckets = _.reduce(
      buckets,
      (acc, b) => ({
        ...acc,
        [b.id]: b.id !== id,
      }),
      {},
    );

    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets,
    });
  };

  const dropOptions = _.keyBy(
    [
      {
        key: 'toggleLegend',
        title: allSelected ? t`Hide All` : t`Show All`,
        onClick: () => toggleSelectAllBuckets(),
      },
      // {
      //   key: 'showFilter',
      //   title: t`Show filter`,
      //   onClick: () => setViewMode('filter'),
      // },
    ],
    'key',
  );

  const isLoading = bucketsLoadable.state === 'loading';
  return (
    <div className={classNames('flex flex-col h-full')}>
      <StatGroup
        classNameStatGroupItems={classNames('!mt-0 !pt-0')}
        classNameTitle={classNames('!text-sm')}
        title={`${metricDescriptor.title} ${
          buckets?.length > 0 ? `(${buckets?.length})` : ''
        }`}
        titleAction={
          <div
            data-component="titleActionWrapper"
            className={classNames(
              'flex items-start',
              'space-x-1',
              'ltr:ml-2 rtl:mr-2',
            )}
          >
            <ButtonToggle
              className={classNames(
                'group',
                'rounded',
                'flex flex-col items-center justify-center flex-1',
                'h-8 w-8',
                'bg-menu/30',
                'hover:bg-menu-active hover:text-menu-active-text',
              )}
              onChange={() =>
                setViewMode(viewMode === 'filter' ? 'legend' : 'filter')
              }
            >
              {viewMode === 'filter' ? (
                <Icon.Close
                  className={classNames('p-0.5 w-4 h-4 fill-current')}
                />
              ) : (
                <Icon.Filter
                  className={classNames('p-0.5 w-4 h-4 fill-current')}
                />
              )}
            </ButtonToggle>
            <DropdownSelector
              classNameLabel={classNames('!text-sm')}
              onClick={e => {
                e.stopPropagation();
              }}
              DropAlignRight
              buttonTransparent
              vertical
              panelMode
              w_sm
              value={'...'}
              values={_.keys(dropOptions)}
              renderValue={key => dropOptions[key]?.title ?? '...'}
              onChange={async (key, e) => {
                const fn = dropOptions[key]?.onClick;
                _.isFunction(fn) && fn();
              }}
            />
          </div>
        }
      >
        {viewMode === 'legend' && <AssignmentStatsFilterSummary />}
      </StatGroup>
      {viewMode === 'filter' && <AssignmentStatsFilterPanel />}
      {viewMode === 'legend' && (
        <HeatmapBucketsList
          buckets={buckets}
          heatmapFilter={heatmapFilter}
          // cast metricDescriptor to any is to prevent TS check error:
          // "Type instantiation is excessively deep and possibly infinite."
          metricDescriptor={metricDescriptor as any}
          isLoading={isLoading}
          onClick={debouncedClick}
        />
      )}
    </div>
  );
};

export default AssignmentStatsLegend;
