import { useTranslation } from 'react-i18next';
import AllocationRequirementSummary from '../../allocation/AllocationRequirementSummary';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';

export default () => {
  const { t } = useTranslation('simulation');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-allocation-requirement',
    title: t('Allocation requirement'),
    loadingMessage: t('Calculating Allocation requirement'),
    size: 'md',
  };

  return (
    <SidebarContainer
      key={containerProps.type}
      {...containerProps}
      // hasIssue={true}
    >
      <AllocationRequirementSummary />
    </SidebarContainer>
  );
};
