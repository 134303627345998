import _ from 'lodash';
import { atom, selectorFamily } from 'recoil';
import { adjustStage, StageType, STAGE_TYPES } from './stage.helper';

const getKey = (postfix: string) => `stage-${postfix}`;

export interface IStagePropsState {
  scale: number;
  position: [x: number, y: number];
  size: [w: number, h: number];
  padW: number;
  padH: number;
  contentBounds: [x: number, y: number][];
  autoSizeId: string;
  forceAdjustContent?: boolean;
  viewPort: [x1: number, y1: number, x2: number, y2: number];
}

type IStagesState = {
  [key in StageType]: IStagePropsState;
};

const stageStatesDefault: IStagesState = STAGE_TYPES.reduce(
  (acc, v) =>
    ({
      ...acc,
      [v]: {
        scale: 0.1,
        position: [100, 1000],
        size: [800, 800],
        padW: 0.05,
        padH: 0.05,
        contentBounds: null,
        autoSizeId: null,
        viewPort: [0, 0, 8000, 8000],
      },
    } as IStagePropsState),
  {},
) as IStagesState;

const stageStates = atom<IStagesState>({
  key: getKey('collection'),
  default: stageStatesDefault,
});

export const stageStateById = selectorFamily<
  Partial<IStagePropsState>,
  StageType
>({
  key: getKey('by-id'),

  get:
    (id: StageType) =>
    ({ get }) => {
      return get(stageStates)[id];
    },

  set:
    id =>
    ({ get, set }, newValue: Partial<IStagePropsState>) => {
      if (_.has(newValue, 'size') && !newValue.size?.[0]) {
        console.warn('Stage size cannot be undefined');
        return;
      }

      const current = get(stageStates)[id];
      let newState = { ...current, ...newValue };

      const isContentSizeChanged =
        newValue.contentBounds && newValue.autoSizeId !== current.autoSizeId;
      const isStageSizeChanged =
        newState.contentBounds && newValue.forceAdjustContent;
      if (isContentSizeChanged || isStageSizeChanged) {
        newState = adjustStage(newState);
      }

      const { position, size, scale } = newState;

      newState.viewPort = [
        (-position[0] - size[0]) / scale,
        ((-position[1] - size[1]) / scale) * -1,
        (-position[0] + 2 * size[0]) / scale,
        ((-position[1] + 2 * size[1]) / scale) * -1,
      ];

      set(stageStates, {
        ...get(stageStates),
        [id]: newState,
      });
    },
});

const stagePointerPositionInner = atom<Record<StageType, [number, number]>>({
  key: getKey('pointer-position-inner'),
  default: {
    'viewer-area-view': [0, 0],
    'viewer-bay-view': [0, 0],
    'simulation-area-view': [0, 0],
    'simulation-bay-view': [0, 0],
    'converter-area-view': [0, 0],
    'converter-bay-view': [0, 0],
    'workforce-agent-schedule': [0, 0],
  },
});

export const stagePointerPosition = selectorFamily<[number, number], StageType>(
  {
    key: getKey('pointer-position'),

    get:
      (id: StageType) =>
      ({ get }) => {
        return get(stagePointerPositionInner)[id];
      },
    set:
      id =>
      ({ get, set }, newValue) => {
        set(stagePointerPositionInner, {
          ...get(stagePointerPositionInner),
          [id]: newValue,
        });
      },
  },
);
