import {
  AllocationRequirementFragment,
  AllocationRequirementRuleUomDetailsFragment,
  LoadAllocationRequirementItemsQuery,
  LoadAllocationRequirementItemsQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export const DEFAULT_UOM_STRING = '-';

export type AllocationRequirementExportJobParams = ExportJobParams<
  LoadAllocationRequirementItemsQueryVariables,
  AllocationRequirementConverterConfig
>;

export const allocationRequirementExportJobParams: AllocationRequirementExportJobParams =
  {
    converterId: 'ALLOCATION_REQUIREMENT',
    query: null,
    config: null,
    variables: null,
    filename: 'allocation-requirement.csv',
  };

export type AllocationRequirementDataRow = Pick<
  AllocationRequirementFragment,
  | 'itemId'
  | 'consignee'
  | 'sku'
  | 'orderLineRuleId'
  | 'requiredQuantity'
  | 'unpickableRequiredQuantity'
> & {
  orderLineRule: string;
};

export type AllocationRequirementDataColumn =
  keyof AllocationRequirementDataRow;

export type AllocationRequirementConverterConfig = {
  columnsConfig: ColumnConfigBase<AllocationRequirementDataRow>[];
  dictionaries: {
    rules: Record<string, string>;
  };
};

function getReducedUoms(
  uoms: AllocationRequirementRuleUomDetailsFragment[],
  current: Record<string, number>,
): Record<string, number> {
  return _.reduce(
    uoms,
    (acc, uom) => {
      const key = uom.uom ?? DEFAULT_UOM_STRING;
      return {
        ...acc,
        [key]: uom.uomQuantity + (current[key] ?? 0),
      };
    },
    {} as Record<string, number>,
  );
}

export const getAllocationRequirementTableRows = (
  data: AllocationRequirementFragment[],
  config: AllocationRequirementConverterConfig,
): AllocationRequirementDataRow[] => {
  const rulesMap = config.dictionaries.rules;

  const reduceAllocationRequirement = (
    requirement: AllocationRequirementFragment,
  ): AllocationRequirementDataRow => {
    const reqByUOM = _.reduce(
      requirement?.details?.rules,
      (acc, rule) => {
        return {
          ...acc,
          ...getReducedUoms(rule?.preferredUoms, acc),
        };
      },
      {} as Record<string, number>,
    );

    return {
      ...requirement,
      ...reqByUOM,
      orderLineRule: rulesMap[requirement.orderLineRuleId],
    };
  };

  return _.map(data, reduceAllocationRequirement);
};

export const ALLOCATION_REQUIREMENT_EXPORT_CONVERTER: ExportConverter<
  LoadAllocationRequirementItemsQuery,
  AllocationRequirementConverterConfig
> = (input, config) => {
  const data = getAllocationRequirementTableRows(
    input.simulation.allocationRequirementSet?.requirements?.content,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
