import { BatchJobStatus } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formatInteger } from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import DropdownSelector from '../components/actions/DropdownSelector';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import { Stat } from '../components/stats/Stat';
import { StatGroup } from '../components/stats/StatGroup';
import PickingPolicyWarnings from '../pickingPolicy/PickingPolicyWarnings';
import { AllocationRequirementGroupByType } from '../simulation/store/allocation/allocation.types';
import {
  getAllocationRequirementGroupByOptions,
  getAllocationRequirementKeyTitle,
} from '../simulation/store/allocation/allocationRequirement.helper';
import {
  allocationRequirement,
  allocationRequirementSummary,
  allocationRequirementSummaryGroupBy,
  allocationRequirementSummaryLoadStatus,
} from '../simulation/store/allocation/allocationRequirement.state';
import { pickingPolicyIsReadyToAnalyze } from '../simulation/store/pickingPolicy/pickingPolicy.state';
import { simulationAssignmentSummary } from '../simulation/store/simulation.layout.state';
import useLoadAllocationRequirementSummary from './useLoadAllocationRequirementSummary';

const AllocationRequirementSummary: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [groupByType, setGroupByType] = useRecoilState(
    allocationRequirementSummaryGroupBy,
  );
  const requirementMeta = useRecoilValue(allocationRequirement);
  const requirementSummary = useRecoilValue(allocationRequirementSummary);
  const summaryLoadStatus = useRecoilValue(
    allocationRequirementSummaryLoadStatus,
  );
  const assignment = useRecoilValue(simulationAssignmentSummary);
  const pickingPolicyStatus = useRecoilValue(pickingPolicyIsReadyToAnalyze);

  const [load, cancelLoad] = useLoadAllocationRequirementSummary();
  const drillOptions = getAllocationRequirementGroupByOptions(t);
  const groupBy = drillOptions[groupByType].groupBy;

  useEffect(() => {
    if (requirementMeta?.status === BatchJobStatus.READY) {
      cancelLoad();
      load({
        groupBy,
      });
    }
  }, [requirementMeta, groupByType]);

  const isLoading =
    summaryLoadStatus === AsyncLoadStatus.None ||
    summaryLoadStatus === AsyncLoadStatus.Loading;

  const hasError = summaryLoadStatus === AsyncLoadStatus.Error;
  const isReady = summaryLoadStatus === AsyncLoadStatus.Ok;

  const totalAllocationRequirementCount = _.sumBy(
    requirementSummary,
    r => r.allocationRequirementCount,
  );

  const sortedRequirementSummary = _.orderBy(
    requirementSummary,
    ['requiredQuantity'],
    ['desc'],
  );
  // Calculate the totals
  const totalRequirementItemQty = _.sumBy(
    requirementSummary,
    r => r.requiredQuantity,
  );
  const totalUnpickableRequiredQty = _.sumBy(
    requirementSummary,
    r => r.unpickableRequiredQuantity,
  );

  const totalStockItemsCount = assignment.totalItemsCount;
  const totalStockProductCount = assignment.totalProductsCount;
  const totalStockGap =
    totalRequirementItemQty - totalStockItemsCount - totalUnpickableRequiredQty;

  return (
    <>
      <Container col hasOverflowY>
        <ScreenTitle
          title={t`Pre-Allocation`}
          subtitle={t`Gap Analysis`}
          icon={Icon.PolicyAllocate}
          isSticky
        />

        {!pickingPolicyStatus?.isAnalyzeReady && <PickingPolicyWarnings />}

        <StatGroup title={t`Total (Qty)`}>
          <div
            className={classNames(
              'flex flex-1 flex-col space-y-0.5 lg:flex-row lg:space-x-1',
            )}
          >
            <Stat
              isCheckLoading={isLoading}
              title={t`Ordered`}
              key={`requirement-totalRequirementItemQty`}
              value={formatInteger(totalRequirementItemQty)}
            ></Stat>
            <Stat
              isCheckLoading={isLoading}
              title={t`In Stock`}
              key={`requirement-totalStockItemsCount`}
              value={formatInteger(totalStockItemsCount)}
            ></Stat>
            <Stat
              isCheckLoading={isLoading}
              title={t`Allocate (Gap)`}
              key={`requirement-totalStockGap`}
              value={formatInteger(totalStockGap)}
            ></Stat>
          </div>
        </StatGroup>
        {totalUnpickableRequiredQty > 0 && (
          <StatGroup title={t`Issues`}>
            <Stat
              isCheckLoading={isLoading}
              hasIssue
              title={t`Unpickable`}
              key={`requirement-totalUnpickableRequiredQuantity`}
              value={formatInteger(totalUnpickableRequiredQty)}
            ></Stat>
          </StatGroup>
        )}
        <StatGroup title={t`Order Summary`} subTitle={t`by SKU`}>
          <Stat
            isPreview
            isCheckLoading={isLoading}
            titleElement={
              <div className="flex flex-1">
                <div className="flex-1 ltr:mr-1 rtl:ml-1">{t`Allocation Requirements`}</div>
                <DropdownSelector
                  className="mx-1 text-sm"
                  DropAlignRight
                  panelMode
                  value={groupByType}
                  values={
                    _.keys(drillOptions) as AllocationRequirementGroupByType[]
                  }
                  onChange={dim => setGroupByType(dim)}
                  renderValue={key => drillOptions[key]?.title ?? key}
                />
              </div>
            }
            value={formatInteger(totalAllocationRequirementCount)}
            unitOfMeasure={`unique sku(s)`}
          />

          {/* {isLoading && <LoadingIndicator />} */}
          {hasError && <ErrorIndicator message={t`Error Loading`} />}
          {isReady &&
            _.map(sortedRequirementSummary, (requirement, index) => {
              const title = getAllocationRequirementKeyTitle(
                groupBy,
                requirement.key,
              );

              const isSelectedConsignee = groupByType === 'consignee';

              const valueRaw = requirement.allocationRequirementCount;
              const value = formatInteger(valueRaw);

              const requirementItemCount = formatInteger(requirement.itemCount);
              const requirementItemQty = formatInteger(
                requirement.requiredQuantity,
              );

              const unpickableRequiredQuantityRaw =
                requirement.unpickableRequiredQuantity;
              const unpickableRequiredQuantity = formatInteger(
                unpickableRequiredQuantityRaw,
              );

              return (
                <div key={`requirement-${title}-${index}`}>
                  <div className="text-menu-active bg-menu-500 px-2 py-2 text-base uppercase">
                    {title}
                  </div>
                  <div className="flex flex-1 space-x-1">
                    <Stat
                      title={t`Ordered`}
                      key={`requirement-${title}-requirementItemQty-#${index}`}
                      value={requirementItemQty}
                      legendColor={
                        isSelectedConsignee ? null : ['#ffcc00', '#000000']
                      }
                    ></Stat>
                    {/* <Stat
                      title={t`Stock`}
                      key={`requirement-${title}-#${index}`}
                      value={requirementItemQty}
                      unitOfMeasure={`(${requirementItemQty})`}
                    ></Stat> */}

                    {unpickableRequiredQuantityRaw > 0 && (
                      <Stat
                        title={t`Unpickable`}
                        key={`requirement-${title}-unpickableRequiredQuantity-#${index}`}
                        value={unpickableRequiredQuantity}
                      ></Stat>
                    )}
                  </div>
                </div>
              );
            })}
        </StatGroup>

        {/* <StatListItem
                    key={`requirement-${title}-#${index}`}
                    title={title}
                  /> */}

        {/* <StatListItem
                    key={`requirement-itemCount-${title}-#${index}`}
                    title={'Total'}
                    value={requirement.itemCount}
                  /> */}
        {/* <StatListItem
                    key={`requirement-requiredQuantity-${title}-#${index}`}
                    title={'Required qty'}
                    value={requirement.requiredQuantity}
                  /> */}
        {/* {requirement.preferredRequiredVolume > 0 && (
                    <StatListItem
                      key={`requirement-preferredRequiredVolume-${title}-#${index}`}
                      title={'Preferred Required Volume'}
                      value={requirement.preferredRequiredVolume}
                    />
                  )} */}
        {/* {requirement.unpickableRequiredQuantity > 0 && (
                    <StatListItem
                      key={`requirement-unpickableRequiredQuantity-${title}-#${index}`}
                      title={'unpickableRequiredQuantity'}
                      value={requirement.unpickableRequiredQuantity}
                    />
                  )} */}
      </Container>
    </>
  );
};

export default AllocationRequirementSummary;
