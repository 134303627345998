import { ActivityEventProcessType } from '@warebee/shared/import-converter';
import { useTranslation } from 'react-i18next';

export type ActivityEventProcessTypeDetails = {
  id: ActivityEventProcessType;
  title: string;
  description: string;
};

function useActivityEventProcessTypeDetails(): ActivityEventProcessTypeDetails[] {
  const { t } = useTranslation('feed');
  return [
    {
      id: ActivityEventProcessType.PICKING,
      title: t`picking`,
      description: t`Selecting items from inventory to fulfill orders.`,
    },
    {
      id: ActivityEventProcessType.REPLENISHMENT,
      title: t`replenishment`,
      description: t`Restocking picking locations from bulk storage.`,
    },
    {
      id: ActivityEventProcessType.REASSIGN,
      title: t`reassign`,
      description: t`Changing the task or location of items or resources.`,
    },
    {
      id: ActivityEventProcessType.PUTAWAY,
      title: t`putaway`,
      description: t`Storing received goods in their designated location.`,
    },
    {
      id: ActivityEventProcessType.PACKING,
      title: t`packing`,
      description: t`Preparing items for shipment.`,
    },
    {
      id: ActivityEventProcessType.RECEIVING,
      title: t`receiving`,
      description: t`Accepting and processing incoming goods.`,
    },
    {
      id: ActivityEventProcessType.QUALITY_CONTROL,
      title: t`quality_control`,
      description: t`Inspecting and verifying item quality.`,
    },
    {
      id: ActivityEventProcessType.INVENTORY_COUNT,
      title: t`inventory_count`,
      description: t`Checking stock to ensure inventory accuracy.`,
    },
    {
      id: ActivityEventProcessType.RETURNS_PROCESSING,
      title: t`returns_processing`,
      description: t`Handling returned goods.`,
    },
    {
      id: ActivityEventProcessType.SHIPPING,
      title: t`shipping`,
      description: t`The process of sending out goods to the customer.`,
    },
    {
      id: ActivityEventProcessType.MAINTENANCE,
      title: t`maintenance`,
      description: t`Equipment and facility upkeep.`,
    },
    {
      id: ActivityEventProcessType.CROSSDOCKING,
      title: t`cross_docking`,
      description:
        'Directly transferring goods from inbound to outbound shipping with minimal or no storage.',
    },
    {
      id: ActivityEventProcessType.MANAGEMENT_YARD,
      title: t`yard_management`,
      description:
        'Activities related to managing the movement and storage of vehicles and goods in the yard area.',
    },
    {
      id: ActivityEventProcessType.OTHER,
      title: t`other`,
      description: t`Any activities not covered by the standard types.`,
    },
    {
      id: ActivityEventProcessType.IGNORE,
      title: t`ignore`,
      description:
        'Activities that should be excluded from analytics or reporting.',
    },
  ];
}

export default useActivityEventProcessTypeDetails;
