import {
  AnalyzeResultAgentSummary,
  AnalyzeResultProcessType,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FormattedValueWithUnit } from '../../../../common/formatHelper';
import useFormatter from '../../../../common/useFormatter';
import { AgentStatItem } from '../../../../components/stats/AgentStatItem';
import {
  AgentCategoryId,
  AgentSettingsWithMeta,
} from '../../../../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../../../../resourcePolicy/agentData/agentDataHelper';
import { simulationAnalyzeDistinctDays } from '../../../store/simulation.state';
import { workforceSelectedAgentId } from '../../../store/workforce.state';
import { StatMiniInline } from '../../route/StatInline';

export type WorkforceAgentStatItemProps = {
  agent: AgentSettingsWithMeta;
  agentSummary: AnalyzeResultAgentSummary;
  distance?: FormattedValueWithUnit;
  co2?: FormattedValueWithUnit;
};

const WorkforceAgentStatItem: React.FC<WorkforceAgentStatItemProps> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const daysCount = useRecoilValue(simulationAnalyzeDistinctDays);
  const [activeAgentId, setActiveAgentId] = useRecoilState(
    workforceSelectedAgentId,
  );
  const shiftInSeconds = props.agent.utilisation.secondsOperation;
  const agentAmount = props.agent.utilisation.agentAmount;

  const costRaw = _.sumBy(
    _.filter(
      props.agentSummary?.eventTypes,
      e => e.processType === AnalyzeResultProcessType.PICKING,
    ),
    event => event.totalCost,
  );

  const durationRaw = _.sumBy(
    _.filter(
      props.agentSummary?.eventTypes,
      e => e.processType === AnalyzeResultProcessType.PICKING,
    ),
    event => event.totalDuration,
  );
  const durationPlanned = agentAmount * shiftInSeconds * daysCount ?? 0;
  const costPlanned = props.agent.cost?.costPerSecond
    ? durationPlanned * props.agent.cost.costPerSecond
    : 0;
  const utilizationRaw = durationRaw / durationPlanned ?? 0;

  // const distance = formatter.formatDistance(analyzeResult.totalDistance);
  const duration = formatter.formatTimeSpan(durationRaw);
  const cost = formatter.formatCurrency(costRaw);
  const utilization = formatter.formatShare(utilizationRaw);

  const activeAgent = props.agent.id === activeAgentId;
  const styleMiniStat = classNames(
    '!group-hover:bg-opacity-0 group-hover:text-menu-active',
    activeAgent
      ? '!bg-menu-active/75 text-menu-active-text !border !border-app-panel-dark group-hover:text-menu-text'
      : '',
  );

  return (
    <AgentStatItem
      isActionable
      className={classNames('w-full rounded-none')}
      // isActive={props.agent.id === selected}
      // onClick={() => onSelectValue(props.agent.id)}
      icon={getAgentIcon(props.agent.resourceTypeId as AgentCategoryId)}
      iconClassName={classNames(
        'w-9 h-9 xl:w-12 xl:h-12',
        'ltr:mr-2 rtl:ml-2 mb-2',
      )}
      title={props.agent.title}
      // subtitle={`Cost: ${cost.value}${cost.unit} Utilisation: ${utilization.value}${utilization.unit}`}
      counter={props.agent.utilisation?.agentAmount}
      isActive={activeAgent}
      onClick={() => setActiveAgentId(props.agent.id)}
    >
      <div
        data-component="StatMiniInlineContainer"
        className={classNames('z-100 mt-2 flex space-x-1')}
      >
        {props.agent.cost?.costPerSecond && (
          <StatMiniInline
            className={styleMiniStat}
            title={t`Cost`}
            value={cost.value}
            unitOfMeasure={cost.unit}
          />
        )}
        <StatMiniInline
          className={styleMiniStat}
          title={t`Utilisation`}
          value={utilization.value}
          unitOfMeasure={utilization.unit}
        />
      </div>
      <div
        data-component="StatMiniInlineContainer"
        className={classNames('z-100 mt-2 flex space-x-1')}
      >
        <StatMiniInline
          className={styleMiniStat}
          title={t`Distance`}
          value={props.distance.value}
          unitOfMeasure={props.distance.unit}
        />
        {props.co2.value && (
          <StatMiniInline
            className={styleMiniStat}
            title={t`Co2`}
            value={props.co2.value}
            unitOfMeasure={props.co2.unit}
          />
        )}
      </div>
    </AgentStatItem>
  );
};
export default WorkforceAgentStatItem;
