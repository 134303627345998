import {
  AreaConfiguration,
  AreaLink,
  AreaLinkPointStatus,
  AreaLinkPointType,
  areaLinkPointTypes,
} from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import { AreaLinkPoint } from './converter.types';

function getPointStatus(
  pointType: AreaLinkPointType,
  currentLinkType: AreaLinkPointType,
  masterLinks: Set<AreaLinkPointType>,
): AreaLinkPointStatus {
  if (pointType === currentLinkType && masterLinks.has(pointType)) {
    return 'linkBoth';
  }
  if (pointType === currentLinkType && !masterLinks.has(pointType)) {
    return 'linkSource';
  }

  if (masterLinks.has(pointType)) {
    return 'linkTarget';
  }

  return 'default';
}

export type GetAreaLinkPointsParams = {
  areaId: string;
  box: Flatten.Box;
  links: AreaLink[];
  areaConfig: AreaConfiguration;
};

export function getAreaLinkPoints(
  params: GetAreaLinkPointsParams,
): AreaLinkPoint[] {
  const { areaId, box } = params;
  const currentAreaLink = params.areaConfig.link?.linkPointType;
  const masterLinks = new Set(
    _(params.links)
      .filter(l => l.masterAreaId === params.areaId)
      .map(l => l.masterLinkPointType)
      .value(),
  );

  const sortedPoints = [
    { x: box.xmin, y: box.ymin },
    { x: box.xmin, y: box.ymax },
    { x: box.xmax, y: box.ymax },
    { x: box.xmax, y: box.ymin },
  ];

  const points: AreaLinkPoint[] = _.map(areaLinkPointTypes, (type, index) => ({
    ...sortedPoints[index],
    id: `${areaId}-${type}`,
    areaId,
    type,
    status: getPointStatus(type, currentAreaLink, masterLinks),
    connectedAreasIds: _(params.links)
      .filter(l => l.masterAreaId === areaId && l.masterLinkPointType === type)
      .map(l => l.areaId)
      .value(),
  }));
  return points;
}
