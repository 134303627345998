import {
  LayoutLocationsSummaryGroupBy,
  LayoutLocationsSummaryUomSpec,
} from '@warebee/frontend/data-access-api-graphql';
import {
  LocationCapacityId,
  LocationsStatsDataRow,
} from '@warebee/shared/export-converter';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { formatInteger } from '../../common/formatHelper';
import { Formatter } from '../../common/useFormatter';
import { ColumnConfig } from '../../components/DatasetTable';
import i18n from '../../i18n';

export type LocationsCapacitySpec = LayoutLocationsSummaryUomSpec & {
  id: LocationCapacityId;
  title: string;
};

export type LocationsStatsDimensionId =
  | keyof typeof LayoutLocationsSummaryGroupBy
  | 'aisle-side';

export type LocationsStatsDimension = ColumnConfig<LocationsStatsDataRow> & {
  id: LocationsStatsDimensionId;
  groupBy: LayoutLocationsSummaryGroupBy[];
};

export const getLocationCapacitySpec = (
  t: TFunction<'simulation'> = i18n.t,
): LocationsCapacitySpec[] => [
  {
    id: 'h130',
    title: t('ISO Pallet (H:130)', { ns: 'simulation' }),
    width: 80,
    length: 80,
    height: 130,
  },
  // {
  //   id: 'h100',
  //   title: t('UOM (H:100)', { ns: 'simulation' }),
  //   // width: 80,
  //   // length: 80,
  //   height: 100,
  // },
];

export const getLocationsStatsDimensions = (
  t: TFunction<'simulation'> = i18n.t,
): Record<LocationsStatsDimensionId, LocationsStatsDimension> => {
  const dims: LocationsStatsDimension[] = [
    {
      id: LayoutLocationsSummaryGroupBy.LOCATION_RACKING_TYPE,
      groupBy: [LayoutLocationsSummaryGroupBy.LOCATION_RACKING_TYPE],
      title: t('Location Type', { ns: 'simulation' }),
      field: 'locationRackingType',
      hasFilter: true,
    },
    {
      id: LayoutLocationsSummaryGroupBy.ACCESS_AISLE,
      groupBy: [LayoutLocationsSummaryGroupBy.ACCESS_AISLE],
      title: t('Access', { ns: 'simulation' }),
      field: 'accessAisleTitle',
      hasFilter: true,
    },
    {
      id: LayoutLocationsSummaryGroupBy.AISLE,
      groupBy: [LayoutLocationsSummaryGroupBy.AISLE],
      title: t('Aisle', { ns: 'simulation' }),
      field: 'aisleTitle',
      hasFilter: true,
    },
    {
      id: LayoutLocationsSummaryGroupBy.LEVEL,
      groupBy: [LayoutLocationsSummaryGroupBy.LEVEL],
      title: t('Level', { ns: 'simulation' }),
      field: 'locationLevel',
      hasFilter: true,
    },
    {
      id: LayoutLocationsSummaryGroupBy.SIDE,
      groupBy: [LayoutLocationsSummaryGroupBy.SIDE],
      title: t('Side', { ns: 'simulation' }),
      field: 'locationSide',
      hasFilter: true,
    },
    {
      id: LayoutLocationsSummaryGroupBy.WAREHOUSE_AREA,
      groupBy: [LayoutLocationsSummaryGroupBy.WAREHOUSE_AREA],
      title: t('Area', { ns: 'simulation' }),
      field: 'warehouseArea',
      hasFilter: true,
    },
    {
      id: LayoutLocationsSummaryGroupBy.LOCATION_STATUS,
      groupBy: [LayoutLocationsSummaryGroupBy.LOCATION_STATUS],
      title: t('Status', { ns: 'simulation' }),
      field: 'locationStatus',
      hasFilter: true,
      filterOptions: {
        true: t('Enabled', { ns: 'simulation' }),
        false: t('Disabled', { ns: 'simulation' }),
      },
      render: v =>
        v ? (
          <span
            className={classNames(
              'uppercase text-minimal',
              'rounded',
              'px-3 py-1',
              'bg-alerts-okay text-alerts-warningText',
            )}
          >
            {t('Enabled', { ns: 'simulation' })}
          </span>
        ) : (
          <span
            className={classNames(
              'uppercase text-minimal',
              'rounded',
              'px-3 py-1',
              'bg-menu-active/30 text-menu-active-text',
            )}
          >
            {t('Disabled', { ns: 'simulation' })}
          </span>
        ),
    },
    {
      id: LayoutLocationsSummaryGroupBy.ASSIGNED,
      groupBy: [LayoutLocationsSummaryGroupBy.ASSIGNED],
      title: t('Occupancy', { ns: 'simulation' }),
      field: 'assigned',
      hasFilter: true,
      filterOptions: {
        true: t('Occupied', { ns: 'simulation' }),
        false: t('Empty', { ns: 'simulation' }),
      },
      render: v =>
        v ? (
          <span
            className={classNames(
              'uppercase text-minimal',
              'rounded',
              'px-3 py-1',
              'bg-location-selected text-menu-active-text',
            )}
          >
            {t('Occupied', { ns: 'simulation' })}
          </span>
        ) : (
          <span
            className={classNames(
              'uppercase text-minimal',
              'rounded',
              'px-3 py-1',
              'bg-app-panel-dark/70 text-location-empty',
            )}
          >
            {t('Empty', { ns: 'simulation' })}
          </span>
        ),
    },
    {
      id: 'aisle-side',
      groupBy: [
        LayoutLocationsSummaryGroupBy.AISLE,
        LayoutLocationsSummaryGroupBy.SIDE,
      ],
      title: t('Aisle and Side', { ns: 'simulation' }),
      field: 'aisleAndSide',
    },
    {
      id: LayoutLocationsSummaryGroupBy.CONSIGNEE,
      groupBy: [LayoutLocationsSummaryGroupBy.CONSIGNEE],
      title: t('Consignee', { ns: 'simulation' }),
      field: 'consignee',
    },
  ];

  return _.keyBy(dims, 'id') as Record<
    LocationsStatsDimensionId,
    LocationsStatsDimension
  >;
};

export const locationStatsBaseDrillDownDimensions: LocationsStatsDimensionId[] =
  [
    LayoutLocationsSummaryGroupBy.LOCATION_RACKING_TYPE,
    LayoutLocationsSummaryGroupBy.AISLE,
    'aisle-side',
    LayoutLocationsSummaryGroupBy.LEVEL,
    LayoutLocationsSummaryGroupBy.ACCESS_AISLE,
    LayoutLocationsSummaryGroupBy.SIDE,
    LayoutLocationsSummaryGroupBy.WAREHOUSE_AREA,
  ];

export const locationStatsDrillDownDimensions: LocationsStatsDimensionId[] = [
  ...locationStatsBaseDrillDownDimensions,
  LayoutLocationsSummaryGroupBy.CONSIGNEE,
];

export const locationStatsFixedDimensions = [
  LayoutLocationsSummaryGroupBy.LOCATION_STATUS,
  LayoutLocationsSummaryGroupBy.ASSIGNED,
];

export function getLocationsStatsTableConfigDefault(
  mainDimension: LocationsStatsDimensionId,
  t: TFunction<'simulation'>,
  formatter: Formatter,
): ColumnConfig<LocationsStatsDataRow>[] {
  const dimensions = getLocationsStatsDimensions(t);
  return [
    ..._.map([mainDimension, ...locationStatsFixedDimensions], d => ({
      isHeader: true,
      ...dimensions[d],
    })),
    {
      field: 'locationCount',
      title: t(`Locations`, { ns: 'simulation' }),
      render: v => [formatInteger(v)] ?? '—',
    },
    ..._.map(getLocationCapacitySpec(t), l => ({
      field: l.id,
      title: l.title,
      render: l => [formatInteger(l)] ?? '—',
    })),
  ];
}
