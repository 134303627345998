import {
  ActualityMetaFragment,
  SimulationMetaFragment,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { RecoilValue, useRecoilState, useRecoilValue } from 'recoil';
import AnalyzedProductsTable from '../analyze/AnalyzedProductsTable';
import AssignmentDataTable from '../assignment/AssignmentDataTable';
import AssignmentFilterTag from '../assignment/AssignmentFilterTag';
import AssignmentPolicyLocationsStatsByRuleTableView from '../assignmentPolicy/AssignmentPolicyLocationsStatsByRuleTableView';
import { panelFixedHeightsCss } from '../common/component.types';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { Container } from '../components/layout/ContainerFlex';
import LoadingIndicator from '../components/LoadingIndicator';
import {
  actualitySelectedDocument,
  actualitySelectedDocumentStatus,
  actualityShowDatasetAsTable,
} from '../feed/store/actuality.state';
import { feedLayoutId } from '../feed/store/feed.state';
import ItemSetDataTable from '../itemSet/ItemSetDataTable';
import ItemSetFilterTag from '../itemSet/ItemSetFilterTag';
import LayoutDataTable from '../layout/LayoutDataTable';
import LayoutFilterTag from '../layout/LayoutFilterTag';
import { layoutDocumentId } from '../layout/store/layout.state';
import OrderSetDataTable from '../orders/OrderSetDataTable';
import OrderSetFilterTag from '../orders/OrderSetFilterTag';
import DatasetLocationsStatsTableView from '../simulation/DatasetLocationsStatsTableView';
import { optimisationAnalyzeId } from '../simulation/store/optimisation.state';
import {
  simulationAnalyzeId,
  simulationCurrent,
  simulationExtraDataViewHeight,
} from '../simulation/store/simulation.state';

export type DatasetViewerProps = {
  children?: React.ReactNode;
  mode: 'simulation' | 'actuality' | 'any';
};

function useDocument<T extends SimulationMetaFragment | ActualityMetaFragment>(
  isSimulation: boolean,
): T {
  return isSimulation
    ? useRecoilValue(simulationCurrent as RecoilValue<T>)
    : useRecoilValue(actualitySelectedDocument as RecoilValue<T>);
}

const DatasetViewer: React.FC<DatasetViewerProps> = ({ mode, children }) => {
  const { t } = useTranslation('simulation');
  const isSimulation = mode === 'simulation';

  const document = useDocument<SimulationMetaFragment | ActualityMetaFragment>(
    isSimulation,
  );

  const documentStatus = useRecoilValue(
    isSimulation ? undefined : actualitySelectedDocumentStatus,
  );
  const layoutId = useRecoilValue(
    isSimulation ? layoutDocumentId : feedLayoutId,
  );

  const [showDatasetTable, setShowDatasetTable] = useRecoilState(
    actualityShowDatasetAsTable,
  );
  const [height, setHeight] = useRecoilState(simulationExtraDataViewHeight);

  const analyzeId = useRecoilValue(simulationAnalyzeId);
  const optimiseAnalyzeId = useRecoilValue(optimisationAnalyzeId);

  if (_.isNil(showDatasetTable)) return null;

  function genPanelTitle() {
    switch (showDatasetTable) {
      case 'feed':
        return t`Feed`;
      case 'assignment':
        return t`Assignment`;
      case 'assignment-optimised':
        return t`Optimised Assignment`;
      case 'item-set':
        return t`Items`;
      case 'layout':
        return t`Locations`;
      case 'locations-stats-all':
        return t`Location Stats: Capacity & Occupancy`;
      case 'locations-stats-by-rule':
        return t`Rule Stats: Capacity & Occupancy`;
      case 'order-set':
        return t`Orders`;
      case 'analyzed-location-products':
        return t`Analyzed Products`;
      case 'optimized-location-products':
        return t`Optimised Products`;
    }
  }

  function getFilterTags() {
    switch (showDatasetTable) {
      case 'layout':
        return <LayoutFilterTag />;
      case 'assignment':
      case 'assignment-optimised':
        return <AssignmentFilterTag />;
      case 'item-set':
        return <ItemSetFilterTag />;
      case 'order-set':
        return <OrderSetFilterTag />;
    }
  }

  function getLayoutId(
    doc: SimulationMetaFragment | ActualityMetaFragment,
  ): string | undefined {
    if ('layout' in doc && doc.layout) {
      return doc.layout.id;
    } else if ('layoutId' in doc) {
      return doc.layoutId;
    }
    return undefined;
  }

  function getAssignmentId(
    doc: SimulationMetaFragment | ActualityMetaFragment,
  ): string | undefined {
    if ('assignment' in doc && doc.assignment) {
      return doc.assignment.id;
    } else if ('assignmentId' in doc) {
      return doc.assignmentId;
    }
    return undefined;
  }

  function getOrderSetId(
    doc: SimulationMetaFragment | ActualityMetaFragment,
  ): string | undefined {
    if ('orderSet' in doc && doc.orderSet) {
      return doc.orderSet.id;
    } else if ('orderSetId' in doc) {
      return doc.orderSetId;
    }
    return undefined;
  }

  function getItemSetId(
    doc: SimulationMetaFragment | ActualityMetaFragment,
  ): string | undefined {
    if ('itemSet' in doc && doc.itemSet) {
      return doc.itemSet.id;
    } else if ('itemSetId' in doc) {
      return doc.itemSetId;
    }
    return undefined;
  }

  const dataTableConfig = {
    layout: {
      check: doc => !!getLayoutId(doc),
      component: doc => (
        <LayoutDataTable
          id={getLayoutId(doc)}
          title="Locations"
          hideScreenTitle={true}
        />
      ),
    },
    assignment: {
      check: doc => !!getAssignmentId(doc),
      component: doc => (
        <AssignmentDataTable
          id={getAssignmentId(doc)}
          title="Assignment"
          hideScreenTitle={true}
        />
      ),
    },
    orderSet: {
      check: doc => !!getOrderSetId(doc),
      component: doc => (
        <OrderSetDataTable
          id={getOrderSetId(doc)}
          title="Orders"
          hideScreenTitle={true}
        />
      ),
    },
    itemSet: {
      check: doc => !!getItemSetId(doc),
      component: doc => (
        <ItemSetDataTable
          id={getItemSetId(doc)}
          title="Items"
          hideScreenTitle={true}
        />
      ),
    },
    locationsStatsAll: {
      check: doc => true,
      component: () => <DatasetLocationsStatsTableView />,
    },
    locationsStatsByRule: {
      check: doc => true,
      component: () => <AssignmentPolicyLocationsStatsByRuleTableView />,
    },
    analyzedLocationProducts: {
      check: doc => !!analyzeId,
      component: () => <AnalyzedProductsTable analyzeId={analyzeId} />,
    },
    optimizedLocationProducts: {
      check: doc => !!optimiseAnalyzeId,
      component: () => <AnalyzedProductsTable analyzeId={optimiseAnalyzeId} />,
    },
  };

  const renderDataTable = (key: string) => {
    const config = dataTableConfig[key];
    if (config && config.check(document)) {
      return config.component(document);
    }
    return null;
  };

  return (
    <Container
      col
      overflow
      componentName={`DatasetTableContainer`}
      fullHeight={false}
      className={classNames(
        height !== 'h-min' ? 'min-h-20' : '',
        'border-app-panel-dark ltr:border-l rtl:border-r',
        'relative',
        height ? panelFixedHeightsCss[height] : null,
      )}
    >
      <Suspense
        fallback={<LoadingIndicator message={t`Loading...`} selfCenter />}
      >
        <PanelHeader
          title={genPanelTitle()}
          closable={true}
          onCloseClick={() => setShowDatasetTable(null)}
          filterPlaceholder={getFilterTags()}
          collapsible
          adjustable={!_.isNil(height)}
          fixedHeight={height}
          onHeightChange={setHeight}
        />
        {children
          ? children
          : Object.keys(dataTableConfig).map(key => renderDataTable(key))}
      </Suspense>
    </Container>
  );
};

export default DatasetViewer;
