import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { Container } from '../../../components/layout/ContainerFlex';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../../containers/SidebarContainer';
import useSelectSimulationWizardStep from '../../hooks/useSelectSimulationWizardStep';
import CompliancePanel from '../../panels/compliance/CompliancePanel';
import ActionBarNextStep from '../ActionBarNextStep';

const ComplianceSidebarContent: React.FC = () => {
  const { t } = useTranslation('simulation');
  // const simulation = useRecoilValue(simulationCurrent);
  const selectStep = useSelectSimulationWizardStep();

  return (
    <>
      <Container col>
        <Suspense
          fallback={<LoadingIndicator selfCenter message={t`Loading...`} />}
        >
          <CompliancePanel />
        </Suspense>
      </Container>
      <ActionBarNextStep />
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-compliance',
    title: t`Compliance`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <ComplianceSidebarContent />
    </SidebarContainer>
  );
};
