import { ImportPipelineConnectorType } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { highlight } from 'prismjs/components/prism-core';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'react-simple-code-editor';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formatInteger } from '../../common/formatHelper';
import { cloneWithoutTypename } from '../../common/utils';
import InboxZero from '../../components/InboxZero';
import KeyValueItem from '../../components/KeyValueItem';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Button } from '../../components/actions/Button';
import CopyToClipboardButton from '../../components/actions/CopyToClipboardButton';
import * as Icon from '../../components/icons';
import InputTextArea from '../../components/inputs/InputTextArea';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import { ActionBar } from '../../components/nav/ActionBar';
import { PolicyWell } from '../../components/policies/PolicyWell';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import Callout from '../../helpContext/Callout';
import Tag from '../../helpContext/Tag';
import { sql } from '../../import/csv/ImportExpressionSqlLanguage';
import RemovePipeline from '../../importJobs/panels/editors/RemovePipeline';
import {
  warehouseCanUpdate,
  warehouseSelected,
} from '../../store/warehouse.state';
import { WarehouseExtraSettings } from '../../store/warehouse.types';
import useUpdateWarehouseExtraSettings from '../../warehouse/hooks/useUpdateWarehouseExtraSettings';
import ImportPipelineDatasetConnector from '../ImportPipelineDatasetConnector';
import PipelineFileUploader from '../ImportPipelineFileUploader';
import ImportPipelineSFTPConnector from '../ImportPipelineSFTPConnector';
import useUpdateImportPipeline from '../hooks/useUpdateImportPipeline';
import {
  connectorNewDatasetKey,
  connectorNewSFTPKey,
} from '../store/importPipeline.helper';
import {
  importPipelineIsActiveEditor,
  importPipelineSelected,
  importPipelineSelectedConnectorId,
  importPipelineSelectedId,
  importPipelineUpdateStatus,
  importPipelinesDataTableState,
} from '../store/importPipelines.state';

const ImportPipelineViewPanel: React.FC = () => {
  const { t } = useTranslation('dataset');

  const pipeline = useRecoilValue(importPipelineSelected);
  const wh = useRecoilValue(warehouseSelected);
  const whExtraSettings: WarehouseExtraSettings = wh?.extraSettings;

  const setSelectedId = useSetRecoilState(importPipelineSelectedId);
  const setConnectorId = useSetRecoilState(importPipelineSelectedConnectorId);
  const [tableState, setTableState] = useRecoilState(
    importPipelinesDataTableState,
  );
  const updateStatus = useRecoilValue(importPipelineUpdateStatus);
  const setShowEditor = useSetRecoilState(importPipelineIsActiveEditor);

  const updatePipeline = useUpdateImportPipeline();

  const [query, setQuery] = useState(
    pipeline?.settings?.mappingSettings?.transformation?.query,
  );
  const [updateWhExtraSettings] = useUpdateWarehouseExtraSettings();

  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const connectorId = useRecoilValue(importPipelineSelectedConnectorId);

  const [genLoading, setGenLoading] = useState(false);
  const [token, setToken] = useState<string>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>(null);

  const isDefaultPipeline =
    pipeline &&
    pipeline?.id === whExtraSettings.defaultPipelines?.[pipeline?.importType];

  function addSftpConnector() {
    setConnectorId(connectorNewSFTPKey);
    setShowEditor(true);
  }
  function addDatasetConnector() {
    setConnectorId(connectorNewDatasetKey);
    setShowEditor(true);
  }

  function removeConnectorById(id: string) {
    const connectors = _(pipeline.settings?.integrationSettings?.connectors)
      .filter(c => c.id !== id)
      .map(cloneWithoutTypename)
      .value();

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  function removeAllConnectorsByType(type: ImportPipelineConnectorType) {
    const connectors = _(pipeline.settings?.integrationSettings?.connectors)
      .filter(c => c.type !== type)
      .map(cloneWithoutTypename)
      .value();

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  function addConnector(type: ImportPipelineConnectorType) {
    const connectors = _.map(
      pipeline.settings?.integrationSettings?.connectors,
      cloneWithoutTypename,
    );
    connectors.push({
      id: nanoid(10),
      type,
      enabled: true,
    });

    updatePipeline({
      importPipelineId: pipeline.id,
      settings: {
        ...pipeline.settings,
        integrationSettings: {
          connectors,
        },
      },
    });
  }

  function setDefaultPipelineSettings(id: string) {
    updateWhExtraSettings({
      patch: {
        defaultPipelines: {
          ...(whExtraSettings.defaultPipelines ?? {}),
          [pipeline.importType]: id,
        },
      },
    });
  }

  const connectors = pipeline?.settings?.integrationSettings?.connectors;

  // Connector: S3 (Push)
  const sftpConnectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.SFTP_PULL,
  );
  const hasSftpConnector = !_.isEmpty(sftpConnectors);

  const datasetConnectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.DATASET_CHANGED,
  );
  const hasDatasetConnector = !_.isEmpty(datasetConnectors);

  // Connector: S3 (Push)
  const s3Connectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.S3_PUSH,
  );
  const hasS3Connector = !_.isEmpty(s3Connectors);

  // Connector: Api (Push)
  const apiConnectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.API_PUSH,
  );
  const hasApiConnector = !_.isEmpty(apiConnectors);

  // Connector: Email (Push)
  const emailConnectors = _.filter(
    connectors,
    c => c.type === ImportPipelineConnectorType.EMAIL_PUSH,
  );
  const hasEmailConnector = !_.isEmpty(emailConnectors);

  const styleApiText = classNames(
    'font-mono',
    // '!bg-app-panel-light/75',
    'text-menu-text',
    'text-start',
    'break-all',
    'select-text',
    // 'py-4 px-2',
    'w-full',
    'shadow',
    'rounded',
  );

  if (_.isNil(pipeline)) {
    return <LoadingIndicator selfCenter message={t`Loading...`} />;
  }

  return (
    <Container
      key={`import-pipeline-view-panel-${pipeline.id}`}
      data-component="ImportPipelineViewPanel"
      col
      hasOverflowY
    >
      <ScreenTitle
        title={pipeline.title}
        subtitle={t`Pipeline`}
        isSticky
        helpNavTo={'importer/importer'}
        icon={Icon.ImportTransform}
      />
      {/* <TitleSection
        id={`pipeline-stats`}
        title={t`Stats`}
        inPanelView
        // className="top-12 xl:top-20 z-400"
        hasScreenTitle
        collapsible
        isSticky
      > */}
      <StatGroup horizontal>
        <Stat
          hasHelper
          isPreview
          title={t`Job Stats`}
          value={formatInteger(pipeline.jobs?.totalCount)}
          isFullWidth
          inPanelMode
        />
        {/* <Stat
            hasHelper
            isPreview
            title={t`Job Errors`}
            value={`—`}
            isFullWidth
            inPanelMode
          /> */}
      </StatGroup>
      {/* </TitleSection> */}
      <TitleSection
        id={`pipeline-setting-file-upload`}
        title={t`Pipeline Uploader`}
        inPanelView
        className={classNames('z-400 top-12 xl:top-20')}
        hasScreenTitle
        // collapsible
        // hasPadding
      >
        <PipelineFileUploader pipelineId={pipeline.id} />
      </TitleSection>
      <TitleSection
        id={`pipeline-properties`}
        title={t`Pipeline Properties`}
        inPanelView
        // className="top-12 xl:top-20 z-400"
        hasScreenTitle
        collapsible
        summaryView
        hasAction={
          <Button
            label={t`Edit`}
            className={classNames('rounded ltr:ml-4 rtl:mr-4')}
            buttonSize="xs"
            buttonType="primary"
            hasIconAfter={
              <Icon.EditPencil className={`h-5 w-5 fill-current`} />
            }
            onPress={() => {
              setConnectorId(null);
              setShowEditor(true);
            }}
          />
        }
      >
        <PolicyWell
          fullHeight
          // isDisabled={!canUpdate}
          className={classNames('mb-2 flex-1')}
          classNameChildren={classNames('space-y-1')}
        >
          <InputTextArea
            optional
            label={t`Pipeline Description`}
            placeholder={t`About the Pipeline...`}
            value={pipeline.description}
            isDisabled
          />

          <div className={classNames('my-2 flex items-center py-1')}>
            <div
              className={classNames('text-menu-text mb-2 flex-1 text-sm')}
            >{t`Data Format`}</div>
            <Tag classType={classNames('bg-app-panel text-menu-text shadow')}>
              {pipeline.settings?.sourceSettings?.format}
            </Tag>
          </div>
          <div className={classNames('my-2 flex items-center py-1')}>
            <div
              className={classNames('text-menu-text flex-1 text-sm')}
            >{t`Compression Settings`}</div>
            <Tag classType={classNames('bg-app-panel text-menu-text shadow')}>
              {pipeline.settings?.sourceSettings?.compression}
            </Tag>
          </div>

          <div className={classNames('my-2 flex items-center py-1')}>
            <div className={classNames('text-menu-text mb-2 flex-1 text-sm')}>
              {isDefaultPipeline ? t`Default Pipeline` : t`Default Pipeline`}
            </div>
            <Button
              className={classNames('rounded ltr:ml-4 rtl:mr-4')}
              label={
                isDefaultPipeline ? t`Unset as: Default` : t`Set as: Default`
              }
              buttonSize="xs"
              buttonType={isDefaultPipeline ? 'primary' : 'secondary'}
              hasIconAfter={
                <Icon.CircleOk className={`h-5 w-5 fill-current`} />
              }
              // hasIconAfter={<Icon.CircleX className={`h-5 w-5 fill-current`} />}
              onPress={() =>
                setDefaultPipelineSettings(
                  isDefaultPipeline ? null : pipeline.id,
                )
              }
            />
          </div>
        </PolicyWell>
      </TitleSection>
      <TitleSection
        id={`pipeline-settings-query`}
        title={t`Settings: Transformation`}
        inPanelView
        hasScreenTitle
        collapsible
      >
        <Editor
          value={pipeline?.settings?.mappingSettings?.transformation?.query}
          onValueChange={query =>
            setQuery(pipeline?.settings?.mappingSettings?.transformation?.query)
          }
          highlight={code => (_.isEmpty(code) ? code : highlight(code, sql))}
          padding={10}
          disabled={isLoading}
          style={{
            position: 'relative',
            overflowY: 'auto',
            fontFamily: 'monospace',
            fontSize: 13,
            paddingBottom: '3rem',
          }}
          className={classNames('bg-app-panel-dark')}
        />
      </TitleSection>
      <TitleSection
        id={`pipeline-settings-column-names`}
        title={t`Settings: Fields`}
        inPanelView
        hasScreenTitle
        collapsible
      >
        <ul className={classNames('p-1', 'space-y-2')}>
          {pipeline.settings?.mappingSettings?.mapping?.fields.map(f => {
            return (
              <li
                key={f.index}
                className={classNames(
                  'flex',
                  'px-1 py-2',
                  'border-menu border-b',
                  'select-text',
                  'text-menu-text text-xs',
                )}
              >
                <Tag leftTag fullWidth>
                  {f.columnName}
                </Tag>
                <div className="bg-app-panel/50 flex select-text flex-col items-center justify-center px-3 py-1 text-xs">
                  <Icon.ArrowRight
                    className={classNames('h-4 w-4 fill-current opacity-50')}
                  />
                </div>
                <Tag rightTag fullWidth>
                  {f.name}
                  {f.measureValue && (
                    <div className={classNames('select-text opacity-70')}>
                      {f.measureValue}
                    </div>
                  )}
                </Tag>
              </li>
            );
          })}
        </ul>
      </TitleSection>
      {/* SFTP */}
      <TitleSection
        id={`pipeline-setting-group-sftp`}
        title={t`Connector: SFTP (Pull)`}
        inPanelView
        className="z-400 top-12 xl:top-20"
        hasScreenTitle
        collapsible
        hasAction={
          <>
            <Button
              label={t`Add`}
              className={classNames('rounded ltr:ml-4 rtl:mr-4')}
              buttonSize="xs"
              buttonType="primary"
              hasIconAfter={
                <Icon.CirclePlus className={`h-5 w-5 fill-current`} />
              }
              onPress={() => addSftpConnector()}
            />
          </>
        }
      >
        <PolicyWell
          fullHeight
          className={classNames('mb-2 flex-1')}
          classNameChildren={classNames('space-y-2')}
        >
          {_.isEmpty(sftpConnectors) && <InboxZero message={t`No Connector`} />}
          {_.map(sftpConnectors, c => (
            <ImportPipelineSFTPConnector
              key={c.id}
              pipelineId={pipeline.id}
              connector={c}
            />
          ))}
        </PolicyWell>
      </TitleSection>
      {/* DATASET CONNECTOR */}
      <TitleSection
        id={`pipeline-setting-group-dataset`}
        title={t`Connector: Dataset`}
        inPanelView
        className="z-400 top-12 xl:top-20"
        hasScreenTitle
        collapsible
        hasAction={
          <>
            <Button
              label={t`Add`}
              className={classNames('rounded ltr:ml-4 rtl:mr-4')}
              buttonSize="xs"
              buttonType="primary"
              hasIconAfter={
                <Icon.CirclePlus className={`h-5 w-5 fill-current`} />
              }
              onPress={() => addDatasetConnector()}
            />
          </>
        }
      >
        <PolicyWell
          fullHeight
          className={classNames('mb-2 flex-1')}
          classNameChildren={classNames('space-y-2')}
        >
          {!hasDatasetConnector && <InboxZero message={t`No Connector`} />}
          {_.map(datasetConnectors, c => {
            const connectorDetails = _.find(
              pipeline.connectorsState,
              cs => cs.id === c.id,
            );
            return (
              <ImportPipelineDatasetConnector
                key={c.id}
                pipelineId={pipeline.id}
                connector={c}
                details={connectorDetails?.datasetChanged}
              />
            );
          })}
        </PolicyWell>
      </TitleSection>
      {/* Email Connector */}
      <TitleSection
        id={`pipeline-setting-group-email`}
        title={t`Connector: Email (Push)`}
        inPanelView
        className={classNames('z-400 top-12 xl:top-20')}
        hasScreenTitle
        collapsible
        hasAction={
          <>
            {!hasEmailConnector ? (
              <Button
                label={t`Add`}
                className={classNames('rounded ltr:ml-4 rtl:mr-4')}
                buttonSize="xs"
                buttonType="primary"
                hasIconAfter={
                  <Icon.CirclePlus className={`h-5 w-5 fill-current`} />
                }
                onPress={() =>
                  addConnector(ImportPipelineConnectorType.EMAIL_PUSH)
                }
              />
            ) : (
              <Icon.CircleOk className={classNames('h-6 w-6 fill-current')} />
            )}
          </>
        }
      >
        <PolicyWell
          fullHeight
          className={classNames('mb-2 flex-1')}
          classNameChildren={classNames('space-y-4')}
        >
          {_.isEmpty(emailConnectors) && (
            <InboxZero message={t`No Connector`} />
          )}
          {_.map(emailConnectors, c => {
            const connectorState = _.find(
              pipeline.connectorsState,
              cs => cs.id === c.id,
            );

            return (
              <Fragment key={c.id}>
                <KeyValueItem
                  className={styleApiText}
                  name={t`Email Endpoint`}
                  value={connectorState?.emailPush?.address}
                  isDisabled={true}
                  isCopyable
                />
                {/* <CopyToClipboardButton value={connectorState.apiPush?.apiUrl} />
                <KeyValueItem
                  className={styleApiText}
                  name={t`API Key`}
                  value={connectorState?.apiPush?.apiAccessKey}
                  isDisabled={true}
                  isCopyable
                /> */}
              </Fragment>
            );
          })}

          {hasEmailConnector && (
            <ActionBar className={classNames('mt-4')}>
              <Button
                label={t`Delete Email`}
                className={classNames('rounded')}
                buttonSize="xs"
                buttonType="delete"
                full
                hasIconAfter={
                  <Icon.CircleX
                    className={classNames('h-5 w-5 fill-current')}
                  />
                }
                onPress={() =>
                  removeAllConnectorsByType(
                    ImportPipelineConnectorType.EMAIL_PUSH,
                  )
                }
              />
            </ActionBar>
          )}
        </PolicyWell>
      </TitleSection>
      {/* API Connector */}
      <TitleSection
        id={`pipeline-setting-group-api`}
        title={t`Connector: API (Push)`}
        inPanelView
        className={classNames('z-400 top-12 xl:top-20')}
        hasScreenTitle
        collapsible
        hasAction={
          <>
            {!hasApiConnector ? (
              <Button
                label={t`Add`}
                className={classNames('rounded ltr:ml-4 rtl:mr-4')}
                buttonSize="xs"
                buttonType="primary"
                hasIconAfter={
                  <Icon.CirclePlus className={`h-5 w-5 fill-current`} />
                }
                onPress={() =>
                  addConnector(ImportPipelineConnectorType.API_PUSH)
                }
              />
            ) : (
              <Icon.CircleOk className={classNames('h-6 w-6 fill-current')} />
            )}
          </>
        }
      >
        <PolicyWell
          fullHeight
          className={classNames('mb-2 flex-1')}
          classNameChildren={classNames('space-y-4')}
        >
          {_.isEmpty(apiConnectors) && <InboxZero message={t`No Connector`} />}
          {_.map(apiConnectors, c => {
            const connectorState = _.find(
              pipeline.connectorsState,
              cs => cs.id === c.id,
            );
            return (
              <Fragment key={c.id}>
                <KeyValueItem
                  className={styleApiText}
                  name={t`API Endpoint`}
                  value={connectorState.apiPush?.apiUrl}
                  isDisabled={true}
                  isCopyable
                />
                <CopyToClipboardButton value={connectorState.apiPush?.apiUrl} />
                <KeyValueItem
                  className={styleApiText}
                  name={t`API Key`}
                  value={connectorState?.apiPush?.apiAccessKey}
                  isDisabled={true}
                  isCopyable
                />

                <Callout type="suggestion">
                  <div>
                    {t`Curl command to upload a file to the API endpoint`}
                  </div>

                  <div className={classNames(styleApiText)}>
                    <KeyValueItem
                      className={styleApiText}
                      name={t`Curl Test Example`}
                      value={`curl --upload-file file.gz -X POST ${connectorState.apiPush?.apiUrl} -v -H "Authorization: ${connectorState?.apiPush?.apiAccessKey}"`}
                      isDisabled={true}
                      isCopyable
                    />
                  </div>
                </Callout>
              </Fragment>
            );
          })}

          {hasApiConnector && (
            <ActionBar className={classNames('mt-4')}>
              <Button
                label={t`Revoke Key (Delete)`}
                className={classNames('rounded')}
                buttonSize="xs"
                buttonType="delete"
                full
                hasIconAfter={
                  <Icon.CircleX
                    className={classNames('h-5 w-5 fill-current')}
                  />
                }
                onPress={() =>
                  removeAllConnectorsByType(
                    ImportPipelineConnectorType.API_PUSH,
                  )
                }
              />
            </ActionBar>
          )}
        </PolicyWell>
      </TitleSection>
      {/* S3 Connector */}
      <TitleSection
        id={`pipeline-setting-group-s3`}
        title={t`Connector: S3 (Push)`}
        inPanelView
        className={classNames('z-400 top-12 xl:top-20')}
        hasScreenTitle
        collapsible
        hasAction={
          <>
            {!hasS3Connector ? (
              <Button
                label={t`Add`}
                className={classNames('rounded ltr:ml-4 rtl:mr-4')}
                buttonSize="xs"
                buttonType="primary"
                hasIconAfter={
                  <Icon.CirclePlus
                    className={classNames('h-5 w-5 fill-current')}
                  />
                }
                onPress={() =>
                  addConnector(ImportPipelineConnectorType.S3_PUSH)
                }
              />
            ) : (
              <Icon.CircleOk className={classNames('h-6 w-6 fill-current')} />
            )}
          </>
        }
      >
        <PolicyWell
          fullHeight
          className={classNames('mb-2 flex-1')}
          classNameChildren={classNames('space-y-4')}
        >
          {_.isEmpty(s3Connectors) && <InboxZero message={t`No Connector`} />}
          {_.map(s3Connectors, c => {
            const connectorState = _.find(
              pipeline.connectorsState,
              cs => cs.id === c.id,
            );
            return (
              <Fragment key={c.id}>
                <KeyValueItem
                  className={styleApiText}
                  name={t`AWS_ACCESS_KEY_ID`}
                  value={connectorState?.s3Push?.awsCredentials?.accessKeyId}
                  isDisabled={true}
                  isCopyableValueOnly
                />
                <KeyValueItem
                  className={styleApiText}
                  name={t`AWS_SECRET_ACCESS_KEY`}
                  value={
                    connectorState?.s3Push?.awsCredentials?.secretAccessKey
                  }
                  isDisabled={true}
                  isCopyableValueOnly
                />
                <KeyValueItem
                  className={styleApiText}
                  name={t`Pipeline ID`}
                  value={pipeline.id}
                  isDisabled={true}
                  isCopyableValueOnly
                />

                <Callout type="suggestion">
                  <div>
                    {t`S3 Api command to upload a file to the S3 bucket`}
                  </div>
                  <div
                    className={classNames(
                      styleApiText,
                      'mt-4',
                      'text-sm',
                      // 'space-y-2',
                    )}
                  >
                    <KeyValueItem
                      className={styleApiText}
                      name={t`Bash Example Test`}
                      value={`export AWS_ACCESS_KEY_ID=${connectorState?.s3Push?.awsCredentials?.accessKeyId}
                      \nexport AWS_SECRET_ACCESS_KEY=${connectorState?.s3Push?.awsCredentials?.secretAccessKey}
                      \naws s3 cp file.csv ${connectorState?.s3Push?.s3Url}
                      `}
                      isDisabled={true}
                      isCopyable
                    />
                  </div>
                </Callout>
              </Fragment>
            );
          })}

          {hasS3Connector && (
            <Button
              label={t`Delete S3 Connection`}
              className={classNames('rounded')}
              buttonSize="xs"
              buttonType="delete"
              full
              hasIconAfter={
                <Icon.CircleX className={classNames('h-5 w-5 fill-current')} />
              }
              onPress={() =>
                removeAllConnectorsByType(ImportPipelineConnectorType.S3_PUSH)
              }
            />
          )}
        </PolicyWell>
      </TitleSection>
      {/* Snowflake S3 Connector */}
      <TitleSection
        id={`pipeline-setting-group-s3-snowflake`}
        title={t`Connector: Snowflake S3 (Push)`}
        inPanelView
        className={classNames('z-400 top-12 xl:top-20')}
        hasScreenTitle
        collapsible
        hasAction={
          <>
            {!hasS3Connector ? (
              <Button
                label={t`Add`}
                className={classNames('rounded ltr:ml-4 rtl:mr-4')}
                buttonSize="xs"
                buttonType="primary"
                hasIconAfter={
                  <Icon.CirclePlus
                    className={classNames('h-5 w-5 fill-current')}
                  />
                }
                onPress={() =>
                  addConnector(ImportPipelineConnectorType.S3_PUSH)
                }
              />
            ) : (
              <Icon.CircleOk className={classNames('h-6 w-6 fill-current')} />
            )}
          </>
        }
      >
        <PolicyWell
          fullHeight
          className={classNames('mb-2 flex-1')}
          classNameChildren={classNames('space-y-4')}
        >
          {_.isEmpty(s3Connectors) && <InboxZero message={t`No Connector`} />}
          {_.map(s3Connectors, c => {
            const connectorState = _.find(
              pipeline.connectorsState,
              cs => cs.id === c.id,
            );
            return (
              <Fragment key={c.id}>
                <Callout type="suggestion">
                  <div>{t`Snowflake command to export the data to WareBee`}</div>
                  <div
                    className={classNames(
                      styleApiText,
                      'mt-4',
                      'text-sm',
                      // 'space-y-2',
                    )}
                  >
                    <KeyValueItem
                      className={styleApiText}
                      name={t`-- SQL Snowflake Export with S3 WareBee endpoint`}
                      value={`                    
-- Create a storage integration for S3, enabling access to an S3 bucket
CREATE STORAGE INTEGRATION WAREBEE_S3_STORAGE
  TYPE = EXTERNAL_STAGE
  STORAGE_PROVIDER = S3
  ENABLED = TRUE
  STORAGE_ALLOWED_LOCATIONS = ('${connectorState?.s3Push?.s3Url}');

-- Switch to the intended database and schema
USE DATABASE test;
USE SCHEMA public;

-- Setup file format for CSV data export
CREATE OR REPLACE FILE FORMAT warebee_data_export
  TYPE = 'CSV'
  -- Fields enclosed by double quotes
  FIELD_OPTIONALLY_ENCLOSED_BY = '"'
  COMPRESSION = 'NONE'
  ENCODING = UTF8
  -- Do not skip any header row
  SKIP_HEADER = 0
  -- Use comma as field delimiter                
  FIELD_DELIMITER = ','
   -- Treat 'NULL' and 'null' as null values         
  NULL_IF = ('NULL', 'null')
  -- Treat empty fields as null values
  EMPTY_FIELD_AS_NULL = TRUE;       

-- Setup WareBee Integration S3 stage
CREATE OR REPLACE STAGE warebee_s3_stage
  -- WareBee Extract S3 bucket URL
  URL='${connectorState?.s3Push?.s3Url}' 
  -- WareBee AWS S3 credentials
  CREDENTIALS=(AWS_KEY_ID='${connectorState?.s3Push?.awsCredentials?.accessKeyId}' AWS_SECRET_KEY='${connectorState?.s3Push?.awsCredentials?.secretAccessKey}')
  -- Use the previously defined file format
  FILE_FORMAT = (FORMAT_NAME = warebee_data_export_format);

-- Generate the file structure, and copy the data to the S3 bucket
-- 1. Replace WAREHOUSENAME with the name of the warehouse
-- 2. Replace DATATYPE with the type of data being exported
-- 3. Replace PUBLIC.TABLE_ORDERS with the source table/query

DECLARE
  file_path STRING;
BEGIN
  file_path := '@warebee_s3_stage/WAREBEE-WAREHOUSENAME-DATATYPE-' || TO_VARCHAR(CONVERT_TIMEZONE('UTC', CURRENT_TIMESTAMP()), 'YYYY-MM-DD-HH24MISS') || '.csv';
  

  EXECUTE IMMEDIATE '
    COPY INTO ' || :file_path || '
    -- Specify the source table/query
    FROM (
      SELECT * FROM PUBLIC.TABLE_ORDERS 
    )
    -- WareBee defined file format
    FILE_FORMAT = (FORMAT_NAME = warebee_data_export_format) 
    -- Overwrite existing files
    OVERWRITE = TRUE
    -- Export as a single file
    SINGLE = TRUE;      
END;

`}
                      isDisabled={true}
                      isCopyable
                    />
                  </div>
                </Callout>
              </Fragment>
            );
          })}

          {hasS3Connector && (
            <Button
              label={t`Delete Snowflake Connection (S3)`}
              className={classNames('rounded')}
              buttonSize="xs"
              buttonType="delete"
              full
              hasIconAfter={
                <Icon.CircleX className={classNames('h-5 w-5 fill-current')} />
              }
              onPress={() =>
                removeAllConnectorsByType(ImportPipelineConnectorType.S3_PUSH)
              }
            />
          )}
        </PolicyWell>
      </TitleSection>
      {/* 
      <TitleSection
        id={`embeding-setting`}
        title={t`Embeding`}
        inPanelView
        className={classNames('z-400 top-12 xl:top-20')}
        hasScreenTitle
        collapsible
        hasPadding
        isClosed
      >
 
        https://app.warebee.com/wh/i/{{warehouse id}}/feed/{{feedID}}?role={{WareBeeRole}}&isEmbedded=true&theme=Proglove&token={{access token}}

      </TitleSection> */}

      <TitleSection
        id={`pipeline-setting-delete`}
        title={t`Delete Pipeline`}
        inPanelView
        className={classNames('z-400 top-12 xl:top-20')}
        hasScreenTitle
        collapsible
        hasPadding
        isClosed
      >
        <Callout type="warning">
          <div className={classNames('mb-2')}>{t`Warning!`}</div>
          <div
            className={classNames('mb-4')}
          >{t`Click Delete Pipeline to delete this pipeline. This action cannot be undone.`}</div>
          <RemovePipeline
            pipeline={pipeline}
            isNew={_.isNil(pipeline)}
            onChange={() => null}
          />
        </Callout>
      </TitleSection>
    </Container>
  );
};
export default ImportPipelineViewPanel;
