import {
  LayoutLocationDataFragment,
  LayoutViewFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { atom, selectorFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { AsyncLoadStatus } from '../../common/types';
import {
  LayoutDataState,
  LayoutMenuItemId,
  LocationColorMode,
} from './layout.types';

const getKey = (postfix: string) => `warebee-layout-${postfix}`;

export const layoutDocumentId = atom<string>({
  key: getKey('document-id'),
  default: null,
});

export const layoutDocument = atom<LayoutViewFragment>({
  key: getKey('document'),
  default: null,
});

export const layoutDocumentLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('document-load-state'),
  default: AsyncLoadStatus.None,
});

export const layoutDocumentSaveStatus = atom<AsyncLoadStatus>({
  key: getKey('document-save-state'),
  default: AsyncLoadStatus.None,
});

export const layoutItemsData = atom<LayoutLocationDataFragment[]>({
  key: getKey('items-data'),
  default: null,
});

export const layoutItemsDataStatus = atom<AsyncLoadStatus>({
  key: getKey('items-data-status'),
  default: AsyncLoadStatus.None,
});

export const layoutItemsDataState = atom<LayoutDataState>({
  key: getKey('items-data-state'),
  default: { totalCount: 0, searchValues: {} },
});

const layoutSelectedMenuItemIdAll = atom<Record<string, LayoutMenuItemId>>({
  key: getKey('selected-menu-item-id-all'),
  default: {},
  effects: [
    recoilPersist({
      key: getKey('selected-menu-item-id-all'),
      storage: localStorage,
    }).persistAtom,
  ],
});

export const layoutSelectedMenuItemId = selectorFamily<
  LayoutMenuItemId,
  string
>({
  key: getKey('selected-menu-item-id'),
  get:
    (layoutId: string) =>
    ({ get }) => {
      return (
        get(layoutSelectedMenuItemIdAll)?.[layoutId] || 'layout-menu-start'
      );
    },

  set:
    (layoutId: string) =>
    ({ get, set }, value: LayoutMenuItemId) => {
      set(layoutSelectedMenuItemIdAll, {
        ...get(layoutSelectedMenuItemIdAll),
        [layoutId]: value,
      });
    },
});

export const layoutLocationColorMode = atom<LocationColorMode>({
  key: getKey('location-color-mode'),
  default: 'sortOrder',
});
