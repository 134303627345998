import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { cctvShow } from '../../cctv/cctv.state';
import CCTVPanel from '../../cctv/CCTVPanel';
import InboxZero from '../../components/InboxZero';
import { ContainerScroll } from '../../components/layout/ContainerScroll';
import LoadingIndicator from '../../components/LoadingIndicator';
import AislePropertiesPanel from '../../components/sharedPanels/AislePropertiesPanel';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import {
  viewerSelectedAisle,
  viewerSelectedBayDetails,
} from '../../layout/viewer/store/viewer.state';
import SimulationBayInfoPanel from '../panels/SimulationBayInfoPanel';
import SimulationInfoPanelAssignment from '../panels/SimulationInfoPanelAssignment';
import SimulationInfoPanelItemSet from '../panels/SimulationInfoPanelItemSet';
import SimulationInfoPanelLayout from '../panels/SimulationInfoPanelLayout';
import SimulationInfoPanelOrders from '../panels/SimulationInfoPanelOrders';
import { simulationWizardSelectedStepId } from '../store/simulation.wizard.state';

const SidebarSimulationCommonInfo: React.FC = () => {
  const { t } = useTranslation('simulation');

  const showCCTV = useRecoilValue(cctvShow);
  const stepId = useRecoilValue(simulationWizardSelectedStepId);
  const bay = useRecoilValue(viewerSelectedBayDetails);
  const aisle = useRecoilValue(viewerSelectedAisle);

  const getLoadingMessage = () => {
    if (bay) {
      return t`Loading bay locations`;
    }

    switch (stepId) {
      case 'import-layout':
        return t`Loading Layout data`;
      case 'import-assignments':
        return t`Loading Assignment data`;
      case 'import-items':
        return t`Loading Items data`;
      case 'import-orders':
        return t`Loading Orders data`;
    }

    return t`Loading`;
  };

  const getContent = () => {
    if (bay) return <SimulationBayInfoPanel />;
    if (aisle) return <AislePropertiesPanel />;

    if (stepId === 'import-layout') {
      return <SimulationInfoPanelLayout />;
    }
    if (stepId === 'import-assignments') {
      return <SimulationInfoPanelAssignment />;
    }
    if (stepId === 'import-orders') {
      return <SimulationInfoPanelOrders />;
    }
    if (stepId === 'import-items') {
      return <SimulationInfoPanelItemSet />;
    }
    if (stepId === 'analyse' && showCCTV) {
      return <CCTVPanel />;
    }
    return (
      <InboxZero
        selfCenter
        message={t`Click on Location, Bay, Aisle on the map to get more info`}
      />
    );
  };

  return (
    <ContainerScroll className="flex flex-col">
      <Suspense
        fallback={<LoadingIndicator selfCenter message={getLoadingMessage()} />}
      >
        {getContent()}
      </Suspense>
    </ContainerScroll>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-info-common',
    title: t`Info`,
    loadingMessage: t`Loading`,
    size: 'sm',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SidebarSimulationCommonInfo />
    </SidebarContainer>
  );
};
