import {
  AssignmentDashboardItemFragment,
  DatasetObjectFragment,
  ItemSetDashboardItemFragment,
  LayoutDashboardItemFragment,
  LayoutImportDashboardItemFragment,
  OrderSetDashboardItemFragment,
  SimulationDashboardItemFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { AsyncLoadStatus } from '../../common/types';
import { getDefaultDashboardState } from './dashboard.default';
import { DashboardGroupState } from './dashboard.types';

const { persistAtom } = recoilPersist({
  key: 'warebee-dashboard',
  storage: localStorage,
});

const getKey = (postfix: string) => `warebee-dashboard-${postfix}`;

export const dashboardSimulationState = atom<DashboardGroupState>({
  key: getKey('sim-state'),
  default: getDefaultDashboardState(),
  effects: [persistAtom],
});

export const dashboardSimulationData = atom<SimulationDashboardItemFragment[]>({
  key: getKey('sim-data'),
  default: null,
});

export const dashboardSimulationLoadState = atom<AsyncLoadStatus>({
  key: getKey('sim-load-status'),
  default: AsyncLoadStatus.None,
});

export const showHelperCardState = atom({
  key: 'showHelperCardState',
  default: false,
  effects: [persistAtom],
});

export const showHeaderAddButtonState = atom({
  key: 'showHeaderAddButton',
  default: false,
  effects: [persistAtom],
});

export const showAutoUpdateDateTimeState = atom({
  key: 'showAutoUpdateDateTime',
  default: false,
  effects: [persistAtom],
});

// Layout import

const { persistAtom: persistDashboardLayoutImportState } = recoilPersist({
  key: getKey('layout-import-state'),
  storage: localStorage,
});

export const dashboardLayoutImportState = atom<DashboardGroupState>({
  key: getKey('layout-import-state'),
  default: getDefaultDashboardState(),
  effects: [persistDashboardLayoutImportState],
});

export const dashboardLayoutImportData = atom<
  LayoutImportDashboardItemFragment[]
>({
  key: getKey('layout-import-data'),
  default: null,
});

export const dashboardLayoutImportLoadState = atom<AsyncLoadStatus>({
  key: getKey('layout-import-load-status'),
  default: AsyncLoadStatus.None,
});

// Layout
const { persistAtom: persistDashboardLayoutState } = recoilPersist({
  key: getKey('layout-state'),
  storage: localStorage,
});

export const dashboardLayoutState = atom<DashboardGroupState>({
  key: getKey('layout-state'),
  default: getDefaultDashboardState(),
  effects: [persistDashboardLayoutState],
});

export const dashboardLayoutData = atom<
  (LayoutDashboardItemFragment | LayoutImportDashboardItemFragment)[]
>({
  key: getKey('layout-data'),
  default: null,
});

export const dashboardLayoutLoadState = atom<AsyncLoadStatus>({
  key: getKey('layout-load-status'),
  default: AsyncLoadStatus.None,
});

//Assignment
const { persistAtom: persistDashboardAssignmentState } = recoilPersist({
  key: getKey('assignment-state'),
  storage: localStorage,
});
export const dashboardAssignmentState = atom<DashboardGroupState>({
  key: getKey('assignment-state'),
  default: getDefaultDashboardState(),
  effects: [persistDashboardAssignmentState],
});

export const dashboardAssignmentData = atom<AssignmentDashboardItemFragment[]>({
  key: getKey('assignment-data'),
  default: null,
});

export const dashboardAssignmentLoadState = atom<AsyncLoadStatus>({
  key: getKey('assignment-load-status'),
  default: AsyncLoadStatus.None,
});

//Order set
const { persistAtom: persistDashboardOrderSetState } = recoilPersist({
  key: getKey('order-set-state'),
  storage: localStorage,
});
export const dashboardOrderSetState = atom<DashboardGroupState>({
  key: getKey('order-set-state'),
  default: getDefaultDashboardState(),
  effects: [persistDashboardOrderSetState],
});

export const dashboardOrderSetData = atom<OrderSetDashboardItemFragment[]>({
  key: getKey('order-set-data'),
  default: null,
});

export const dashboardOrderSetLoadState = atom<AsyncLoadStatus>({
  key: getKey('order-set-load-status'),
  default: AsyncLoadStatus.None,
});

//Item set
const { persistAtom: persistDashboardItemSetState } = recoilPersist({
  key: getKey('item-set-state'),
  storage: localStorage,
});
export const dashboardItemSetState = atom<DashboardGroupState>({
  key: getKey('item-set-state'),
  default: getDefaultDashboardState(),
  effects: [persistDashboardItemSetState],
});

export const dashboardItemSetData = atom<ItemSetDashboardItemFragment[]>({
  //ItemSetDashboardItemFragment
  key: getKey('item-set-data'),
  default: null,
});

export const dashboardItemSetLoadState = atom<AsyncLoadStatus>({
  key: getKey('item-set-load-status'),
  default: AsyncLoadStatus.None,
});

//Dataset objects
const { persistAtom: persistDashboardDatasetObjectState } = recoilPersist({
  key: getKey('dataset-object-state'),
  storage: localStorage,
});
export const dashboardDatasetObjectState = atom<DashboardGroupState>({
  key: getKey('dataset-object-state'),
  default: getDefaultDashboardState(),
  effects: [persistDashboardDatasetObjectState],
});

export const dashboardDatasetObjectData = atom<DatasetObjectFragment[]>({
  key: getKey('dataset-object-data'),
  default: null,
});

export const dashboardDatasetObjectLoadState = atom<AsyncLoadStatus>({
  key: getKey('dataset-object-load-status'),
  default: AsyncLoadStatus.None,
});
