import { useFindSimulationItemsTotalByFilterQuery } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formatInteger } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import { cn } from '../../common/utils';
import PolicyFilterGroupList from '../../components/policies/PolicyFilterGroupList';
import { PolicyStatContainer } from '../../components/policies/PolicyStatContainer';
import RuleContainer, {
  RuleContainerProps,
} from '../../components/policies/RuleContainer';
import RuleSectionContainer from '../../components/policies/RuleSectionContainer';
import { Stat } from '../../components/stats/Stat';
import { getPolicyMatchInput } from '../../policyFilters/policyFilter.helper';
import { sidebarStateByType } from '../../store/sidebar.state';
import {
  deallocationPolicyRule,
  deallocationPolicySelectedIdentity,
} from '../store/allocationPolicy/allocationPolicy.state';
import { getProductFilterConfigCommon } from '../store/assignmentPolicy.default';
import { simulationCurrentId } from '../store/simulation.state';

export type DeallocatePolicyRuleProps = {
  ruleId: string;
  index?: number;
  className?: string;
  isDefault?: boolean;
  isRemovable?: boolean;
  isNew?: boolean;
  hasIssue?: boolean;
  isDisabled?: boolean;

  canDelete?: boolean;
  onDeleteClick?: () => void;
  canRename?: boolean;
};

const DeallocatePolicyRule: React.FC<DeallocatePolicyRuleProps> = props => {
  const { t } = useTranslation('simulation');
  const simulationId = useRecoilValue(simulationCurrentId);
  const formatter = useFormatter();
  const { ruleId } = props;
  const [rule, updateRule] = useRecoilState(deallocationPolicyRule(ruleId));
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    deallocationPolicySelectedIdentity,
  );

  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-deallocate-editor'),
  );

  const {
    data: itemsTotalByFilter,
    loading: isLoading,
    error: itemsTotalByFilterError,
  } = useFindSimulationItemsTotalByFilterQuery({
    variables: {
      input: {
        simulationId,
        includeMatching: getPolicyMatchInput(rule.itemsMatch),
      },
    },
  });

  const matchedItemsCount =
    itemsTotalByFilter?.findSimulationItemsByFilter?.totalCount;
  function renameRule(newTitle: string) {
    updateRule({
      ...rule,
      title: newTitle,
    });
  }

  function selectProductFilterIntersection(
    filterIntersectionId: string,
    shouldSelect: boolean,
  ) {
    if (!shouldSelect) {
      // deselect intersection only
      setSelectedIdentity(null);
    } else {
      // select intersection and rule details
      setSelectedIdentity({
        ruleId: rule.id,
        filterId: filterIntersectionId,
      });
    }
    setPolicyEditPanelState({
      ...policyEditPanelState,
      isCollapsed: !shouldSelect,
      isHidden: !shouldSelect,
    });
  }

  function addProductIntersection() {
    const id = nanoid();
    updateRule({
      ...rule,
      itemsMatch: {
        anyOf: [...(rule.itemsMatch?.anyOf ?? []), { id, allOf: [] }],
      },
    });
    selectProductFilterIntersection(id, true);
  }

  const removeField = (fieldId: string, fieldType: string) => {
    updateRule({
      ...rule,
      itemsMatch: {
        anyOf: _.map(rule.itemsMatch?.anyOf, match => {
          if (match.id !== fieldId) return match;
          return {
            id: match.id,
            allOf: match.allOf.filter(filter => filter.type !== fieldType),
          };
        }),
      },
    });
  };

  const removeIntersection = (groupId: string) => {
    updateRule({
      ...rule,
      itemsMatch: {
        anyOf: _.filter(rule.itemsMatch?.anyOf, fg => fg.id !== groupId),
      },
    });
  };

  const hasIssue = false;

  const isActive = selectedIdentity?.ruleId === ruleId;
  const containerParams: RuleContainerProps = {
    id: rule.id,
    title: rule.title,
    isCollapsible: true,
    isRemovable: props.canDelete,
    isActive,
    isNew: props.isNew,
    orderCounter: props.index,
    onDeleteClick: () => props.onDeleteClick && props.onDeleteClick(),

    canRename: props.canRename,
    onTitleChange: title => renameRule(title),
  };

  return (
    <RuleContainer
      {...containerParams}
      isDisabled={props.isDisabled}
      hasIssue={hasIssue}
      hasIcon
      hasColorMode={false}
      hasOrder
    >
      <RuleSectionContainer
        isSelected={isActive}
        namedColorKey={isActive ? rule.title : null}
        className={cn('p-1')}
      >
        <PolicyStatContainer>
          <Stat
            hasNoPadding
            className={cn('px-1 py-1 xl:px-2')}
            transparent
            title={t`Item(s) Filtered`}
            value={matchedItemsCount ? formatInteger(matchedItemsCount) : 0}
            inPanelMode
            // isActionable
            inFilterStat
            hasIssue={!_.isNil(itemsTotalByFilterError)}
            // toggleTable
            isCheckLoading={isLoading}
            isSelected={isActive}
            onClick={() => {
              setSelectedIdentity(
                isActive
                  ? null
                  : {
                      ruleId: rule.id,
                    },
              );
            }}
          />
        </PolicyStatContainer>

        <PolicyFilterGroupList
          isDisabled={props.isDisabled}
          filterSets={_.map(rule.itemsMatch?.anyOf, filterGroup => ({
            id: filterGroup.id,
            allOf: [...filterGroup.allOf],
          }))}
          config={getProductFilterConfigCommon(t, formatter)}
          selectedId={selectedIdentity?.filterId}
          onAddGroup={addProductIntersection}
          onDeleteField={removeField}
          onSelectGroup={selectProductFilterIntersection}
          onDeleteGroup={removeIntersection}
        />
      </RuleSectionContainer>
    </RuleContainer>
  );
};
export default DeallocatePolicyRule;
