import classNames from 'classnames';
import _ from 'lodash';
import { HTMLAttributes, useState } from 'react';
import { Button } from '../actions/Button';
import * as Icon from '../icons';
import Loader from '../loaders/Loader';

export type AlertProps = {
  children?: React.ReactNode;
  icon?: React.FC<HTMLAttributes<Element>>;
  className?: string;
  message?: string | React.ReactNode;
  messageHelper?: string;
  actionable?: boolean;
  action_title?: string;
  hasStatusAlert?: boolean;
  hasStatusError?: boolean;
  hasStatusWarning?: boolean;
  hasStatusInfo?: boolean;
  hasStatusOkay?: boolean;
  hasStatusDisabled?: boolean;
  hasStatusOverlap?: boolean;
  hasStatusHelper?: boolean;
  hasStatusConsent?: boolean;
  hasStatusLoading?: boolean;
  isAbsoluteBottom?: boolean;
  fullWidth?: boolean;
  horizontal?: boolean;
  onActionButtonClick?: () => void;
};

export const Alert = ({
  children,
  className,
  icon,
  message,
  messageHelper,
  actionable,
  action_title,
  hasStatusAlert,
  hasStatusError,
  hasStatusWarning,
  hasStatusInfo,
  hasStatusOkay,
  hasStatusDisabled,
  hasStatusHelper,
  hasStatusConsent,
  hasStatusOverlap,
  hasStatusLoading,
  isAbsoluteBottom,
  fullWidth,
  horizontal,
  onActionButtonClick,
}: AlertProps) => {
  const [id] = useState<string>(_.uniqueId());
  const IconMenu = icon;

  return (
    <div
      data-component="Alert"
      data-label={`alert-${message || ''}`}
      aria-label={`alert-${message || ''}`}
      id={id}
      className={classNames(
        'flex items-center z-alert rounded-sm',
        'text-sm xl:text-base',
        'p-2',
        { 'flex-col items-start': hasStatusAlert },
        hasStatusHelper
          ? 'text-menu-active bg-opacity-50 rounded bg-menu backdrop-filter backdrop-blur-lg backdrop-saturate-110'
          : hasStatusConsent
            ? 'rounded bg-white/50 backdrop-filter backdrop-blur-xl backdrop-saturate-120'
            : 'text-menu-text',

        { 'w-full': fullWidth },
        { 'bg-alerts-error': hasStatusError },
        { 'bg-alerts-alert': hasStatusAlert },
        { 'bg-alerts-warning text-alerts-alertText': hasStatusWarning },
        { 'bg-alerts-info': hasStatusInfo },
        { 'bg-alerts-okay': hasStatusOkay },
        { 'bg-alerts-disabled': hasStatusDisabled },
        { 'bg-alerts-overlap': hasStatusOverlap },
        { 'bg-app-panel-dark/60 text-menu-active': hasStatusLoading },
        {
          'bg-opacity-70 backdrop-filter backdrop-blur absolute bottom-0 left-0 right-0':
            isAbsoluteBottom || hasStatusLoading,
        },
        className,
      )}
    >
      <div
        className={classNames(
          'flex flex-1',
          children && !hasStatusConsent
            ? 'mb-2 items-center self-start'
            : 'items-center w-full',
        )}
      >
        {hasStatusLoading && (
          <Loader
            size={24}
            stroke={5}
            type={'loading-original'}
            className={classNames('ltr:mr-2 rtl:ml-2')}
          />
        )}

        {hasStatusOkay && (
          <Icon.CircleInfo
            className={classNames(
              'w-5 h-5 fill-current mx-1 ltr:mr-2 rtl:ml-2',
            )}
          />
        )}
        {hasStatusWarning && (
          <Icon.TriangleInfo
            className={classNames(
              'w-5 h-5 fill-current mx-1 ltr:mr-2 rtl:ml-2',
            )}
          />
        )}
        {!messageHelper && hasStatusAlert && (
          <Icon.TriangleInfo
            className={classNames(
              'w-5 h-5 fill-current mx-1 ltr:mr-2 rtl:ml-2',
            )}
          />
        )}

        {hasStatusError && (
          <Icon.TriangleInfo
            className={`mx-1 fill-current ${
              children ? 'w-4 h-4' : 'w-5 h-5 ltr:mr-2 rtl:ml-2'
            }`}
          />
        )}

        {!hasStatusLoading && hasStatusInfo && (
          <Icon.CircleInfo className="w-5 h-5 mx-2 fill-current" />
        )}

        {IconMenu && (
          <IconMenu
            className={classNames(
              'w-6 h-6 fill-current ltr:mr-4 rtl:ml-4 ltr:lg:ml-2 rtl:lg:mr-2',
              horizontal ? '' : 'mt-2 lg:mt-4',
            )}
          />
        )}

        <div
          className={classNames(
            'flex flex-col flex-1 select-text',
            { 'lg:mt-0 lg:pt-0': !horizontal },
            {
              'border-l border-menu-100/30 ltr:pl-3 rtl:pr-3 lg:ltr:pl-4 lg:rtl:pr-4 lg:pr-2 lg:border-t-0 lg:ltr:border-l rtl:border-r lg:border-menu-100/30':
                IconMenu,
            },
          )}
        >
          {messageHelper && (
            <div
              className={classNames(
                'shadow',
                'px-2 py-1',
                'text-sm font-bold',
                'text-start align-middle',
                'rounded',
                'bg-menu/50',
                'max-w-xs my-1 mb-2 xl:my-3',
                'w-full',
                {
                  'flex items-center': hasStatusAlert,
                },
              )}
            >
              {hasStatusAlert && (
                <Icon.TriangleInfo
                  className={classNames(
                    'w-4 h-4 xl:w-5 xl:h-5 fill-current mx-1 ltr:mr-2 rtl:ml-2',
                  )}
                />
              )}
              {messageHelper}
            </div>
          )}
          {message && <div className="flex-1">{message}</div>}
        </div>
      </div>

      {actionable && (
        <Button
          className={classNames('', {
            'border border-black shadow-lg hover:shadow-xl hover:bg-menu-active px-4 mx-4 min-w-12 rounded cursor-pointer text-menu-active-text text-base xl:text-xl':
              hasStatusConsent,
          })}
          buttonSize="xs"
          buttonType={`
          ${hasStatusConsent && 'primary'}
         ${hasStatusOkay ? 'primary' : 'primary'}
         ${hasStatusError && 'error'}
        `}
          onPress={() => {
            onActionButtonClick && onActionButtonClick();
          }}
        >
          {action_title}
        </Button>
      )}
      {children && (
        <div className={classNames('flex-1 w-full')}>{children}</div>
      )}
    </div>
  );
};
