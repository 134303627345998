import {
  Field,
  ID,
  InputAndObjectType,
  Int,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import {
  BayWidthConstraintSettings,
  LocationSizeConstraintSettings,
} from '../constraint.model';
import { OrderSetFilter } from '../dataset-filters.model';
import { SimulationItemFilterUnion } from '../policies';

@InputAndObjectType()
export class AllocationAssignmentPolicyConstraintSettings {
  @Field({ nullable: true })
  disabled?: boolean;

  @Field({ nullable: true })
  makeCompliant?: boolean;
}

@InputAndObjectType()
export class AllocationLocationSizeConstraintSettings extends LocationSizeConstraintSettings {
  @Field({ nullable: true })
  makeCompliant?: boolean;
}

@InputAndObjectType()
export class AllocationBayWidthConstraintSettings extends BayWidthConstraintSettings {
  @Field({ nullable: true })
  makeCompliant?: boolean;
}

@InputAndObjectType()
export class AllocationDeallocateRule {
  @Field(() => ID, { nullable: true })
  id?: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => SimulationItemFilterUnion, { nullable: true })
  itemsMatch?: SimulationItemFilterUnion;
}

@InputAndObjectType()
export class AllocationDeallocateSettings {
  @Field({ nullable: true })
  disabled?: boolean;

  @Field(() => [AllocationDeallocateRule], { nullable: true })
  rules?: AllocationDeallocateRule[];
}

export enum AllocationEnforcePickability {
  FIRST = 'FIRST',
  LAST = 'LAST',
  NEVER = 'NEVER',
}

registerEnumType(AllocationEnforcePickability, {
  name: 'AllocationEnforcePickability',
});

@InputAndObjectType()
export class AllocationPickabilitySettings {
  @Field(() => AllocationEnforcePickability, {
    nullable: true,
    defaultValue: AllocationEnforcePickability.FIRST,
  })
  enforcePickability?: AllocationEnforcePickability;
}

export enum AllocationItemPriority {
  ORDER_LINE_COUNT = 'ORDER_LINE_COUNT',
  ESTIMATED_REPLENISHMENT_COUNT = 'ESTIMATED_REPLENISHMENT_COUNT',
  REMAINING_REQUIRED_VOLUME = 'REMAINING_REQUIRED_VOLUME',
}

registerEnumType(AllocationItemPriority, {
  name: 'AllocationItemPriority',
});

export enum AllocationLocationPriority {
  DISTANCE_FROM_START = 'DISTANCE_FROM_START',
  LOCATION_ORDER = 'LOCATION_ORDER',
  LOCATION_VOLUME = 'LOCATION_VOLUME',
  LOCATION_LEVEL = 'LOCATION_LEVEL',
}

registerEnumType(AllocationLocationPriority, {
  name: 'AllocationLocationPriority',
});

export enum AllocationPriorityDirection {
  DEFAULT = 'DEFAULT',
  ASC = 'ASC',
  DESC = 'DESC',
}

registerEnumType(AllocationPriorityDirection, {
  name: 'AllocationPriorityDirection',
});

@InputAndObjectType()
export class AllocationItemPrioritySpec {
  @Field(() => AllocationItemPriority)
  priority: AllocationItemPriority;

  @Field(() => AllocationPriorityDirection, {
    nullable: true,
    defaultValue: AllocationPriorityDirection.DEFAULT,
  })
  direction?: AllocationPriorityDirection;
}

@InputAndObjectType()
export class AllocationLocationPrioritySpec {
  @Field(() => AllocationLocationPriority)
  priority: AllocationLocationPriority;

  @Field(() => AllocationPriorityDirection, {
    nullable: true,
    defaultValue: AllocationPriorityDirection.DEFAULT,
  })
  direction?: AllocationPriorityDirection;
}

@InputAndObjectType()
export class AllocationPrioritySettings {
  @Field(() => [AllocationItemPrioritySpec], {
    nullable: true,
    defaultValue: [{ priority: AllocationItemPriority.ORDER_LINE_COUNT }],
  })
  itemPriority?: AllocationItemPrioritySpec[];

  @Field(() => [AllocationLocationPrioritySpec], {
    nullable: true,
    defaultValue: [
      { priority: AllocationLocationPriority.DISTANCE_FROM_START },
    ],
  })
  locationPriority?: AllocationLocationPrioritySpec[];
}

export enum AllocationAllowLocationReuse {
  ALWAYS = 'ALWAYS',
  FOR_PICKABILITY = 'FOR_PICKABILITY',
  NEVER = 'NEVER',
}

registerEnumType(AllocationAllowLocationReuse, {
  name: 'AllocationAllowReplacement',
});

@InputAndObjectType()
export class AllocationReplacementSettings {
  @Field(() => AllocationAllowLocationReuse, {
    nullable: true,
    defaultValue: AllocationAllowLocationReuse.FOR_PICKABILITY,
  })
  allow?: AllocationAllowLocationReuse;
}

@InputAndObjectType()
export class AllocationMultiplexingSettings {
  @Field(() => AllocationAllowLocationReuse, {
    nullable: true,
    defaultValue: AllocationAllowLocationReuse.FOR_PICKABILITY,
  })
  allow?: AllocationAllowLocationReuse;
}

@InputAndObjectType()
export class AllocationLimitSettings {
  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true })
  maxPickableAssignments?: number;

  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true })
  maxAllocatedAssignments?: number;

  @Field({ nullable: true })
  maxPickableVolume?: number;

  @Field({ nullable: true })
  maxAllocatedVolume?: number;
}

@InputAndObjectType()
export class AllocationRoundSettings {
  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true, defaultValue: 1 })
  maxAssignmentsPerRound?: number;
}

export enum AllocationRequirementAggregationMethod {
  DAILY_AVERAGE = 'DAILY_AVERAGE',
}

registerEnumType(AllocationRequirementAggregationMethod, {
  name: 'AllocationRequirementAggregationMethod',
});

@InputAndObjectType()
export class AllocationRequirementAggregationSettings {
  @Field(() => AllocationRequirementAggregationMethod, { nullable: true })
  aggregation?: AllocationRequirementAggregationMethod;
}

@InputAndObjectType()
export class AllocationRule {
  @Field(() => ID)
  id: string;

  @Field({ nullable: true })
  title?: string;

  // filter
  @Field(() => [String], { nullable: true })
  uomTypes?: string[];

  @Field(() => SimulationItemFilterUnion, { nullable: true })
  itemsMatch?: SimulationItemFilterUnion;

  // settings
  /**
   * @TJS-type  integer
   */
  @Field(() => Int, {
    nullable: true,
    description:
      'allocation round limit: 0 - ensure pickability only, 1 and above - number of extra locations to minimize replenishment',
    deprecationReason: 'in favour of limitSettings',
  })
  roundLimit?: number;

  @Field(() => AllocationLimitSettings, { nullable: true })
  limitSettings?: AllocationLimitSettings;

  @Field(() => AllocationRoundSettings, { nullable: true })
  roundSettings?: AllocationRoundSettings;

  @Field(() => AllocationPickabilitySettings, { nullable: true })
  pickabilitySettings?: AllocationPickabilitySettings;

  @Field(() => AllocationPrioritySettings, { nullable: true })
  prioritySettings?: AllocationPrioritySettings;

  @Field(() => AllocationReplacementSettings, { nullable: true })
  replacementSettings?: AllocationReplacementSettings;

  @Field(() => AllocationMultiplexingSettings, { nullable: true })
  multiplexingSettings?: AllocationMultiplexingSettings;
}

@InputAndObjectType()
export class AllocationSettings {
  // constraints
  @Field(() => AllocationAssignmentPolicyConstraintSettings, { nullable: true })
  assignmentPolicyConstraint?: AllocationAssignmentPolicyConstraintSettings;

  @Field(() => AllocationLocationSizeConstraintSettings, { nullable: true })
  locationSizeConstraint?: AllocationLocationSizeConstraintSettings;

  @Field(() => AllocationBayWidthConstraintSettings, { nullable: true })
  bayWidthConstraint?: AllocationBayWidthConstraintSettings;

  // deallocate
  @Field(() => AllocationDeallocateSettings, { nullable: true })
  deallocateSettings?: AllocationDeallocateSettings;

  // input settings
  @Field(() => OrderSetFilter, { nullable: true })
  orderSetFilter?: OrderSetFilter;

  @Field(() => AllocationRequirementAggregationSettings, { nullable: true })
  requirementAggregationSettings?: AllocationRequirementAggregationSettings;

  // rules
  @Field(() => [AllocationRule], { nullable: true })
  rules?: AllocationRule[];

  // fallback filter
  @Field(() => [String], { nullable: true })
  fallbackUomTypes?: string[];

  @Field(() => SimulationItemFilterUnion, { nullable: true })
  fallbackItemsMatch?: SimulationItemFilterUnion;

  // fallback settings
  /**
   * @TJS-type  integer
   */
  @Field(() => Int, {
    nullable: true,
    description:
      'allocation round limit: 0 - ensure pickability only, 1 and above - number of extra locations to minimize replenishment',
    deprecationReason: 'in favour of limitSettings',
  })
  roundLimit?: number;

  @Field(() => AllocationLimitSettings, { nullable: true })
  limitSettings?: AllocationLimitSettings;

  @Field(() => AllocationRoundSettings, { nullable: true })
  roundSettings?: AllocationRoundSettings;

  @Field(() => AllocationPickabilitySettings, { nullable: true })
  pickabilitySettings?: AllocationPickabilitySettings;

  @Field(() => AllocationPrioritySettings, { nullable: true })
  prioritySettings?: AllocationPrioritySettings;

  @Field(() => AllocationReplacementSettings, { nullable: true })
  replacementSettings?: AllocationReplacementSettings;

  @Field(() => AllocationMultiplexingSettings, { nullable: true })
  multiplexingSettings?: AllocationMultiplexingSettings;
}
