import { BatchJobStatus } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AssignmentPolicy from '../../assignmentPolicy/AssignmentPolicy';
import useLoadSimulationItemSet from '../../assignmentPolicy/useLoadSimulationItemSet';
import { COMPLIANCE_PROGRESS_REFRESH_INTERVAL } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import {
  simulationCurrent,
  simulationEffectiveItemSet,
  simulationEffectiveItemSetLoadStatus,
} from '../store/simulation.state';
import ActionBarNextStep from './ActionBarNextStep';

const SimulationSidebarDatasetAssignmentPolicy: React.FC = () => {
  const { t } = useTranslation('simulation');
  const sim = useRecoilValue(simulationCurrent);
  const selectStep = useSelectSimulationWizardStep();
  const effectiveItemSet = useRecoilValue(simulationEffectiveItemSet);
  const effectiveItemSetLoadStatus = useRecoilValue(
    simulationEffectiveItemSetLoadStatus,
  );
  const [loadItemSetStatus, cancelLoadItemSetStatus] =
    useLoadSimulationItemSet();

  useEffect(() => {
    if (effectiveItemSetLoadStatus === AsyncLoadStatus.Error) return;
    let timeoutId;
    //  recalculate SimulationOrderLineSet data if it not calculated ye
    if (_.isNil(effectiveItemSet)) {
      loadItemSetStatus({
        simulationId: sim.id,
      });
    }
    if (
      effectiveItemSet?.status === BatchJobStatus.CREATED ||
      effectiveItemSet?.status === BatchJobStatus.CALCULATING
    ) {
      timeoutId = setTimeout(() => {
        loadItemSetStatus({
          simulationId: sim.id,
        });
      }, COMPLIANCE_PROGRESS_REFRESH_INTERVAL);
    }

    return () => {
      clearTimeout(timeoutId);
      cancelLoadItemSetStatus();
    };
  }, [effectiveItemSet]);

  const hasError = effectiveItemSetLoadStatus === AsyncLoadStatus.Error;
  const isLoading =
    effectiveItemSetLoadStatus !== AsyncLoadStatus.Ok ||
    effectiveItemSet?.status !== BatchJobStatus.READY;

  return (
    <>
      {hasError && <ErrorIndicator message={t`Error`} />}
      {isLoading && !hasError && (
        <LoadingIndicator message={t`Building Policy Filters...`} selfCenter />
      )}
      {!isLoading && !hasError && <AssignmentPolicy />}

      {/* ToDo: 1. Disable button if not feasible */}
      {/* ToDo: 2. Set Menu State with Warning, so even if the user exits the Policy, he can see the issue */}
      <ActionBarNextStep isDisabled={isLoading || hasError} />
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-policy-storage',
    title: t`Storage Policy + Compliance`,
    loadingMessage: t`Loading Storage Policy...`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SimulationSidebarDatasetAssignmentPolicy />
    </SidebarContainer>
  );
};
