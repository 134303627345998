import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import InboxZero from '../../components/InboxZero';
import { Button } from '../../components/actions/Button';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import PolicyRuleSectionTitle from '../../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../../components/policies/PolicyWell';
import ResourcePolicyAgent from '../../resourcePolicy/ResourcePolicyAgent';
import useUpdateFeedResourcePolicy from '../hooks/useUpdateFeedResourcePolicy';
import {
  actualityDocumentUpdateStatus,
  actualityPolicyAgentById,
  actualityResourcePolicy,
} from '../store/actuality.state';

const FeedResourcePolicyPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const [policy, setPolicy] = useRecoilState(actualityResourcePolicy);
  const updateStatus = useRecoilValue(actualityDocumentUpdateStatus);
  const update = useUpdateFeedResourcePolicy();

  const hasRules = _.size(policy?.agents) > 0;

  function removeAgent(id: string) {
    setPolicy({
      agents: _.filter(policy.agents, a => a.id !== id),
    });
  }
  return (
    <Container col hasOverflowY hasExtraPadding>
      <ScreenTitle
        subtitle={t`Resource Policy`}
        title={t`MHE Agents`}
        isSticky
        helpNavTo={'simulation/policies/policy-resource/policy-resource'}
        icon={Icon.PolicyPickingAgents}
      />

      <TitleSection
        title={
          <PolicyRuleSectionTitle
            title={t`Resources`}
            isFeasible={hasRules}
            counter={_.size(policy?.agents)}
          />
        }
        inPanelView
        className="top-12 xl:top-20 z-400"
        hasScreenTitle
        hasAction={
          <Button
            label={t`Update`}
            className={classNames('ltr:ml-4 rtl:mr-4 rounded')}
            buttonSize="xs"
            buttonType="primary"
            hasIconAfter={
              <Icon.CirclePlus className={classNames('w-5 h-5 fill-current')} />
            }
            isDisabled={updateStatus === AsyncLoadStatus.Loading}
            isLoading={updateStatus === AsyncLoadStatus.Loading}
            onPress={update}
          />
        }
      />
      <PolicyWell fullHeight>
        {!hasRules && <InboxZero center selfCenter message={t`No Resources`} />}

        {_.map(policy?.agents, (agent, index) => (
          <ResourcePolicyAgent
            key={`agent-${agent.id}`}
            agentId={agent.id}
            agentSelector={actualityPolicyAgentById}
            index={index + 1}
            isCheckLoading={false}
            isDisabled={false}
            canDelete={true}
            canRename={true}
            onDeleteClick={() => removeAgent(agent.id)}
            // onCloneClick={() => clone(agent)}
          />
        ))}
      </PolicyWell>
    </Container>
  );
};

export default FeedResourcePolicyPanel;
