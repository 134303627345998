import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Container } from '../../components/layout/ContainerFlex';
import LoadingIndicator from '../../components/LoadingIndicator';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import ItemSetSimulationPanel from '../panels/ItemSetSimulationPanel';
import ItemSetStartPanel from '../panels/ItemSetStartPanel';
import ItemSetStatsPanel from '../panels/ItemSetStatsPanel';
import { itemSetMenuItems } from '../store/itemSet.default';
import {
  itemSetDocument,
  itemSetSelectedMenuItemId,
} from '../store/itemSet.state';

export const ItemSetMainSidebar: React.FC = () => {
  const { t: tApp } = useTranslation('app');
  const { t } = useTranslation('dataset');
  const [stepId, setStepId] = useRecoilState(itemSetSelectedMenuItemId);
  const itemSet = useRecoilValue(itemSetDocument);

  const menuItems = itemSetMenuItems(tApp);
  const totalSteps = menuItems.length;
  const current = _.findIndex(menuItems, s => s.id === stepId);
  const isLastStep = totalSteps === current + 1;
  const next = () => {
    if (current < totalSteps - 1) {
      setStepId(menuItems[current + 1].id);
    }
  };

  if (!itemSet) {
    return <LoadingIndicator selfCenter message={t`Loading Data...`} />;
  }

  const getContent = () => {
    switch (stepId) {
      case 'item-set-menu-start':
        return <ItemSetStartPanel />;
      case 'item-set-menu-stats':
        return <ItemSetStatsPanel />;
      case 'item-set-menu-simulation':
        return <ItemSetSimulationPanel />;
      // case 'item-set-menu-pallet-policy':
      //   return <ItemSetPalletOrderPanel />;
    }
  };

  return (
    <>
      <Container col hasOverflowY>
        {getContent()}
      </Container>
      {/* <ActionBar>
        <Spacer flexspace />
        {!isLastStep && (
          <Button buttonType="primary" label={t`Next`} onPress={next} />
        )}
      </ActionBar> */}
    </>
  );
};

export default () => {
  const { t } = useTranslation('dataset');
  const [stepId, setStepId] = useRecoilState(itemSetSelectedMenuItemId);

  const getTitle = (): string => {
    switch (stepId) {
      case 'item-set-menu-start':
        return t`Dataset Properties`;
      case 'item-set-menu-stats':
        return t`Dataset Stats`;
      case 'item-set-menu-simulation':
        return t`Dataset in Simulations`;
    }
  };

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-item-set-main',
    title: getTitle(),
    size: 'sm',
  };

  return (
    <SidebarContainer {...containerProps}>
      <ItemSetMainSidebar />
    </SidebarContainer>
  );
};
