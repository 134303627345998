import {
  Equals,
  IsEmail,
  IsNotEmpty,
  ValidateBy,
} from '@warego/class-validator';
import { confirmSignUp, resendSignUpCode, signUp } from 'aws-amplify/auth';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { analyticsAgent } from '../AnalyticTracker';
import FormError from '../components/FormError';
import { Button } from '../components/actions/Button';
import InputCheckbox from '../components/inputs/InputCheckbox';
import InputText, {
  InputEmail,
  InputPassword,
} from '../components/inputs/InputText';
import {
  Container,
  ContainerHalfPromo,
} from '../components/layout/ContainerFlex';
import { ContainerPublic } from '../components/layout/ContainerPublic';
import { ContainerScroll } from '../components/layout/ContainerScroll';
import { LogoHeader } from '../components/layout/LogoHeader';
import { Spacer } from '../components/layout/Spacer';
import {
  IconArrowDir,
  IconArrowDirectional,
} from '../components/nav/IconArrowDirectional';
import { authUserPoolType } from '../store/auth.state';
import useValidatedForm from '../utils/use-validated-form';
import { useErrorTranslator } from './ErrorTranslator';
import { FooterPublic } from './FooterPublic';
import { HeaderPublic } from './HeaderPublic';
import { Intro } from './Intro';

class RegisterForm {
  @IsNotEmpty({
    message: ({ t }) => t('First name is required', { ns: 'app-public' }),
  })
  firstName: string;

  @IsNotEmpty({
    message: ({ t }) => t('Last name is required', { ns: 'app-public' }),
  })
  lastName: string;

  @IsNotEmpty({
    message: ({ t }) => t('E-mail is required', { ns: 'app-public' }),
  })
  @IsEmail(
    {},
    {
      message: ({ t }) =>
        t('Must be a valid e-mail address', { ns: 'app-public' }),
    },
  )
  email: string;

  @IsNotEmpty({
    message: ({ t }) => t('Password is required', { ns: 'app-public' }),
  })
  // TODO should we check password strength on client?
  // @MinLength(8, {
  //   message: ({ t, constraints }) =>
  //     t('password must be at least {{chatCount}} characters long', {
  //       ns: 'app-public',
  //       chatCount: constraints[0],
  //     }),
  // })
  // @Matches(/.*[A-Z].*/, {
  //   message: ({ t }) =>
  //     t('password must contain uppercase letters', { ns: 'app-public' }),
  // })
  // @Matches(/.*[a-z].*/, {
  //   message: ({ t }) =>
  //     t('password must contain lowercase letters', { ns: 'app-public' }),
  // })
  // @Matches(/.*[0-9].*/, {
  //   message: ({ t }) => t('password must contain digits', { ns: 'app-public' }),
  // })
  password: string;

  @ValidateBy({
    name: 'passwordMatch',
    validator: {
      validate: (value, args) => value === (args?.object as any).password,
      defaultMessage: () => 'Passwords should match',
    },
  })
  confirmPassword: string;

  @Equals(true, {
    message: ({ t }) => t('You must agree to the Terms of Service.'),
  })
  tosConfirmation: boolean;

  @Equals(true, {
    message: ({ t }) => t('You must agree to the Privacy Policy.'),
  })
  privacyPolicyConfirmation: boolean;
}

class ValidateCodeForm {
  @IsNotEmpty({
    message: ({ t }) => t('Verification code is required', { ns: 'app' }),
  })
  code: string;
}

type FormRegisterProps = {
  onSignUp: (form: RegisterForm, confirmed: boolean) => void;
  email?: string;
  invitationCode?: string;
  enforceMfa?: boolean;
};

const FormRegister = ({
  onSignUp,
  email,
  invitationCode,
  enforceMfa,
}: FormRegisterProps) => {
  const { t } = useTranslation('app-public');

  const [registering, setRegistering] = useState(false);

  const { register, handleSubmit, formState, setError } = useValidatedForm(
    RegisterForm,
    {
      validatorOptions: {
        extraArguments: {
          t,
        },
      },
    },
  );

  const { translateError } = useErrorTranslator();

  async function handleSignup(form: RegisterForm): Promise<void> {
    if (registering) {
      return;
    }
    const { firstName, lastName, email, password } = form;
    setRegistering(true);

    try {
      const signUpResult = await signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            email,
            given_name: firstName,
            family_name: lastName,
          },
          clientMetadata: invitationCode
            ? {
                invitationCode,
              }
            : null,
        },
      });

      onSignUp(form, signUpResult.nextStep.signUpStep === 'DONE');
    } catch (err) {
      console.log('signup error: %o', err);
      const translatedMessage = translateError(err); // Translate the error here

      // If analytics tracking is needed, you can add it here
      analyticsAgent?.track('Sign Up: Registration Error', {
        error: err.message,
      });

      if (err?.code === 'InvalidPasswordException') {
        setError('password', {
          message: translatedMessage || t('Invalid password'),
        });
      } else {
        setError('email', {
          message: translatedMessage || t('Error occurred during registration'),
        });
      }
      setRegistering(false);
    }
  }

  const firstNameFieldProps = register('firstName');
  const lastNameFieldProps = register('lastName');
  const emailFieldProps = register('email');
  const pwdFieldProps = register('password');
  const confirmPwdFieldProps = register('confirmPassword');
  const tosFieldProps = register('tosConfirmation');
  const privacyFieldProps = register('privacyPolicyConfirmation');

  const passwordPolicyLength = 10;
  const passwordPolicyLengthHelper = t(
    `at least {{passwordPolicyLength}} characters`,
    { passwordPolicyLength },
  );

  return (
    <form className="form-register" onSubmit={handleSubmit(handleSignup)}>
      <section className="p-10 pb-1 pt-1 xl:p-20 xl:py-1">
        <p className="text-menu-active text-2xl font-bold lg:text-4xl">
          {t`Register and WareBee AI engine will helping you make better decisions, driving costs down and sales up!`}
        </p>
      </section>
      <section className="w-full space-y-8 p-10 pb-0 pt-4 xl:p-20 xl:pb-0 xl:pt-4">
        {email && (
          <fieldset>
            <InputEmail
              autoComplete="username"
              value={email}
              label={t`Account Username (Email)`}
              name="email"
              {..._.omit(emailFieldProps, 'onChange')}
              isReadOnly
            />
          </fieldset>
        )}
        <fieldset>
          <InputText
            required
            autoComplete="name"
            placeholder={t`First Name`}
            label={t`First Name`}
            name="firstName"
            {..._.omit(firstNameFieldProps, 'onChange')}
            onChangeValidate={firstNameFieldProps.onChange}
          />
          <FormError errors={formState.errors} name="firstName" />
        </fieldset>
        <fieldset>
          <InputText
            required
            autoComplete="family-name"
            placeholder={t`Last Name`}
            label={t`Last Name`}
            name="lastName"
            {..._.omit(lastNameFieldProps, 'onChange')}
            onChangeValidate={lastNameFieldProps.onChange}
          />
          <FormError errors={formState.errors} name="lastName" />
        </fieldset>
        <fieldset>
          {email ? (
            <></>
          ) : (
            <InputEmail
              required
              autoComplete="username"
              placeholder="you@example.com"
              label={t`Email`}
              name="email"
              {..._.omit(emailFieldProps, 'onChange')}
              onChangeValidate={emailFieldProps.onChange}
            />
          )}
          <FormError errors={formState.errors} name="email" />
        </fieldset>
        <fieldset>
          <InputPassword
            required
            autoComplete="new-password"
            placeholder={passwordPolicyLengthHelper}
            label="Password"
            name="password"
            {..._.omit(pwdFieldProps, 'onChange')}
            onChangeValidate={pwdFieldProps.onChange}
            showPasswordPreview
          />
          <FormError errors={formState.errors} name="password" />
        </fieldset>
        <fieldset>
          <InputPassword
            required
            autoComplete="new-password"
            placeholder={t`Confirm password`}
            label={t`Confirm password`}
            name="confirmPassword"
            {..._.omit(confirmPwdFieldProps, 'onChange')}
            onChangeValidate={confirmPwdFieldProps.onChange}
            showPasswordPreview
          />
          <FormError errors={formState.errors} name="confirmPassword" />
        </fieldset>

        <div>
          <fieldset>
            <InputCheckbox
              name="tosConfirmation"
              {..._.omit(tosFieldProps, 'onChange')}
              onChangeValidate={tosFieldProps.onChange}
              className="pt-4 ltr:pl-2 rtl:pr-2"
            >
              <div>
                {t`I agree to`}
                {` `}
                {t`WareBee`}
                {` `}
                <Link to="/legal/terms" className="font-bold underline">
                  {t`Terms of Service`}
                </Link>
              </div>
            </InputCheckbox>
            <FormError errors={formState.errors} name="tosConfirmation" />
          </fieldset>
          <fieldset>
            <InputCheckbox
              name="privacyPolicyConfirmation"
              {..._.omit(privacyFieldProps, 'onChange')}
              onChangeValidate={privacyFieldProps.onChange}
              className="ltr:pl-2 rtl:pr-2"
            >
              <div>
                {t`I accept WareBee use of my data for the service and everything else described in the `}
                <Link to="/legal/privacy" className="font-bold underline">
                  {t`Privacy Policy`}
                </Link>
                .
              </div>
            </InputCheckbox>
            <FormError
              errors={formState.errors}
              name="privacyPolicyConfirmation"
            />
          </fieldset>
        </div>
      </section>
      <footer className="mb-8 mt-4 flex w-full items-center space-y-2 p-10 xl:p-20 xl:py-0">
        <Spacer flexspace />
        <Button
          buttonType="primary"
          type="submit"
          label={t`Create my Account`}
          hasIconAfter
          buttonIcon={<IconArrowDir />}
          isDisabled={registering || !_.isEmpty(formState.errors)}
        />
      </footer>
    </form>
  );
};

export type ConfirmationFormProps = {
  email: string;
  onConfirm: () => void;
  sendCode?: boolean;
};

export const FormConfirmation = ({
  email,
  onConfirm,
  sendCode,
}: ConfirmationFormProps) => {
  React.useEffect(() => {
    if (sendCode) {
      try {
        resendSignUpCode({ username: email });
      } catch (err) {
        console.log('error when sending initial code %o', err);
      }
    }
  }, [email, sendCode]);

  const { t } = useTranslation('app-public');

  const [confirming, setConfirming] = useState(false);
  const [resending, setResending] = useState(false);
  const { register, handleSubmit, formState, setError } =
    useValidatedForm<ValidateCodeForm>(ValidateCodeForm, {
      validatorOptions: {
        extraArguments: {
          t,
        },
      },
    });

  async function handleConfirm({ code }): Promise<void> {
    if (confirming) {
      return;
    }

    setConfirming(true);

    try {
      const confirmResult = await confirmSignUp({
        username: email,
        confirmationCode: code,
      });
      console.log('confirmed: %o', confirmResult);
    } catch (err) {
      console.log('confirmation error: %o', err);

      setError('code', {
        message: err?.message || 'signup error',
      });

      setConfirming(false);

      return;
    }

    onConfirm();
  }

  async function resendConfirmationCode() {
    if (resending) {
      return;
    }

    setResending(true);

    try {
      await resendSignUpCode({ username: email });
    } catch (err) {
      console.log('error when resending verification code: %o', err);
    }

    setResending(false);
  }

  const codeFieldProps = register('code');

  return (
    <form
      className="form-register-confirmation"
      onSubmit={handleSubmit(handleConfirm)}
    >
      <section className="p-10 pb-1 pt-1 xl:p-20 xl:py-1">
        {/* <h1 className="hidden w-full text-2xl text-start text-menu-active">
          {t`Confirm`}
        </h1> */}
        <p className="text-menu-active text-4xl font-bold">{t`Enter verification code`}</p>
      </section>
      <section className="space w-full space-y-8 p-10 pb-0 pt-4 xl:p-20 xl:pt-4">
        <InputText
          autoComplete="username"
          placeholder="you@example.com"
          label={t`Email`}
          name="email"
          value={email}
          isReadOnly={true}
        />

        <InputText
          autoComplete="one-time-code"
          placeholder={t`Verification code`}
          label={t`Verification code`}
          name="code"
          {..._.omit(codeFieldProps, 'onChange')}
          onChangeValidate={codeFieldProps.onChange}
        />
        <FormError errors={formState.errors} name="code" />

        <Button
          buttonType="basic"
          type="button"
          label={t`Resend Verification code`}
          isDisabled={confirming || resending}
          onPress={resendConfirmationCode}
        />
      </section>
      <section className="mt-6 w-full space-y-2 p-10 xl:p-20">
        <div className="flex w-full items-center">
          {/* <Link to="/help">
            <ButtonLink label={t`Need Help?`} />
          </Link> */}
          <Spacer flexspace />
          <Button
            buttonType="primary"
            type="submit"
            label={t`Confirm registration`}
            isDisabled={confirming || !_.isEmpty(formState.errors)}
          />
        </div>
      </section>
    </form>
  );
};

export type RegisterProps = {
  children?: React.ReactNode;
};

export const Register = ({ children }: RegisterProps) => {
  const [id] = useState<string>(_.uniqueId());

  const [confirmationRequired, setConfirmationRequired] = useState(false);
  const [userData, setUserData] = useState<RegisterForm | null>(null);
  const setUserPoolType = useSetRecoilState(authUserPoolType);
  const { t } = useTranslation('app-public');

  const navigate = useNavigate();

  const [params] = useSearchParams();

  const invitationCode = params.get('invitationCode');
  const email = params.get('email');
  const enforceMfa = params.get('enforceMfa') == 'true';

  useEffect(() => {
    setUserPoolType(enforceMfa ? 'mfa' : 'default');
  }, [enforceMfa]);

  function goToLogin(user: RegisterForm) {
    const fullName = [user.firstName, user.lastName].join(' ');
    navigate('/login', {
      state: {
        username: user.email,
        message: t(`{{fullName}} registered successfully, please login.`, {
          fullName: fullName,
        }),
      },
    });
  }

  function onSignUp(user: RegisterForm, confirmed: boolean) {
    if (confirmed) {
      goToLogin(user);
    } else {
      setUserData(user);
      setConfirmationRequired(true);
    }
  }

  function onConfirm() {
    goToLogin(userData);
  }

  const stylePadding = 'px-10 py-2';
  const linkWebsite = 'https://warebee.com/?source=app?feature=register';

  return (
    <ContainerPublic consent dataComponent="Register">
      <ContainerHalfPromo>
        {/* <header
          className={classNames(
            'sticky top-0',
            'flex items-center',
            'bg-brand/80 text-menu-text lg:bg-app-panel-dark/90 lg:text-menu-active',
            'bg-opacity-70 backdrop-filter backdrop-blur',
            'px-10 py-3',
          )}
        >
          <a href={`${linkWebsite}`}>
            <NavBackWebsite message={t('Back to WareBee.com')} />
          </a>
        </header> */}
        <HeaderPublic
          hasIconBefore={<IconArrowDirectional direction="Back" />}
          className={classNames(
            'sticky top-0',
            'flex items-center',
            'bg-brand/80 text-menu-text lg:bg-app-panel-dark/90 lg:text-menu-active',
            'bg-opacity-70 backdrop-blur backdrop-filter',
            'px-10 py-3',
          )}
          url={'/login'}
          message={t`Back to login`}
        ></HeaderPublic>
        <Container>
          <Intro />
        </Container>
      </ContainerHalfPromo>
      {/* ——————————————— Right Side ——————————————— */}
      <div className="bg-menu-app flex h-full w-full flex-col md:w-1/2">
        <ContainerScroll hasOverflowY>
          <HeaderPublic
            hasIconAfter={<IconArrowDirectional direction="Forward" />}
            className={classNames(
              'sticky top-0',
              'flex items-center',
              // 'bg-brand/80 text-menu-text lg:bg-app-panel-dark/90 lg:text-menu-active',
              'bg-opacity-70 backdrop-blur backdrop-filter',
              'px-10 py-3',
            )}
            url={'/login'}
            message={t`Already registered, click to login?`}
          ></HeaderPublic>
          <LogoHeader
            logoFull
            logoSize={'md'}
            className={`mb-4 mt-8 p-10 pb-0 pt-0 xl:py-0`}
          />

          <div className="flex-1">
            {confirmationRequired ? (
              <FormConfirmation email={userData.email} onConfirm={onConfirm} />
            ) : (
              <FormRegister
                onSignUp={onSignUp}
                email={email}
                invitationCode={invitationCode}
                enforceMfa={enforceMfa}
              />
            )}
          </div>
          <FooterPublic />
        </ContainerScroll>
      </div>
    </ContainerPublic>
  );
};
