import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import HelpContainer from '../../helpContext/HelpContainer';

const FeedSetup: React.FC = props => {
  const { t } = useTranslation('dataset');

  return (
    <Container hasOverflowY flex1 col>
      <ScreenTitle title={t`Setup`} subtitle={t`Live feed`} isSticky truncate />
      <HelpContainer id={'actuality/actuality-how-it-works'} hasPadding />
      <HelpContainer id={'actuality/actuality-getting-started'} hasPadding />
    </Container>
  );
};

export default FeedSetup;
