import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import AppHeaderData, { AppHeaderProps } from '../components/AppHeaderData';
import {
  actualityAnalyzeLoadStatus,
  actualitySelectedDocument,
} from '../feed/store/actuality.state';
import Tag from '../helpContext/Tag';
import { layoutDocument } from '../layout/store/layout.state';
import {
  warehouseDefaultRoute,
  warehouseSelected,
} from '../store/warehouse.state';

const FeedHeader: React.FC = () => {
  const { t } = useTranslation('feed');

  const wh = useRecoilValue(warehouseSelected);
  const document = useRecoilValue(layoutDocument);
  const actuality = useRecoilValue(actualitySelectedDocument);
  const warehouseUserRoute = useRecoilValue(warehouseDefaultRoute);
  const analyzeStatus = useRecoilValue(actualityAnalyzeLoadStatus);

  if (!wh) return null;

  const headerProps: AppHeaderProps = {
    backPath:
      warehouseUserRoute === 'simulations'
        ? `/wh/i/${wh.id}`
        : `/wh/i/${wh.id}/simulations`,
    warehouseId: wh.id,
    breadcrumbItems: [
      {
        title: wh.title,
        href:
          warehouseUserRoute === 'simulations'
            ? `/wh/i/${wh.id}`
            : `/wh/i/${wh.id}/simulations`,
      },
    ],
  };
  if (document) {
    headerProps.breadcrumbItems.push({
      title: actuality?.title || t`Activity`,
      href: `/wh/i/${wh.id}/feed/${actuality?.id}`,
    });
  }
  return (
    <AppHeaderData {...headerProps} isLoading={document ? false : true}>
      {analyzeStatus === AsyncLoadStatus.Loading ? (
        <Tag> {t`Analyzing`}</Tag>
      ) : null}
    </AppHeaderData>
  );
};
export default FeedHeader;
