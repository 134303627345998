import {
  LayoutBayLocationFragment,
  WeightComplianceLocationFilter,
  WeightComplianceLocationDetailsFragment,
  WeightComplianceLocationSortBy,
  WeightComplianceLocationSortOption,
  SortDirection,
  useExportLocationWeightComplianceMutation,
  WeightComplianceLocationDataFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { containsFilter, numberFilter } from '../../../../common/filterHelper';
import { AsyncLoadStatus } from '../../../../common/types';
import useFormatter from '../../../../common/useFormatter';
import DatasetTable, {
  ColumnConfig,
} from '../../../../components/DatasetTable';
import { ContainerScroll } from '../../../../components/layout/ContainerScroll';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLocationIdWithToggle,
  viewerSelectedPlaneId,
} from '../../../../layout/viewer/store/viewer.state';
import useLoadWeightComplianceLocations from '../../../hooks/useLoadWeightComplianceLocations';
import { simulationCurrent } from '../../../store/simulation.state';
import { getWeightComplianceStatusMap } from '../../../store/weightCompliance.helper';
import {
  weightComplianceDataLoadStatus,
  weightComplianceLocationsData,
  weightComplianceLocationsState,
  weightComplianceMeta,
} from '../../../store/weightCompliance.state';
import WeightComplianceStatusTag from './WeightComplianceStatusTag';

const sortFieldsMapping: Partial<
  Record<
    keyof WeightComplianceLocationTableRow,
    WeightComplianceLocationSortOption
  >
> = {
  locationId: WeightComplianceLocationSortOption.LOCATION_ID,
};

export type WeightComplianceLocationTableRow =
  WeightComplianceLocationDetailsFragment & {
    locationId: string;
    area: string;
    rackType: string;
    bay: string;
    floor: string;
    level: number;
    locationData: WeightComplianceLocationDataFragment;
  };
export type WeightComplianceLocationTableColumn =
  keyof WeightComplianceLocationTableRow;

const WeightComplianceLocationsTable: React.FC = () => {
  const { t } = useTranslation('simulation');

  const sim = useRecoilValue(simulationCurrent);
  const complianceMeta = useRecoilValue(weightComplianceMeta);
  const loadStatus = useRecoilValue(weightComplianceDataLoadStatus);
  const locData = useRecoilValue(weightComplianceLocationsData);
  const [loadCallback, cancelLoad] = useLoadWeightComplianceLocations();
  const [state, setState] = useRecoilState(weightComplianceLocationsState);
  const selectLocation = useSetRecoilState(viewerSelectedLocationIdWithToggle);
  const selectBay = useSetRecoilState(viewerSelectedBayIdAtom);
  const selectPlane = useSetRecoilState(viewerSelectedPlaneId);
  const [runExportCSV] = useExportLocationWeightComplianceMutation();
  const formatter = useFormatter();
  const { searchValues, sortValues } = state;

  function callDataLoad(
    searchValues: Partial<
      Record<keyof WeightComplianceLocationTableRow, string>
    >,
    sortValues: Partial<
      Record<keyof WeightComplianceLocationTableRow, SortDirection>
    >,
    page = { isAppend: false, skip: 0 },
  ) {
    const filter: WeightComplianceLocationFilter = {
      status: searchValues['status'] ? ([searchValues.status] as any) : null,
      locationId: containsFilter(searchValues.locationId),
      warehouseArea: containsFilter(searchValues.area),
      locationPlaneTitle: containsFilter(searchValues.floor),
      locationBayTitle: containsFilter(searchValues.bay),
      locationRackingType: containsFilter(searchValues.rackType),
      locationLevel: numberFilter(searchValues.level),
      locationWeight: numberFilter(searchValues.maxWeight),
    };

    const sortBy: WeightComplianceLocationSortBy[] = _.map(
      sortValues,
      (value, key, index) => {
        const sortObjectKey = sortFieldsMapping[key];
        return sortObjectKey
          ? { field: sortObjectKey, direction: value }
          : null;
      },
    ).filter(i => !!i);

    cancelLoad();
    loadCallback({
      filter,
      sortBy,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(searchValues, sortValues);
  }, [searchValues, sortValues]);

  async function startExportCSV() {
    const { data, errors } = await runExportCSV({
      variables: {
        weightComplianceId: complianceMeta?.id,
      },
    });
    //
    return {
      errors: [],
      job: data.exportLocationWeightCompliance,
    };
  }

  const statusMap = {
    '': t`All`,
    ...getWeightComplianceStatusMap(t),
  };
  const columnsConfig: ColumnConfig<WeightComplianceLocationTableRow>[] = [
    {
      field: 'status',
      title: t`Status`,
      hasFilter: true,
      //hasSort: true,
      render: (status, row) => (
        <WeightComplianceStatusTag
          status={row.status}
          weight={row.weight}
          maxWeight={row.maxWeight}
        />
      ),
      filterOptions: statusMap,
    },
    {
      field: 'locationId',
      title: t`Location`,
      hasFilter: true,
      isHeader: true,
      hasSort: true,
    },
    {
      field: 'floor',
      title: t`Floor`,
      hasFilter: true,
    },
    {
      field: 'area',
      title: t`Area`,
      hasFilter: true,
    },
    {
      field: 'bay',
      title: t`Bay`,
      hasFilter: true,
    },
    {
      field: 'level',
      title: t`Level`,
      hasFilter: true,
    },
    {
      field: 'rackType',
      title: t`Rack Type`,
      hasFilter: true,
    },
    {
      field: 'maxWeight',
      title: t`Location Weight`,
      render: (weight: number) =>
        !weight ? '-' : formatter.formatWeight(weight).fullString,
    },
    {
      field: 'weight',
      title: t`Assignment Weight`,
      render: (weight: number) =>
        !weight ? '-' : formatter.formatWeight(weight).fullString,
    },
  ];

  const isLoading = loadStatus === AsyncLoadStatus.Loading;
  const itemsCount = locData?.content?.length || 0;
  const totalCount = locData?.totalCount ?? 0;

  const reduceLocation = (
    locationWeightData: WeightComplianceLocationDetailsFragment,
  ) => {
    const l = locationWeightData.location;
    return {
      ...locationWeightData,
      locationId: l.locationId,
      area: l.warehouseArea,
      rackType: l.locationRackingType,
      bay: l.bay?.title,
      floor: l.plane?.title,
      level: l.locationLevel,
      locationData: l,
    };
  };

  const data = _.map(locData?.content, reduceLocation);

  function onLoadNext() {
    callDataLoad(searchValues, sortValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  function onRowSelect(row: WeightComplianceLocationTableRow, index: number) {
    const targetPlaneId = row.locationData?.plane?.id;
    const targetBayId = row.locationData.bay?.id;
    const targetLocationId = row.locationData.locationId;
    targetPlaneId && selectPlane(targetPlaneId);
    targetBayId && selectBay(targetBayId);
    targetLocationId && selectLocation(targetLocationId);
  }
  return (
    <ContainerScroll className="flex flex-col">
      <DatasetTable
        subtitle={t(`{{totalCount}} Filtered Items for`, {
          totalCount,
        })}
        title={t`Locations — weight compliance`}
        isSticky
        id={'weight-compliance-bay-table'}
        columnsConfig={columnsConfig}
        keyFields={['locationId']}
        data={data}
        onLoadNext={onLoadNext}
        onSearch={searchValues => setState({ ...state, searchValues })}
        totalCount={totalCount}
        searchValues={searchValues}
        sortBy={sortValues}
        onSort={sortValues => setState({ ...state, sortValues })}
        isLoading={isLoading}
        onRowSelect={onRowSelect}
        onStartExportClick={startExportCSV}
      />
    </ContainerScroll>
  );
};

export default WeightComplianceLocationsTable;
