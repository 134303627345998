import Flatten from '@flatten-js/core';
import {
  ConnectivityGraph,
  Location,
  LocationPortal,
  PlaneAccessSpec,
  WaypointType,
} from '@warebee/shared/engine-model';
import { first, groupBy } from 'lodash';
import { loadPoint } from './geometry.engine';
import { RoutingSettings } from './local-navigation';
import {
  NavigationEngine,
  RoutePointSpec,
  createNavigationEngine,
} from './navigation-engine';
import { Route, Waypoint } from './navigation.model';
import { VisualPlane } from './visual-layout-map.model';

export type LocationWaypoint = Pick<
  Location,
  'locationId' | 'portals' | 'locationBayId'
>;

export class RouteSettings {
  includeTerminals?: boolean;
}

export function getEffectiveLocationPortal(
  loc: LocationWaypoint,
): LocationPortal | null {
  return first(loc.portals);
}

/**
 * Navigation engine for layout plane.
 */
export class LayoutPlaneNavigationEngine {
  navigationEngine: NavigationEngine;

  constructor(
    readonly map: Pick<
      VisualPlane,
      'navigablePolygons' | 'start' | 'end' | 'id'
    >,
    connectivityGraph: ConnectivityGraph,
    routingSettings?: RoutingSettings,
  ) {
    this.navigationEngine = createNavigationEngine(
      map.navigablePolygons,
      connectivityGraph,
      routingSettings,
    );
  }

  accessSpecToWaypoint(spec: PlaneAccessSpec, id: string): RoutePointSpec {
    return {
      polygonId: spec.aisleId,
      waypoint: spec.position && {
        type: WaypointType.TERMINAL,
        id,
        position: loadPoint(spec.position),
      },
    };
  }

  locationSpecToWaypoint(loc: LocationWaypoint): RoutePointSpec {
    const portal = getEffectiveLocationPortal(loc);
    if (!portal) {
      throw new Error(`unreachable location ${loc.locationId}`);
    }
    return {
      waypoint: {
        id: loc.locationId,
        position: loadPoint(portal.position),
        type: WaypointType.LOCATION,
      },
      polygonId: portal.aisleId,
    };
  }

  hasRouteBetween(src: RoutePointSpec, dest: RoutePointSpec): boolean {
    return (
      this.navigationEngine.findRoute([src, dest]).unreachablePoints.length == 0
    );
  }

  findRoute(locations: LocationWaypoint[], settings?: RouteSettings): Route {
    const unreachableLocations = new Set<string>();
    const unreachableTerminals: Waypoint[] = [];
    let prevBayId = null;
    const spec: RoutePointSpec[] = locations.map(loc => {
      const locSpec = this.locationSpecToWaypoint(loc);
      if (prevBayId == loc.locationBayId) {
        // ignore allowed directing when navigating within bay
        locSpec.settings = { ignoreAllowedDirection: true };
      }
      prevBayId = loc.locationBayId;
      return locSpec;
    });

    if (spec.length === 0) {
      return {
        waypoints: [],
        distance: 0,
        unreachableLocations,
        unreachableTerminals,
      };
    } else {
      if (settings?.includeTerminals != false) {
        if (this.map.start) {
          spec.unshift(
            this.accessSpecToWaypoint(this.map.start, 'start-' + this.map.id),
          );
        }
        if (this.map.end) {
          spec.push(
            this.accessSpecToWaypoint(this.map.end, 'end-' + this.map.id),
          );
        }
      }

      const route = this.navigationEngine.findRoute(spec);
      if (route.unreachablePoints.length > 0) {
        route.unreachablePoints.forEach(p => {
          if (p.waypoint) {
            if (p.waypoint.type == WaypointType.LOCATION) {
              unreachableLocations.add(p.waypoint.id);
            } else if (p.waypoint.type == WaypointType.TERMINAL) {
              unreachableTerminals.push(p.waypoint);
            }
          }
        });
      }
      return {
        waypoints: route.waypoints,
        distance: route.distance,
        unreachableLocations,
        unreachableTerminals,
      };
    }
  }

  checkLocationAccessibility<L extends LocationWaypoint>(
    locations: L[],
    start: PlaneAccessSpec | null,
    end: PlaneAccessSpec | null,
    handler: (
      locs: L[],
      hasAccessFromStart: boolean,
      hasAccessToEnd: boolean,
    ) => void,
  ) {
    if (!start && !end) {
      throw new Error('neither start nor end point defined');
    }

    const locationsByFeatureId = groupBy(
      locations,
      l => getEffectiveLocationPortal(l)?.aisleId,
    );

    let reachableFromStart: Record<string, Waypoint[]> = null;
    let endReachableFrom: Record<string, Waypoint[]> = null;

    let isReachableFromStart: (
      featureId: string,
      pt: Flatten.Point,
    ) => boolean = () => true;
    let isEndReachableFrom: (
      featureId: string,
      pt: Flatten.Point,
    ) => boolean = () => true;

    if (start) {
      const startPos = loadPoint(start.position);
      reachableFromStart = this.navigationEngine.findPolygonsReachableFrom(
        start.aisleId,
        startPos,
      );
      isReachableFromStart = (featureId, pt) => {
        if (
          featureId == start.aisleId &&
          this.navigationEngine.isLocallyReachable(featureId, [startPos], pt)
        ) {
          return true;
        }

        return this.navigationEngine.isLocallyReachable(
          featureId,
          reachableFromStart[featureId]?.map(p => p.position) || [],
          pt,
        );
      };
    }

    if (end) {
      const endPos = loadPoint(end.position);
      endReachableFrom = this.navigationEngine.findPolygonsReachableFrom(
        end.aisleId,
        endPos,
        true,
      );
      isEndReachableFrom = (featureId, pt) => {
        if (
          featureId == end.aisleId &&
          this.navigationEngine.isLocallyReachable(
            featureId,
            [endPos],
            pt,
            true,
          )
        ) {
          return true;
        }
        return this.navigationEngine.isLocallyReachable(
          featureId,
          endReachableFrom[featureId]?.map(p => p.position) || [],
          pt,
          true,
        );
      };
    }

    for (const featureId in locationsByFeatureId) {
      const hasAccessFromStart =
        !start ||
        featureId == start.aisleId ||
        reachableFromStart[featureId] != null;
      const hasAccessToEnd =
        !end || featureId == end.aisleId || endReachableFrom[featureId] != null;

      const featureLocations = locationsByFeatureId[featureId];

      if (hasAccessFromStart && hasAccessToEnd) {
        for (const loc of featureLocations) {
          const locPosition = loadPoint(
            getEffectiveLocationPortal(loc).position,
          );
          const locHasAccessFromStart = isReachableFromStart(
            featureId,
            locPosition,
          );
          const locHasAccessToEnd = isEndReachableFrom(featureId, locPosition);

          handler([loc], locHasAccessFromStart, locHasAccessToEnd);
        }
      } else {
        handler(featureLocations, hasAccessFromStart, hasAccessToEnd);
      }
    }
  }
}
