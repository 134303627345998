import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ColumnConfig } from '../../../components/DatasetTable';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import Tag from '../../../helpContext/Tag';
import {
  importMappingSettingsByType,
  importTypeCurrent,
} from '../../store/import.state';
import {
  ImportType,
  MappingFieldSettings,
  ValidationError,
} from '../../store/import.types';
import useApplyMappingSettings from '../../useApplyMappingSettings';

export type ValidationErrorsTableConfigParams = {
  type: ImportType;
  showAs: 'grouped' | 'all';
};

type ResolveSelectorProps = {
  field: string;
  value: string;
};

const ResolveSelector: React.FC<ResolveSelectorProps> = props => {
  const { t } = useTranslation('importer');
  const type = useRecoilValue(importTypeCurrent);
  const mappingSettings = useRecoilValue(importMappingSettingsByType(type));
  const applyMappingChange = useApplyMappingSettings();

  const field = _.find(mappingSettings.fields, f => f.name === props.field);
  const resolver = field.valueResolver ?? field.defaultValueResolver;
  const resolverValues: (string | boolean)[] = _(_.values(resolver))
    .uniq()
    .value() as any;
  const isResolved = _.has(resolver, props.value);

  function resolve(resolveTo: any) {
    const newField: MappingFieldSettings<Object> = {
      ...field,
      valueResolver: {
        ...(field.valueResolver ?? {}),
        [props.value]: resolveTo as any,
      },
    };

    applyMappingChange({
      mappingSettings: {
        ...mappingSettings,
        fields: _.map(mappingSettings.fields, f =>
          f.name === props.field ? newField : f,
        ),
      },
    });
  }
  return (
    <div className="flex w-full flex-row">
      <div className="flex w-full">
        <DropdownSelector
          className={classNames('w-full rounded', {
            //'mapped bg-alerts-okay': isMapped,
            'unmapped bg-alerts-error': !isResolved,
            'unmapped bg-alerts-warn': isResolved,
          })}
          classNameValue={`ltr:pl-2 rtl:pr-2`}
          widthFull
          values={resolverValues as any}
          value={resolver?.[props.value]}
          renderValueSelected={f => f?.toString() ?? t`— Select Value:`}
          onChange={field => resolve(field)}
        />
      </div>
      {/* <Button
        className={classNames(
          'group',
          'rounded',
          'flex flex-col items-center justify-center flex-1',
          'h-8 w-8',
          'bg-menu-500/30',
          'any-hover:hover:bg-brand-hover any-hover:hover:text-menu-text',
        )}
        buttonType="secondary"
        onPress={() => props.onMappingChange(null)}
      >
        <Icon.Close className="p-0.5 w-4 h-4 fill-current" />
      </Button> */}
    </div>
  );
};

function useValidationErrorsTableConfig(
  params: ValidationErrorsTableConfigParams,
): ColumnConfig<ValidationError>[] {
  const { t } = useTranslation('importer');
  const isGrouped = params.showAs === 'grouped';

  const columnsConfig: ColumnConfig<ValidationError>[] = [
    {
      field: 'fieldTitle',
      title: t`Warebee field`,
      isHeader: true,
    },
    {
      field: 'csvColumn',
      title: t`Source Field`,
      isHeader: true,
    },
    {
      field: 'message',
      title: t`Error Message`,
      render: (m: string) => <Tag classType="bg-alerts-error">{m}</Tag>,
    },
    {
      field: 'value',
      title: t`Value`,
    },
    {
      field: 'line',
      title: isGrouped ? t`First Line` : t`Line`,
    },
    // {
    //   field: 'code',
    //   title: t`Code`,
    // },
    {
      field: 'count',
      title: t`Count`,
      hiddenInBrowser: !isGrouped,
    },
    {
      field: 'valueResolver',
      title: 'Resolve',
      render: (v, row) =>
        row['canResolve'] ? (
          <ResolveSelector field={row['property']} value={row.value} />
        ) : null,
    },
  ];

  return columnsConfig;
}

export default useValidationErrorsTableConfig;
