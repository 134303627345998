import classNames from 'classnames';
import _ from 'lodash';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import LoadingIndicator from '../../components/LoadingIndicator';
import DropdownSelector from '../../components/actions/DropdownSelector';
import { HeaderAppMenu } from '../../components/headers/HeaderAppMenu';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { Spacer } from '../../components/layout/Spacer';
import { ActionBar, ActionBarFooter } from '../../components/nav/ActionBar';
import ErrorContainer from '../../containers/ErrorContainer';
import SelectTargetDocument from '../../import/SelectTargetDocument';
import { getImportConfiguration } from '../../import/store/import.default';
import useApplyMappingSettings from '../../import/useApplyMappingSettings';
import ImporterFieldMapping from '../csv/components/ImporterFieldMapping';
import ImporterMappingErrors from '../csv/components/ImporterMappingErrors';
import ImporterMappingPreview from '../csv/components/ImporterMappingPreview';
import {
  importMappingSettingsByType,
  importSelectedFile,
  importUnmappedFields,
  importValidationErrors,
  importValidationStatus,
  showAdvancedImportSetup,
  showTargetImportSetup,
} from '../store/import.state';
import { ImportStepContainerProps } from '../store/import.types';

type MenuOptionsTuple = typeof menuAdvancedOptions;
const menuAdvancedOptions = [
  'Show Mapping Settings',
  'Show Dest. Settings',
] as const;

export type menuAdvancedOptions = MenuOptionsTuple[number];

const ImportMappingStep: React.FC<ImportStepContainerProps> = props => {
  const { t } = useTranslation('importer');

  const [mappingSettings, setMappingSettings] = useRecoilState(
    importMappingSettingsByType(props.type),
  );

  const validationErrors = useRecoilValue(importValidationErrors);
  const unmappedFields = useRecoilValue(importUnmappedFields(props.type));
  const validationStatus = useRecoilValue(importValidationStatus);
  const file = useRecoilValue(importSelectedFile);
  const filename = file?.name;
  const applyMappingChange = useApplyMappingSettings();

  const [showImportAdvancedSetup, setShowImportAdvancedSetup] = useRecoilState(
    showAdvancedImportSetup,
  );

  const [showImportTargetSetup, setShowImportTargetSetup] = useRecoilState(
    showTargetImportSetup,
  );

  function resetMappingSettings() {
    const defaultSettings = getImportConfiguration(
      props.type,
    ).mappingSettingsDefault;

    applyMappingChange({
      mappingSettings: defaultSettings,
    });
  }

  function resetMappingResolverSettings() {
    const newSettings = {
      ...mappingSettings,
      fields: _.map(mappingSettings.fields, f => ({
        ...f,
        valueResolver: f.defaultValueResolver,
      })),
    };

    applyMappingChange({
      mappingSettings: newSettings,
    });
  }

  const unmappedFieldCount = _.size(unmappedFields);
  const unmappedRequiredCount = _(unmappedFields)
    .filter(f => !f.optional)
    .size();
  const validationErrorCount = _.size(validationErrors);
  const hasAlerts = validationErrorCount > 0;

  const isValidation = validationStatus === AsyncLoadStatus.Loading;
  return (
    <Container
      col
      componentName="ImportMappingStep"
      overflow
      className={classNames(
        'flex-1',
        'border-app-panel-dark ltr:border-l rtl:border-r',
        'relative',
        'bg-app-panel-dark',
      )}
    >
      <HeaderAppMenu
        className="z-alert"
        title={
          <>
            {t('Map {{filename}} data', { filename: filename ?? 'dataset' })}
            <Spacer flexspace />
            <DropdownSelector
              classNameLabel={classNames('text-xxs xl:text-sm')}
              className={classNames(
                'ltr:mr-0.5 xl:ltr:mr-2 rtl:ml-0.5 xl:rtl:ml-2',
              )}
              classNameDropDownMenu="z-alert"
              onClick={e => {
                e.stopPropagation();
              }}
              DropAlignRight
              buttonTransparent
              vertical
              panelMode
              value={``}
              values={[...menuAdvancedOptions]}
              onChange={(option: menuAdvancedOptions) => {
                switch (option) {
                  case 'Show Mapping Settings':
                    showImportAdvancedSetup;
                    setShowImportAdvancedSetup(!showImportAdvancedSetup);
                    break;
                  case 'Show Dest. Settings':
                    showImportTargetSetup;
                    setShowImportTargetSetup(!showImportTargetSetup);
                    break;
                }
              }}
            />
          </>
        }
      />

      {isValidation && (
        <LoadingIndicator
          absolute
          // backdrop
          className="h-full w-full"
          selfCenter
          message={t`Validating data`}
        />
      )}
      <Container
        componentName="MapStageDataView"
        col
        overflow
        flex1
        className={classNames(
          'border-app-panel-dark relative ltr:border-l rtl:border-r',
        )}
      >
        <Suspense
          fallback={<LoadingIndicator selfCenter message={t`Loading`} />}
        >
          <Container overflow className="flex">
            <Container col overflow flex1>
              {showImportAdvancedSetup && (
                <ActionBarFooter className="py-2">
                  <div
                    className={classNames(
                      'text-menu-active',
                      'inline-flex',
                      'mx-4',
                      'cursor-pointer select-none',
                      'border-b-2 border-transparent',
                      'border-menu-active hover:border-current',
                    )}
                    onClick={() => resetMappingSettings()}
                  >
                    <span
                      className={classNames('inline-flex items-center text-xs')}
                    >
                      <Icon.CircleX
                        className={classNames('mx-1 h-5 w-5 fill-current')}
                      />
                      {t`Reset Mapping Settings`}
                    </span>
                  </div>
                  <div
                    className={classNames(
                      'text-menu-active',
                      'inline-flex',
                      'mx-4',
                      'cursor-pointer select-none',
                      'border-b-2 border-transparent',
                      'border-menu-active hover:border-current',
                    )}
                    onClick={() => resetMappingResolverSettings()}
                  >
                    <span
                      className={classNames('inline-flex items-center text-xs')}
                    >
                      <Icon.CircleX
                        className={classNames('mx-1 h-5 w-5 fill-current')}
                      />
                      {t`Reset Mapping Field Resolver Settings`}
                    </span>
                  </div>

                  <Spacer flexspace />
                </ActionBarFooter>
              )}

              {showImportTargetSetup && (
                <ActionBar className="p-3">
                  <SelectTargetDocument />
                </ActionBar>
              )}

              {unmappedRequiredCount > 0 && (
                <ImporterMappingErrors type={props.type} />
              )}
              {hasAlerts && (
                <ErrorContainer
                  status={'ERROR'}
                  id="validation-error-container-importer-mapped"
                  icon={Icon.CircleOk}
                  title={t(
                    'There are {{validationErrorCount}} validation errors',
                    { validationErrorCount },
                  )}
                />
              )}
              {unmappedRequiredCount === 0 && !hasAlerts && (
                <ErrorContainer
                  status={'OKAY'}
                  id="error-container-importer-mapped"
                  icon={Icon.CircleOk}
                  title={t`All Required fields are mapped`}
                  hasZindex={false}
                />
              )}
              <ImporterFieldMapping type={props.type} />
            </Container>
          </Container>
        </Suspense>
        <ImporterMappingPreview />
      </Container>
    </Container>
  );
};

export default ImportMappingStep;
