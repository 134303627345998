export const HelpPathPrefix = '/app/assets/docs';
export type HelpIndex =
  | 'index'
  | 'index/app'
  | 'index/legal'
  | 'index/settings'
  | 'index/designer'
  | 'index/importer'
  | 'index/dataset'
  | 'index/simulation'
  | 'toc'
  | 'simulation/index'
  | 'warebee'
  | 'getting-started'
  | 'digital-twin'
  | 'app/getting-started'
  | 'designer/01-getting-started'
  | 'designer/01-layout-properties'
  | 'designer/01-setup-floors'
  | 'designer/01-system-of-measurement'
  | 'designer/02-areas'
  | 'designer/02-areas-floors-assignment'
  | 'designer/02-areas-delete'
  | 'designer/02-areas-orientation'
  | 'designer/03-aisles-locations-bays'
  | 'designer/03-setup-aisles'
  | 'designer/03-setup-aisles-merge'
  | 'designer/03-setup-aisles-order'
  | 'designer/03-setup-aisles-access'
  | 'designer/03-setup-aisles-tunnel'
  | 'designer/03-setup-aisles-custom'
  | 'designer/03-setup-obstacles'
  | 'designer/03-setup-racks'
  | 'designer/03-setup-locations'
  | 'designer/03-setup-locations-profiles'
  | 'designer/03-setup-locations-dimensions'
  | 'designer/04-navigation'
  | 'designer/04-navigation-starting-point'
  | 'designer/04-navigation-connect-areas'
  | 'designer/05-finish'
  | 'importer/datetimeTable'
  | 'importer/date-time'
  | 'importer/getting-started'
  | 'importer/importer'
  | 'importer/preparing'
  | 'importer/excel'
  | 'importer/transform'
  | 'importer/transform-intro'
  | 'importer/transform-helper'
  | 'importer/jobs'
  | 'importer/pipelines'
  | 'importer/pipeline/connectors'
  | 'importer/pipeline/connector/sftp-pull'
  | 'importer/pipeline/connector/api-push'
  | 'importer/pipeline/connector/s3'
  | 'dataset/importer'
  | 'dataset/importing-large-datasets'
  | 'dataset/exporter'
  | 'dataset/layout'
  | 'dataset/layout/layout-field-guide'
  | 'dataset/assignment'
  | 'dataset/assignment/assignment-field-guide'
  | 'dataset/items'
  | 'dataset/items/items-field-guide'
  | 'dataset/orders'
  | 'dataset/orders/orders-field-guide'
  | 'dataset/field-guide/location-id'
  | 'dataset/field-guide/item-sku'
  | 'dataset/field-guide/client-consignee'
  | 'dataset/field-guide/assignment-item-uom'
  | 'dataset/field-guide/assignment-item-uom-qty'
  | 'dataset/feed'
  | 'dataset/feed/feed-field-guide'
  | 'live/feed'
  | 'actuality/feed'
  | 'actuality/actuality-setup'
  | 'actuality/actuality-getting-ready'
  | 'actuality/actuality-getting-started'
  | 'actuality/actuality-how-it-works'
  | 'actuality/actuality-policies'
  | 'actuality/actuality-kpi'
  | 'actuality/feed-activity'
  | 'actuality/feed-kpi'
  | 'actuality/feed-resources'
  | 'actuality/feed-stock'
  | 'actuality/integration-proglove'
  | 'simulation/simulation-getting-started'
  | 'simulation/simulation-setup'
  | 'simulation/simulation-getting-ready'
  | 'simulation/simulation-how-it-works'
  | 'simulation/simulation-what-is-simulation'
  | 'simulation/dataset/simulation-dataset'
  | 'simulation/dataset/simulation-dataset-layout'
  | 'simulation/dataset/simulation-dataset-items'
  | 'simulation/dataset/simulation-dataset-assignment'
  | 'simulation/dataset/simulation-dataset-orders'
  | 'simulation/dataset/simulation-dataset-stats'
  | 'simulation/dataset/simulation-dataset-stats-layout'
  | 'simulation/dataset/simulation-dataset-stats-items'
  | 'simulation/dataset/simulation-dataset-stats-assignment'
  | 'simulation/dataset/simulation-dataset-stats-orders'
  | 'simulation/dataset/simulation-dataset-issues'
  | 'simulation/dataset/simulation-dataset-heatmaps'
  | 'simulation/dataset/simulation-dataset-feed'
  | 'simulation/dataset/simulation-datasets'
  | 'simulation/simulation-policies'
  | 'simulation/policy'
  | 'simulation/policies/policy-resource/policy-resource'
  | 'simulation/policies/policy-resource/policy-resource-properties'
  | 'simulation/policies/policy-resource/agent-a0-properties-title'
  | 'simulation/policies/policy-resource/agent-a1-properties-type'
  | 'simulation/policies/policy-resource/agent-a2-properties-amount'
  | 'simulation/policies/policy-resource/agent-a3-properties-manufacturer-model'
  | 'simulation/policies/policy-resource/agent-a4-properties-role'
  | 'simulation/policies/policy-resource/policy-resource-model-intro'
  | 'simulation/policies/policy-resource/policy-resource-model-motion'
  | 'simulation/policies/policy-resource/agent-c1-specs-travelling'
  | 'simulation/policies/policy-resource/agent-c1-specs-speed'
  | 'simulation/policies/policy-resource/agent-c2-specs-capacity'
  | 'simulation/policies/policy-resource/agent-c3-specs-energy'
  | 'simulation/policies/policy-resource/agent-c4-specs-other'
  | 'simulation/policies/policy-resource/policy-resource-model-time'
  | 'simulation/policies/policy-resource/agent-b1-timing-order-prehandling'
  | 'simulation/policies/policy-resource/agent-b2-timing-picking'
  | 'simulation/policies/policy-resource/agent-b3-timing-picking-reordering'
  | 'simulation/policies/policy-resource/agent-b4-timing-picking-posthandling'
  | 'simulation/policies/policy-resource/agent-b5-timing-order-posthandling'
  | 'simulation/policies/policy-resource/policy-resource-model-cost'
  | 'simulation/policies/policy-resource/time-and-motion'
  | 'simulation/policies/policy-routing/policy-routing'
  | 'simulation/policies/policy-storage/policy-storage'
  | 'simulation/policies/policy-storage/policy-storage-step-by-step-guide'
  | 'simulation/policies/policy-picking/policy-picking'
  | 'simulation/policies/policy-optimisation/policy-optimisation'
  | 'simulation/policies/policy-stacking/policy-stacking'
  | 'simulation/policies/filter-location-item'
  | 'simulation/policies/policy-import-rules'
  | 'simulation/analyse/simulation-analyse'
  | 'simulation/analyse/simulation-analyse-intro'
  | 'simulation/analyse/simulation-analyse-summary'
  | 'simulation/analyse/simulation-analyse-summary-abc'
  | 'simulation/analyse/simulation-analyse-summary-co2'
  | 'simulation/analyse/simulation-analyse-summary-compliance'
  | 'simulation/analyse/simulation-analyse-summary-orders'
  | 'simulation/analyse/simulation-analyse-summary-resources'
  | 'simulation/analyse/simulation-analyse-summary-totals'
  | 'simulation/analyse/simulation-analyse-abc'
  | 'simulation/analyse/simulation-analyse-heatmap'
  | 'simulation/analyse/simulation-analyse-congestion'
  | 'simulation/analyse/simulation-analyse-visits'
  | 'simulation/analyse/simulation-analyse-orders'
  | 'simulation/analyse/simulation-analyse-co2'
  | 'simulation/analyse/simulation-analyse-resources'
  | 'simulation/analyse/simulation-analyse-capacity-occupancy'
  | 'simulation/workforce/simulation-workforce'
  | 'simulation/compliance/simulation-compliance'
  | 'simulation/compliance/simulation-compliance-weight'
  | 'simulation/compliance/simulation-compliance-size'
  | 'simulation/compliance/simulation-compliance-volume'
  | 'simulation/compliance/simulation-compliance-policy-storage'
  | 'simulation/optimise/simulation-optimise'
  | 'simulation/optimise/simulation-optimise-policy'
  | 'simulation/optimise/simulation-optimise-policy-workload'
  | 'simulation/optimise/simulation-optimise-policy-swap'
  | 'simulation/optimise/simulation-optimise-setup'
  | 'simulation/optimise/simulation-optimise-plan'
  | 'simulation/optimise/simulation-optimise-implement'
  | 'simulation/optimise/simulation-optimise-reassign'
  | 'simulation/optimise/simulation-optimise-results'
  | 'simulation/optimise/simulation-optimise-summary'
  | 'simulation/optimise/simulation-optimise-summary-events'
  | 'simulation/optimise/simulation-optimise-summary-diff'
  | 'simulation/optimise/simulation-optimise-summary-abc'
  | 'simulation/optimise/simulation-optimise-summary-orders'
  | 'simulation/optimise/simulation-optimise-summary-workload'
  | 'simulation/simulation-results'
  | 'legal/legal'
  | 'legal/privacy-policy'
  | 'legal/cookie-policy'
  | 'legal/terms-policy'
  | 'legal/policy-privacy'
  | 'legal/policy-cookie'
  | 'legal/policy-terms'
  | 'legal/policy-dpa'
  | 'legal/policy-eula'
  | 'legal/policy-eusa'
  | 'legal/policy-usage'
  | 'legal/agreements'
  | 'legal/agreement-master-service'
  | 'legal/agreement-professional-services'
  | 'legal/agreement-subscription'
  | 'legal/agreement-trial'
  | 'legal/contact'
  | 'legal/subprocessors'
  | 'legal/disclaimer'
  | 'legal/changes'
  | 'legal/compliance'
  | 'legal/';
