import {
  AssignmentFragment,
  AssignmentItemDataFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { atom, selectorFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { AsyncLoadStatus } from '../../common/types';
import { AssignmentDataState, AssignmentMenuItemId } from './assignment.types';

const getKey = (postfix: string) => `warebee-assignment-${postfix}`;

export const assignmentDocumentId = atom<string>({
  key: getKey('document-id'),
  default: null,
});

export const assignmentDocument = atom<AssignmentFragment>({
  key: getKey('document'),
  default: null,
});

export const assignmentDocumentLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('document-load-state'),
  default: AsyncLoadStatus.None,
});

export const assignmentDocumentSaveStatus = atom<AsyncLoadStatus>({
  key: getKey('document-save-state'),
  default: AsyncLoadStatus.None,
});

export const assignmentItemsData = atom<AssignmentItemDataFragment[]>({
  key: getKey('items-data'),
  default: null,
});

export const assignmentItemsDataStatus = atom<AsyncLoadStatus>({
  key: getKey('items-data-status'),
  default: AsyncLoadStatus.None,
});

export const assignmentItemsDataState = atom<AssignmentDataState>({
  key: getKey('items-data-state'),
  default: { totalCount: 0, searchValues: {} },
});

const assignmentSelectedMenuItemIdAll = atom<
  Record<string, AssignmentMenuItemId>
>({
  key: getKey('selected-menu-item-id-all'),
  default: {},
  effects: [
    recoilPersist({
      key: getKey('selected-menu-item-id-all'),
      storage: localStorage,
    }).persistAtom,
  ],
});

export const assignmentSelectedMenuItemId = selectorFamily<
  AssignmentMenuItemId,
  string
>({
  key: getKey('selected-menu-item-id'),
  get:
    (assignmentId: string) =>
    ({ get }) => {
      return (
        get(assignmentSelectedMenuItemIdAll)?.[assignmentId] ||
        'assignment-menu-start'
      );
    },

  set:
    (assignmentId: string) =>
    ({ get, set }, value: AssignmentMenuItemId) => {
      set(assignmentSelectedMenuItemIdAll, {
        ...get(assignmentSelectedMenuItemIdAll),
        [assignmentId]: value,
      });
    },
});
