import {
  AllocationRunResultRequirementsFilter,
  LoadAllocationSummaryDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AllocationSummaryConverterConfig,
  AllocationSummaryExportJobParams,
  allocationSummaryExportJobParams,
  getAllocationSummaryTableRows,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import { cn } from '../../../common/utils';
import DatasetTable from '../../../components/DatasetTable';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../../../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import { Container } from '../../../components/layout/ContainerFlex';
import { Spacer } from '../../../components/layout/Spacer';
import { ActionBar } from '../../../components/nav/ActionBar';
import { analyzeProductMetricSelected } from '../../../metrics/analyzeProduct/analyzeProductMetric.state';
import {
  getAllocationMetricDescriptors,
  getAllocationSummaryGroutByOptions,
} from '../../store/allocation/allocation.helper';
import {
  allocationSummaryData,
  allocationSummaryDataLoadStatus,
  allocationSummaryGroupBy,
  allocationSummarySelectedMetric,
  allocationSummaryTableState,
} from '../../store/allocation/allocation.state';
import { simulationCurrentId } from '../../store/simulation.state';
import useAllocationSummaryTableConfig from './useAllocationSummaryTableConfig';
import useLoadAllocationSummary from './useLoadAllocationSummary';

/**
 *
 *
 * @return {*}
 */
const AllocationSummaryView: React.FC = () => {
  const { t } = useTranslation('simulation');
  const simulationId = useRecoilValue(simulationCurrentId);
  const summaryData = useRecoilValue(allocationSummaryData);
  const [loadCallback, cancelLoad] = useLoadAllocationSummary();
  const loadStatus = useRecoilValue(allocationSummaryDataLoadStatus);
  const [state, setState] = useRecoilState(allocationSummaryTableState);
  const [runExportCSV] = useRunExportJobMutation();
  const [selectedGroupBy, setSelectedGroupBy] = useRecoilState(
    allocationSummaryGroupBy,
  );
  const [selectedMetric, setSelectedMetric] = useRecoilState(
    allocationSummarySelectedMetric,
  );
  const setHeatmapMetric = useSetRecoilState(analyzeProductMetricSelected);

  const columnsConfig = useAllocationSummaryTableConfig({
    key: selectedGroupBy,
  });
  const { searchValues, sortValues } = state;

  const groupByOptions = getAllocationSummaryGroutByOptions(t);
  const metricDescriptors = getAllocationMetricDescriptors(t);
  const metricDescriptorsArray = _.values(metricDescriptors);
  const selectedGroupByDescriptor = groupByOptions[selectedGroupBy];
  const metricDescriptor = metricDescriptors[selectedMetric];

  function getFilter(): AllocationRunResultRequirementsFilter {
    return {
      consigneeContains: searchValues?.consignee,
      skuContains: searchValues?.sku,
      skuGroupContains: searchValues?.skuGroup,
    };
  }

  useEffect(() => {
    setHeatmapMetric(selectedGroupByDescriptor.heatmapMetric);
    cancelLoad();

    loadCallback({
      simulationId,
      groupBy: selectedGroupByDescriptor.groupBy,
      filter: getFilter(),
    });
  }, [selectedGroupBy, searchValues]);

  const converterConfig: AllocationSummaryConverterConfig = {
    columnsConfig,
  };

  async function startExportCSV() {
    const variables: AllocationSummaryExportJobParams = {
      ...allocationSummaryExportJobParams,
      query: LoadAllocationSummaryDocument.loc.source.body,
      config: converterConfig,
      variables: {
        simulationId,
        groupBy: selectedGroupByDescriptor.groupBy,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const flattenData = getAllocationSummaryTableRows(
    summaryData,
    converterConfig,
  );

  const isLoading = loadStatus === AsyncLoadStatus.Loading;

  const metricSwitchOptions: ButtonSwitchMultiOption[] = _.map(
    metricDescriptorsArray,
    d => ({ label: d.title }),
  );

  const selectedMetricIndex = _.findIndex(
    metricDescriptorsArray,
    o => o.type === selectedMetric,
  );

  const filtered = _.isEmpty(metricDescriptor.columns)
    ? flattenData
    : _.filter(flattenData, row =>
        _.every(
          metricDescriptor.columns,
          c => _.isNumber(row[c]) && (row[c] as number) > 0,
        ),
      );

  const sorted = _.orderBy(
    filtered,
    _.keys(sortValues),
    _.values(sortValues).map(v => v.toLowerCase()) as any,
  );

  const itemsCount = sorted?.length ?? 0;
  const totalCount = sorted?.length ?? 0;

  const chartData = _(sorted).take(200).value();

  return (
    <>
      <ActionBar className={cn('flex flex-col 2xl:flex-row', 'px-1 py-2')}>
        <ButtonSwitchMulti
          buttonType="minimal"
          className={cn('w-full flex-1')}
          autoSize
          options={metricSwitchOptions}
          selectedIndex={selectedMetricIndex}
          onClick={index =>
            setSelectedMetric(metricDescriptorsArray[index].type)
          }
        />
        <Spacer flexspace />
        <DropdownSelector
          // label={t`Group By`}
          panelMode
          className={cn('mx-0 mt-2 2xl:mx-2 2xl:mt-0', 'w-full', 'text-xs')}
          value={selectedGroupBy}
          DropAlignRight
          widthFull
          values={_.map(groupByOptions, o => o.type)}
          onChange={v => {
            setSelectedGroupBy(v);
            setState(current => ({ ...current, searchValues: {} }));
          }}
          renderValue={o => groupByOptions[o].title}
        />
      </ActionBar>

      {/* <div className="h-full overflow-hidden">
        <AllocationSummaryChart
          data={chartData}
          selectedGroup={selectedGroupByDescriptor}
          selectedMetric={metricDescriptor}
          isLoading={isLoading}
        />
      </div> */}

      <Container fullHeight col>
        <DatasetTable
          id={'allocation-summary-table'}
          data={sorted}
          columnsConfig={columnsConfig}
          keyFields={selectedGroupByDescriptor.keyColumns}
          isActionable
          hideScreenTitle={true}
          isSticky
          onLoadNext={_.noop}
          onSearch={searchValues => setState({ ...state, searchValues })}
          totalCount={totalCount}
          searchValues={searchValues}
          sortBy={sortValues}
          onSort={sortValues => setState({ ...state, sortValues })}
          isLoading={isLoading}
          onStartExportClick={startExportCSV}
          hasCounter
        />
      </Container>
    </>
  );
};

export default AllocationSummaryView;
