import { LayoutImportConverterLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import { LocationTypeSettings } from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { formatInteger } from '../../../../common/formatHelper';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../../common/measureHelper';
import {
  HelperMessage,
  HelperMessageAction,
} from '../../../../components/HelperMessage';
import { Button } from '../../../../components/actions/Button';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import InputCheckbox from '../../../../components/inputs/InputCheckbox';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import InputNumber from '../../../../components/inputs/InputNumber';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { InputSearch } from '../../../../components/inputs/InputSearch';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import { Spacer } from '../../../../components/layout/Spacer';
import TitleSection from '../../../../components/layout/TitleSection';
import { ActionBar } from '../../../../components/nav/ActionBar';
import { Stat } from '../../../../components/stats/Stat';
import { StatGroup } from '../../../../components/stats/StatGroup';
import HelpContainer from '../../../../helpContext/HelpContainer';
import { warehouseSelected } from '../../../../store/warehouse.state';
import { getLocationDefaultConfig } from '../store/converter.defaults';
import { applyLocationSettings } from '../store/converter.helper';
import { converterHighlightedRackTypes } from '../store/converter.layout.state';
import {
  converterBayTypesBuilder,
  converterLayoutData,
  converterLayoutDataInitial,
  converterLocationPatches,
  converterLocationTypesBuilder,
} from '../store/converter.state';

type NumericLocationTypeFields = Pick<
  LocationTypeSettings,
  'gapDepth' | 'gapHeight' | 'gapWidth' | 'minDepth'
>;

const StepDefaultSizes: React.FC = () => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('designer');

  const wh = useRecoilValue(warehouseSelected);
  const layoutData = useRecoilValue(converterLayoutData);
  const locationTypesInitial = useRecoilValue(converterLocationTypesBuilder);
  const bayTypesInitial = useRecoilValue(converterBayTypesBuilder);
  const [locationTypes, setLocationTypes] = useState(locationTypesInitial);
  const [bayTypes, setBayTypes] = useState(bayTypesInitial);
  const [selectedRackTypes, setSelectedRackTypes] = useRecoilState(
    converterHighlightedRackTypes,
  );
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    setLocationTypes(locationTypesInitial);
  }, [locationTypesInitial]);

  useEffect(() => {
    setBayTypes(bayTypesInitial);
  }, [bayTypesInitial]);

  const saveChanges = useRecoilCallback(({ snapshot, set }) => async () => {
    const layoutData = await snapshot.getPromise(converterLayoutDataInitial);
    const patches = await snapshot.getPromise(converterLocationPatches);

    const data = applyLocationSettings({
      data: layoutData,
      locationPatches: patches,
      locationTypeMap: locationTypes,
      locationTypeDefault: getLocationDefaultConfig(wh.measurementSystem),
      bayModelMap: bayTypes,
    });

    // Set Data
    set(converterLocationTypesBuilder, locationTypes);
    set(converterBayTypesBuilder, bayTypes);
    set(converterLayoutData, data);
    setLoading(false);
  });

  if (!layoutData) return null;

  const locs: LayoutImportConverterLocationFragment[] = _.flattenDeep(
    layoutData.areas.map(area =>
      area.aisles.map(aisle =>
        aisle.sides.map(s => s.bays.map(b => b.locations)),
      ),
    ),
  );
  const locByRackType = _.groupBy(locs, loc => loc.locationRackingType);

  function toggleSelectionRackType(typeId: string) {
    const isSelected = _.includes(selectedRackTypes, typeId);
    setSelectedRackTypes(
      isSelected
        ? selectedRackTypes.filter(rt => rt !== typeId)
        : [...selectedRackTypes, typeId],
    );
  }

  function changeLocationSetting(
    value: number,
    typeId: string,
    field: keyof NumericLocationTypeFields,
  ) {
    setLocationTypes({
      ...locationTypes,
      [typeId]: {
        ...locationTypes[typeId],
        [field]: value,
      },
    });
  }

  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'size'),
    tMeasures,
  );

  const drawLocationTypesSettings = (settings: LocationTypeSettings) => {
    const isSelected = _.includes(selectedRackTypes, settings.typeId);
    return (
      <PanelBody key={`location-type-${settings.typeId}`}>
        <TitleSection
          id={`location-stat-${settings.typeId}`}
          title={t`Location Type`}
          titleSummaryClosed={
            <span className="text-end text-sm p-1 px-2 rounded border border-menu-400">
              {settings.typeId}
            </span>
          }
          titleSummaryOpen={
            <span className="text-end text-sm p-1 px-2 rounded border border-menu-active/30 text-menu-active">
              {settings.typeId}
            </span>
          }
          inPanelView
          collapsible
        >
          <StatGroup className="pt-2 pb-4 px-1">
            <Stat
              titleElement={
                <div>
                  <span>{settings.typeId}</span>
                  <span className="opacity-50 mx-1">{t`Locations:`}</span>
                </div>
              }
              value={formatInteger(locByRackType[settings.typeId]?.length)}
              isFullWidth
              hasHelper
              // isActionable
              isPreview
              isSelected={isSelected}
              onClick={() => toggleSelectionRackType(settings.typeId)}
              className="shadow-xl"
            >
              <InputGroupList
                hasSpacing
                // className="divide-y divide-menu-400/50"
              >
                <InputNumber
                  key={`location-gap-width-${settings.typeId}`}
                  title={t`Gap between`}
                  value={settings.gapWidth}
                  unitOfMeasure={sizeMeasureValue}
                  onChange={v =>
                    changeLocationSetting(v, settings.typeId, 'gapWidth')
                  }
                  debounceInterval={100}
                  range={[0, null]}
                />
                <InputNumber
                  key={`location-gap-height-${settings.typeId}`}
                  title={t`Gap above`}
                  // titleAlt={t`Gap to the rack beam above`}
                  value={settings.gapHeight}
                  unitOfMeasure={sizeMeasureValue}
                  onChange={v =>
                    changeLocationSetting(v, settings.typeId, 'gapHeight')
                  }
                  debounceInterval={100}
                  range={[0, null]}
                />
                <InputNumber
                  key={`location-gap-depth-${settings.typeId}`}
                  title={t`Gap behind`}
                  value={settings.gapDepth}
                  unitOfMeasure={sizeMeasureValue}
                  onChange={v =>
                    changeLocationSetting(v, settings.typeId, 'gapDepth')
                  }
                  debounceInterval={100}
                  range={[0, null]}
                />
                <InputCheckbox
                  className="py-1 whitespace-normal"
                  key={`location-flex-depth-${settings.typeId}`}
                  isSelected={settings.isWeakDepth}
                  label={t`Flex depth`}
                  onChange={v =>
                    setLocationTypes({
                      ...locationTypes,
                      [settings.typeId]: {
                        ...locationTypes[settings.typeId],
                        isWeakDepth: v,
                      },
                    })
                  }
                >
                  {t`Align to the narrowest location`}
                </InputCheckbox>
                <InputNumber
                  key={`location-flex-min-depth-${settings.typeId}`}
                  title={t`Min flex depth`}
                  value={settings.minDepth ?? 1}
                  unitOfMeasure={sizeMeasureValue}
                  disabled={!settings.isWeakDepth}
                  onChange={v =>
                    changeLocationSetting(v, settings.typeId, 'minDepth')
                  }
                  debounceInterval={100}
                  range={[1, null]}
                />
              </InputGroupList>
              <ActionBar className="flex">
                <Spacer flexspace />
                <Button
                  className="mt-6 mb-2 rounded"
                  label={t`Apply`}
                  buttonType="primary"
                  buttonSize="xs"
                  full
                  onPress={() => {
                    setLoading(true);
                    setTimeout(saveChanges, 1);
                  }}
                  isLoading={loading}
                  isDisabled={loading}
                />
              </ActionBar>
            </Stat>
          </StatGroup>
        </TitleSection>
      </PanelBody>
    );
  };

  const searchExp = new RegExp(search, 'i');
  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        subtitle={t`Setup Locations`}
        title={t`Location Profiles`}
      />
      <InputQA>
        <InputQuestion
          icon={Icon.DesignerLocationsProfile}
          question={t`Location defaults`}
        />
        <InputAnswerGroup>
          <HelpContainer
            id={'designer/03-setup-locations-profiles'}
            hasPadding
          />
          <HelperMessage hasBefore iconAction={Icon.ArrowRightBottom}>
            {t`Click on`}
            <HelperMessageAction>{t`Update Sizes`}</HelperMessageAction>
            {t`to update locations.`}
          </HelperMessage>
        </InputAnswerGroup>
      </InputQA>
      <TitleSection
        className="mt-4"
        titleView
        title={t`Location Sizes (by type)`}
        hasAction={
          <InputSearch
            placeholder={t`Search`}
            headerTableMode
            value={search}
            onChange={setSearch}
          />
        }
      />
      <InputQA>
        {_.values(locationTypes)
          .filter(
            lt =>
              (_.isEmpty(search) || !_.isEmpty(lt.typeId.match(searchExp))) && // match by filter
              !_.isNil(locByRackType[lt.typeId]), //has locations of current type
          )
          .map(drawLocationTypesSettings)}
      </InputQA>
    </PanelBody>
  );
};
export default StepDefaultSizes;
