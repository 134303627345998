import { useApolloClient } from '@apollo/client';
import {
  UpdateCurrentUserSettingsDocument,
  UpdateCurrentUserSettingsMutation,
  UpdateCurrentUserSettingsMutationVariables,
  UpdateUserSettingsInput,
} from '@warebee/frontend/data-access-api-graphql';
import { FetchResult } from 'apollo-link';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { analyticsAgent } from '../../AnalyticTracker';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
  userSettings,
  userSettingsLoadStatus,
} from '../../store/warehouse.state';

function useUpdateUserSettings() {
  const client = useApolloClient();
  const { t } = useTranslation('app');
  const errorUpdate = t`Can not update user profile`;

  const init = useRecoilCallback(({ snapshot, set }) => async () => {
    set(userSettingsLoadStatus, AsyncLoadStatus.Loading);
  });

  const updateUserSettings = useRecoilCallback(
    ({ snapshot, set }) =>
      async (settings: UpdateUserSettingsInput) => {
        let response: FetchResult<UpdateCurrentUserSettingsMutation>;
        try {
          response = await client.mutate<
            UpdateCurrentUserSettingsMutation,
            UpdateCurrentUserSettingsMutationVariables
          >({
            mutation: UpdateCurrentUserSettingsDocument,
            variables: {
              input: settings,
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: errorUpdate,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          set(userSettingsLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: errorUpdate,
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          set(userSettingsLoadStatus, AsyncLoadStatus.Error);
          return;
        }
        set(userSettingsLoadStatus, AsyncLoadStatus.Ok);
        set(userSettings, response.data.updateCurrentUserSettings);

        analyticsAgent?.track('User setting: Changed', {
          changes: settings,
        });
      },
  );

  return async (settings: UpdateUserSettingsInput) => {
    await init();
    await updateUserSettings(settings);
  };
}

export default useUpdateUserSettings;
