import {
  AssignmentDiffItemsFilter,
  AssignmentDiffItemsSortBy,
  AssignmentDiffItemsSortOption,
  AssignmentItemDataFragment,
  LoadAssignmentDataDocument,
  LoadOptimizationDiffDocument,
  SortDirection,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AssignmentConverterConfig,
  AssignmentDiffConverterConfig,
  AssignmentDiffDataRow,
  AssignmentDiffExportJobParams,
  AssignmentExportJobParams,
  assignmentDiffExportJobParams,
  assignmentExportJobParams,
  getAssignmentDiffTableRows,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import DatasetTable, { ColumnConfig } from '../../../components/DatasetTable';
import * as Icon from '../../../components/icons';
import useLoadAssignmentDiff from '../../hooks/useLoadAssignmentDiff';
import {
  getProductCategories,
  getProductCategoryDescriptorExport,
  getProductCategoryFull,
} from '../../store/abc/simulation.ABC.helper';
import {
  optimisationAssignmentDiff,
  optimisationAssignmentDiffLoadStatus,
  optimisationAssignmentDiffState,
  optimisationResult,
  optimisationSelectedAssignmentDiff,
} from '../../store/optimisation.state';
import ItemTag from '../../tags/ItemTag';

const sortFieldsMapping: Partial<
  Record<OptimizationAssignmentDiffTableColumn, AssignmentDiffItemsSortOption>
> = {
  sku: AssignmentDiffItemsSortOption.SKU,
  consignee: AssignmentDiffItemsSortOption.CONSIGNEE,
  category: AssignmentDiffItemsSortOption.HIT_COUNT,
};

export type OptimizationAssignmentDiffTableColumn = keyof AssignmentDiffDataRow;

const OptimizationAssignmentDiffTable: React.FC = () => {
  const { t } = useTranslation('simulation');

  const optimisation = useRecoilValue(optimisationResult);
  const assignmentDiff = useRecoilValue(optimisationAssignmentDiff);
  const loadStatus = useRecoilValue(optimisationAssignmentDiffLoadStatus);
  const [assignmentDiffState, setAssignmentDiffState] = useRecoilState(
    optimisationAssignmentDiffState,
  );
  const [selectedDiff, setSelectedDiff] = useRecoilState(
    optimisationSelectedAssignmentDiff,
  );
  const [loadCallback, cancelLoad] = useLoadAssignmentDiff();
  const [runExportCSV] = useRunExportJobMutation();

  const { searchValues, sortValues } = assignmentDiffState;

  const productCategories = getProductCategories(t);

  function callDataLoad(
    searchValues: Partial<
      Record<OptimizationAssignmentDiffTableColumn, string>
    >,
    sortValues: Partial<
      Record<OptimizationAssignmentDiffTableColumn, SortDirection>
    >,
    page = { isAppend: false, skip: 0 },
  ) {
    if (!optimisation) return;
    const filter: AssignmentDiffItemsFilter = {
      productConsigneeContains: searchValues.consignee,
      productSkuContains: searchValues.sku,
      srcLocationIdContains: searchValues.srcLocations,
      destLocationIdContains: searchValues.destLocations,
    };

    const sortBy: AssignmentDiffItemsSortBy[] = _.map(
      sortValues,
      (value, key, index) => {
        const sortObjectKey = sortFieldsMapping[key];
        return sortObjectKey
          ? { field: sortObjectKey, direction: value }
          : null;
      },
    ).filter(f => !_.isNil(f?.direction));

    cancelLoad();
    loadCallback({
      optimisationId: optimisation.id,
      filter,
      sortBy: _.takeRight(sortBy, 1),
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(searchValues, sortValues);
  }, [optimisation, searchValues, sortValues]);

  const columnsConfig: ColumnConfig<AssignmentDiffDataRow>[] = [
    {
      field: 'data',
      title: t`data`,
      isHidden: true,
    },
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
      hasSort: true,
      hasFilter: true,
      isHeader: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      hasFilter: true,
      hasSort: true,
      isHeader: true,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
    {
      field: 'categorySting',
      title: t`Category`,
      hiddenInBrowser: true,
    },
    {
      field: 'category',
      title: t`Cumulative Percent Rank`,
      hiddenInBrowser: true,
    },
    {
      field: 'category',
      title: t`Category`,
      hiddenInExport: true,
      hasFilter: false,
      hasSort: true,
      render: (value: number) => {
        const cat = getProductCategoryFull(value, productCategories);
        return (
          <p
            title={
              cat.title +
              ` (${cat.fromCmlPercentile}) ` +
              `–> (${cat.toCmlPercentile})`
            }
            className={`flex items-center rounded-md px-1 py-0.5 `}
            style={{
              color: cat.textColor,
              backgroundColor: cat.color,
            }}
          >
            {cat.tag}
          </p>
        );
      },
    },
    {
      field: 'srcLocations',
      title: t`Source`,
      hasFilter: true,
      render: (value, row) => (
        <>
          {_.split(value as string, ',').map(location => (
            <span
              key={`src-location-tag-${location}`}
              className={`text-xxs border-location-srcLocations bg-app-panel-dark/60 my-0.5 rounded border p-1 px-2 font-bold text-white ltr:mr-1 rtl:ml-1`}
            >
              {location}
            </span>
          ))}
        </>
      ),
    },

    {
      field: 'spacer',
      title: t``,
      hiddenInExport: true,
      render: (value, row) => (
        <Icon.ChevronTripleRight
          className={`h-5 w-5 fill-current ${
            row.consignee === selectedDiff?.product.consignee &&
            row.sku === selectedDiff?.product.sku
              ? 'text-menu-text'
              : 'text-menu-active any-hover:group-hover:text-menu-text'
          } `}
        />
      ),
    },
    {
      field: 'destLocations',
      title: t`Destination`,
      hasFilter: true,
      render: (value, row) => (
        <>
          {_.split(value as string, ',').map(location => (
            <span
              key={`dest-location-tag-${location}`}
              className={`text-xxs border-location-destLocations bg-app-panel-dark/60 my-0.5 rounded border p-1 px-2 font-bold text-white ltr:mr-1 rtl:ml-1`}
            >
              {location}
            </span>
          ))}
        </>
      ),
    },
  ];

  function onLoadNext() {
    callDataLoad(searchValues, sortValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  function onRowSelect(row: AssignmentDiffDataRow, index: number) {
    setSelectedDiff(row?.data);
  }

  const converterConfig: AssignmentDiffConverterConfig = {
    columnsConfig,
    dictionaries: {
      categories: getProductCategoryDescriptorExport(productCategories),
    },
  };

  async function startExportCSV() {
    const variables: AssignmentDiffExportJobParams = {
      ...assignmentDiffExportJobParams,
      query: LoadOptimizationDiffDocument.loc.source.body,
      config: converterConfig,
      variables: {
        optimisationRunId: optimisation?.id,
        page: null,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const isLoading =
    loadStatus === AsyncLoadStatus.Loading ||
    loadStatus === AsyncLoadStatus.None;
  const itemsCount = assignmentDiff?.content?.length || 0;
  const totalCount = assignmentDiff?.totalCount ?? 0;

  const flattenData = getAssignmentDiffTableRows(
    assignmentDiff?.content,
    converterConfig,
  );

  const columnsConfigAssignment: ColumnConfig<AssignmentItemDataFragment>[] = [
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
      hasFilter: true,
      isHeader: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      hasFilter: true,
      isHeader: true,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
    {
      field: 'locationId',
      title: t`Location ID`,
      hasFilter: true,
    },
  ];

  const converterAssignmentConfig: AssignmentConverterConfig = {
    columnsConfig: columnsConfigAssignment,
  };

  async function startExportAssignmentCSV() {
    const variables: AssignmentExportJobParams = {
      ...assignmentExportJobParams,
      query: LoadAssignmentDataDocument.loc.source.body,
      config: converterAssignmentConfig,
      variables: {
        assignmentId: optimisation?.resultAssignment?.id,
        page: null,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });
    //
    return {
      errors: [],
      job: data.createExportJob,
    };
  }

  return (
    <DatasetTable
      id={'assignment-diff-table'}
      hideScreenTitle
      isSticky
      isActionable
      columnsConfig={columnsConfig}
      keyFields={['consignee', 'sku']}
      data={flattenData}
      onLoadNext={onLoadNext}
      onSearch={searchValues =>
        setAssignmentDiffState({ ...assignmentDiffState, searchValues })
      }
      sortBy={sortValues}
      onSort={sortValues =>
        setAssignmentDiffState({ ...assignmentDiffState, sortValues })
      }
      totalCount={totalCount}
      searchValues={searchValues}
      isLoading={isLoading}
      onRowSelect={onRowSelect}
      onStartExportClick={startExportCSV}
      onStartExtraExportClick={startExportAssignmentCSV}
      hasCounter
    />
  );
};

export default OptimizationAssignmentDiffTable;
