import {
  ImportJobImportType,
  useRemoveDatasetObjectMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import * as Icon from '../../components/icons';
import DataDashboardCard from '../../dashboard/sections/DataDashboardCard';
import { getDataObjectTypeOptions } from '../../import/store/import.default';
import { warehouseSelectedId } from '../../store/warehouse.state';
import DashboardItemsGroupContainer from '../DashboardItemsGroupContainer';
import DashboardItemContainer, {
  DashboardItemContainerProps,
} from '../containers/DashboardItemContainer';
import DashboardListItemContainer from '../containers/DashboardListItemContainer';
import useLoadDatasetObjectDashboard from '../hooks/useLoadDatasetObjectDashboard';
import {
  dashboardDatasetObjectData,
  dashboardDatasetObjectLoadState,
  dashboardDatasetObjectState,
} from '../store/dashboard.state';
import {
  DashboardItemStatusType,
  DashboardOrder,
  DashboardViewMode,
} from '../store/dashboard.types';

const DataDashboardDatasetObject: React.FC = () => {
  const { t } = useTranslation('app');
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const [removeDatasetObject] = useRemoveDatasetObjectMutation();
  const dashData = useRecoilValue(dashboardDatasetObjectData);
  const [dashState, setDashState] = useRecoilState(dashboardDatasetObjectState);
  const loadingState = useRecoilValue(dashboardDatasetObjectLoadState);
  const loadDashboardData = useLoadDatasetObjectDashboard();

  const type = ImportJobImportType.ACTIVITY_FEED;
  useEffect(() => {
    loadDashboardData({
      sort: dashState.order,
      type,
    });
  }, [warehouseId]);

  function changeViewMode(viewMode: DashboardViewMode) {
    setDashState({
      ...dashState,
      viewMode,
    });
  }

  function changeSort(sortMode: DashboardOrder) {
    setDashState({
      ...dashState,
      order: sortMode,
    });
    loadDashboardData({
      sort: sortMode,
      limit: dashState.loadedCount,
      type,
    });
  }

  function loadMore() {
    loadDashboardData({
      sort: dashState.order,
      skip: dashState.loadedCount,
      isAppend: true,
      type,
    });
  }

  const getDatasetObjectsCards = () => {
    if (_.isEmpty(dashData)) return null;
    return _.sortBy(dashData).map(item => {
      const { id, title, description, summary } = item;

      const getTranslatedTitle = (objectType: string): string => {
        const options = getDataObjectTypeOptions(t);
        const matchedOption = options.find(option => option.id === objectType);
        return matchedOption ? matchedOption.title : objectType;
      };
      const translatedTitle = getTranslatedTitle(item.objectType);

      const path = `/wh/i/${warehouseId}/dataset/${id}`;

      const status = {
        type: DashboardItemStatusType.data,
        title: translatedTitle,
      };

      const containerProps: DashboardItemContainerProps = {
        id,
        title,
        description,
        createdDate: new Date(item.createdAt),
        updatedDate: new Date(item.updatedAt),
        path,
        status: status,
        canDelete: true,
        onDeleteClick: async () => {
          await removeDatasetObject({
            variables: { id: id },
          });
          loadDashboardData({ sort: dashState.order, type });
        },
      };

      return dashState.viewMode === DashboardViewMode.card ? (
        <DashboardItemContainer
          icon={Icon.Simulation}
          key={`card-dataset-object-${id}`}
          {...containerProps}
        >
          <DataDashboardCard
            summary={item?.summary?.totalCount ?? 0}
            isActionable
          />
        </DashboardItemContainer>
      ) : (
        <DashboardListItemContainer
          key={`item-assignment-${id}`}
          {...containerProps}
        />
      );
    });
  };

  return (
    <DashboardItemsGroupContainer
      id={'dashboard-data-dataset-objects'}
      title={t`Datasets`}
      state={dashState}
      isLoading={loadingState === AsyncLoadStatus.Loading}
      // collapsible
      onSortChanged={changeSort}
      onViewModeChanged={changeViewMode}
      onLoadMoreClick={loadMore}
      datasetPath="feed"
    >
      {getDatasetObjectsCards()}
    </DashboardItemsGroupContainer>
  );
};

export default DataDashboardDatasetObject;
