import { BatchJobStatus } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import AllocationRequirementTable from '../../allocation/AllocationRequirementTable';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { PanelHeader } from '../../components/designer/panels/PanelHeader';
import { Container } from '../../components/layout/ContainerFlex';
import { Spacer } from '../../components/layout/Spacer';
import LayoutActiveFeatureTag from '../../layout/LayoutActiveFeatureTag';
import SimulationComplianceLayoutView from '../SimulationComplianceLayoutView';
import useLoadAllocationRequirementsMeta from '../hooks/useLoadAllocationRequirementsMeta';
import SimulationDataIssuesLocationLayer from '../layers/SimulationDataIssuesLocationLayer';
import {
  allocationRequirement,
  allocationRequirementShowTable,
} from '../store/allocation/allocationRequirement.state';
import { pickingPolicyIsReadyToAnalyze } from '../store/pickingPolicy/pickingPolicy.state';

const SimulationAllocationView: React.FC = () => {
  const { t } = useTranslation('simulation');
  const requirement = useRecoilValue(allocationRequirement);
  const [showTable, setShowTable] = useRecoilState(
    allocationRequirementShowTable,
  );
  const pickingPolicyStatus = useRecoilValue(pickingPolicyIsReadyToAnalyze);
  const [runJob, cancelJob] = useLoadAllocationRequirementsMeta();

  useEffect(() => {
    if (_.isNil(requirement) && pickingPolicyStatus.isAnalyzeReady) {
      cancelJob();
      runJob();
    }
  }, [requirement]);

  const viewAs = [
    {
      key: 'table',
      label: t`Table`,
    },
    {
      key: 'layout',
      label: t`Layout`,
    },
  ];
  const isReady = requirement?.status === BatchJobStatus.READY;
  const hasError = requirement?.status === BatchJobStatus.FAILED;
  return (
    <Container
      col
      overflow
      componentName={`SimulationAllocationViewContainer`}
      className={classNames(
        'flex-1 ltr:border-l rtl:border-r border-app-panel-dark',
      )}
    >
      <Container overflow className={classNames('relative !h-auto')}>
        <PanelHeader
          title={t`Allocation requirement`}
          className={classNames('flex-1')}
        >
          <Spacer flexspace />
          <LayoutActiveFeatureTag />
          <ButtonSwitchMulti
            autoSize
            className="mx-3"
            classNameLabel="text-xs py-1 px-2"
            buttonType="header"
            options={viewAs}
            selectedIndex={0}
            onClick={index => setShowTable(index === 0)}
            disabled={true}
          />
        </PanelHeader>
      </Container>

      {!isReady && !hasError && <LoadingIndicator selfCenter />}
      {hasError && <ErrorIndicator message="" selfCenter />}
      {isReady && showTable && <AllocationRequirementTable />}
      {isReady && !showTable && (
        <Container
          overflow
          flex1
          className={classNames(
            'relative',
            'border-8 border-app-background/70',
          )}
        >
          <Container
            col
            overflow-hidden
            componentName={`SimulationLayoutViewContainer`}
            className={classNames('relative flex-1')}
          >
            <SimulationComplianceLayoutView
              getLocationLayer={() => <SimulationDataIssuesLocationLayer />}
            />
          </Container>
        </Container>
      )}
    </Container>
  );
};

export default SimulationAllocationView;
