import { Container } from '../../components/layout/ContainerFlex';
import _ from 'lodash';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import SimulationDatasetPanelLayout from '../panels/SimulationDatasetPanelLayout';
import { simulationCurrent } from '../store/simulation.state';
import ActionBarNextStep from './ActionBarNextStep';
import classNames from 'classnames';

const SimulationSidebarDatasetLayout: React.FC = () => {
  const { t } = useTranslation('simulation');
  const simulation = useRecoilValue(simulationCurrent);

  return (
    <>
      <Container col hasOverflowY hasExtraPadding>
        <Suspense
          fallback={
            <LoadingIndicator
              selfCenter
              message={t`Loading Orders Dataset...`}
            />
          }
        >
          <SimulationDatasetPanelLayout />
        </Suspense>
      </Container>
      <ActionBarNextStep isDisabled={_.isNil(simulation?.layout?.id)} />
    </>
  );
};
export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-dataset-layout',
    title: t`Dataset > Layout`,
    size: 'md',
  };
  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SimulationSidebarDatasetLayout />
    </SidebarContainer>
  );
};
