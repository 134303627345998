import { StringSearchFilterType } from '@warebee/frontend/data-access-api-graphql';
import { format } from 'date-fns';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { toLocalDateFromMs } from '../../common/dateTimeHelper';
import { formatInteger } from '../../common/formatHelper';
import { Formatter } from '../../common/useFormatter';
import * as Icon from '../../components/icons';
import {
  ActualityMetric,
  ActualityMetricDescriptor,
  ActualityMetricDescriptorBase,
} from './actualityMetric.types';

export const actualityMetricDescriptors: ActualityMetricDescriptorBase[] = [
  //  Time Based
  {
    type: 'lastActivity',
    path: 'lastEventTime',
    rangeType: 'distinct-range',
    sequentialPaletteId: 'velocity-analytics',
    aggregationFn: 'max',
  },

  {
    type: 'processType',
    path: 'eventProcessType',
    rangeType: 'distinct',
    sequentialPaletteId: 'heatmap-analytics',
    aggregationFn: 'min',
  },
  // Velocity
  {
    type: 'abc',
    path: 'skuKey',
    rangeType: 'distinct',
    aggregationFn: 'min',
  },
  {
    type: 'visits',
    path: 'locationId',
    rangeType: 'range-pow',
    sequentialPaletteId: 'heatmap-analytics',
    aggregationFn: 'count',
  },
  // {
  //   type: 'visitsCurrent',
  //   path: 'layoutLocationId',
  //   rangeType: 'range-pow',
  //   sequentialPaletteId: 'heatmap-analytics',
  //   aggregationFn: 'count',
  // },
  {
    type: 'visitsReplenishment',
    path: 'assignmentItem',
    rangeType: 'range-pow',
    sequentialPaletteId: 'heatmap-analytics',
    aggregationFn: 'count',
    filterBy: {
      eventProcessType: {
        type: StringSearchFilterType.CONTAINS,
        value: 'REPLENISHMENT',
      },
    },
  },
  // Location
  {
    type: 'locationRackingType',
    path: 'locationRackingType',
    rangeType: 'distinct',
    aggregationFn: 'min',
  },
  {
    type: 'locationUsageType',
    path: 'locationUsageType',
    rangeType: 'distinct',
    aggregationFn: 'min',
  },
  {
    type: 'locationOrder',
    path: 'locationOrder',
    rangeType: 'distinct-range',
    aggregationFn: 'min',
  },
  {
    type: 'congestionZone',
    path: 'congestionZone',
    rangeType: 'distinct',
    aggregationFn: 'min',
  },
  // Item
  {
    type: 'consignee',
    path: 'consignee',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'consigneeSku',
    path: 'skuKey',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'skuGroup',
    path: 'skuGroup',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'subGroup',
    path: 'subGroup',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'transportClass',
    path: 'transportClass',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'stockCategory',
    path: 'stockCategory',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'storageClass',
    path: 'storageClass',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'pollutionClass',
    path: 'pollutionClass',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'uom',
    path: 'uom',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'uomWeight',
    path: 'netWeight',
    rangeType: 'distinct-range',
    aggregationFn: 'min',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'uomWeightPow',
    path: 'netWeight',
    rangeType: 'distinct',
    aggregationFn: 'min',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'uomVolume',
    path: 'volume',
    rangeType: 'distinct-range',
    aggregationFn: 'min',
    sequentialPaletteId: 'elevation-sort',
  },
  // {
  //   type: 'uomVolumePow',
  //   path: 'volume',
  //   rangeType: 'range-pow',
  //   aggregationFn: 'min',
  //   sequentialPaletteId: 'elevation-sort',
  // },
  // {
  //   type: 'uomCapacity',
  //   path: 'uomCapacity',
  //   rangeType: 'distinct-range',
  //   aggregationFn: 'min',
  //   sequentialPaletteId: 'elevation-sort',
  // },
];

export const actualityMetricDescriptorsMap = _.keyBy(
  actualityMetricDescriptors,
  d => d.type,
) as Record<ActualityMetric, ActualityMetricDescriptorBase>;

export function getActualityMetricDescriptorsMap(
  t: TFunction<'feed'>,
  formatter: Formatter,
) {
  const metrics: ActualityMetricDescriptor[] = [
    // TIME BASED METRICS
    {
      ...actualityMetricDescriptorsMap.lastActivity,
      titleCategory: t('Time', { ns: 'simulation' }),
      title: t('Activity (Latest)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Visits heatmap', { ns: 'simulation' }),
      icon: Icon.HeatMap9,
      format: ms =>
        ms > 0 ? format(toLocalDateFromMs(ms * 1000), 'dd MMM, HH:mm') : '-',
    },

    // VELOCITY METRICS
    {
      ...actualityMetricDescriptorsMap.visits,
      titleCategory: t('Velocity', { ns: 'simulation' }),
      title: t('Visits (All)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Visits heatmap', { ns: 'simulation' }),
      icon: Icon.HeatMap9,
      format: formatInteger,
    },
    // {
    //   ...actualityMetricDescriptorsMap.visitsCurrent,
    //   titleCategory: t('Velocity', { ns: 'simulation' }),
    //   title: t('Visits', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   actionTitle: t('show Visits heatmap', { ns: 'simulation' }),
    //   icon: Icon.HeatMap9,
    //   format: formatInteger,
    // },
    {
      ...actualityMetricDescriptorsMap.abc,
      titleCategory: t('Velocity', { ns: 'simulation' }),
      title: t('ABC+', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t(`show ABC heatmap`, { ns: 'simulation' }),
      icon: Icon.HeatMap4,
    },
    {
      ...actualityMetricDescriptorsMap.visitsReplenishment,
      titleCategory: t('Velocity', { ns: 'simulation' }),
      title: t('Replenishments', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Visits heatmap', { ns: 'simulation' }),
      icon: Icon.HeatMap9,
      format: formatInteger,
    },

    {
      ...actualityMetricDescriptorsMap.processType,
      titleCategory: t('Time', { ns: 'simulation' }),
      // titleCategory: t('By Process', { ns: 'simulation' }),
      title: t('Process (Latest)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Visits heatmap', { ns: 'simulation' }),
      icon: Icon.HeatMap9,
    },

    // ITEMS METRICS
    {
      ...actualityMetricDescriptorsMap.consigneeSku,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('SKU', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show sku', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.consignee,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Consignee', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show consignee', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.skuGroup,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Item Group', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Item (SKU) Group', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.subGroup,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Sub Group', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Sub Group', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },

    {
      ...actualityMetricDescriptorsMap.stockCategory,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Stock Category', { ns: 'simulation' }),
      subTitle: t('Stock Category', { ns: 'simulation' }),
      actionTitle: t('show tStock Category', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.transportClass,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Transport Class', { ns: 'simulation' }),
      subTitle: t('Transport Class', { ns: 'simulation' }),
      actionTitle: t('show Transport Class', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.storageClass,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Storage Class', { ns: 'simulation' }),
      subTitle: t('Storage Class', { ns: 'simulation' }),
      actionTitle: t('show Storage Class', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.pollutionClass,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Pollution Class', { ns: 'simulation' }),
      subTitle: t('Pollution Class', { ns: 'simulation' }),
      actionTitle: t('show pollution class', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    // LOCATION METRICS
    {
      ...actualityMetricDescriptorsMap.congestionZone,
      titleCategory: t('Location', { ns: 'simulation' }),
      title: t('Work Zone', { ns: 'simulation' }),
      subTitle: t('Location', { ns: 'simulation' }),
    },
    {
      ...actualityMetricDescriptorsMap.locationRackingType,
      titleCategory: t('Location', { ns: 'simulation' }),
      title: t('Location Type', { ns: 'simulation' }),
      subTitle: t('Location', { ns: 'simulation' }),
    },
    {
      ...actualityMetricDescriptorsMap.locationUsageType,
      titleCategory: t('Location', { ns: 'simulation' }),
      title: t('Usage Type', { ns: 'simulation' }),
      subTitle: t('Location', { ns: 'simulation' }),
    },
    {
      ...actualityMetricDescriptorsMap.locationOrder,
      titleCategory: t('Location', { ns: 'simulation' }),
      title: t('Picking Order', { ns: 'simulation' }),
      subTitle: t('Location', { ns: 'simulation' }),
    },
    // Item UOM METRICS
    {
      ...actualityMetricDescriptorsMap.uom,
      titleCategory: t('Item (UOM)', { ns: 'simulation' }),
      title: t('UOM Type', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.uomWeight,
      titleCategory: t('Item (UOM)', { ns: 'simulation' }),
      title: t('UOM Weight', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatWeight(v, 10).fullString,
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.uomWeightPow,
      titleCategory: t('Item (UOM)', { ns: 'simulation' }),
      title: t('UOM Weight (Distinct)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...actualityMetricDescriptorsMap.uomVolume,
      titleCategory: t('Item (UOM)', { ns: 'simulation' }),
      title: t('UOM Volume', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatVolume(v).fullString,
      icon: Icon.CongestionMap4,
    },
    // {
    //   ...actualityMetricDescriptorsMap.uomVolumePow,
    //   titleCategory: t('Item (UOM)', { ns: 'simulation' }),
    //   title: t('Base UOM Volume (Relative)', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   format: v => formatter.formatVolume(v).fullString,
    //   icon: Icon.CongestionMap4,
    // },
    // {
    //   ...actualityMetricDescriptorsMap.uomDensity,
    //   titleCategory: t('Item (UOM)', { ns: 'simulation' }),
    //   title: t('UOM Density', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   format: v => formatter.formatInteger(v).fullString,
    //   icon: Icon.CongestionMap4,
    // },
    // {
    //   ...actualityMetricDescriptorsMap.uomCapacity,
    //   titleCategory: t('Item (UOM)', { ns: 'simulation' }),
    //   title: t('UOM Capacity', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   format: v => formatter.formatInteger(v).fullString,
    //   icon: Icon.CongestionMap4,
    // },
    // {
    //   ...actualityMetricDescriptorsMap.uomProportionality,
    //   titleCategory: t('Item (UOM)', { ns: 'simulation' }),
    //   title: t('UOM Proportionality', { ns: 'simulation' }),
    //   subTitle: t('Item', { ns: 'simulation' }),
    //   format: v => formatter.formatInteger(v).fullString,
    //   icon: Icon.CongestionMap4,
    // },
  ];

  return _.keyBy(metrics, d => d.type);
}
