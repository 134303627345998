import classNames from 'classnames';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { useRecoilValue } from 'recoil';
import { toHumanReadableDateDay } from '../../common/dateTimeHelper';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import TitleSection from '../../components/layout/TitleSection';
import FeedActivityEventDetails from '../components/FeedActivityEventDetails';
import { actualityAnalyzedEventsSummary } from '../store/actuality.state';
import { delayDuration } from '../store/feed.helper'; // Assuming this exists for issue filtering
import { feedLatestEvents } from '../store/feed.state';
import FeedEventsStats from './FeedEventsStats';

const FeedEventsPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const eventsAll = useRecoilValue(feedLatestEvents);
  const analyzeEventsSummary = useRecoilValue(actualityAnalyzedEventsSummary);

  const displayOptions = [
    { label: t('Stats'), value: 'stats' },
    { label: t('All'), value: 'all' },
    { label: t('Issues'), value: 'issues' },
  ];
  const [displayOption, setDisplayOption] = useState('all');

  const groupingOptions = [
    { label: t('By Day'), value: 'byDay' },
    { label: t('By Hour'), value: 'byHour' },
  ];
  const [groupingOption, setGroupingOption] = useState('byDay');

  // Determine grouping key based on option
  const getGroupingKey = event => {
    const eventDateEnd = new Date(event.eventEndTime);
    const eventDateStart = new Date(event.eventStartTime ?? null);

    return groupingOption === 'byHour'
      ? `${toHumanReadableDateDay(eventDateEnd)} (${eventDateStart.getHours()}:00 – ${eventDateEnd.getHours()}:00)`
      : toHumanReadableDateDay(eventDateEnd);
  };

  // Filter events based on legendOption
  const filteredEvents = useMemo(() => {
    if (displayOption === 'issues') {
      return _.filter(eventsAll, event => {
        const endTime = new Date(event.eventEndTime).getTime();
        const startTime = new Date(event.eventStartTime).getTime();
        return Math.abs(endTime - startTime) > delayDuration;
      });
    }
    return eventsAll;
  }, [eventsAll, displayOption]);

  // Group filtered events
  const groupedEvents = useMemo(() => {
    return _(filteredEvents)
      .groupBy(event => getGroupingKey(event))
      .map((events, key) => ({
        groupKey: key,
        events: _.orderBy(
          events,
          ['eventEndTime', 'eventStartTime', 'agentId'],
          ['desc', 'desc', 'asc'],
        ),
      }))
      .value();
  }, [filteredEvents, groupingOption]);

  // Prepare events for Virtuoso
  const eventsForVirtuoso = useMemo(() => {
    return groupedEvents.reduce((acc, group) => {
      acc.push({
        type: 'header',
        content: group.groupKey,
        events: group.events,
      });
      return acc;
    }, []);
  }, [groupedEvents]);

  // Calculate if there are multiple unique days or hours for the collapsible condition
  const hasMultipleTimeGroups = useMemo(
    () => groupedEvents.length > 1,
    [groupedEvents],
  );

  return (
    <div data-component="FeedEventsPanel" className={classNames('h-full')}>
      {/* <ButtonSwitchMulti
          className="mx-2 my-1 text-xs"
          autoSize
          selectedIndex={groupingOptions.findIndex(
            option => option.value === groupingOption,
          )}
          onClick={index => setGroupingOption(groupingOptions[index].value)}
          options={groupingOptions}
          buttonType="header"
        /> */}
      <div className={classNames('px-1 py-2')}>
        <ButtonSwitchMulti
          className="mx-2 my-1 text-xs"
          autoSize
          selectedIndex={displayOptions.findIndex(
            option => option.value === displayOption,
          )}
          onClick={index => setDisplayOption(displayOptions[index].value)}
          options={displayOptions}
          buttonType="header"
        />
      </div>

      {displayOption === 'stats' ? (
        <FeedEventsStats />
      ) : _.isEmpty(eventsForVirtuoso) ? (
        <div className="py-10 text-center">{t('No events found')}</div>
      ) : (
        <Virtuoso
          className={classNames('h-full overflow-x-hidden')}
          data={eventsForVirtuoso}
          itemContent={(index, item) => {
            if (item.type === 'header') {
              return (
                <TitleSection
                  id={`header-event-${index}`}
                  key={`header-event-${index}`}
                  title={item.content}
                  isSticky
                  className="!top-0"
                  fullWidth
                  inPanelView
                  collapsible={hasMultipleTimeGroups}
                >
                  {item.events.map((evt, eventIndex) => (
                    <FeedActivityEventDetails
                      key={`feed-event-details-${evt.eventId}`}
                      event={evt}
                      index={eventIndex}
                    />
                  ))}
                </TitleSection>
              );
            }
          }}
          components={{
            List: React.forwardRef((props, ref) => (
              <div ref={ref} {...props} className="" />
            )),
          }}
        />
      )}
    </div>
  );
};

export default FeedEventsPanel;
