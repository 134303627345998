import {
  SimulationItemFilterFragment,
  SimulationItemFilterIntersectionFragment,
  SimulationItemFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FilterFieldConfigBase } from '../../../common/types';
import PolicyFilterValueEditor, {
  PolicyFilterValueEditorProps,
} from '../../../policyFilters/PolicyFilterValueEditor';
import {
  getPolicyFilterDataKeysByEditorType,
  getPolicyFilterKey,
} from '../../../policyFilters/policyFilter.helper';
import { LoadItemFilterValuesParams } from '../../../policyFilters/useLoadItemFilterValues';
import {
  deallocationPolicyProductFilterIntersection,
  deallocationPolicySelectedIdentity,
} from '../../store/allocationPolicy/allocationPolicy.state';
import { getProductFilterConfigCommon } from '../../store/assignmentPolicy.default';

export type DeallocatePolicyProductFilterEditorProps = {
  filterConfig: FilterFieldConfigBase<SimulationItemFilterType>;
  startLoadData: (params: LoadItemFilterValuesParams) => Promise<void>;
  cancelLoadData: (fieldId: string) => void;
};

const DeallocatePolicyProductFilterEditor: React.FC<
  DeallocatePolicyProductFilterEditorProps
> = props => {
  const { type } = props.filterConfig;
  const { startLoadData, cancelLoadData } = props;

  const editableFilterSetIdentity = useRecoilValue(
    deallocationPolicySelectedIdentity,
  );
  const [filterIntersection, setFilterIntersection] = useRecoilState(
    deallocationPolicyProductFilterIntersection(editableFilterSetIdentity),
  );

  if (!editableFilterSetIdentity || !filterIntersection) return null;

  const fieldValue = _.find(filterIntersection?.allOf, f => f.type === type);

  const onSelectValue = (value: SimulationItemFilterFragment) => {
    const others = filterIntersection.allOf.filter(fi => fi.type !== type);
    // If all values vas deselected in filter, we shouldn't add this filter to set
    if (
      !_.isEmpty(value.valueIn) ||
      !_.isNil(value?.range?.from) ||
      !_.isNil(value?.range?.to)
    ) {
      others.push(value);
    }

    const newIntersection: SimulationItemFilterIntersectionFragment = {
      id: filterIntersection.id,
      allOf: others,
    };

    setFilterIntersection(newIntersection);

    // trigger other filter to refresh
    getProductFilterConfigCommon().forEach(configItem => {
      if (configItem.type !== type) {
        const keyParts = getPolicyFilterDataKeysByEditorType(
          configItem.editorType,
        );

        keyParts.forEach(key => {
          const filterKey = getPolicyFilterKey(configItem.type, key);
          cancelLoadData(filterKey);
          startLoadData({
            field: configItem.type,
            filterKey,
            filterIntersection: newIntersection,
          });
        });
      }
    });
  };

  const editorProps: PolicyFilterValueEditorProps<SimulationItemFilterType> = {
    config: props.filterConfig,
    value: fieldValue,
    onValueChange: onSelectValue,
    startLoadData: params => startLoadData({ ...params, filterIntersection }),
    cancelLoadData,
  };

  return <PolicyFilterValueEditor {...editorProps} />;
};
export default DeallocatePolicyProductFilterEditor;
