import classNames from 'classnames';
import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import HeaderDataset from '../components/headers/HeaderDataset';
import { Container } from '../components/layout/ContainerFlex';
import { ContainerMain } from '../components/layout/ContainerMain';
import { ContainerSidebar } from '../components/layout/ContainerSidebar';
import DatasetObjectDataView from '../datasetObject/DatasetObjectDataView';
import DatasetObjectMenuSidebar from '../datasetObject/sidebar/DatasetObjectMenuSidebar';
import DatasetObjectPipelineSidebar from '../datasetObject/sidebar/DatasetObjectPipelineSidebar';
import useLoadDatasetObject from './hooks/useLoadDatasetObject';
import DatasetObjectEditorSidebar from './sidebar/DatasetObjectEditorSidebar';
import DatasetObjectMainSidebar from './sidebar/DatasetObjectMainSidebar';
import {
  datasetObjectDocument,
  datasetObjectDocumentLoadStatus,
} from './store/datasetObject.state';

export type DatasetObjectRootParams = {
  datasetObjectId: string;
};

const DatasetObjectRoot: React.FC = () => {
  const { t } = useTranslation('dataset');

  const { datasetObjectId } = useParams<DatasetObjectRootParams>();
  const [document, setDocument] = useRecoilState(datasetObjectDocument);

  const [loadDataset, resetDataset] = useLoadDatasetObject();
  const loadStatus = useRecoilValue(datasetObjectDocumentLoadStatus);

  useEffect(() => {
    loadDataset(datasetObjectId);
    return () => {
      resetDataset();
    };
  }, [datasetObjectId]);

  const isLoading = loadStatus === AsyncLoadStatus.Loading;
  const hasError = loadStatus === AsyncLoadStatus.Error;
  const isReady = loadStatus === AsyncLoadStatus.Ok;
  return (
    <>
      <HeaderDataset
        dataType={'activityFeed'}
        title={document?.title ?? t`Untitled`}
      />
      <ContainerMain>
        {hasError && <ErrorIndicator selfCenter message={t`Error`} />}

        {isLoading && <LoadingIndicator selfCenter message={t`Loading... `} />}

        {isReady && (
          <>
            <ContainerSidebar side="left">
              <DatasetObjectMenuSidebar />
              <DatasetObjectMainSidebar />
            </ContainerSidebar>
            <ErrorBoundary
              fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorIndicator
                  selfCenter
                  message={error.message}
                  hasReloadButton={true}
                  exception={error}
                />
              )}
            >
              <Suspense
                fallback={
                  <Container col hasOverflowY>
                    <PanelHeader isLoading title={t`Loading...`} />
                    <LoadingIndicator
                      className={classNames(
                        'bg-map-area-striped',
                        'border-app-background border-8',
                      )}
                      selfCenter
                      message={t`Loading`}
                    />
                  </Container>
                }
              >
                <DatasetObjectDataView />
              </Suspense>
            </ErrorBoundary>
            <ContainerSidebar side="right">
              <DatasetObjectPipelineSidebar />
              <DatasetObjectEditorSidebar />
            </ContainerSidebar>
          </>
        )}
      </ContainerMain>
    </>
  );
};

export default DatasetObjectRoot;
