import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { InboxZero } from '../../components/InboxZero';
import LoadingIndicator from '../../components/LoadingIndicator';
import Tabs, { TabItem } from '../../components/actions/Tabs';
import { Container } from '../../components/layout/ContainerFlex';
import PanelContainer from '../../containers/PanelContainer';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import OrderComparePanel from '../panels/OrderComparePanel';
import OrderSummaryPanel from '../panels/OrderSummaryPanel';
import AnalyzeOrderPicklists from '../panels/route/AnalyzeOrderPicklists';
import OptimizeOrderPicklists from '../panels/route/OptimizeOrderPicklists';
import {
  optimisationAnalyzeOrderDetails,
  optimisationAnalyzeOrderDetailsLoadStatus,
  optimisationSelectedOrder,
  optimisationViewMode,
} from '../store/optimisation.state';
import {
  simulationLayoutSelectedOrder,
  simulationLayoutSelectedOrderId,
  simulationLayoutSelectedWaypointType,
} from '../store/simulation.layout.state';
import {
  simulationAnalyzeOrderDetails,
  simulationAnalyzeOrderDetailsLoadStatus,
} from '../store/simulation.state';
import { OptimisationViewMode } from '../store/simulation.types';
import { simulationWizardSelectedStep } from '../store/simulation.wizard.state';

const SimulationRouteInfoSidebar: React.FC = () => {
  const { t } = useTranslation('simulation');

  const orderId = useRecoilValue(simulationLayoutSelectedOrderId);
  const viewMode = useRecoilValue(optimisationViewMode);
  const step = useRecoilValue(simulationWizardSelectedStep);
  const orderAnl = useRecoilValue(simulationLayoutSelectedOrder);
  const orderOpt = useRecoilValue(optimisationSelectedOrder);
  const orderDetails = useRecoilValue(simulationAnalyzeOrderDetails);
  const orderDetailsLoadStatus = useRecoilValue(
    simulationAnalyzeOrderDetailsLoadStatus,
  );
  const orderDetailsOptimised = useRecoilValue(optimisationAnalyzeOrderDetails);
  const orderDetailsOptimisedLoadStatus = useRecoilValue(
    optimisationAnalyzeOrderDetailsLoadStatus,
  );
  const [selectedWPType, setSelectedWPType] = useRecoilState(
    simulationLayoutSelectedWaypointType,
  );

  const isOptimisationStep = step.id === 'optimise';

  const showAnalyze =
    !isOptimisationStep || viewMode === OptimisationViewMode.Original;

  const showOptimize =
    isOptimisationStep && viewMode === OptimisationViewMode.Optimised;

  const showCompare =
    isOptimisationStep && viewMode === OptimisationViewMode.Compare;

  const isAnalyzeLoading =
    (showAnalyze || showCompare) &&
    (orderDetailsLoadStatus === AsyncLoadStatus.Loading ||
      orderDetailsLoadStatus === AsyncLoadStatus.None);
  const isOptimizeLoading =
    (showOptimize || showCompare) &&
    (orderDetailsOptimisedLoadStatus === AsyncLoadStatus.Loading ||
      orderDetailsOptimisedLoadStatus === AsyncLoadStatus.None);

  const picklistCount = orderDetails?.picklists?.length || 0;

  const isLoading = isAnalyzeLoading || isOptimizeLoading;
  if (isLoading) {
    return <LoadingIndicator selfCenter message={t`Loading order details`} />;
  }
  if (!orderId)
    return (
      <InboxZero
        message={t`No Order selected`}
        message_helper={t`Click on Order Item to view`}
        hasIcon
        selfCenter
      />
    );

  const tabItems: TabItem[] = [
    {
      id: 'before',
      title: t`Before`,
      content: <AnalyzeOrderPicklists />,
    },
    {
      id: 'after',
      title: t`After`,
      content: <OptimizeOrderPicklists />,
    },
  ];

  function drawSummary() {
    if (showCompare) return <OrderComparePanel />;
    if (showOptimize)
      return <OrderSummaryPanel order={orderDetailsOptimised} />;
    return <OrderSummaryPanel order={orderDetails} />;
  }

  function drawPicklist() {
    const selectedIndex = selectedWPType === 'before' ? 0 : 1;
    if (showCompare) {
      return (
        <Tabs
          componentName="PicklistsComparePanel"
          items={tabItems}
          selectedIndex={selectedIndex}
          onChange={index =>
            setSelectedWPType(index === 0 ? 'before' : 'after')
          }
        />
      );
    }
    if (showOptimize) {
      return <OptimizeOrderPicklists />;
    }
    return <AnalyzeOrderPicklists />;
  }

  return (
    <Container col hasOverflowY>
      {drawSummary()}

      <PanelContainer
        id="panel-routes-details"
        title={t`Picklists`}
        collapsible
        subtitle={`${picklistCount > 0 ? picklistCount : '—'}`}
      >
        {picklistCount > 0 ? (
          <div
            data-component="drawPicklist"
            className={classNames(
              'flex items-center justify-center flex-1 w-full h-full',
            )}
          >
            {drawPicklist()}
          </div>
        ) : (
          <InboxZero message={t`No Picklists found`} />
        )}
      </PanelContainer>
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-routes',
    title: t`Order Info`,
    // loadingMessage: i18n.t('Loading Order Info...'),
    size: 'sm',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SimulationRouteInfoSidebar />
    </SidebarContainer>
  );
};
