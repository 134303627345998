import { signOut } from 'aws-amplify/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loggedInUser } from '../store/auth.state';
import DropdownSelector from './actions/DropdownSelector';

const userOptions = ['logout'] as const;
type UserOptionsTuple = typeof userOptions;
export type UserOption = UserOptionsTuple[number];

export default function AuthHeader() {
  const user = useRecoilValue(loggedInUser);
  const logout = signOut;
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  if (!user) {
    return null;
  }

  function getTitle(option: UserOption) {
    switch (option) {
      case 'logout':
        return t`Logout`;
    }
    return option;
  }

  return (
    <DropdownSelector
      onClick={e => {
        e.stopPropagation();
      }}
      DropAlignRight
      buttonTransparent
      value={user.fullName}
      values={[...userOptions]}
      onChange={(option: UserOption, e) => {
        e.stopPropagation();
        switch (option) {
          // case 'Account Settings':
          //   // navigate(props.path);
          //   break;
          case 'logout':
            logout();
            break;
        }
      }}
      renderValue={getTitle}
      // renderValue={user.fullName.}
    />
  );
}
