import { useApolloClient } from '@apollo/client';
import {
  AnalyzeActualityJobEngine,
  BatchJobExecutionStatus,
  LoadAnalyzeActualityStatusDocument,
  LoadAnalyzeActualityStatusQuery,
  LoadAnalyzeActualityStatusQueryVariables,
  useRunAnalyzeActualityMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { CHECK_POLICY_JOB_REFRESH_INTERVAL } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
  actualityAnalyzeLoadStatus,
  actualitySelectedId,
} from '../store/actuality.state';

export type AnalyzeActualityParams = {
  newEventsOnly: boolean;
  engine?: AnalyzeActualityJobEngine;
};
function useAnalyzeActuality() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot analyze actuality`;
  const [runAnalyze] = useRunAnalyzeActualityMutation();
  const [observable, setObservable] = useState<ZenObservable.Subscription>();
  let timeoutId = null;

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(actualityAnalyzeLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadJob = useRecoilCallback(
    ({ snapshot, set }) =>
      async (token: string) => {
        const query = client.watchQuery<
          LoadAnalyzeActualityStatusQuery,
          LoadAnalyzeActualityStatusQueryVariables
        >({
          query: LoadAnalyzeActualityStatusDocument,
          variables: { token },
        });

        function handleError(details?, stack?) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(actualityAnalyzeLoadStatus, AsyncLoadStatus.Error);
        }
        const queryObservable = query.subscribe(
          async ({ data, errors }) => {
            if (errors) {
              const stack = errors.map(e => e.message).join('. ');
              handleError(null, stack);
              return;
            }

            const job = data.analyzeActualityStatus;
            if (
              job.status === BatchJobExecutionStatus.FAILED ||
              job.status === BatchJobExecutionStatus.ABORTED ||
              job.status === BatchJobExecutionStatus.TIMED_OUT
            ) {
              handleError();
            }

            if (job.status === BatchJobExecutionStatus.RUNNING) {
              timeoutId = setTimeout(() => {
                loadJob(token);
              }, CHECK_POLICY_JOB_REFRESH_INTERVAL);
            }

            if (job.status === BatchJobExecutionStatus.SUCCEEDED) {
              set(actualityAnalyzeLoadStatus, AsyncLoadStatus.Ok);
            }
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  const startAnalyze = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: AnalyzeActualityParams) => {
        const actualityId = await snapshot.getPromise(actualitySelectedId);

        function handleError(details?, stack?) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(actualityAnalyzeLoadStatus, AsyncLoadStatus.Error);
        }

        try {
          const { data: jobData, errors: jobErrors } = await runAnalyze({
            variables: {
              input: {
                actualityId,
                engine: params.engine ?? AnalyzeActualityJobEngine.FARGATE,
                newEventsOnly: params.newEventsOnly,
              },
            },
          });

          if (
            !_.isEmpty(jobErrors) ||
            _.isNil(jobData.analyzeActuality?.token)
          ) {
            const details = _(jobErrors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }

          loadJob(jobData.analyzeActuality?.token);
        } catch (ex) {
          handleError(ex?.message ?? ex);
        }
      },
  );

  async function call(params: AnalyzeActualityParams) {
    await initLoading();
    await startAnalyze(params);
  }

  function cancel() {
    observable?.unsubscribe();
    clearTimeout(timeoutId);
  }

  return [call, cancel] as const;
}

export default useAnalyzeActuality;
