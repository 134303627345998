import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { cn } from '../common/utils';
import {
  ImportDatasetButton,
  ImportDatasetLink,
  ShowDatasetLink,
} from '../components/importer/DatasetActionHelpers';
import { getImportTypeFromDataType } from '../import/store/import.default';
import { ImportType } from '../import/store/import.types';
import { warehouseSelected } from '../store/warehouse.state';
import { WarehouseExtraSettings } from '../store/warehouse.types';
import PipelineFileUploader from './ImportPipelineFileUploader';

export type ImportPipelineFileUploaderByTypeProps = {
  dataType: ImportType;
  viewMode?: 'header' | 'table' | 'dashboard' | 'panel' | 'minimal';
  datasetId?: string;
};

const ImportPipelineFileUploaderByType: React.FC<
  ImportPipelineFileUploaderByTypeProps
> = props => {
  const { t } = useTranslation('importer');
  const wh = useRecoilValue(warehouseSelected);
  const whExtraSettings: WarehouseExtraSettings = wh?.extraSettings;
  const importType = getImportTypeFromDataType(props.dataType);

  const defaultPipelineId = whExtraSettings?.defaultPipelines?.[importType];

  // if (_.isNil(defaultPipelineId)) return null;
  return (
    <div
      data-component="ImportPipelineFileUploaderByType"
      className={cn('flex flex-1 flex-col', 'w-full')}
    >
      {_.isNil(defaultPipelineId) && (
        <>
          {props.viewMode === 'panel' || props.viewMode === 'minimal' ? (
            <ImportDatasetLink dataType={props.dataType} />
          ) : (
            <ImportDatasetButton
              dataType={props.dataType}
              viewMode={props.viewMode}
            />
          )}
        </>
      )}
      {!_.isNil(defaultPipelineId) && (
        <>
          <PipelineFileUploader
            dataType={props.dataType}
            pipelineId={defaultPipelineId}
            viewMode={props.viewMode}
          />

          <div className={cn('grid grid-cols-2 content-center gap-2')}>
            <ImportDatasetLink
              className="justify-self-start"
              dataType={props.dataType}
            />
            <ShowDatasetLink
              className="justify-self-end"
              dataType={props.dataType}
              datasetId={props.datasetId}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ImportPipelineFileUploaderByType;
