import {
  AllocationDeallocateRuleFragment,
  AllocationDeallocateSettingsFragment,
  SimulationItemFilterIntersectionFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { simulationCurrent } from '../simulation.state';
import { DeallocationPolicySelectedIdentity } from './allocationPolicy.types';

const getKey = (postfix: string) =>
  `warebee-simulation-deallocation-${postfix}`;

export const deallocationPolicyDocument =
  selector<AllocationDeallocateSettingsFragment>({
    key: getKey('document'),
    get: ({ get }) => {
      return get(simulationCurrent).allocationSettings?.deallocateSettings;
    },
    set: ({ get, set }, value: AllocationDeallocateSettingsFragment) => {
      set(simulationCurrent, current => ({
        ...current,
        allocationSettings: {
          ...current.allocationSettings,
          deallocateSettings: value,
        },
      }));
    },
  });

export const deallocationPolicySelectedIdentity =
  atom<DeallocationPolicySelectedIdentity>({
    key: getKey('selected-identity'),
    default: null,
  });

export const deallocationPolicyRule = selectorFamily<
  AllocationDeallocateRuleFragment,
  string
>({
  key: getKey('rule-by-id'),
  get:
    ruleId =>
    ({ get }) => {
      const policy = get(deallocationPolicyDocument);
      return _.find(policy?.rules, rule => rule.id === ruleId);
    },
  set:
    ruleId =>
    ({ get, set }, value: AllocationDeallocateRuleFragment) => {
      const policy = get(deallocationPolicyDocument);
      set(deallocationPolicyDocument, {
        ...policy,
        rules: policy.rules.map(rule => (rule.id === ruleId ? value : rule)),
      });
    },
});

export const deallocationPolicyProductFilterIntersection = selectorFamily<
  SimulationItemFilterIntersectionFragment,
  DeallocationPolicySelectedIdentity
>({
  key: getKey('product-filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) return null;

      const rule = get(deallocationPolicyRule(identity.ruleId));
      if (!rule) return null;

      const allFilters = rule?.itemsMatch?.anyOf;

      const filterIntersection = _.find(
        allFilters,
        fs => fs.id === identity.filterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: SimulationItemFilterIntersectionFragment) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) {
        throw new Error('Invalid parameters. Filter identity is invalid');
      }

      const rule = get(deallocationPolicyRule(identity.ruleId));
      if (!rule) return null;

      set(deallocationPolicyRule(identity.ruleId), {
        ...rule,
        itemsMatch: {
          ...(rule?.itemsMatch ?? {}),
          anyOf: _.map(rule?.itemsMatch?.anyOf, fs =>
            fs.id === identity.filterId ? value : fs,
          ),
        },
      });
    },
});

export const deallocationPolicyCheckResult = atom<Object>({
  key: getKey('check-result'),
  default: null,
});
