import { Picklist, PicklistLine } from '@warebee/backend/backend-model';
import {
  PickingPolicy,
  ResourcePolicy,
  RoutingPolicy,
  StackingPolicy,
  WaypointPolicy,
} from '@warebee/shared/engine-model';

export class StartAnalyzeInput {
  analyzeResultId: string;
}

export interface AnalyzeEvent {
  eventType: 'analyze.success' | 'analyze.failure';
  analyzeResultId: string;
}

export interface AnalyzeSuccess extends AnalyzeEvent {
  eventType: 'analyze.success';
  startedAt?: number;
  completedAt?: number;
}

export interface AnalyzeFailure extends AnalyzeEvent {
  eventType: 'analyze.failure';
  error?: string;
  cause?: string;
}

export class GeneratePicklistEventsInput {
  analyzeResultId: string;
  picklistIds: string[];
}

export type AnalyzablePicklistLine = Omit<
  PicklistLine,
  'picklistSetId' | 'picklistId' | 'picklist' | 'consignee' | 'sku'
>;

export type AnalyzablePicklist = Omit<
  Picklist,
  'picklistSetId' | 'picklistSet' | 'pickingMethod' | 'picklistOrders'
> & {
  jobDate: string;
  processType?: string;
  lines: AnalyzablePicklistLine[];
};

export interface AnalyzeJobSaveResultInput {
  warehouseId: string;
  documentId: string;
}

export class AnalyzePicklistInput {
  picklists: AnalyzablePicklist[];

  layoutId: string;
  itemSetId?: string;

  resourcePolicy: ResourcePolicy;
  pickingPolicy?: PickingPolicy;
  routingPolicy?: RoutingPolicy;
  stackingPolicy?: StackingPolicy;
  waypointPolicy?: WaypointPolicy;

  saveResult?: AnalyzeJobSaveResultInput;
}

export interface AnalyzeActualitySourceEventFilter {
  jobDateFrom?: string;
  jobDateTo?: string;
}

export enum AnalyzeActualityJobEngine {
  LAMBDA = 'LAMBDA',
  FARGATE = 'FARGATE',
}

export interface AnalyzeActualityInput {
  actualityId: string;
  sourceEventFilter?: AnalyzeActualitySourceEventFilter;

  newEventsOnly?: boolean;

  engine?: AnalyzeActualityJobEngine;
}
