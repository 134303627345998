import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { Button } from '../../components/actions/Button';
import { Spacer } from '../../components/layout/Spacer';
import { ActionBarFooter } from '../../components/nav/ActionBar';
import useAnalyzeActuality from '../hooks/useAnalyzeActuality';
import { actualityAnalyzeLoadStatus } from '../store/actuality.state';

const AnalyzeActuality: React.FC = () => {
  const { t } = useTranslation('feed');
  const [startAnalyze] = useAnalyzeActuality();
  const status = useRecoilValue(actualityAnalyzeLoadStatus);

  const hasError = status === AsyncLoadStatus.Error;
  const isLoading = status === AsyncLoadStatus.Loading;

  return (
    <ActionBarFooter sticky stickyBottom>
      <Button
        label={isLoading ? t`Analysing` : t`Analyse New`}
        buttonType={hasError ? 'delete' : 'primary'}
        buttonSize="sm"
        isLoading={isLoading}
        hasIconBefore={isLoading}
        isDisabled={isLoading}
        onPress={() =>
          startAnalyze({
            newEventsOnly: true,
          })
        }
      />
      <Spacer flexspace />
      <Button
        label={isLoading ? t`Analysing` : t`Re-Analyse All`}
        buttonType={hasError ? 'delete' : 'primary'}
        buttonSize="sm"
        isLoading={isLoading}
        hasIconBefore={isLoading}
        isDisabled={isLoading}
        onPress={() =>
          startAnalyze({
            newEventsOnly: false,
          })
        }
      />
    </ActionBarFooter>
  );
};

export default AnalyzeActuality;
