import {
  AllocationRequirementGroupBy,
  LoadAllocationRequirementSummaryDocument,
  LoadAllocationRequirementSummaryQuery,
  LoadAllocationRequirementSummaryQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import { secureClient } from '../GraphQLClient';
import {
  allocationRequirementSummary,
  allocationRequirementSummaryLoadStatus,
} from '../simulation/store/allocation/allocationRequirement.state';
import { simulationCurrentId } from '../simulation/store/simulation.state';
import { errorAppender } from '../store/error.state';

export type LoadAllocationRequirementSummaryParams = {
  groupBy: AllocationRequirementGroupBy[];
};

function useLoadAllocationRequirementSummary() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load allocation requirement summary`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ snapshot, set }) => async () => {
    set(allocationRequirementSummaryLoadStatus, AsyncLoadStatus.Loading);
  });

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAllocationRequirementSummaryParams) => {
        const simulationId = await snapshot.getPromise(simulationCurrentId);
        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(allocationRequirementSummaryLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const query = secureClient.watchQuery<
          LoadAllocationRequirementSummaryQuery,
          LoadAllocationRequirementSummaryQueryVariables
        >({
          query: LoadAllocationRequirementSummaryDocument,
          variables: {
            simulationId,
            groupBy: params.groupBy,
          },
        });

        const queryObservable = query.subscribe(
          result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            set(
              allocationRequirementSummary,
              data?.simulation?.allocationRequirementSet?.requirementsSummary,
            );
            set(allocationRequirementSummaryLoadStatus, AsyncLoadStatus.Ok);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAllocationRequirementSummaryParams) {
    await initLoading();
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadAllocationRequirementSummary;
