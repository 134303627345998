import {
  Field,
  ID,
  ObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { ConstraintSummary } from '../constraint.model';
import { AllocationRequirementUomQuantity } from './allocation-requirement-set.model';

export enum AllocationRunStage {
  CALCULATE_ALLOCATION_REQUIREMENTS = 'CALCULATING_ALLOCATION_REQUIREMENTS',
  ALLOCATE = 'ALLOCATING',
  ANALYZING = 'ANALYZING',
}

registerEnumType(AllocationRunStage, {
  name: 'AllocationRunStage',
});

@ObjectType()
export class AllocationSummary {
  @Field(() => [ConstraintSummary])
  constraints?: ConstraintSummary[];
}

@ObjectType()
export class AllocationRunResultRequirementQuantity {
  @Field({ nullable: true })
  totalQuantity?: number;

  @Field(() => [AllocationRequirementUomQuantity], { nullable: true })
  uoms?: AllocationRequirementUomQuantity[];
}

@ObjectType()
export class AllocationRunResultRequirementLocationQuantity extends AllocationRunResultRequirementQuantity {
  @Field(() => ID)
  locationId: string;
}

@ObjectType()
export class AllocationRunResultRequirementPickableQuantity extends AllocationRunResultRequirementQuantity {
  @Field(() => [AllocationRunResultRequirementLocationQuantity], {
    nullable: true,
  })
  locations?: AllocationRunResultRequirementLocationQuantity[];
}

export enum AllocationRunResultUnallocatedReason {
  NO_SPACE_FOR_PICKABILITY = 'NO_SPACE_FOR_PICKABILITY',
  NO_SPACE = 'NO_SPACE',
  DEALLOCATE_RULE = 'DEALLOCATE_RULE',
  ROUND_LIMIT = 'ROUND_LIMIT',
  NO_RULE = 'NO_RULE',
}

registerEnumType(AllocationRunResultUnallocatedReason, {
  name: 'AllocationRunResultUnallocatedReason',
});

@ObjectType()
export class AllocationRunResultRequirementUnallocatedQuantity extends AllocationRunResultRequirementQuantity {
  @Field(() => [AllocationRunResultUnallocatedReason], { nullable: true })
  reasons?: AllocationRunResultUnallocatedReason[];
}

export enum AllocationRunResultDeallocatedReason {
  NON_COMPLIANT = 'NON_COMPLIANT',
  DEALLOCATE_RULE = 'DEALLOCATE_RULE',
  REPLACED = 'REPLACED',
}

registerEnumType(AllocationRunResultDeallocatedReason, {
  name: 'AllocationRunResultDeallocatedReason',
});

@ObjectType()
export class AllocationRunResultRequirementLocationDeallocatedQuantity extends AllocationRunResultRequirementLocationQuantity {
  @Field(() => AllocationRunResultDeallocatedReason)
  reason: AllocationRunResultDeallocatedReason;

  @Field(() => [ID], { nullable: true })
  constraints?: string[];

  @Field(() => [ID], { nullable: true })
  deallocateRuleIds?: string[];
}

@ObjectType()
export class AllocationRunResultRequirementDeallocatedQuantity extends AllocationRunResultRequirementQuantity {
  @Field(() => [AllocationRunResultDeallocatedReason], { nullable: true })
  reasons?: AllocationRunResultDeallocatedReason[];

  @Field(() => [ID], { nullable: true })
  constraints?: string[];

  @Field(() => [ID], { nullable: true })
  deallocateRuleIds?: string[];

  @Field(() => [AllocationRunResultRequirementLocationDeallocatedQuantity], {
    nullable: true,
  })
  locations?: AllocationRunResultRequirementLocationDeallocatedQuantity[];
}

@ObjectType()
export class AllocationRunResultRequirementDetails {
  @Field(() => AllocationRunResultRequirementQuantity, { nullable: true })
  required?: AllocationRunResultRequirementQuantity;

  @Field(() => AllocationRunResultRequirementQuantity, { nullable: true })
  unpickable?: AllocationRunResultRequirementQuantity;

  @Field(() => AllocationRunResultRequirementPickableQuantity, {
    nullable: true,
  })
  initiallyPickable?: AllocationRunResultRequirementPickableQuantity;

  @Field(() => AllocationRunResultRequirementPickableQuantity, {
    nullable: true,
  })
  allocated?: AllocationRunResultRequirementPickableQuantity;

  @Field(() => AllocationRunResultRequirementPickableQuantity, {
    nullable: true,
  })
  allocatedShared?: AllocationRunResultRequirementPickableQuantity;

  @Field(() => AllocationRunResultRequirementUnallocatedQuantity, {
    nullable: true,
  })
  unallocated?: AllocationRunResultRequirementUnallocatedQuantity;

  @Field(() => AllocationRunResultRequirementDeallocatedQuantity, {
    nullable: true,
  })
  deallocated?: AllocationRunResultRequirementDeallocatedQuantity;
}
