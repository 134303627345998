import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { getAuthState, getPreferredMFAType } from './auth.helper';
import {
  AuthState,
  CognitoUserPool,
  LoggedInUser,
  MfaMethod,
} from './auth.types';

const getKey = (postfix: string) => `warebee-auth-${postfix}`;

const authUserPoolTypeAtom = atom<CognitoUserPool>({
  key: getKey('user-pool-type-atom'),
  default: 'default',
  effects: [
    recoilPersist({
      key: getKey('user-pool-type-atom'),
      storage: localStorage,
    }).persistAtom,
  ],
});

export const authUserPoolType = selector<CognitoUserPool>({
  key: getKey('user-pool-type'),
  get: ({ get }) => get(authUserPoolTypeAtom),
  set: ({ get, set }, value: CognitoUserPool) => {
    const state = get(authStateAtom);
    if (state?.status === 'authorized') {
      throw new Error('User pool cannot be changed when authorized');
    }
    set(authUserPoolTypeAtom, value);
  },
});

const authStateAtom = atom<AuthState>({
  key: getKey('state'),
  default: null,
});

export const authState = selector<AuthState>({
  key: getKey('state-selector'),
  get: async ({ get }) => {
    const current = get(authStateAtom);
    if (current !== null) return current;
    return await getAuthState();
  },
  set: ({ set }, value) => {
    set(authStateAtom, value);
  },
});

export const loggedInUser = selector<LoggedInUser | undefined>({
  key: getKey('logged-in-user'),
  get: ({ get }) => {
    const state = get(authState);
    if (state.status === 'authorized') {
      return state.user;
    }
    return undefined;
  },
});

export const authMFAType = selector<MfaMethod>({
  key: getKey('mfa-type'),
  get: async ({ get }) => {
    const state = get(authState);
    console.log('call authMFAType');
    if (state.status === 'authorized') {
      return await getPreferredMFAType();
    }
    return null;
  },
});
