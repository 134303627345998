import { AnalyzedProductsDataRow } from '@warebee/shared/export-converter';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { Formatter } from '../common/useFormatter';
import ButtonLocateLocation from '../components/ButtonLocateLocation';
import { ColumnConfig } from '../components/DatasetTable';
import ItemCategoryTag from '../components/table/ItemCategoryTag';
import ItemTag from '../simulation/tags/ItemTag';
import StackingPolicyCategoryTag from '../stackingPolicy/StackingPolicyCategoryTag';

export function getAnalyzedProductsTableConfigDefault(
  t: TFunction<'simulation'>,
  formatter: Formatter,
): ColumnConfig<AnalyzedProductsDataRow>[] {
  return [
    {
      field: 'locationId',
      title: t`Location`,
      isHeader: true,
      hasFilter: true,
      render: (locationId: string, row) => (
        <ButtonLocateLocation locationId={locationId} />
      ),
    },
    {
      field: 'consignee',
      title: t`Client`,
      isHeader: true,
      hasFilter: true,
    },
    {
      field: 'sku',
      title: t`Item`,
      isHeader: true,
      hasFilter: true,
      render: (sku: string, row) => (
        <ItemTag
          title={sku}
          filters={{ consignee: row['consignee'], sku }}
          // color={getQualitativeColor(sku, 'dimension')[0]}
        />
      ),
    },
    {
      field: 'categorySting',
      title: t`Category`,
      isHeader: false,
      hiddenInBrowser: true,
    },
    {
      field: 'cumulativePercentRank',
      title: t`Category`,
      hiddenInExport: true,
      render: (v: number) => <ItemCategoryTag cmlPercent={v} />,
    },
    {
      field: 'item.skuGroup',
      title: t`Item Group`,
    },
    {
      field: 'item.subGroup',
      title: t`Sub Group`,
    },
    {
      field: 'item.stockCategory',
      title: t`Stock Category`,
    },
    {
      field: 'item.storageClass',
      title: t`Storage Class`,
    },
    {
      field: 'pickCount',
      title: t`Picking Visits`,
    },
    {
      field: 'quantityPicked',
      title: t`Picked qty`,
    },
    {
      field: 'replenishmentCount',
      title: t`Replenishment visits`,
    },
    {
      field: 'replenishmentQuantity',
      title: t`Replenishment qty`,
    },
    {
      field: 'reassignCount',
      title: t`Reassign visits`,
    },
    {
      field: 'stackingPolicyRule',
      title: t`Stacking rule`,
      hiddenInBrowser: true,
    },
    {
      field: 'stackingCategoryIndex',
      title: t`Stacking rule`,
      hiddenInExport: true,
      render: (v: number) => (
        <StackingPolicyCategoryTag stackingPriority={[v, null]} />
      ),
    },

    {
      field: 'triggeredReorderCount',
      title: t`Triggered reorders count`,
    },
    {
      field: 'triggeredReorderTotalCost',
      title: t`Triggered reorders cost`,
      render: (v: number) =>
        _.isNil(v) ? '-' : formatter.formatCurrency(v, 2).fullString,
    },
    {
      field: 'triggeredReorderTotalDuration',
      title: t`Triggered reorders duration`,
      render: (v: number) => formatter.formatTimeSpan(v, 2).fullString,
    },
    {
      field: 'triggeredReorderTotalReorderedWeight',
      title: t`Triggered reorders weight`,
      render: (v: number) => formatter.formatWeight(v).fullString,
    },
    {
      field: 'appliedReorderCount',
      title: t`Applied reorders count`,
    },
    {
      field: 'appliedReorderTotalCost',
      title: t`Applied reorders cost`,
      render: (v: number) =>
        _.isNil(v) ? '-' : formatter.formatCurrency(v, 2).fullString,
    },
    {
      field: 'appliedReorderTotalDuration',
      title: t`Applied reorders duration`,
      render: (v: number) => formatter.formatTimeSpan(v, 2).fullString,
    },
    {
      field: 'appliedReorderTotalReorderedWeight',
      title: t`Applied reorders weight`,
      render: (v: number) => formatter.formatWeight(v).fullString,
    },
  ];
}
