import {
  ImportJobImportType,
  UpdateActualityInput,
  useLoadAllLayoutsMetaQuery,
  useLoadDatasetObjectsQuery,
  useUpdateActualityMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as Icon from '../../components/icons';
import FeedSettingsDataPanel from '../../feed/panels/FeedSettingsDataPanel';
import Callout from '../../helpContext/Callout';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { actualitySelectedDocument } from '../store/actuality.state';

const FeedSettingsPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const whId = useRecoilValue(warehouseSelectedId);
  const [actuality, setActuality] = useRecoilState(actualitySelectedDocument);
  const [callUpdateActuality] = useUpdateActualityMutation();

  function updateActuality(
    patch: Pick<
      UpdateActualityInput,
      'activityFeedId' | 'assignmentId' | 'layoutId'
    >,
  ) {
    setActuality({
      ...actuality,
      ...patch,
    });

    callUpdateActuality({
      variables: {
        input: {
          ...patch,
          actualityId: actuality.id,
        },
      },
    });
  }

  const {
    data: feedObjectsData,
    loading: feedObjectsLoading,
    error: feedObjectsLoadingError,
  } = useLoadDatasetObjectsQuery({
    variables: {
      warehouseId: whId,
    },
  });

  const {
    data: layoutsData,
    loading: layoutsLoading,
    error: layoutsLoadingError,
  } = useLoadAllLayoutsMetaQuery({
    variables: {
      warehouseId: whId,
    },
  });

  const activityFeeds = _.filter(
    feedObjectsData?.warehouse?.datasetObjects?.content,
    obj => {
      return obj.objectType === ImportJobImportType.ACTIVITY_FEED;
    },
  );

  // const activityFeeds = feedObjectsData?.warehouse?.datasetObjects?.content;
  const activityFeedsMap = _.keyBy(activityFeeds, a => a.id);

  const layouts = layoutsData?.warehouse?.layouts?.content;
  const layoutsMap = _.keyBy(layouts, a => a.id);

  const isLoading = feedObjectsLoading; //|| layoutsLoading;
  if (isLoading) return null;

  return (
    <>
      <Callout panelMode type="suggestion" iconAfter icon={Icon.ArrowBottom}>
        {t`Let's get started with the Warehouse Setup.`}
      </Callout>

      <FeedSettingsDataPanel />
    </>
  );
};

export default FeedSettingsPanel;
