import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getImportConfigurations } from '../../import/store/import.default';
import { ImportType } from '../../import/store/import.types';
import {
  simulationCurrent,
  simulationIsEditable,
} from '../../simulation/store/simulation.state';
import { appIsEmbedded, importTriggeredBySim } from '../../store/global.state';
import {
  warehouseCanUpdate,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import { Button } from '../actions/Button';
import * as Icon from '../icons';
import LinkTraced from '../nav/LinkTraced';

export type DatasetButtonProps = PropsWithChildren & {
  className?: string;
  isLoading?: boolean;
  isSimulation?: boolean;
  updateDataset?: boolean;
  hasDatasets?: boolean;
  dataType: ImportType;
  datasetId?: string;
  headerMode?: boolean;
  viewMode?: 'header' | 'table' | 'dashboard' | 'panel' | 'minimal';
};

export const ImportDatasetButton: React.FC<DatasetButtonProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const canChange = useRecoilValue(simulationIsEditable);
  const [simulation, setSimulation] = useRecoilState(simulationCurrent);
  const setImportTriggerSimId = useSetRecoilState(importTriggeredBySim);
  const importConfigurations = getImportConfigurations(t);
  const datasetConfig = importConfigurations.find(
    config => config.dataType === props.dataType,
  );
  const dataTypeTitle = datasetConfig.actionName;
  const DataTypeIcon = datasetConfig.icon;

  if (!canUpdate || !canChange || !datasetConfig) {
    return null;
  }

  const iconClassStyleBefore =
    props.viewMode === 'header'
      ? 'w-6 h-6 xl:w-8 xl:h-8 fill-current'
      : 'w-4 h-4 xl:w-5 xl:h-5 fill-current';
  const iconClassStyleAction = iconClassStyleBefore;

  return (
    <div
      data-component="ImportDatasetButton"
      className={classNames('flex w-full flex-col items-center')}
    >
      {props.hasDatasets && <div className="text-menu/50 mt-2">{t`OR`}</div>}

      <div
        className={classNames(
          props.className,
          'flex w-full items-center',
          props.isSimulation ? 'bg-app-panel-dark/60 p-4' : '',
        )}
      >
        <Button
          className={classNames(
            props.viewMode === 'header' ? 'text-sm' : 'text-xs',
            'rounded',
            'w-full',
          )}
          label={dataTypeTitle}
          buttonType={
            props.isSimulation || props.viewMode === 'header'
              ? 'primary'
              : 'secondary'
          }
          buttonSize={
            props.isSimulation || props.viewMode === 'header' ? 'sm' : 'xs'
          }
          isDisabled={props.isLoading}
          full
          hasIconBefore={
            props.viewMode === 'header' && (
              <DataTypeIcon className={iconClassStyleBefore} />
            )
          }
          hasIconAfter={<Icon.CirclePlus className={iconClassStyleAction} />}
          onPress={() => {
            props.isSimulation && setImportTriggerSimId(simulation.id);
            navigate(datasetConfig.getImportPath(warehouseId));
          }}
          isLoading={props.isLoading}
        />
        {props.children}
      </div>
    </div>
  );
};

// export default ImportDatasetButton;

export const ImportDatasetLink: React.FC<DatasetButtonProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const canChange = useRecoilValue(simulationIsEditable);
  const isAppEmbedded = useRecoilValue(appIsEmbedded);

  const importConfigurations = getImportConfigurations(t);
  const datasetConfig = importConfigurations.find(
    config => config.dataType === props.dataType,
  );

  const importPath = datasetConfig.getImportPath(warehouseId);

  if (!canUpdate || !canChange || !datasetConfig) {
    return null;
  }

  const dataTypeTitle = datasetConfig.actionNameLink;

  return (
    <LinkTraced
      data-component="ImportDatasetLink"
      data-label={`import-dataset-link-${dataTypeTitle || ''}`}
      aria-label={`import-dataset-link-${dataTypeTitle || ''}`}
      titleTrace={`Import Link: ${dataTypeTitle || ''}`}
      to={importPath}
      target={isAppEmbedded || props.viewMode === 'header' ? '_self' : '_blank'}
      className={classNames(
        'group',
        // 'bg-sidebar-header/50',
        // 'hover:bg-menu-active hover:text-menu-active-text',
        'rounded px-2 py-1',
        'underline',
        'decoration-menu-active/20 hover:decoration-menu-active',
        'underline-offset-4 hover:underline',
        'inline-flex',
        'text-app-panel-light hover:text-menu-active',
        'text-xs',
        'mx-1 mt-2',
        'px-1',
        props.className,
      )}
    >
      {dataTypeTitle}
      <Icon.CloudUpload
        className={classNames('h-4 w-4', 'mx-1', 'fill-current')}
      />
    </LinkTraced>
  );
};

export const ShowDatasetLink: React.FC<DatasetButtonProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const canChange = useRecoilValue(simulationIsEditable);
  const isAppEmbedded = useRecoilValue(appIsEmbedded);

  const importConfigurations = getImportConfigurations(t);
  const datasetConfig = importConfigurations.find(
    config => config.dataType === props.dataType,
  );

  const dataPath = datasetConfig.getDataTablePath(warehouseId, props.datasetId);

  if (!canUpdate || !canChange || !datasetConfig) {
    return null;
  }

  const dataTypeTitle = datasetConfig.actionTitleShowData;

  return (
    <LinkTraced
      data-component="ShowDatasetLink"
      data-label={`import-dataset-link-${dataTypeTitle || ''}`}
      aria-label={`import-dataset-link-${dataTypeTitle || ''}`}
      titleTrace={`Import Link: ${dataTypeTitle || ''}`}
      to={dataPath}
      target={isAppEmbedded ? '_self' : '_blank'}
      className={classNames(
        'group',
        // 'bg-sidebar-header/50',
        // 'hover:bg-menu-active hover:text-menu-active-text',
        'rounded px-2 py-1',
        'underline',
        'decoration-menu-active/20 hover:decoration-menu-active',
        'underline-offset-4 hover:underline',
        'inline-flex',
        'text-app-panel-light hover:text-menu-active',
        'text-xs',
        'mx-1 mt-2',
        'px-1',
        props.className,
      )}
    >
      {dataTypeTitle}
      <Icon.ShowTable
        className={classNames('h-4 w-4', 'mx-1', 'fill-current')}
      />
    </LinkTraced>
  );
};
