import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsAgent } from '../../AnalyticTracker';
import { Feature } from '../../permissions/types';
import LoadingIndicator from '../LoadingIndicator';

export type TabItem<T = string | number> = {
  id: T;
  title?: string;
  content: React.ReactNode;
  icon?: React.ReactNode;
  secondary?: boolean;
  permission?: Feature;
  feature?: Feature;
};

export type TabsProps = {
  items: TabItem[];
  defaultIndex?: number;
  selectedIndex?: number;
  onChange?: (index: number) => void;
  className?: string;
  tabsWrapperClassNames?: string;
  panelWrapperClassNames?: string;
  contentWrapperClassNames?: string;
  innerScroll?: boolean;
  classNameIcon?: string;
  classNameTitle?: string;
  fullHeight?: boolean;
  hasScroll?: boolean;
  hasActionbar?: boolean;
  componentName?: string;
};

const Tabs: React.FC<TabsProps> = props => {
  const { t } = useTranslation('app');

  // Destructure props and set default value for hasActionbar
  const { hasActionbar = true, items } = props;

  const gridColsClass = `grid-cols-${items.length ?? 1}`;

  function drawTabs() {
    return props.items.map((item, index) => {
      return (
        <Tab
          key={`tab-${item.id}-${index}`}
          className={({ selected }) =>
            classNames(
              'transition-all duration-200 ease-in-out',
              selected
                ? 'bg-menu-active text-menu-active-text'
                : 'bg-menu-level-1/90 text-menu-text hover:bg-menu-active/75 hover:text-menu-active-text',

              'focus:outline-none focus:ring-0',
              'py-2',
              { 'flex flex-col items-center px-2': item.icon },
              { 'flex-1': !item.secondary || props.fullHeight },
              // { 'bg-menu-active text-menu-active-text': selected },
              // {
              //   'text-menu-text bg-sidebar-header/75 hover:text-menu-active-text hover:bg-menu-hover backdrop-filter backdrop-blur-lg backdrop-saturate-100':
              //     !selected,
              // },
              props.tabsWrapperClassNames,
            )
          }
          onClick={() => {
            console.log('onClick', `Tab: ${item.title}`);
            try {
              analyticsAgent?.track(`Tab: ${item.title}`);
            } catch (ex) {
              console.debug('analyticsAgent Error:', ex);
            }
          }}
        >
          {item.icon && (
            <span
              className={classNames(
                item.title && 'xl:mb-2',
                props.classNameIcon,
              )}
            >
              {item.icon}
            </span>
          )}
          {item.title && (
            <span
              className={classNames(
                item.icon && 'hidden xl:block',
                item.icon && 'text-xxs',
                props.classNameTitle,
              )}
            >
              {item.title}
            </span>
          )}
        </Tab>
      );
    });
  }

  function drawContent() {
    return props.items.map((item, index) => {
      return (
        <Tab.Panel
          key={`tabPanel-${item.id}-${index}`}
          className={({ selected }) =>
            classNames(
              'relative',
              'h-full',
              'flex flex-1 flex-col',
              selected ? 'tab-selected' : '',
              props.innerScroll ? 'overflow-auto' : '', // 'pb-14', //'overflow-hidden',
              props.contentWrapperClassNames,
            )
          }
        >
          <Suspense
            fallback={
              <LoadingIndicator selfCenter absolute message={t`Loading`} />
            }
          >
            {item.content}
          </Suspense>
        </Tab.Panel>
      );
    });
  }

  return (
    <div
      data-component={`Tab${props.componentName ?? ''}`}
      className={classNames(
        'w-full',
        'relative',
        { 'overflow-y-auto': props.hasScroll },
        'flex h-full flex-col',
        { 'pb-[3rem]': hasActionbar },
        props.className,
      )}
    >
      <Tab.Group
        // data-component="TabGroup"
        defaultIndex={props.defaultIndex ?? 0}
        selectedIndex={props.selectedIndex}
        onChange={props.onChange}
      >
        <Tab.List
          data-component="TabList"
          className={classNames(
            'sticky top-0',
            // 'flex',
            'grid auto-cols-max grid-flow-col',
            'justify-items-stretch',
            gridColsClass,
            'gap-x-0.5',
            'z-tabs',
            'bg-app-panel-dark/20 backdrop-saturate-110 backdrop-blur-lg backdrop-filter',
          )}
        >
          {drawTabs()}
        </Tab.List>
        <Tab.Panels
          data-component="TabPanel"
          className={classNames(
            // 'h-full flex-1 pb-20',
            // 'pb-12',
            'relative',
            { 'flex-1': props.fullHeight }, //h-full
            props.innerScroll ? 'overflow-y-auto' : null,
            props.panelWrapperClassNames,
          )}
        >
          {drawContent()}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Tabs;
