import { LayoutLevelLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { Loadable, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { getHeatmapBucketByValue } from '../../common/heatmap.helper';
import {
  actualityHeatmapBuckets,
  actualityHeatmapFilters,
  actualityHeatmapRangeDescriptor,
  actualityMetricDescriptor,
} from '../store/actuality.heatmap.state';
import { ActualityHeatmapDataRow } from '../store/datasetQueries/actualityHeatmapData';
import { getItemKey } from '../store/feed.helper';
import { feedItemsWithRank } from '../store/feed.state';

export type FeedLocationColorOut = [
  getColor: (loc: LayoutLevelLocationFragment) => [string, string] | null,
  getGradientColors: (loc: LayoutLevelLocationFragment) => string[],
];

export type ActualityLocationColorParams = {
  dataLoadable: Loadable<ActualityHeatmapDataRow[]>;
};

const useActualityLocationColor = (
  params: ActualityLocationColorParams,
): FeedLocationColorOut => {
  const metricDescriptor = useRecoilValue(actualityMetricDescriptor);
  const heatmapByLevelLoadable = params.dataLoadable;
  const rangeDescriptorLoadable = useRecoilValueLoadable(
    actualityHeatmapRangeDescriptor,
  );
  const heatmapBucketsLoadable = useRecoilValueLoadable(
    actualityHeatmapBuckets,
  );
  const heatmapFilter = useRecoilValue(actualityHeatmapFilters);
  const itemsWithRankLoadable = useRecoilValueLoadable(feedItemsWithRank);

  let getColor: (loc: LayoutLevelLocationFragment) => [string, string] | null;
  let getLabel: (loc: LayoutLevelLocationFragment) => string;
  let getGradientColors: (loc: LayoutLevelLocationFragment) => string[];

  const hasAllHeatmapData =
    heatmapByLevelLoadable.state === 'hasValue' &&
    heatmapBucketsLoadable.state === 'hasValue' &&
    itemsWithRankLoadable.state === 'hasValue' &&
    rangeDescriptorLoadable.state === 'hasValue';

  const heatmapByLoc: ActualityHeatmapDataRow[] =
    heatmapByLevelLoadable.contents;
  const buckets = heatmapBucketsLoadable.contents;
  const itemsWithRank = itemsWithRankLoadable.contents;
  const rangeDescriptor = rangeDescriptorLoadable.contents;

  const bucketsMap = _.keyBy(buckets, b => b.id);
  const itemsDictionary = _.keyBy(itemsWithRank, item => getItemKey(item));

  const locations = _.groupBy(heatmapByLoc, row => row.locationId);

  if (hasAllHeatmapData) {
    getGradientColors = (loc: LayoutLevelLocationFragment): string[] => {
      const locationRows = locations[loc.locationId];
      const colors = _(locationRows)
        .map(data => {
          return getHeatmapBucketByValue({
            data: {
              [metricDescriptor.path]: data.dimensionValue,
            },
            buckets: bucketsMap,
            metricDescriptor: metricDescriptor as any,
            rangeDescriptor,
          });
        })
        .compact()
        .filter(b => b && heatmapFilter.hiddenBuckets[b.id] !== true)
        .sortBy(b => -b.index)
        .map(p => p.color)
        .uniq()
        .value();
      return _.isEmpty(colors) ? null : colors;
    };
  }

  return [getColor, getGradientColors];
};

export default useActualityLocationColor;
