import {
  AriaPopoverProps,
  DismissButton,
  Overlay,
  usePopover,
} from '@react-aria/overlays';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { OverlayTriggerState } from 'react-stately';

export type PopoverProps = PropsWithChildren &
  _.Omit<AriaPopoverProps, 'popoverRef'> & {
    state: OverlayTriggerState;
  };
const Popover: React.FC<PopoverProps> = props => {
  const ref = React.useRef(null);
  const { children, state } = props;

  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef: ref,
    },
    state,
  );

  return (
    <Overlay>
      <div {...underlayProps} className="fixed inset-0" />
      <div
        {...popoverProps}
        ref={ref}
        className={classNames(
          'absolute',
          'left-1',
          'bg-app-header-menu/70',
          'backdrop-saturate-110 backdrop-blur backdrop-filter',
          'rounded-md',
          'shadow-lg',
          'mt-2 p-4',
          'z-10',
        )}
      >
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
};
export default Popover;
