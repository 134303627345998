import { ImportJobImportType } from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { datasetAutomationMenuItems } from './datasetAutomation.default';
import {
  DatasetAutomationMenuItem,
  DatasetAutomationMenuItemId,
} from './datasetAutomation.types';

const getKey = (postfix: string) => `warebee-dataset-automation-${postfix}`;

export const datasetAutomationType = atom<ImportJobImportType>({
  key: getKey('document-type'),
  default: null,
});

const datasetAutomationSelectedMenuItemIdAll = atom<
  Partial<Record<ImportJobImportType, DatasetAutomationMenuItemId>>
>({
  key: getKey('selected-menu-item-id-all'),
  default: {},
  effects: [
    recoilPersist({
      key: getKey('selected-menu-item-id-all'),
      storage: localStorage,
    }).persistAtom,
  ],
});

export const datasetAutomationSelectedMenuItemId = selectorFamily<
  DatasetAutomationMenuItemId,
  ImportJobImportType
>({
  key: getKey('selected-menu-item-id'),
  get:
    (importType: ImportJobImportType) =>
    ({ get }) => {
      return (
        get(datasetAutomationSelectedMenuItemIdAll)?.[importType] ||
        'dataset-automation-menu-import-jobs'
      );
    },

  set:
    (importType: ImportJobImportType) =>
    ({ get, set }, value: DatasetAutomationMenuItemId) => {
      set(datasetAutomationSelectedMenuItemIdAll, {
        ...get(datasetAutomationSelectedMenuItemIdAll),
        [importType]: value,
      });
    },
});

export const datasetAutomationSelectedMenuItem =
  selector<DatasetAutomationMenuItem>({
    key: getKey('selected-step'),
    get: ({ get }) => {
      const datasetType = get(datasetAutomationType);
      const menuId = get(datasetAutomationSelectedMenuItemId(datasetType));
      return _.find(
        datasetAutomationMenuItems(_.identity as TFunction),
        s => s.id === menuId,
      );
    },
  });
