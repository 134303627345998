import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import BayLocationLayer from '../../layout/bayViewer/layers/BayLocationLayer';
import { actualityHeatmapBayData } from '../store/actuality.heatmap.state';
import useActualityLocationColor from './useActualityLocationColor';

const FeedBayLocationLayer: React.FC = () => {
  const dataLoadable = useRecoilValueLoadable(actualityHeatmapBayData);
  const [getHeatmapColor, getGradientHeatmapColors] = useActualityLocationColor(
    { dataLoadable },
  );

  return (
    <BayLocationLayer
      getColor={getHeatmapColor}
      getGradientColors={getGradientHeatmapColors}
    />
  );
};

export default FeedBayLocationLayer;
