import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatDateTime,
  formatInteger,
  formatTimespan,
} from '../../common/formatHelper';
import { Stat } from '../../components/stats/Stat';
import AnalyzeStatsPanel from '../panels/AnalyzeStatsPanel';
import OptimisationEventsComparePanel from '../panels/OptimisationEventsComparePanel';
import OptimizationSettingsStats from '../panels/optimization/OptimisationSettingsInfo';
import OptimisationStatsInfo from '../panels/optimization/OptimisationStatsInfo';
import { SimulationDashboardCardProps } from './SimulationDashboardCard';
/**
 *
 *
 * @param {*} props
 * @return {*}
 */
const SimulationOptimisedCardContent: React.FC<
  SimulationDashboardCardProps
> = props => {
  const { t } = useTranslation('simulation');
  const { sim } = props;

  const analyzeTime = formatTimespan(
    sim.analyzeResult?.completedAt ?? 0,
    sim.analyzeResult?.startedAt ?? 0,
  );

  const optimiseTime = formatTimespan(
    sim.latestOptimizationRun?.completedAt ?? 0,
    sim.latestOptimizationRun?.startedAt ?? 0,
  );

  const optimiseSteps = formatInteger(sim.latestOptimizationRun?.steps);

  return (
    <>
      <AnalyzeStatsPanel
        dashboardSimulationView
        analyzeResult={sim.analyzeResult}
        analyzeResultToCompare={sim.latestOptimizationRun?.analyzeResult}
      />

      {!props.showAdvancedStats && (
        <>
          <OptimisationEventsComparePanel
            dashboardSimulationView
            cardView
            eventsBefore={sim.analyzeResult?.summary?.eventTypes}
            eventsAfter={
              sim.latestOptimizationRun?.analyzeResult?.summary?.eventTypes
            }
            energyRate={
              sim.resourcePolicy.agents?.[0]?.specs?.powerSource
                ?.energyConsumptionPerSecond ?? 0
            }
          />
          <Stat
            transparent
            isMetaValue
            title={t`Analyse Stats:`}
            titleAlt={t('Date: {{dt}}', {
              dt: formatDateTime(new Date(sim.analyzeResult?.completedAt)),
            })}
            value={analyzeTime}
          />
          <span data-stats={optimiseSteps} title={optimiseSteps}>
            <Stat
              transparent
              isMetaValue
              title={t`Optimisation Stats:`}
              titleAlt={`${formatDateTime(
                new Date(sim.latestOptimizationRun?.startedAt),
              )} — ${formatDateTime(
                new Date(sim.latestOptimizationRun?.completedAt),
              )}`}
              value={optimiseTime}
            />
          </span>
        </>
      )}

      {props.showAdvancedStats && (
        <>
          <OptimisationStatsInfo
            opt={sim.latestOptimizationRun}
            layoutSummary={sim?.layout?.summary}
          />

          <OptimizationSettingsStats
            optimizationSettings={sim.optimizationSettings}
          />
        </>
      )}
    </>
  );
};

export default SimulationOptimisedCardContent;
