import { Container } from '../../components/layout/ContainerFlex';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { getAssignmentStatItemMetricDescriptorsMap } from '../../assignmentStats/assignmentStats.default';
import { getAssignmentStatsMetricGroups } from '../../assignmentStats/assignmentStats.helper';
import { assignmentStatsItemMetric } from '../../assignmentStats/assignmentStats.state';
import useFormatter from '../../common/useFormatter';
import * as Icon from '../../components/icons';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import ActionBarNextStep from './ActionBarNextStep';

const DatasetStatsSidebar: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [metric, setMetric] = useRecoilState(assignmentStatsItemMetric);
  const selectStep = useSelectSimulationWizardStep();
  const formatter = useFormatter();
  const metricDescriptors = getAssignmentStatItemMetricDescriptorsMap(
    t,
    formatter,
  );
  const metricGroups = getAssignmentStatsMetricGroups(t);

  return (
    <>
      <Container hasOverflowY col>
        <ScreenTitle
          title={t`Heatmaps`}
          subtitle={t`Analyse`}
          isSticky
          helpNavTo={'simulation/dataset/simulation-dataset-heatmaps'}
          icon={Icon.HeatMap9}
        />

        <TitleSection
          inPanelView
          title={t`Heatmap by`}
          classNameInner="pb-20 mb-4 flex-1"
        >
          <StatGroup>
            {_.map(metricGroups, group => {
              return (
                <TitleSection
                  key={`dataset-metric-group-${group.id}`}
                  id={`dataset-metric-group-${group.id}`}
                  title={group.title}
                  //inSidebarView
                  inPanelView
                  collapsible
                  classNameInner="space-y-px"
                >
                  {_.map(group.metrics, metricId => {
                    const d = metricDescriptors[metricId];
                    return (
                      <Stat
                        key={d.title}
                        isActionable
                        isSelectable
                        isSelected={metric === d.type}
                        title={d.title}
                        onClick={() => {
                          setMetric(d.type);
                        }}
                      />
                    );
                  })}
                </TitleSection>
              );
            })}
          </StatGroup>
        </TitleSection>
      </Container>
      <ActionBarNextStep />
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-dataset-stats',
    title: t`Heatmaps`,
    loadingMessage: t`Loading Data...`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <DatasetStatsSidebar />
    </SidebarContainer>
  );
};
