import { Transition } from '@headlessui/react';
import {
  getBestContrastColor,
  getColorDarker,
} from 'apps/frontend/src/app/common/color.helper';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import React, { HTMLAttributes, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cn } from '../../common/utils';
import { collapsibleState } from '../../store/collapsible.state';
import { namedColorsByKey } from '../../store/namedColors.state';
import DropdownSelector from '../actions/DropdownSelector';
import * as Icon from '../icons';
import { InputValue } from '../inputs/InputValue';
import NamedColor from '../inputs/NamedColor';

export type RuleContainerAction = 'delete' | 'rename' | 'clone' | 'advanced';

function getActionTitle(
  action: RuleContainerAction,
  t: TFunction<'components'>,
) {
  switch (action) {
    case 'rename':
      return t`Rename`;
    case 'delete':
      return t`Delete`;
    case 'clone':
      return t`Clone`;
    case 'advanced':
      return t`Advanced Mode`;
  }
  return action;
}

const actionsDefault: RuleContainerAction[] = ['rename', 'delete'];

type Issue = {
  title: string;
  value: string;
  count?: number;
};

export type RuleContainerProps = PropsWithChildren & {
  id: string;
  title: string;
  titleHelper?: React.ReactNode;
  subtitle?: string | React.ReactNode;
  dataComponent?: string;

  className?: string;
  classNameInner?: string;
  icon?: React.FC<HTMLAttributes<Element>>;
  iconCollapsible?: React.FC<HTMLAttributes<Element>>;
  actions?: Partial<
    Record<RuleContainerAction, (action: RuleContainerAction) => void>
  >;

  isActive?: boolean;
  isCollapsible?: boolean;
  isDisabled?: boolean;
  isNew?: boolean;
  hasIcon?: boolean;
  hasIssue?: boolean;
  issueList?: Issue[];

  hasColorMode?: boolean;
  hasOrder?: boolean;
  transparent?: boolean;

  namedColorKey?: string;
  color?: string;
  colorInner?: string;

  stickyTop?: number;
  orderCounter?: number;
  onClick?: () => void;

  isRemovable?: boolean;
  onDeleteClick?: () => void;

  canRename?: boolean;
  onTitleChange?: (value: string) => void;

  hasArrowUp?: boolean;
  onArrowUpClick?: () => void;

  hasArrowDown?: boolean;
  onArrowDownClick?: () => void;
};

const RuleContainer: React.FC<RuleContainerProps> = props => {
  const { t } = useTranslation('app');

  const colorPair = useRecoilValue(namedColorsByKey(props.namedColorKey));
  const [state, setState] = useRecoilState(collapsibleState(props.id));
  const isClosed = state.isCollapsed;
  const [renameMode, setRenameMode] = useState(false);
  const [title, setTitle] = useState(props.title);

  const hasColor = !_.isEmpty(colorPair);
  const bgColor = _.head(colorPair) ?? props.color;

  const actions: RuleContainerAction[] = [
    ...actionsDefault,
    ...(_.keys(props.actions) as any),
  ];
  const IconMenu = props.icon;
  const IconCollapsible =
    props.iconCollapsible || isClosed
      ? Icon.ChevronDualCloseVertical
      : Icon.ChevronDualOpenVertical;
  const hasIcon = !_.isNil(props.icon);

  const toggleOpen = () => {
    setState({
      isCollapsed: !isClosed,
    });
  };

  const defaultIcon = (
    <IconCollapsible
      className={cn(
        'h-4 w-5',
        'fill-current',
        'any-hover:hover:text-menu-active',
        'ltr:mr-1 rtl:ml-1',
      )}
    />
  );

  const issueIcon = (
    <Icon.TriangleInfo
      className={cn(
        'h-4 w-4',
        // 'mb-1',
        // 'ltr:ml-1 ltr:mr-2',
        // 'rtl:ml-2 rtl:mr-1',
        'fill-current',
        // 'text-menu-active any-hover:group-hover:text-menu-active',
      )}
    />
  );

  const orderController = (
    <div
      data-component="orderController"
      className={classNames(
        'p-1',
        'rounded',
        'any-hover:hover:bg-app-panel/70 any-hover:hover:text-menu-active',
        {
          'border-menu/80 any-hover:group-hover:border-menu/50 border':
            props.hasArrowUp || props.hasArrowDown,
        },
      )}
    >
      <div className={classNames('flex items-center')}>
        {(props.hasArrowUp || props.hasArrowDown) && (
          <div
            className={classNames(
              props.isDisabled ? 'hidden' : 'flex flex-1 ltr:mr-2 rtl:ml-2',
            )}
          >
            {props.hasArrowUp && (
              <button
                className={classNames(
                  props.hasArrowUp ? 'rounded-l' : 'rounded-md',
                  'relative',
                  'items-center',
                  'px-2 py-1 ltr:-ml-px rtl:-mr-px',
                  'opacity-90',
                  'hover:bg-menu-active hover:text-menu-active-text',
                  'whitespace-nowrap',
                  'border-app-panel-dark/30 border',
                  'transition duration-500',
                  'focus:z-10 focus:outline-none',
                  'focus:ring-menu-active focus:border-menu-active focus:ring-1',
                )}
                onClick={() => props.onArrowUpClick && props.onArrowUpClick()}
              >
                <Icon.ArrowTop className="h-4 w-4 fill-current" />
              </button>
            )}

            {props.hasArrowDown && (
              <button
                className={classNames(
                  props.hasArrowUp ? 'rounded-r' : 'rounded-md',
                  'relative items-center',
                  'px-2 py-1 ltr:-ml-px rtl:-mr-px',
                  'opacity-90',
                  'hover:text-menu-text hover:bg-brand whitespace-nowrap',
                  'border-menu/80 border',
                  'transition duration-500',
                  'focus:ring-menu-active-500 focus:border-menu-active-500 focus:z-10 focus:outline-none focus:ring-1',
                )}
                onClick={() =>
                  props.onArrowDownClick && props.onArrowDownClick()
                }
              >
                <Icon.ArrowBottom
                  className={classNames('h-4 w-4 fill-current')}
                />
              </button>
            )}
          </div>
        )}

        {props.isRemovable && !_.isNil(props.orderCounter) && (
          <div
            className={classNames(
              'p-1',
              'text-xs',
              'rounded-md',
              'h-6 w-6',
              'flex items-center',
              'transition duration-500 ease-in',
            )}
          >
            <span className={classNames('flex-1 text-center')}>
              {props.orderCounter}
            </span>
          </div>
        )}
      </div>
    </div>
  );

  const [isHovered, setIsHovered] = useState(false);

  function getActiveStyle() {
    if (props.isActive) {
      return {
        backgroundColor: bgColor,
        borderColor: bgColor,
        color: getBestContrastColor(bgColor),
      };
    }

    if (isHovered) {
      return {
        backgroundColor: getColorDarker(bgColor, 0.2),
        borderColor: bgColor,
      };
    }

    return {
      backgroundColor: getColorDarker(bgColor, 0.9),
      borderColor: bgColor,
      // color: getBestContrastColor(bgColor),
    };
  }

  function getActiveInnerStyle() {
    if (props.isActive) {
      return {
        backgroundColor: bgColor,
        borderColor: bgColor,
        color: getBestContrastColor(bgColor),
      };
    }

    if (isHovered) {
      return {
        backgroundColor: getColorDarker(bgColor, 0.2),
        borderColor: bgColor,
      };
    }

    return {
      backgroundColor: getColorDarker(bgColor, 0.9),
      borderColor: bgColor,
      // color: getBestContrastColor(bgColor),
    };
  }

  const filteredIssues =
    props.issueList?.filter(issue => issue.count && issue.count > 0) || [];

  const formatCount = (count: number): string => {
    if (count >= 1000) {
      return `${Math.floor(count / 1000)}k`;
    }
    return count.toString();
  };

  return (
    <div
      data-component={`RuleContainer${
        props.dataComponent ? '-' + props.dataComponent : ''
      }`}
      className={classNames(
        'flex flex-1 items-center',
        'w-full',
        'z-auto',
        'space-y-0.5 2xl:space-y-1',
        {
          'bg-app-panel/70 bg-issue-transparent/40': props.hasIssue,
        },
        isClosed ? 'mb-1' : props.isCollapsible ? 'mb-4' : '',
        'rounded',
      )}
      onClick={() => props.onClick && props.onClick()}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={getActiveStyle()}
    >
      <div
        data-component="RuleTagContainer"
        className={classNames(
          'rounded',
          'w-full flex-1',
          { 'bg-app-panel-light/50': !props.transparent && !props.hasIssue },
          // props.isActive
          //   ? 'border-2 border-menu-active any-hover:group-hover:border-menu-active'
          //   : 'border border-menu/80 any-hover:group-hover:border any-hover:group-hover:border-menu-active hover:border-menu-hover hover:border-opacity-50 any-hover:group-hover:border-opacity-50',
          // 'border border-alerts-alert border-opacity-50',
          props.className,
        )}
      >
        <header
          data-component="RuleTitle"
          className={classNames(
            'flex items-center',
            'h-10',
            isClosed ? 'rounded' : 'rounded-t',
            // 'p-0.5 xl:p-1',
            // 'text-menu-active',
            props.stickyTop
              ? `top-[${props.stickyTop || 0}rem]` +
                  ' ' +
                  `${
                    isClosed ? '' : '' //'backdrop-filter backdrop-blur-lg backdrop-saturate-110'
                  } ` +
                  ' ' +
                  // `sticky` +
                  ' ' +
                  `z-200`
              : ``,
            props.hasIssue
              ? 'bg-app-panel/70 bg-issue-warning/40 rounded'
              : props.isActive && !hasColor
                ? 'bg-menu-active/50 text-menu-active-text'
                : 'bg-sidebar-header/70 text-sidebar-header-text',
          )}
        >
          <div
            data-component="PolicyFilterRuleGroupTitle"
            className={classNames(
              'flex-1 truncate',
              'transition delay-75 duration-200 ease-in-out',
              'flex items-center',
              'h-full',
              {
                'cursor-pointer rounded': props.isCollapsible,
              },
              props.isActive && props.isCollapsible && !props.hasIssue
                ? hasColor
                  ? 'text-menu-text rounded'
                  : 'bg-menu-active text-menu-text rounded'
                : 'hover:bg-sidebar-header/70 rounded-l',
            )}
            onClick={e => {
              e.stopPropagation();
              props.isCollapsible && toggleOpen();
            }}
          >
            <div
              title={props.title}
              className={cn('flex flex-1 items-center', 'h-full', 'truncate', {
                'px-3': !props.isCollapsible,
              })}
            >
              {props.isCollapsible && props.hasIcon && defaultIcon}

              {props.hasIssue && filteredIssues.length > 0 && (
                <div
                  data-component="IssueCountSummary"
                  className={cn(
                    'flex items-center',
                    'border-menu-button/50 border',
                    'px-1 py-0.5',
                    'rounded',
                    'ltr:mr-2 rtl:ml-2',
                    'h-8',
                    'w-14',
                  )}
                >
                  {props.hasIssue && issueIcon}

                  <ul
                    className={cn(
                      'text-xxs',
                      'flex flex-col',
                      'space-y-0.5',
                      'truncate',
                      'ltr:ml-1',
                    )}
                  >
                    {filteredIssues.map((issue, index) => (
                      <li
                        key={index}
                        title={`${issue.title} (${issue.count})`}
                        className={cn(
                          'border-menu-button truncate',
                          index > 0 && 'border-t pt-px',
                        )}
                      >
                        {formatCount(issue.count)}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {isClosed && hasIcon && (
                <IconMenu
                  className={classNames(
                    'h-8 w-8',
                    'fill-current',
                    'ltr:mr-2 rtl:ml-2',
                  )}
                />
              )}

              {!renameMode && isClosed && props.titleHelper}

              {renameMode || props.isNew ? (
                <>
                  <InputValue
                    className={classNames(
                      'h-8',
                      'flex-1',
                      'text-start text-xs lg:text-sm',
                    )}
                    key={`tag-title-editor-${props.id}`}
                    value={title}
                    onChange={setTitle}
                    roundedLeft
                  />
                  {props.canRename && (
                    <button
                      className={classNames(
                        'h-8',
                        'bg-menu-active hover:bg-menu-hover',
                        'text-menu-active-text hover:text-menu-active-text',
                        'relative',
                        'items-center',
                        'px-2 py-2',
                        '-ml-px mr-1',
                        'text-sm font-medium',
                        'rounded-r',
                        'group-hover:block',
                        'whitespace-nowrap',
                        'group-hover:rounded-r-md',
                        'focus:z-10 focus:outline-none',
                      )}
                      onClick={e => {
                        e.stopPropagation();
                        if (renameMode) {
                          props.onTitleChange && props.onTitleChange(title);
                        }
                        setRenameMode(!renameMode);
                      }}
                    >
                      <span>{renameMode ? t`Apply` : t`Rename`}</span>
                    </button>
                  )}
                </>
              ) : (
                <>
                  <h4
                    className={classNames(
                      'flex-1',
                      'ltr:pr-1 rtl:pl-1',
                      'overflow-hidden truncate overflow-ellipsis',
                      'text-xs lg:text-sm',
                    )}
                  >
                    {props.title}
                  </h4>
                  {props.subtitle && (
                    <h5
                      className={classNames(
                        'group',
                        'flex items-center',
                        'px-2 py-1',
                        'text-xs',
                        'rounded',
                        'bg-menu-active text-menu-active-text hover:bg-menu-hover',
                      )}
                    >
                      <div
                        className={classNames(
                          'block hover:hidden group-hover:hidden',
                        )}
                        title={t`hide filter`}
                      >
                        <Icon.Filter
                          className={classNames(
                            'h-3 w-3 fill-current ltr:mr-2 rtl:ml-2',
                          )}
                        />
                      </div>
                      <div
                        className={classNames('hidden group-hover:block')}
                        title={t`show filter`}
                      >
                        <Icon.CircleX
                          className={classNames(
                            'h-5 w-5 fill-current ltr:mr-2 rtl:ml-2',
                          )}
                        />
                      </div>
                      <span className="flex-1">{props.subtitle}</span>
                    </h5>
                  )}
                  {props.hasColorMode && (
                    <NamedColor namedColorKey={props.title} />
                  )}
                  {props.color && !props.hasColorMode && (
                    <div
                      data-component="StaticRuleColor"
                      style={{ background: props.color }}
                      className={classNames(
                        'border-menu-600 mx-2 h-4 w-4 rounded border',
                      )}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {!renameMode &&
            (props.hasOrder ||
              props.hasArrowDown ||
              props.hasArrowUp ||
              props.isRemovable ||
              props.isRemovable) && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>{props.hasOrder && orderController}</>
            )}
          {!props.isDisabled && props.isRemovable && (
            <DropdownSelector
              className={classNames(['flex text-xs'])}
              DropAlignRight
              buttonTransparent
              vertical
              panelMode
              w_lg
              value={'...'}
              values={actions}
              renderValue={(v: RuleContainerAction) => getActionTitle(v, t)}
              onChange={(v: RuleContainerAction) => {
                switch (v) {
                  case 'rename':
                    if (renameMode) {
                      props.onTitleChange && props.onTitleChange(title);
                    }
                    setRenameMode(!renameMode);
                    break;
                  case 'delete':
                    props.onDeleteClick && props.onDeleteClick();
                    break;
                }
                props.actions?.[v] && props.actions[v](v);
              }}
            />
          )}
        </header>
        <Transition
          data-component="RuleContainerInner"
          className={classNames(
            props.classNameInner ?? 'rounded p-0.5',
            props.isActive ? 'bg-app-panel/50' : '',
          )}
          style={getActiveInnerStyle()}
          show={!isClosed}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          {props.children}
        </Transition>
      </div>
    </div>
  );
};

export default RuleContainer;
