import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import ButtonToolbar from '../../components/actions/ButtonToolbar';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { importTriggeredBySim } from '../../store/global.state';
import {
  warehouseAllItemSetsMeta,
  warehouseCanUpdate,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import { useLoadAllItemSetsMeta } from '../../warehouse/hooks/useLoadAllItemSetsMeta';
import {
  HelperWidgetImportInitialSetup,
  HelperWidgetImportLocked,
  HelperWidgetImportSelector,
  HelperWidgetNextStep,
} from '../helper/HelperWidgets';
import useUpdateSimulation from '../hooks/useUpdateSimulation';
import {
  simulationCurrent,
  simulationIsEditable,
  simulationShowDatasetAsTable,
} from '../store/simulation.state';

const SimulationDatasetPanelItems: React.FC = () => {
  const { t } = useTranslation('simulation');
  const navigate = useNavigate();
  const [simulation, setSimulation] = useRecoilState(simulationCurrent);
  const updateSimulation = useUpdateSimulation();
  const setImportTriggerSimId = useSetRecoilState(importTriggeredBySim);
  const whId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const canChange = useRecoilValue(simulationIsEditable);
  const [showAsTable, setShowAsTable] = useRecoilState(
    simulationShowDatasetAsTable,
  );
  const ItemSetsMeta = useRecoilValue(warehouseAllItemSetsMeta);

  const loadItemSetsList = useLoadAllItemSetsMeta();
  useEffect(() => {
    loadItemSetsList(whId);
  }, []);

  const proceedWithImport = () => {
    setImportTriggerSimId(simulation.id);
    navigate(`/wh/i/${whId}/import/items`);
  };

  const changeItemSet = (itemSetId: string) => {
    setSimulation({
      ...simulation,
      itemSet: { id: itemSetId, uomTypes: null },
    });
    updateSimulation({ itemSetId: itemSetId });
  };
  const ItemSets = ItemSetsMeta.items;

  const ItemSetMap = _.keyBy(ItemSets, a => a.id);
  const isLoading =
    ItemSetsMeta.status === AsyncLoadStatus.None ||
    ItemSetsMeta.status === AsyncLoadStatus.Loading;

  const datasetSelectorMenu = () => {
    return (
      <div
        className={classNames(
          'group',
          'flex items-center',
          'py-1 lg:py-2',
          canChange ? 'px-4' : 'px-1',
        )}
      >
        <DropdownSelector
          value={
            simulation?.itemSet?.id || t`No Items Selected, Click to Select`
          }
          values={_.keys(ItemSetMap)}
          renderValue={v => ItemSetMap[v]?.title || v}
          valueHelper={v =>
            ItemSetMap[v]?.updatedAt
              ? new Date(ItemSetMap[v]?.updatedAt).toLocaleString('en-GB')
              : ''
          }
          hasSubAction
          subAction={t`Import Items`}
          DropAlignRight
          widthFull
          valuePrimary
          multiline
          light
          onChange={changeItemSet}
          onSubActionClick={proceedWithImport}
          disabled={!canChange || !canUpdate}
          border={canChange || canUpdate}
          hasSearch
          hasSearchLabel={t`Item Sets`}
        />
        {simulation?.itemSet?.id && (
          <ButtonToolbar
            titleTrace={`Assignment Datatable`}
            className={classNames(
              'ltr:ml-2 rtl:mr-2',
              showAsTable === 'item-set' ? '' : 'hidden group-hover:block',
            )}
            label={t`Show Data`}
            isBoxed
            isSelected={showAsTable === 'item-set'}
            onChange={() =>
              setShowAsTable(showAsTable === 'item-set' ? null : 'item-set')
            }
          >
            {showAsTable === 'item-set' ? (
              <Icon.ShowTable
                className={classNames('w-9 h-9 lg:w-10 lg:h-10', 'p-0.5')}
              />
            ) : (
              <Icon.HideTable
                className={classNames('w-9 h-9 lg:w-10 lg:h-10', 'p-0.5')}
              />
            )}
          </ButtonToolbar>
        )}
      </div>
    );
  };

  return (
    <>
      <ScreenTitle
        title={t`Items`}
        subtitle={t`Simulation Dataset`}
        isSticky
        helpNavTo={'simulation/dataset/simulation-dataset-items'}
        icon={Icon.DataItems}
      />

      {isLoading ? (
        <LoadingIndicator message={t`Loading Item Set`} selfCenter />
      ) : ItemSetsMeta.status === AsyncLoadStatus.Error ? (
        <ErrorIndicator selfCenter message={t`Cannot load Item Set`} />
      ) : !_.isEmpty(ItemSets) ? (
        !canChange ? (
          <HelperWidgetImportLocked title={t`Item Set`}>
            {datasetSelectorMenu()}
          </HelperWidgetImportLocked>
        ) : (
          <>
            <HelperWidgetImportSelector title={t`Items`} />
            {datasetSelectorMenu()}
          </>
        )
      ) : (
        <HelperWidgetImportInitialSetup
          dataType="items"
          title={t`Item Set (Product SKUs)`}
        />
      )}

      {simulation?.itemSet?.id ? (
        <HelperWidgetNextStep title={t`Review Data issues`} hasDataIssues />
      ) : null}
    </>
  );
};

export default SimulationDatasetPanelItems;
