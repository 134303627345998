import { AppBillingWarehouseStateFragment } from '@warebee/frontend/app-billing-graphql-api';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { getWarehouseBillingSummary } from '../../../appBilling/store/appBilling.helper';
import { AsyncLoadStatus } from '../../../common/types';
import { billingStatusVisibilityState } from '../../../store/global.state';
import { warehouseSelectedId } from '../../../store/warehouse.state';
import BillingMenuTag from '../../billing/BillingMenuTag';
import * as Icon from '../../icons';
import NavLink from '../../nav/NavLink';

export type MenuItemWarehouseProps = {
  id: string;
  title: string;
  billing: AppBillingWarehouseStateFragment;
  billingLoadStatus: AsyncLoadStatus;
  isDemo?: boolean;
};

export const MenuItemWarehouse: React.FC<MenuItemWarehouseProps> = ({
  id,
  title = '',
  isDemo,
  billing,
  billingLoadStatus,
}) => {
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const isBillingStatusVisible = useRecoilValue(billingStatusVisibilityState);

  const to = `/wh/i/${id}/simulations`;
  const billingState = getWarehouseBillingSummary(billing);
  const showBillingTag =
    billingState.status === 'unpaid' ||
    billingState.status === 'hasTrial' ||
    billingState.status === 'hasExpiredOneTime' ||
    billingState.status === 'hasExpiredSubscription';

  const MenuIcon = isDemo ? Icon.WarehouseDemo : Icon.Warehouse3;

  return (
    <NavLink
      className={classNames('relative overflow-hidden w-full')}
      to={to}
      active={warehouseId === id}
      data-component={`MenuItemWarehouse`}
      data-label={`warehouse-button-${title}`}
      aria-label={`warehouse-button-${title}`}
      titleTrace={`Warehouse: ${title}`}
    >
      {isBillingStatusVisible && showBillingTag && (
        <div
          data-component="BillingMenuTagContainer"
          className={classNames(
            'absolute top-1',
            'ltr:-left-1 rtl:-right-1',
            'text-xs',
            AsyncLoadStatus.Loading
              ? 'w-full h-full'
              : 'ltr:-left-2 rtl:-right-2 ltr:pl-2 rtl:pr-2 top-1',
          )}
        >
          {!isDemo && (
            <BillingMenuTag
              billingState={billingState}
              billingStateLoadStatus={
                _.isNil(billing) ? AsyncLoadStatus.Loading : AsyncLoadStatus.Ok
                /* billingLoadStatus*/
              }
            />
          )}
        </div>
      )}

      <MenuIcon className={classNames('w-full h-20 lg:h-24 fill-current')} />
      <span className="text-center px-1">{title}</span>
    </NavLink>
  );
};
