import { AssignmentPolicyRuleMatchType } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { formatInteger } from '../common/formatHelper';
import useFormatter from '../common/useFormatter';
import { PolicyFilter } from '../components/policies/PolicyFilter';
import PolicyFilterGroupList from '../components/policies/PolicyFilterGroupList';
import PolicyFilterItem from '../components/policies/PolicyFilterItem';
import { PolicyFilterTag } from '../components/policies/PolicyFilterTag';
import { PolicyStatContainer } from '../components/policies/PolicyStatContainer';
import RuleContainer, {
  RuleContainerProps,
} from '../components/policies/RuleContainer';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { Stat } from '../components/stats/Stat';
import {
  getLocationFilterConfigCommon,
  getProductFilterConfigCommon,
} from '../simulation/store/assignmentPolicy.default';
import { getAssignmentPolicyRuleTypes } from '../simulation/store/assignmentPolicy.helper';
import {
  assignmentPolicyRule,
  assignmentPolicySelectedIdentity,
} from '../simulation/store/assignmentPolicy.state';
import { simulationShowDatasetAsTable } from '../simulation/store/simulation.state';
import { sidebarStateByType } from '../store/sidebar.state';

export type AssignmentPolicyRuleProps = {
  ruleId: string;
  index?: number;
  className?: string;
  isDefault?: boolean;
  isRemovable?: boolean;
  isNew?: boolean;
  hasIssue?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  isCheckLoading: boolean;
  productCount?: [number, number];
  locationCount?: [total: number, exclusive: number, empty?: number];

  canDelete?: boolean;
  onDeleteClick?: () => void;

  canMoveUp?: boolean;
  onMoveUp?: () => void;
  canMoveDown?: boolean;
  onMoveDown?: () => void;

  canRename?: boolean;
  hasOrder?: boolean;
};

const AssignmentPolicyRule: React.FC<AssignmentPolicyRuleProps> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const [rule, updateRule] = useRecoilState(assignmentPolicyRule(props.ruleId));
  const setShowDatasetTable = useSetRecoilState(simulationShowDatasetAsTable);
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    assignmentPolicySelectedIdentity,
  );
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-storage-editor'),
  );

  function selectProductFilterIntersection(
    filterIntersectionId: string,
    shouldSelect: boolean,
  ) {
    if (!shouldSelect) {
      // deselect intersection only
      setSelectedIdentity(null);
    } else {
      // select intersection and rule details
      setSelectedIdentity({
        ruleId: rule.id,
        filterType: 'items',
        productFilterId: filterIntersectionId,
      });
    }

    if (!policyEditPanelState.isPinned) {
      setPolicyEditPanelState({
        ...policyEditPanelState,
        isCollapsed: !shouldSelect,
      });
    }
  }

  function selectLocationFilterIntersection(
    filterIntersectionId: string,
    shouldSelect: boolean,
  ) {
    if (!shouldSelect) {
      // deselect intersection only
      setSelectedIdentity(null);
    } else {
      // select intersection and rule details
      setSelectedIdentity({
        ruleId: rule.id,
        filterType: 'locations',
        locationFilterId: filterIntersectionId,
      });
    }

    if (!policyEditPanelState.isPinned) {
      setPolicyEditPanelState({
        ...policyEditPanelState,
        isCollapsed: !shouldSelect,
      });
    }
  }

  function addLocationIntersection() {
    const id = nanoid();
    updateRule({
      ...rule,
      locationsMatch: {
        anyOf: [...(rule.locationsMatch?.anyOf ?? []), { id, allOf: [] }],
      },
    });
    selectLocationFilterIntersection(id, true);
  }

  function addProductIntersection() {
    const id = nanoid();
    updateRule({
      ...rule,
      productsMatch: {
        anyOf: [...(rule.productsMatch?.anyOf ?? []), { id, allOf: [] }],
      },
    });
    selectProductFilterIntersection(id, true);
  }

  function renameRule(newTitle: string) {
    updateRule({
      ...rule,
      title: newTitle,
    });
  }

  const removeField = (groupId: string, fieldId: string) => {
    updateRule({
      ...rule,
      locationsMatch: {
        anyOf: _.map(rule.locationsMatch?.anyOf, match => {
          if (match.id !== groupId) return match;
          return {
            id: groupId,
            allOf: match.allOf.filter(filter => filter.type !== fieldId),
          };
        }),
      },
      productsMatch: {
        anyOf: _.map(rule.productsMatch?.anyOf, match => {
          if (match.id !== groupId) return match;
          return {
            id: groupId,
            allOf: match.allOf.filter(filter => filter.type !== fieldId),
          };
        }),
      },
    });
  };

  const removeIntersection = (groupId: string) => {
    updateRule({
      ...rule,
      locationsMatch: {
        anyOf: _.filter(rule.locationsMatch?.anyOf, fg => fg.id !== groupId),
      },
      productsMatch: {
        anyOf: _.filter(rule.productsMatch?.anyOf, fg => fg.id !== groupId),
      },
    });
  };

  const isActive = selectedIdentity?.ruleId === rule.id;
  const isSelectedLocations =
    isActive && selectedIdentity?.filterType === 'locations';
  const isSelectedProducts =
    isActive && selectedIdentity?.filterType === 'items';
  const isSelectedRuleType =
    isActive && selectedIdentity?.filterType === 'ruleType';

  const ruleTypes = _.keyBy(getAssignmentPolicyRuleTypes(t), r => r.id);
  const ruleTypeSelected =
    rule.type ?? AssignmentPolicyRuleMatchType.ITEM_MUST_BE_IN_LOCATION;
  const ruleTypeSelectedString = ruleTypes[ruleTypeSelected].title;

  const containerParams: RuleContainerProps = {
    id: rule.id,
    title: rule.title,
    isCollapsible: true,
    isDisabled: props.isDisabled,
    isRemovable: props.canDelete,
    isActive: isActive,
    isNew: props.isNew,
    orderCounter: props.index,
    hasIcon: true,
    namedColorKey: rule.title,
    hasColorMode: true,

    onDeleteClick: () => props.onDeleteClick && props.onDeleteClick(),

    canRename: props.canRename,
    hasOrder: props.hasOrder,
    onTitleChange: title => renameRule(title),

    hasArrowUp: props.canMoveUp,
    onArrowUpClick: () => props.onMoveUp && props.onMoveUp(),

    hasArrowDown: props.canMoveDown,
    onArrowDownClick: () => props.onMoveDown && props.onMoveDown(),
  };

  return (
    <RuleContainer
      dataComponent="AssignmentPolicyRule"
      {...containerParams}
      classNameInner={classNames(
        isActive ? '' : 'bg-sidebar-header/70',
        'p-1 xl:p-2',
        'space-y-2',
      )}
    >
      <PolicyFilter
        label={t`Rule Type`}
        isActive={isSelectedRuleType}
        isDisabled={props.isDisabled}
        onClick={() => {
          setSelectedIdentity(
            isSelectedRuleType
              ? null
              : {
                  ruleId: rule.id,
                  filterType: 'ruleType',
                },
          );
        }}
      >
        <PolicyFilterItem
          // name={t`Mode`}
          value={ruleTypeSelectedString}
          isActive={isSelectedRuleType}
          isDisabled={props.isDisabled}
          isRemovable={false}
        />
      </PolicyFilter>
      <PolicyFilterTag className={'space-y-3'}>
        <RuleSectionContainer
          isSelected={isSelectedProducts}
          namedColorKey={isSelectedProducts ? rule.title : null}
          className={classNames('p-1')}
        >
          <PolicyStatContainer>
            <Stat
              hasNoPadding
              className={classNames('px-1 py-1 xl:px-2')}
              transparent
              title={t`Item(s) Filtered`}
              value={
                props.productCount[1] !== null &&
                formatInteger(props.productCount[1])
              }
              inPanelMode
              isActionable
              inFilterStat
              toggleTable
              isCheckLoading={props.isCheckLoading}
              hasTooltip={t`Global Items: ` + props.productCount[0]}
              isSelected={isSelectedProducts}
              onClick={() => {
                setSelectedIdentity(
                  isSelectedProducts
                    ? null
                    : {
                        ruleId: rule.id,
                        filterType: 'items',
                      },
                );
              }}
            />
          </PolicyStatContainer>
          <PolicyFilterGroupList
            isDisabled={props.isDisabled}
            filterSets={_.map(rule.productsMatch?.anyOf, filterGroup => ({
              id: filterGroup.id,
              allOf: [...filterGroup.allOf],
            }))}
            config={getProductFilterConfigCommon(t, formatter)}
            selectedId={selectedIdentity?.productFilterId}
            onAddGroup={addProductIntersection}
            onDeleteField={removeField}
            onSelectGroup={selectProductFilterIntersection}
            onDeleteGroup={removeIntersection}
          />
        </RuleSectionContainer>

        <RuleSectionContainer
          isSelected={isSelectedLocations}
          namedColorKey={isSelectedLocations ? rule.title : null}
        >
          <PolicyStatContainer>
            <Stat
              hasNoPadding
              className={classNames('px-1 py-1 xl:px-2')}
              transparent
              title={t`Location(s) Filtered`}
              value={
                (props.isCheckLoading || props.locationCount[1]) !== null &&
                formatInteger(props.locationCount[1])
              }
              inPanelMode
              isActionable
              inFilterStat
              isCheckLoading={props.isCheckLoading}
              hasTooltip={t`Global Locations: ` + props.locationCount[0]}
              isSelected={isSelectedLocations}
              onClick={() => {
                setShowDatasetTable(
                  isSelectedLocations ? null : 'locations-stats-by-rule',
                );
                setSelectedIdentity(
                  isSelectedLocations
                    ? null
                    : {
                        ruleId: rule.id,
                        filterType: 'locations',
                      },
                );
              }}
            />
          </PolicyStatContainer>
          <PolicyFilterGroupList
            isDisabled={props.isDisabled}
            filterSets={_.map(rule.locationsMatch?.anyOf, filterGroup => ({
              id: filterGroup.id,
              allOf: [...filterGroup.allOf],
            }))}
            config={getLocationFilterConfigCommon(t)}
            selectedId={selectedIdentity?.locationFilterId}
            onAddGroup={addLocationIntersection}
            onDeleteField={removeField}
            onSelectGroup={selectLocationFilterIntersection}
            onDeleteGroup={removeIntersection}
          />
        </RuleSectionContainer>
      </PolicyFilterTag>
    </RuleContainer>
  );
};
export default AssignmentPolicyRule;
