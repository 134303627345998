import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formatInteger, formatPercent } from '../../common/formatHelper';
import { Stat } from '../../components/stats/Stat';
import { getABCProductHeatmapBuckets } from '../../metrics/analyzeProduct/analyzeProductMetric.helper';
import SimulationABCChart, {
  ABCSerie,
} from '../../simulation/panels/SimulationABCChart';
import { getProductCategories } from '../../simulation/store/abc/simulation.ABC.helper';
import {
  actualityHeatmapFilters,
  actualityMetricSelected,
} from '../store/actuality.heatmap.state';
import { feedItemsWithRank } from '../store/feed.state';

const FeedStockPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const categoryDescriptors = getProductCategories(t);
  const buckets = getABCProductHeatmapBuckets();
  const items = useRecoilValue(feedItemsWithRank);

  const [heatmapFilter, setHeatmapFilter] = useRecoilState(
    actualityHeatmapFilters,
  );

  const setHeatmapMetric = useSetRecoilState(actualityMetricSelected);

  useEffect(() => {
    setHeatmapMetric('abc');
    return () => {
      setHeatmapMetric('visits');
    };
  }, []);

  const mappedDescriptors = _.map(categoryDescriptors, d => {
    const categoryItems = _.filter(items, i => i.category === d.key);

    const minPercentRank = _.minBy(
      categoryItems,
      i => i.percentRank,
    )?.percentRank;

    const maxPercentRank = _.maxBy(
      categoryItems,
      i => i.percentRank,
    )?.percentRank;

    return {
      ...d,
      count: _.size(categoryItems),
      minPercentRank,
      maxPercentRank,
      orderLineCount: _.sumBy(categoryItems, i => i.pickCount),
      pickedCount: _.sumBy(categoryItems, i => i.pickCount),
      items: categoryItems,
    };
  });

  const orderLineCount = _.sumBy(mappedDescriptors, d => d.orderLineCount);

  let lastMinPercentRank = 0;
  const chartDescriptors = _.initial(_.values(mappedDescriptors));
  const chartDescriptorSize = _.size(chartDescriptors);
  const chartData: ABCSerie[] = _.initial(_.values(mappedDescriptors)).map(
    (descriptor, index) => {
      const isLast = index === chartDescriptorSize - 1;
      const chunkSize = Math.floor(descriptor.count / 5);
      const chunks = _.chunk(descriptor.items, chunkSize);

      const startPoint = {
        x: lastMinPercentRank,
        y: descriptor.fromCmlPercentile,
      };

      const extraPoints = _(chunks)
        .initial()
        .tail()
        .map(chunk => {
          const item = _.head(chunk);
          return {
            x: item.percentRank,
            y: item.cmlPercentRank,
          };
        })
        .value();

      const endPoint = {
        x: isLast ? 1 : descriptor.maxPercentRank ?? lastMinPercentRank,
        y: isLast ? 1 : descriptor.toCmlPercentile,
      };

      lastMinPercentRank = descriptor.maxPercentRank ?? lastMinPercentRank;

      return {
        id: descriptor.key,
        category: descriptor,
        data: [startPoint, ...extraPoints, endPoint],
      };
    },
  );

  const debouncedClick = useCallback(
    _.debounce(
      (
        id: string,
        value: boolean,
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        if (evt.detail === 2) {
          forceSelectSingleBucket(id);
        } else {
          updateBucketFilter(id, value);
        }
      },
      400,
    ),
    [heatmapFilter, buckets],
  );

  const updateBucketFilter = (id: string, value: boolean) => {
    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets: {
        ...heatmapFilter.hiddenBuckets,
        [id]: !value,
      },
    });
  };

  const forceSelectSingleBucket = (id: string) => {
    const hiddenBuckets = _.reduce(
      buckets,
      (acc, b) => ({
        ...acc,
        [b.id]: b.id !== id,
      }),
      {},
    );

    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets,
    });
  };

  return (
    <>
      <div
        data-component="FeedStockPanel"
        className={classNames('flex flex-col items-center relative w-full')}
      >
        <div
          className={classNames(
            'p-1 md:p-2 lg:p-3 xl:p-4',
            'ltr:px-0.5 rtl:px-0.5',
            'ltr:xl:px-1 rtl:xl:px-1',
            'm-0.5 bg-app-panel-dark/60',
            'h-60 xl:h-80',
          )}
          style={{ width: '100%', minWidth: '100%' }}
        >
          <label
            aria-label="Title"
            className="max-w-ch-30 text-xxs xl:text-sm uppercase pl-4 flex-1 truncate"
          >
            {t`ABC Items analysis (Cumulative)`}
          </label>
          <div className="h-full pb-4 pt-4 px-0.5 lg:px-2">
            <SimulationABCChart
              current={chartData}
              ticks={_(mappedDescriptors)
                .map(c => c.maxPercentRank)
                .compact()
                .value()}
              categories={mappedDescriptors}
            />
          </div>
        </div>
      </div>
      <div className="space-y-1 w-full">
        {_.values(mappedDescriptors).map((category, index) => {
          // last item in descriptors is for disabled location;
          const productCount = category.count;
          const cumulativeShareDiff = formatPercent(
            category.maxPercentRank - category?.minPercentRank,
          );
          const orderLinesShare = formatPercent(
            category.orderLineCount / orderLineCount,
          );
          const orderLines = formatInteger(category.orderLineCount);

          const orderLineStat = t(
            `{{cumulativeShareDiff}} of Items, {{orderLinesShare}} of Sales ({{orderLines}} OL)`,
            {
              cumulativeShareDiff,
              orderLinesShare,
              orderLines,
            },
          );
          const isDeselected =
            heatmapFilter?.hiddenBuckets[category.key] === true;

          return (
            <Stat
              key={`product-category-stat-${category.key}`}
              legendColor={[category.color, category.textColor]}
              inPanelMode
              hasHelper
              isActionable
              isPreview
              title={`[${category.tag}] ${category.title}`}
              value={formatInteger(productCount)}
              isSectionable
              isFullWidth
              isSelected={!isDeselected}
              onClick={evt => debouncedClick(category.key, isDeselected, evt)}
            >
              {category.count > 0 && (
                <div className="opacity-75">
                  <div>{orderLineStat}</div>
                </div>
              )}
            </Stat>
          );
        })}
      </div>
    </>
  );
};

export default FeedStockPanel;
