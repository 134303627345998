import { AnalyzeResultEventTypeSummary } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AnalyzeEventsDetailsPanel from '../../simulation/panels/AnalyzeEventsDetailsPanel';
import FeedProcessPie from '../components/FeedProcessPie';
import { actualityAnalyzedEventsSummary } from '../store/actuality.state';
import { feedProcessTypeTotals } from '../store/feed.state';

const FeedEventsStats: React.FC = () => {
  const { t } = useTranslation('feed');
  const analyzeEventsSummary = useRecoilValue(actualityAnalyzedEventsSummary);
  const processTypeTotals = useRecoilValue(feedProcessTypeTotals);

  return (
    <>
      <FeedProcessPie />
      {_.map(processTypeTotals, original => {
        const events: (AnalyzeResultEventTypeSummary & { distance: number })[] =
          _.filter(
            analyzeEventsSummary,
            analyze => analyze.processType === original.eventProcessType,
          ).map(e => ({
            count: e.eventsCount,
            eventType: e.eventType as any,
            totalCost: e.cost,
            totalDuration: e.duration,
            distance: e.distance,
          }));

        const eventsTotal = _.transform(
          events,
          (current, e) => {
            current.distance += e.distance;
            current.duration += e.totalDuration;
            current.cost += e.totalCost;
          },
          {
            distance: 0,
            duration: 0,
            cost: 0,
          },
        );
        return (
          <AnalyzeEventsDetailsPanel
            title={t(`{{title}}`, { title: original.eventProcessType })}
            subTitle={t`Activity Summary`}
            totalTitle={original.eventProcessType}
            events={events}
            eventsTotal={eventsTotal}
            showKpiSwitch
            showTotalStats
            showLegendStats
          />
        );
      })}
    </>
  );
};

export default FeedEventsStats;
