import {
  AnalyzeEventFragment,
  PicklistEventType,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsAgent } from '../../../AnalyticTracker';
import { FormattedValueWithUnit } from '../../../common/formatHelper';
import useFormatter from '../../../common/useFormatter';
import { getEventColor, getEventTitle } from '../../store/simulation.helper';
import { StatMicroInline } from './StatInline';

export type PicklistEventDetailsProps = {
  index: number;
  event: AnalyzeEventFragment;
  onHover: (event: AnalyzeEventFragment) => void;
  onClick: (event: AnalyzeEventFragment) => void;
};

const styleEventPath = 'w-2 rounded-sm';
const styleLegendPosition = 'absolute z-0 ltr:left-5 rtl:right-5';

export const PicklistEventDetails: React.FC<
  PicklistEventDetailsProps
> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const { event } = props;

  const duration = formatter.formatTimeSpan(event.duration, 1);
  const cost = formatter.formatCurrency(event.cost);

  let distance: FormattedValueWithUnit;
  let weight: FormattedValueWithUnit;
  let volume: FormattedValueWithUnit;

  if (event.details?.__typename === 'TravellingHorizontalDetails') {
    distance = formatter.formatDistance(event.details.distance);
    weight = formatter.formatWeight(event.details.weight);
    volume = formatter.formatVolume(event.details.volume);
  }
  if (event.details?.__typename === 'HandlingExecutionDetails') {
    weight = formatter.formatWeight(event.details.weight);
    volume = formatter.formatVolume(event.details.volume);
  }
  if (event.details?.__typename === 'TravellingVerticalDetails') {
    weight = formatter.formatWeight(event.details.weight);
    volume = formatter.formatVolume(event.details.volume);
  }
  if (event.details?.__typename === 'HandlingPalletReorderingDetails') {
    const reorderedWeight = _.sumBy(
      event.details.reordered,
      item => item.weight,
    );
    weight = formatter.formatWeight(reorderedWeight);
  }

  return (
    <div
      data-component="PicklistEvent"
      className={classNames(
        'group cursor-pointer',
        'relative text-xs',
        'divide-menu-600/50 bg-app-panel-dark/75 hover:bg-brand divide-x hover:bg-opacity-5',
      )}
      onMouseEnter={() => props.onHover(event)}
      onMouseLeave={() => props.onHover(null)}
      onClick={() => props.onClick(event)}
    >
      <div
        className={classNames(
          'flex flex-1 flex-col items-center',
          'min-h-[2.5rem] px-2 text-xs xl:text-sm',
          'border-menu-400/20 border-b border-solid',
        )}
      >
        <div
          data-component="EventDetailItemLegend"
          className={classNames(
            `any-hover:group-hover:border-menu-active my-px h-full`,
            styleEventPath,
            styleLegendPosition,
          )}
          style={{
            backgroundColor: getEventColor(event.eventType),
          }}
        />

        <div
          data-component="EventDetailItemCounter"
          className={classNames(
            'flex items-center justify-center',
            'text-xxs text-menu-text font-bold',
            'z-100 absolute h-6 w-10 ltr:left-1 rtl:right-1',
            'top-1',
            'rounded-full',
            'border-menu-700 any-hover:group-hover:border-menu-300 border',
            'bg-app-panel-dark',
          )}
          style={{
            color: getEventColor(event.eventType),
            borderColor: getEventColor(event.eventType),
          }}
          title={t(`Sequence ID: {{sequenceId}}`, {
            sequenceId: event?.sequenceId,
          })}
        >
          {props.index}
        </div>

        <span
          onClick={() => {
            props.onClick(event);
            try {
              analyticsAgent?.track(`Event: [${event.eventType}]`);
            } catch (ex) {
              console.debug('analyticsAgent Error:', ex);
            }
          }}
          title={
            getEventTitle(event.eventType, t) === 'Picking' ||
            getEventTitle(event.eventType, t) === 'Travelling'
              ? event?.locationId
                ? t`At location: ` + event.locationId
                : ''
              : ''
          }
          data-meta={_.map(
            event?.details,
            (value, key) => `${key}: ${value}`,
          ).join(',')}
          className={classNames(
            'w-full flex-1',
            'pt-2 ltr:mr-2 ltr:pl-12 rtl:ml-2 rtl:pr-12',
            'truncate text-sm',
            'text-menu-text/60 any-hover:group-hover:text-menu-active',
            'select-text',
          )}
        >
          {getEventTitle(event.eventType, t)}
          {event.eventType === PicklistEventType.HANDLING_EXECUTION && (
            <>
              <span className="mx-1">{t`at`}</span>
              <span className="text-menu-text any-hover:group-hover:text-menu-active">
                {event.locationId ?? '–-'}
              </span>
            </>
          )}
        </span>
        <div
          data-component="minStatContainer"
          className={classNames(
            'flex flex-1 flex-wrap items-center',
            'w-full py-2',
            'space-x-0.5 xl:space-x-1',
            'ltr:pl-12 rtl:pr-12',
            'text-menu-text',
          )}
        >
          {!_.isNil(distance) && (
            <StatMicroInline
              title={t`Distance`}
              value={`${distance.value}`}
              unitOfMeasure={`${distance.unitCode}`}
            />
          )}
          {!_.isNil(weight) && weight.raw > 0 && (
            <StatMicroInline
              title={t`Weight`}
              value={`${weight.value}`}
              unitOfMeasure={weight.unit}
            />
          )}
          {!_.isNil(volume) && volume.raw > 0 && (
            <StatMicroInline
              title={t`Volume`}
              value={`${volume.value}`}
              unitOfMeasure={volume.unit}
            />
          )}
          {!_.isNil(duration) && duration.raw > 0 && (
            <StatMicroInline
              title={t`Duration`}
              value={`${duration.value}`}
              unitOfMeasure={duration.unit}
            />
          )}
          {!_.isNil(cost) && cost.raw > 0 && (
            <StatMicroInline
              title={t`Cost`}
              value={
                cost.raw === 0
                  ? '—'
                  : // : cost.raw < 0.01
                    // ? `< ${cost.raw.toPrecision(1)}`
                    // : cost.raw < 0.1
                    // ? `< ${cost.raw.toPrecision(1)}`
                    cost.raw < 1
                    ? `< ${cost.raw.toPrecision(1)}`
                    : `${cost.value}` //${cost.unit}${cost.value}
              }
              unitOfMeasure={cost.unit}
            />
          )}
        </div>
      </div>
    </div>
  );
};
