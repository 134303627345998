import {
  AnalyzeResultFragment,
  AnalyzeResultJobsConnectionFragment,
  AnalyzeResultLocationsConnectionFragment,
  AnalyzedPicklistEventsFragment,
  AssignmentChangeSequenceAction,
  LoadPicklistDocument,
  LoadPicklistQuery,
  ResourceUsageSummaryFragment,
  ResourceUsageSummaryGroupBy,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AnalyzedProductsDataColumn,
  AnalyzedProductsDataRow,
  ReassignJobsDataColumn,
  ReassignJobsDataRow,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { secureClient } from '../../GraphQLClient';
import {
  AsyncLoadStatus,
  ContentViewAs,
  DatasetTableState,
  EventViewAs,
  ItemsViewAs,
  WorkloadViewAs,
} from '../../common/types';
import {
  FeedPicklists,
  getFeedPicklistQuery,
} from '../../feed/store/datasetQueries/feedPicklists';
import { executeDatasetQuery } from '../../feed/store/feed.helper';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { allocationAnalyzeResult } from './allocation/allocation.state';
import { optimisationAnalyzeResult } from './optimisation.state';
import {
  simulationAnalyzeResult,
  simulationAnalyzeResultAtom,
} from './simulation.state';
import { loadWorkforceData } from './workforce.helper';

const getKey = (postfix: string) => `warebee-simulation-analyze-${postfix}`;

/**
 * ANALYZE JOBS TABLE related props
 */

export const analyzeJobDetails = atom<AnalyzeResultJobsConnectionFragment>({
  key: getKey('job-details-data'),
  default: null,
});

export const analyzeJobDetailsLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('job-details-load-status'),
  default: AsyncLoadStatus.None,
});

export const analyzeJobDetailsTableState = atom<
  DatasetTableState<ReassignJobsDataColumn>
>({
  key: getKey('job-details-table-state'),
  default: {
    searchValues: {},
    sortValues: {
      stepId: SortDirection.ASC,
    },
  },
});

export const analyzeJobDetailsSelectedRow = atom<ReassignJobsDataRow>({
  key: getKey('job-details-table-selected-row'),
  default: null,
});

export const analyzeJobsTypeFilter = atom<AssignmentChangeSequenceAction>({
  key: getKey('jobs-type-filter'),
  default: null,
});

/**
 * ANALYZE PRODUCT TABLE related props
 */

export const analyzeLocationProductsData =
  atom<AnalyzeResultLocationsConnectionFragment>({
    key: getKey('location-products-data'),
    default: null,
  });

export const analyzeLocationProductsLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('location-products-load-status'),
  default: AsyncLoadStatus.None,
});

export const analyzeLocationProductsTableState = atom<
  DatasetTableState<AnalyzedProductsDataColumn>
>({
  key: getKey('location-products-table-state'),
  default: {
    searchValues: {},
    sortValues: {},
  },
});

export const analyzeLocationProductsSelectedRow = atom<AnalyzedProductsDataRow>(
  {
    key: getKey('location-products-table-selected-row'),
    default: null,
  },
);

export const analyzeContentViewAs = atom<ContentViewAs>({
  key: getKey('view-as'),
  default: 'layout',
});

export const eventsContentViewAs = atom<EventViewAs>({
  key: getKey('events-view-as'),
  default: 'picking',
});

export const workloadContentViewAs = atom<WorkloadViewAs>({
  key: getKey('workload-view-as'),
  default: 'workforce',
});

export const itemsContentViewAs = atom<ItemsViewAs>({
  key: getKey('item-view-as'),
  default: 'stock',
});

export const analyzeAgentPerformanceAgentId = atom<string>({
  key: getKey('agent-performance-data'),
  default: null,
});

export const analyzeAgentPerformance = selectorFamily<
  ResourceUsageSummaryFragment[],
  string
>({
  key: getKey('agent-performance-data'),
  get:
    (analyzeId: string) =>
    async ({ get }) => {
      if (_.isNil(analyzeId)) return null;
      const agentId = get(analyzeAgentPerformanceAgentId);
      return loadWorkforceData({
        analyzeId,
        drillKeys: [
          ResourceUsageSummaryGroupBy.AGENT,
          ResourceUsageSummaryGroupBy.EVENT_TYPE,
        ],
        agentIds: _.isNil(agentId) ? null : [agentId],
      });
    },
});

export const analyzeResultById = selectorFamily<AnalyzeResultFragment, string>({
  key: getKey('result-by-id'),
  get:
    (analyzeId: string) =>
    ({ get }) => {
      const base = get(simulationAnalyzeResult);
      const allocate = get(allocationAnalyzeResult);
      const optimised = get(optimisationAnalyzeResult);
      const all = [base, allocate, optimised];

      return _.find(all, anl => anl?.id === analyzeId);
    },
});

export const analyzeResultOrderEvents = selector<
  Record<string, AnalyzedPicklistEventsFragment[]>
>({
  key: getKey('result-orders-summary'),
  get: async ({ get }) => {
    const analyzeId = get(simulationAnalyzeResultAtom)?.id;
    if (!analyzeId) return null;
    const response = await secureClient.query<LoadPicklistQuery>({
      query: LoadPicklistDocument,
      variables: {
        analyzeId,
      },
    });

    const res = response?.data?.analyzeResult?.orders;

    const picklists = _.reduce(
      res?.content,
      (acc, order) => {
        return {
          ...acc,
          [order.orderId]: order.picklists,
        };
      },
      {},
    );
    return picklists;
  },
});

export const analyzeFeedPicklistEvents = selector<FeedPicklists>({
  key: getKey('latest-events'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getFeedPicklistQuery({
      datasetId: 'a76f33ad-235c-49cb-b1f5-b2803ca0e353',
      from: new Date(0),
      to: new Date(),
      disabledAgentTypes: [],
      disabledProcessTypes: [],
    }).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: 'Analyze Feed Picklist Events',
    });
    return result;
  },
});
