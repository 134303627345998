import {
  AssignmentOccupancyLocationDetailsFragment,
  AssignmentOccupancyLocationFilter,
  AssignmentOccupancyLocationFragment,
  AssignmentOccupancyLocationSortBy,
  AssignmentOccupancyLocationSortOption,
  AssignmentOccupancyLocationStatus,
  SortDirection,
  useExportAssignmentOccupancyMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { containsFilter, numberFilter } from '../common/filterHelper';
import { AsyncLoadStatus } from '../common/types';
import useFormatter from '../common/useFormatter';
import DatasetTable, { ColumnConfig } from '../components/DatasetTable';
import FormattedValue from '../components/FormattedValue';
import { ContainerScroll } from '../components/layout/ContainerScroll';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLocationIdWithToggle,
  viewerSelectedPlaneId,
} from '../layout/viewer/store/viewer.state';
import useLoadAssignmentOccupancyLocations from '../simulation/hooks/useLoadAssignmentOccupancyLocations';
import { getAssignmentOccupancyStatusMap } from '../simulation/store/occupancy/assignmentOccupancy.helper';
import {
  assignmentOccupancyDataTableState,
  assignmentOccupancyLocationsData,
  assignmentOccupancyLocationsDataLoadStatus,
  assignmentOccupancyMeta,
} from '../simulation/store/occupancy/assignmentOccupancy.state';
import AssignmentOccupancyTag from './AssignmentOccupancyTag';

const sortFieldsMapping: Partial<
  Record<AssignmentOccupancyTableColumn, AssignmentOccupancyLocationSortOption>
> = {
  occupancy: AssignmentOccupancyLocationSortOption.OCCUPANCY,
  locationId: AssignmentOccupancyLocationSortOption.LOCATION_ID,
};

export type AssignmentOccupancyTableRow =
  AssignmentOccupancyLocationDetailsFragment &
    AssignmentOccupancyLocationFragment & {
      locationPlaneTitle: string;
      locationBayTitle: string;
      locationAisleTitle: string;
      //  uom: string;
    };

export type AssignmentOccupancyTableColumn = keyof AssignmentOccupancyTableRow;

const AssignmentOccupancyTable: React.FC = () => {
  const { t } = useTranslation('simulation');
  const occupancyMeta = useRecoilValue(assignmentOccupancyMeta);
  const loadStatus = useRecoilValue(assignmentOccupancyLocationsDataLoadStatus);
  const locData = useRecoilValue(assignmentOccupancyLocationsData);
  const [loadCallback, cancelLoad] = useLoadAssignmentOccupancyLocations();
  const [state, setState] = useRecoilState(assignmentOccupancyDataTableState);
  const selectLocation = useSetRecoilState(viewerSelectedLocationIdWithToggle);
  const selectBay = useSetRecoilState(viewerSelectedBayIdAtom);
  const selectPlane = useSetRecoilState(viewerSelectedPlaneId);
  const [runExportCSV] = useExportAssignmentOccupancyMutation();
  const formatter = useFormatter();
  const { searchValues, sortValues } = state;

  function callDataLoad(
    searchValues: Partial<Record<AssignmentOccupancyTableColumn, string>>,
    sortValues: Partial<Record<AssignmentOccupancyTableColumn, SortDirection>>,
    page = { isAppend: false, skip: 0 },
  ) {
    const filter: AssignmentOccupancyLocationFilter = {
      status: searchValues.occupancy
        ? ([searchValues.occupancy] as AssignmentOccupancyLocationStatus[])
        : null,
      locationId: containsFilter(searchValues.locationId),
      locationPlaneTitle: containsFilter(searchValues.locationPlaneTitle),
      locationAisleTitle: containsFilter(searchValues.locationAisleTitle),
      locationBayTitle: containsFilter(searchValues.locationBayTitle),
      locationLevel: numberFilter(searchValues.locationLevel),
      locationRackingType: containsFilter(searchValues.locationRackingType),
    };

    const sortBy: AssignmentOccupancyLocationSortBy[] = _.map(
      sortValues,
      (value, key, index) => {
        const sortObjectKey = sortFieldsMapping[key];
        return sortObjectKey
          ? { field: sortObjectKey, direction: value }
          : null;
      },
    ).filter(i => !!i);

    cancelLoad();
    loadCallback({
      filter,
      sortBy,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(searchValues, sortValues);
  }, [searchValues, sortValues]);

  async function startExportCSV() {
    const { data, errors } = await runExportCSV({
      variables: {
        assignmentOccupancyId: occupancyMeta?.id,
      },
    });
    //
    return {
      errors: [],
      job: data.exportAssignmentOccupancy,
    };
  }

  const statusMap = {
    '': t`All`,
    ...getAssignmentOccupancyStatusMap(t),
  };

  const columnsConfig: ColumnConfig<AssignmentOccupancyTableRow>[] = [
    {
      field: 'occupancy',
      title: t`Status`,
      hasSort: true,
      isHeader: true,
      render: (v: number) => <AssignmentOccupancyTag occupancy={v} />,
      hasFilter: true,
      filterOptions: statusMap,
    },
    {
      field: 'locationId',
      title: t`Location`,
      hasFilter: true,
      isHeader: true,
      hasSort: true,
    },
    {
      field: 'locationPlaneTitle',
      title: t`Floor`,
      hasFilter: true,
    },
    {
      field: 'locationAisleTitle',
      title: t`Aisle`,
      hasFilter: true,
    },
    {
      field: 'locationBayTitle',
      title: t`Bay`,
      hasFilter: true,
    },
    {
      field: 'locationLevel',
      title: t`Level`,
      hasFilter: true,
    },
    {
      field: 'locationRackingType',
      title: t`Location Type`,
      hasFilter: true,
    },
    {
      field: 'locationVolume',
      title: t`Locations Volume`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatVolume(v)} />
      ),
    },
    {
      field: 'occupiedVolume',
      title: t`Occupied Volume`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatVolume(v)} />
      ),
    },
  ];

  const isLoading = loadStatus === AsyncLoadStatus.Loading;
  const itemsCount = locData?.content?.length || 0;
  const totalCount = locData?.totalCount ?? 0;

  const reduceLocation = (
    assignmentLocationData: AssignmentOccupancyLocationDetailsFragment,
  ) => {
    const loc = assignmentLocationData.location;
    return {
      ...assignmentLocationData,
      ...loc,
      locationPlaneTitle: loc.plane.title,
      locationBayTitle: loc.bay.title,
      locationAisleTitle: loc.aisle.title,
    } as AssignmentOccupancyTableRow;
  };

  const data: AssignmentOccupancyTableRow[] = _.map(
    locData?.content,
    reduceLocation,
  );

  function onLoadNext() {
    callDataLoad(searchValues, sortValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  function onRowSelect(row: AssignmentOccupancyTableRow, index: number) {
    const targetPlaneId = row.location?.plane?.id;
    const targetBayId = row.location.bay?.id;
    const targetLocationId = row.location.locationId;
    targetPlaneId && selectPlane(targetPlaneId);
    targetBayId && selectBay(targetBayId);
    targetLocationId && selectLocation(targetLocationId);
  }

  return (
    <ContainerScroll className="flex flex-col">
      <DatasetTable
        isActionable
        subtitle={t`Filtered Items for`}
        title={t`Assignment Occupancy`}
        isSticky
        id={'assignment-occupancy-locations-table'}
        columnsConfig={columnsConfig}
        keyFields={['locationId']}
        data={data}
        onLoadNext={onLoadNext}
        onSearch={searchValues => setState({ ...state, searchValues })}
        totalCount={totalCount}
        searchValues={searchValues}
        sortBy={sortValues}
        onSort={sortValues => setState({ ...state, sortValues })}
        isLoading={isLoading}
        onRowSelect={onRowSelect}
        onStartExportClick={startExportCSV}
      />
    </ContainerScroll>
  );
};

export default AssignmentOccupancyTable;
