import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedValueWithUnit } from '../common/formatHelper';
import * as Icon from './icons';
import { UnitOfMeasure } from './stats/UnitOfMeasure';

export type FormattedValueProps = {
  value: FormattedValueWithUnit;
  className?: string;
  hasIssue?: boolean;
  hasIssueHelper?: string;
};

const FormattedValue: React.FC<FormattedValueProps> = props => {
  const { t } = useTranslation('app');
  const { value, raw: unformattedValue, unit } = props.value;
  return (
    <span
      data-component="FormattedValue"
      aria-label="FormattedValue"
      className={classNames('flex items-baseline', props.className)}
      title={t(`(RAW):{{unformattedValue}}`, { unformattedValue })}
    >
      {props.hasIssue && (
        <Icon.TriangleInfo className="w-4 h-4 ltr:mr-2 rtl:ml-2 fill-current text-menu-active/50" />
      )}
      <span>{value}</span>
      {!_.isNil(unit) && (
        <UnitOfMeasure classSize="text-xs" unitOfMeasure={unit} />
      )}
    </span>
  );
};
export default FormattedValue;
