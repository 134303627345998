import { BillableOperationType } from '@warebee/frontend/app-billing-graphql-api';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatDateTime } from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import InboxZero from '../components/InboxZero';
import LoadingIndicator from '../components/LoadingIndicator';
import { ContainerCol } from '../components/layout/ContainerFlex';
import { warehouseSelectedId } from '../store/warehouse.state';
import useLoadWarehouseBillingState from './hooks/useLoadWarehouseBillingState';
import {
  appBillingWarehouseLoadStatus,
  appBillingWarehouseState,
} from './store/appBilling.state';

export type AppBillingWarehouseStatusProps = {};

const AppBillingWarehouseStatus: React.FC<
  AppBillingWarehouseStatusProps
> = props => {
  const { t } = useTranslation('app');
  const whId = useRecoilValue(warehouseSelectedId);
  const whState = useRecoilValue(appBillingWarehouseState);
  const whLoadStatus = useRecoilValue(appBillingWarehouseLoadStatus);
  const updateState = useLoadWarehouseBillingState();

  useEffect(() => {
    if (_.isNil(whState)) {
      updateState([whId]);
    }
  }, [updateState, whId, whState]);

  const isLoading =
    whLoadStatus === AsyncLoadStatus.Loading ||
    whLoadStatus === AsyncLoadStatus.None;

  if (isLoading) {
    return <LoadingIndicator message={t`Loading...`} />;
  }
  const hasAllowedOperation = !_.isEmpty(whState?.allowedOperations);
  const hasSubscription = !_.isEmpty(whState?.currentSubscriptions);

  if (!hasAllowedOperation) {
    return <InboxZero message={t`No simulations allowed`} />;
  }
  const allowedOperations = whState.allowedOperations;

  const subEnds: number = _.head(
    whState.currentSubscriptions,
  )?.currentPeriodEnd;

  const isOptimiseAllowed = _.includes(
    allowedOperations,
    BillableOperationType.OPTIMIZE,
  );
  const analyzeCounter = _.find(
    whState.balance,
    b => b.operationType === BillableOperationType.ANALYZE,
  );
  const optimizeCounter = _.find(
    whState.balance,
    b => b.operationType === BillableOperationType.OPTIMIZE,
  );

  return (
    <ContainerCol overflow componentName="AppBillingWarehouseStatus">
      <div className="border-b border-menu-400 py-4 mt-4">
        <div className="text-sm uppercase">{t`Included in warehouse plan:`}</div>
        <ul>
          <li className="text-xl text-menu-active">
            {isOptimiseAllowed
              ? t`Warehouse Simulations for Optimization, Analysis and Compliance.`
              : t`Warehouse Simulations for Analysis and Compliance only.`}
          </li>
        </ul>
      </div>

      {(!_.isNil(optimizeCounter) ?? !_.isNil(analyzeCounter)) && (
        <>
          {!_.isNil(analyzeCounter) && (
            <div className="border-b border-menu-400 py-4 mt-4">
              <div className="text-sm uppercase">{t`Analyse Simulations:`}</div>
              <div className="text-xl text-menu-active">
                {analyzeCounter?.balance}
              </div>
            </div>
          )}
          {!_.isNil(optimizeCounter) && (
            <div className="border-b border-menu-400 py-4 mt-4">
              <div className="text-xs uppercase">{t`Optimise Simulations:`}</div>
              <div className="text-xl text-menu-active">
                {optimizeCounter?.balance}
              </div>
            </div>
          )}
        </>
      )}

      {subEnds && (
        <div className="border-b border-menu-400 py-4 mt-4">
          <div className="text-sm uppercase">{t`Subscription renews/expires on:`}</div>
          <div className="text-xl text-menu-active">
            {formatDateTime(new Date(subEnds))}
          </div>
        </div>
      )}
    </ContainerCol>
  );
};

export default AppBillingWarehouseStatus;
