import {
  AssignmentComplianceDataFragment,
  AssignmentComplianceItemFragment,
  AssignmentComplianceItemStatus,
  AssignmentComplianceSummaryFragment,
  BatchJobStatus,
  LayoutLocationWithMatchingFiltersFragment,
  LoadAssignmentComplianceLocationsDocument,
  LoadAssignmentComplianceLocationsQuery,
  LoadAssignmentComplianceLocationsQueryVariables,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import { AssignmentComplianceTableColumn } from '../../assignmentCompliance/AssignmentComplianceTable';
import { AsyncLoadStatus, DataTableState } from '../../common/types';
import { secureClient } from '../../GraphQLClient';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
  viewerShowLocations,
} from '../../layout/viewer/store/viewer.state';
import { loadLocationsByPolicy } from './assignmentPolicy.helper';
import { assignmentPolicyDocument } from './assignmentPolicy.state';
import { AssignmentPolicySelectedIdentity } from './assignmentPolicy.types';
import { simulationCurrent, simulationLayoutId } from './simulation.state';
import {
  simulationComplianceTabKey,
  simulationWizardSelectedStepId,
} from './simulation.wizard.state';

const getKey = (postfix: string) => `warebee-assignment-compliance-${postfix}`;

export const assignmentComplianceSummary =
  atom<AssignmentComplianceSummaryFragment>({
    key: getKey('document-atom'),
    default: null,
  });

export const assignmentComplianceStatus = selector<BatchJobStatus>({
  key: getKey('document-status'),
  get: ({ get }) => get(assignmentComplianceSummary)?.status,
  set: ({ get, set }, value) => {
    set(assignmentComplianceSummary, {
      ...get(assignmentComplianceSummary),
      status: value,
    });
  },
});

export const assignmentComplianceSummaryLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('summary-load-status'),
  default: AsyncLoadStatus.Ok,
});

export const assignmentComplianceLocationsByLevel = selector<
  Record<string, AssignmentComplianceItemFragment[]>
>({
  key: getKey('noncompliant-location-by-rule'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const tabKey = get(simulationComplianceTabKey);
    const planeId = get(viewerSelectedPlaneId);
    const level = get(viewerSelectedLevel);
    const compliance = get(assignmentComplianceSummary);
    const stepId = get(simulationWizardSelectedStepId);
    if (
      _.isNil(planeId) ||
      _.isNil(level) ||
      stepId !== 'compliance' ||
      tabKey !== 'tab-compliance-policy' ||
      compliance?.status !== BatchJobStatus.READY
    )
      return null;

    const response = await secureClient.query<
      LoadAssignmentComplianceLocationsQuery,
      LoadAssignmentComplianceLocationsQueryVariables
    >({
      query: LoadAssignmentComplianceLocationsDocument,
      variables: {
        simulationId: sim?.id,
        filter: {
          planeId: [planeId],
          level: [level],
        },
      },
    });
    const locationsMap = _.groupBy(
      response.data.simulation?.assignmentCompliance?.items?.content,
      l => l.locationId,
    );
    return locationsMap;
  },
});

export const assignmentComplianceLocationsByLevelAll = selector<
  Record<string, LayoutLocationWithMatchingFiltersFragment>
>({
  key: getKey('locations-by-rule'),
  get: async ({ get }) => {
    const layoutId = get(simulationLayoutId);
    const planeId = get(viewerSelectedPlaneId);
    const level = get(viewerSelectedLevel);
    const compliance = get(assignmentComplianceSummary);
    const stepId = get(simulationWizardSelectedStepId);
    const tabKey = get(simulationComplianceTabKey);
    const policy = get(assignmentPolicyDocument);
    if (
      _.isNil(planeId) ||
      _.isNil(level) ||
      stepId !== 'compliance' ||
      tabKey !== 'tab-compliance-policy' ||
      compliance?.status !== BatchJobStatus.READY
    )
      return null;

    return await loadLocationsByPolicy({
      layoutId,
      policy,
      filter: {
        planeId: [planeId],
        level: [level],
      },
    });
  },
});

export const assignmentComplianceLocationsByBay = selector<
  Record<string, AssignmentComplianceItemFragment[]>
>({
  key: getKey('product-location-by-rule'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const planeId = get(viewerSelectedPlaneId);
    const bay = get(viewerSelectedBayIdAtom);
    const compliance = get(assignmentComplianceSummary);
    const stepId = get(simulationWizardSelectedStepId);
    const tabKey = get(simulationComplianceTabKey);
    if (
      _.isNil(planeId) ||
      _.isNil(bay) ||
      stepId !== 'compliance' ||
      tabKey !== 'tab-compliance-policy' ||
      compliance?.status !== BatchJobStatus.READY
    )
      return null;

    const response = await secureClient.query<
      LoadAssignmentComplianceLocationsQuery,
      LoadAssignmentComplianceLocationsQueryVariables
    >({
      query: LoadAssignmentComplianceLocationsDocument,
      variables: {
        simulationId: sim?.id,
        filter: {
          planeId: [planeId],
          bayId: [bay],
        },
      },
    });
    const locationsMap = _.groupBy(
      response.data.simulation?.assignmentCompliance?.items?.content,
      l => l.locationId,
    );
    return locationsMap;
  },
});

export const assignmentComplianceLocationsAllByBay = selector<
  Record<string, LayoutLocationWithMatchingFiltersFragment>
>({
  key: getKey('locations-all-by-bay'),
  get: async ({ get }) => {
    const layoutId = get(simulationLayoutId);
    const planeId = get(viewerSelectedPlaneId);
    const compliance = get(assignmentComplianceSummary);
    const stepId = get(simulationWizardSelectedStepId);
    const policy = get(assignmentPolicyDocument);
    const tabKey = get(simulationComplianceTabKey);
    const bay = get(viewerSelectedBayIdAtom);
    if (
      _.isNil(planeId) ||
      _.isNil(bay) ||
      stepId !== 'compliance' ||
      tabKey !== 'tab-compliance-policy' ||
      compliance?.status !== BatchJobStatus.READY
    )
      return null;

    return await loadLocationsByPolicy({
      layoutId,
      policy,
      filter: {
        planeId: [planeId],
        bayId: [bay],
      },
    });
  },
});

const assignmentComplianceSelectedRuleDetailsAtom =
  atom<AssignmentPolicySelectedIdentity>({
    key: getKey('selected-rule-details-atom'),
    default: null,
  });

export const assignmentComplianceSelectedIdentity =
  selector<AssignmentPolicySelectedIdentity>({
    key: getKey('selected-rule-details'),
    get: ({ get }) => get(assignmentComplianceSelectedRuleDetailsAtom),
    set: ({ get, set }, value: AssignmentPolicySelectedIdentity) => {
      if (value?.filterType === 'locations') {
        set(viewerShowLocations, true);
      }

      set(assignmentComplianceDataTableState, {
        ...get(assignmentComplianceDataTableState),
        searchValues: {
          status: AssignmentComplianceItemStatus.NONCOMPLIANT,
          itemRuleIds: value?.filterType === 'items' ? value.ruleId : null,
          locationRuleIds:
            value?.filterType === 'locations' ? value.ruleId : null,
        },
      });

      set(assignmentComplianceSelectedRuleDetailsAtom, value);
    },
  });

export const assignmentComplianceDataTableState = atom<
  DataTableState<AssignmentComplianceTableColumn>
>({
  key: getKey('data-table-state'),
  default: {
    searchValues: {
      status: AssignmentComplianceItemStatus.NONCOMPLIANT,
    },
    sortValues: {
      consignee: SortDirection.ASC,
    },
  },
});

export const assignmentComplianceItemsData =
  atom<AssignmentComplianceDataFragment>({
    key: getKey('table-data'),
    default: null,
  });

export const assignmentComplianceItemsDataLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('table-data-load-status'),
  default: AsyncLoadStatus.None,
});

export const assignmentComplianceShowTable = atom<boolean>({
  key: getKey('show-table-data'),
  default: false,
});

export const assignmentComplianceUseComplianceColors = atom<boolean>({
  key: getKey('use-compliance-colors'),
  default: false,
});
