import {
  AssignmentComplianceItemFilter,
  AssignmentComplianceItemSortBy,
  AssignmentComplianceItemSortOption,
  AssignmentComplianceItemStatus,
  LoadAssignmentComplianceItemsDocument,
  SortDirection,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AssignmentComplianceConverterConfig,
  AssignmentComplianceDataRow,
  AssignmentComplianceExportJobParams,
  assignmentComplianceExportJobParams,
  getAssignmentComplianceTableRows,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import DatasetTable, { ColumnConfig } from '../components/DatasetTable';
import { Container } from '../components/layout/ContainerFlex';
import useLoadAssignmentComplianceItems from '../simulation/hooks/useLoadAssignmentComplianceItems';
import { getAssignmentComplianceStatusMap } from '../simulation/store/assignmentCompliance.helper';
import {
  assignmentComplianceDataTableState,
  assignmentComplianceItemsData,
  assignmentComplianceItemsDataLoadStatus,
} from '../simulation/store/assignmentCompliance.state';
import { AP_FALLBACK_RULE_ID } from '../simulation/store/assignmentPolicy.default';
import { assignmentPolicyDocument } from '../simulation/store/assignmentPolicy.state';
import { simulationCurrent } from '../simulation/store/simulation.state';
import { getAssignmentComplianceTableConfig } from './getAssignmentComplianceTableConfig';

const sortFieldsMapping: Partial<
  Record<AssignmentComplianceTableColumn, AssignmentComplianceItemSortOption>
> = {
  consignee: AssignmentComplianceItemSortOption.CONSIGNEE,
  sku: AssignmentComplianceItemSortOption.SKU,
  locationId: AssignmentComplianceItemSortOption.LOCATION_ID,
};

export type AssignmentComplianceTableColumn = keyof AssignmentComplianceDataRow;

const AssignmentComplianceTable: React.FC = () => {
  const { t } = useTranslation('simulation');

  const sim = useRecoilValue(simulationCurrent);
  const policy = useRecoilValue(assignmentPolicyDocument);
  const complianceItems = useRecoilValue(assignmentComplianceItemsData);
  const [loadCallback, cancelLoad] = useLoadAssignmentComplianceItems();
  const loadStatus = useRecoilValue(assignmentComplianceItemsDataLoadStatus);
  const [state, setState] = useRecoilState(assignmentComplianceDataTableState);
  const [runExportCSV] = useRunExportJobMutation();

  const { searchValues, sortValues } = state;

  function getFilter(): AssignmentComplianceItemFilter {
    return {
      status: searchValues?.status
        ? [searchValues.status as AssignmentComplianceItemStatus]
        : null,
      locationRuleId: searchValues?.locationRuleIds
        ? [searchValues.locationRuleIds]
        : null,
      itemRuleId: searchValues?.itemRuleIds ? [searchValues.itemRuleIds] : null,
      violatedRuleId: searchValues?.violatedRuleIds
        ? [searchValues.violatedRuleIds]
        : null,
    };
  }
  function getSortBy(): AssignmentComplianceItemSortBy[] {
    return _.map(sortValues, (value, key, index) => {
      const sortObjectKey = sortFieldsMapping[key];
      return sortObjectKey ? { field: sortObjectKey, direction: value } : null;
    }).filter(i => !!i);
  }

  function callDataLoad(
    searchValues: Partial<Record<AssignmentComplianceTableColumn, string>>,
    sortValues: Partial<Record<AssignmentComplianceTableColumn, SortDirection>>,
    page = { isAppend: false, skip: 0 },
  ) {
    const filter: AssignmentComplianceItemFilter = getFilter();
    const sortBy: AssignmentComplianceItemSortBy[] = getSortBy();
    cancelLoad();
    loadCallback({
      filter,
      sortBy,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(searchValues, sortValues);
  }, [searchValues, sortValues]);

  const rulesMap = _.reduce(
    policy?.rules,
    (acc, rule) => {
      return {
        ...acc,
        [rule.id]: rule.title,
      };
    },
    {
      '': t`All`,
      [AP_FALLBACK_RULE_ID]: t`Default rule`,
    },
  );

  const columnsConfig: ColumnConfig<AssignmentComplianceDataRow>[] =
    getAssignmentComplianceTableConfig(policy, t);

  const converterConfig: AssignmentComplianceConverterConfig = {
    columnsConfig,
    dictionaries: {
      status: getAssignmentComplianceStatusMap(t),
      rules: rulesMap,
    },
  };

  async function startExportCSV() {
    const variables: AssignmentComplianceExportJobParams = {
      ...assignmentComplianceExportJobParams,
      query: LoadAssignmentComplianceItemsDocument.loc.source.body,
      config: converterConfig,
      variables: {
        simulationId: sim.id,
        filter: getFilter(),
        sortBy: getSortBy(),
        page: null,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const isLoading = loadStatus === AsyncLoadStatus.Loading;
  const itemsCount = complianceItems?.content?.length || 0;
  const totalCount = complianceItems?.totalCount ?? 0;

  const flattenData = getAssignmentComplianceTableRows(
    complianceItems?.content,
    converterConfig,
  );

  function onLoadNext() {
    callDataLoad(searchValues, sortValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  return (
    <Container col>
      <DatasetTable
        isActionable
        subtitle={t`Filtered Items for`}
        title={t`Assignment compliance`}
        isSticky
        id={'assignment-compliance-items-table'}
        columnsConfig={columnsConfig}
        keyFields={['itemId', 'locationId']}
        data={flattenData}
        onLoadNext={onLoadNext}
        onSearch={searchValues => setState({ ...state, searchValues })}
        totalCount={totalCount}
        searchValues={searchValues}
        sortBy={sortValues}
        onSort={sortValues => setState({ ...state, sortValues })}
        isLoading={isLoading}
        onStartExportClick={startExportCSV}
        hasCounter
      />
    </Container>
  );
};

export default AssignmentComplianceTable;
