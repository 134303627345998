import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

export type TagProps = PropsWithChildren & {
  className?: string;
  classType?: string;
  leftTag?: boolean;
  rightTag?: boolean;
  fullWidth?: boolean;
};

const Tag: React.FC<TagProps> = props => {
  return (
    <span
      data-component="Tag"
      className={classNames(
        'flex-inline flex-col justify-center',
        'select-text',
        'text-xs',
        'px-3 py-1',
        !props.leftTag && !props.rightTag
          ? ['rounded', 'mx-1', 'items-center']
          : '',
        props.leftTag ? 'rounded-l ltr:ml-1 rtl:mr-1 items-start' : '',
        props.rightTag ? 'rounded-r ltr:mr-1 rtl:ml-1 items-start' : '',
        props.fullWidth ? 'flex-1' : '',
        props.classType ? props.classType : 'bg-app-panel',
        props.className,
      )}
    >
      {props.children}
    </span>
  );
};

export default Tag;
