import classNames from 'classnames';
import _ from 'lodash';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getQualitativeColor } from '../../common/color.helper';
import {
  toHumanReadableTime,
  toLocaleDateString,
} from '../../common/dateTimeHelper';
import { formatInteger } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import LoadingIndicator from '../../components/LoadingIndicator';
import TitleSection from '../../components/layout/TitleSection';
import { Stat } from '../../components/stats/Stat';
import { StatListItem } from '../../components/stats/StatListItem';
import { AgentCategoryId } from '../../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../../resourcePolicy/agentData/agentDataHelper';
import { CollapsibleStateMap } from '../../store/collapsible.default';
import { collapsibleStateAtom } from '../../store/collapsible.state';
import { actualityResourcePolicy } from '../store/actuality.state';
import { ActualityAnalyzedJob } from '../store/datasetQueries/actualityAnalyzedJobs';
import {
  feedDistinctAgents,
  feedIsDateRangeWithinDay,
  feedSelectedPickListId,
} from '../store/feed.state';
import ActualityAnalyzedJobEvents from './ActualityAnalyzedJobEvents';
import AnalyzeStackedLine from './AnalyzeStakedLine';

export type FeedPicklistDetailsProps = {
  index: number;
  job: ActualityAnalyzedJob;
};

export const FeedPicklistDetails: React.FC<
  FeedPicklistDetailsProps
> = props => {
  const { job } = props;
  const { t } = useTranslation('feed');
  const formatter = useFormatter();
  const isWithinOneDay = useRecoilValue(feedIsDateRangeWithinDay);
  const [selectedPickList, setSelectedPicklist] = useRecoilState(
    feedSelectedPickListId,
  );

  const allAgents = useRecoilValue(feedDistinctAgents);
  const resourcePolicy = useRecoilValue(actualityResourcePolicy);
  const setCollapsible = useSetRecoilState(collapsibleStateAtom);

  const agentMapByResourcePolicy = _.keyBy(resourcePolicy.agents, 'id');
  const agentMapByData = _.keyBy(allAgents, 'agentId');

  const handleStatClick = () => {
    // Toggle selected picklist
    setSelectedPicklist(isSelected ? null : job.jobId);
    const changeCollapsible: Partial<CollapsibleStateMap> = {};

    if (!_.isNil(selectedPickList) && !isSelected) {
      changeCollapsible[getSectionKey(selectedPickList)] = {
        isCollapsed: true,
      };
    }

    setCollapsible(current => ({
      ...current,
      ...changeCollapsible,
      [getSectionKey(job.jobId)]: {
        isCollapsed: isSelected,
      },
    }));
  };

  const picklistDate = isWithinOneDay
    ? toHumanReadableTime(job.minEventTime)
    : toLocaleDateString(job.minEventTime);
  const title = `${job.jobId} @ ${picklistDate}`;
  const getSectionKey = id => `feed-picklist-events-${id}`;

  const isSelected = selectedPickList === job.jobId;
  const agentColors = getQualitativeColor(job.agentId, 'dimension');

  const from = job.minEventTime;
  const to = job.maxEventTime;

  const distance = job.distance;
  const picklistDistance = formatter.formatDistance(job.distance);

  const actualDurationRaw = to - from;
  const actualDuration = formatter.formatTimeSpan(actualDurationRaw / 1000, 2);

  const picklistPickDuration = formatter.formatTimeSpan(job.duration / 1000);
  const analyzedDuration = formatter.formatTimeSpan(job.duration, 2);
  const travellingDuration = formatter.formatTimeSpan(job.durationTravelling);

  const statStyle = classNames(
    'm-0.5 border border-px',
    isSelected
      ? 'border-app-panel-dark/50 !text-menu-active-text !bg-menu-active'
      : 'shadow border-menu/50',
  );

  const statStyleThreshold = classNames(
    'm-0.5 border border-px',
    isSelected
      ? 'border-app-panel-dark/50 !text-menu-active-text !bg-menu-active'
      : 'shadow border-menu/50',
  );
  const statStyleThresholdNull = classNames(
    'm-0.5 border border-px',
    isSelected
      ? 'border-app-panel-dark/50 !text-menu-active-text !bg-menu-active'
      : 'shadow border-menu/50',
  );

  let globalEventVisualIndex = 0;

  const agentType = agentMapByData[props.job.agentId]?.agentType;
  const resourceTypeId = agentType
    ? (agentMapByResourcePolicy[agentType]?.resourceTypeId as AgentCategoryId)
    : null;
  const agentIcon = resourceTypeId ? getAgentIcon(resourceTypeId) : null;

  return (
    <div data-component="PicklistDetails" className="group/eventlist relative">
      <Stat
        inPanelMode
        hasHelper
        isActionable
        isPreview
        titleAlt={title}
        title={title}
        toggleIconOpen={agentIcon}
        toggleIconClosed={agentIcon}
        value={formatInteger(job.lines)}
        unitOfMeasure={t`lines`}
        isUnitPrefix={false}
        isFullWidth
        isSelected={isSelected}
        onClick={handleStatClick}
        className={classNames('!xl:p-2')}
        color={isSelected ? agentColors : null}
      >
        <div className={classNames('flex w-full flex-col flex-wrap')}>
          {/* <CopyToClipboardButton value={picklist.id} /> */}
          {distance > 0 && (
            <StatListItem
              title={t`Distance`}
              value={picklistDistance.value}
              unitOfMeasure={picklistDistance.unit}
            />
          )}

          {analyzedDuration.value ? null : (
            <StatListItem
              title={t`Duration`}
              value={actualDuration.value}
              unitOfMeasure={actualDuration.unit}
            />
          )}
        </div>
        <div className="w-full flex-1">
          {analyzedDuration.raw > 0 && actualDuration.raw > 0 && (
            <>
              <StatListItem
                title={t`Actual vs Standard`}
                valueAfter={analyzedDuration}
                valueBefore={actualDuration}
                unitOfMeasure={analyzedDuration.unit}
                isComparable
                separator={<span className="mx-1">{t`vs`}</span>}
              />
            </>
          )}
        </div>
      </Stat>

      <TitleSection
        id={getSectionKey(job.jobId)}
        dataComponent="Picklist"
        className={classNames(
          'relative',
          'ltr:pl-4 rtl:pr-4',
          '!top-0',
          isSelected ? 'bg-app-panel bg-opacity-100' : '',
        )}
        title={t`Events`}
        // inListView
        inPanelView
        eventView
        collapsible
        color={isSelected ? agentColors : null}
      >
        <AnalyzeStackedLine {...job} />

        <Suspense
          fallback={
            <LoadingIndicator selfCenter message={t`Loading Events...`} />
          }
        >
          <ActualityAnalyzedJobEvents jobId={job.jobId} />
        </Suspense>
      </TitleSection>
    </div>
  );
};
