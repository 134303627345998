import { QueryCreator } from 'kysely';
import { toLocaleDateTimeDBString } from '../../../../common/dateTimeHelper';
import { DatasetDatabase } from './datasetQueryBuilder';
import { ActivityFeedQueryBuilderParams } from './feedEventsQueryBuilders';

export type AnalyzeEventsQueryBuilderParams = ActivityFeedQueryBuilderParams & {
  actualityId: string;
};

export function getAnalyzeEventsFilteredQueryBuilder(
  params: AnalyzeEventsQueryBuilderParams,
  db: QueryCreator<DatasetDatabase>,
) {
  const start = params.from
    ? toLocaleDateTimeDBString(params.from)
    : new Date(0);
  const end = params.to ? toLocaleDateTimeDBString(params.to) : new Date();
  let at = db
    .selectFrom('___analyzed_job_event___')
    .where('documentId', '=', params.actualityId);

  // .where(
  //   sql<any>`
  //   ${sql.ref('j')} >= cast(${start} as timestamp)`,
  // )
  // .where(
  //   sql<any>`
  // ${sql.ref('jobDate')} <= cast(${end} as timestamp)`,
  // );
  return at;
}
