import { useApolloClient } from '@apollo/client';
import {
  StopOptimizationRunDocument,
  StopOptimizationRunMutation,
  StopOptimizationRunMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { FetchResult } from 'apollo-link';
import { nanoid } from 'nanoid';
import { useRecoilCallback } from 'recoil';
import { optimisationResult } from '../../simulation/store/optimisation.state';
import { errorAppender } from '../../store/error.state';

function useStopOptimizationRun() {
  const client = useApolloClient();

  const stopOptimization = useRecoilCallback(
    ({ snapshot, set }) =>
      async (id: string) => {
        let response: FetchResult<StopOptimizationRunMutation>;
        try {
          response = await client.mutate<
            StopOptimizationRunMutation,
            StopOptimizationRunMutationVariables
          >({
            mutation: StopOptimizationRunDocument,
            variables: {
              id,
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: 'Can not stop optimisation run',
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: 'Can not stop optimisation run',
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          return;
        }
        const optimizeRunResult = response.data.stopOptimizationRun;
        set(optimisationResult, optimizeRunResult);
      },
  );

  return async (optimisationId: string) => {
    await stopOptimization(optimisationId);
  };
}

export default useStopOptimizationRun;
