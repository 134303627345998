import { Color, parseColor } from '@react-stately/color';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useOverlayTrigger } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { useOverlayTriggerState } from 'react-stately';
import * as Icon from '../../components/icons';
import { Button } from '../actions/Button';
import ColorSlider from './ColorSlider';
import Popover from './Popover';

export type ColorPickerProps = {
  color: string;
  onChange: (value: string) => void;
};

const ColorPicker: React.FC<ColorPickerProps> = props => {
  const { t } = useTranslation('app');
  const editorColor = props.color ?? '#FF0000';
  const [color, setColor] = useState<Color>(
    parseColor(editorColor).toFormat('hsl'),
  );
  const ref = useRef(null);
  const popoverState = useOverlayTriggerState({});
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: 'dialog' },
    popoverState,
    ref,
  );

  let bgColor = props.color;
  if (popoverState.isOpen) {
    bgColor = color.toString('css');
  }

  return (
    <div
      data-component="ColorPickerButton"
      className="relative inline-flex flex-col text-start"
      onClick={e => e.stopPropagation()}
    >
      <Button {...triggerProps} className="hover:bg-app-panel-dark/60">
        <div
          ref={ref}
          style={{ background: bgColor }}
          className={classNames(
            'w-4 h-4',
            'rounded',
            bgColor
              ? 'hover:border hover:border-menu-50'
              : 'border border-menu-100',
          )}
        />
      </Button>

      {popoverState.isOpen && (
        <Popover
          {...overlayProps}
          state={popoverState}
          triggerRef={ref}
          placement="bottom start"
        >
          <div
            {...overlayProps}
            className="flex flex-col align-center w-64 space-y-4"
          >
            <ColorSlider value={color} channel="hue" onChange={setColor} />
            <ColorSlider
              value={color}
              channel="saturation"
              onChange={setColor}
            />
            <ColorSlider
              value={color}
              channel="lightness"
              onChange={setColor}
            />
            <div className={`flex flex-1 flex-col items-end mt-8`}>
              <Button
                label={t`Apply`}
                className="ltr:ml-2 rtl:mr-2 rounded"
                buttonSize="xxs"
                buttonType="primary"
                hasIconAfter={
                  <Icon.CircleOk className={`w-4 h-4 fill-current mx-2`} />
                }
                onPress={() => {
                  props.onChange(color.toString('hex'));
                  popoverState.close();
                }}
              />
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default ColorPicker;
