import classNames from 'classnames';
import React from 'react';
import { formatInteger } from '../../common/formatHelper';

type UomProps = {
  uom: string;
  value: number;
  className?: string;
  classType?: string;
};

const UomTag: React.FC<UomProps> = props => {
  if (!props.uom) {
    return (
      <span className={classNames('text-right flex-1 opacity-30')}>—</span>
    );
  }

  return (
    <span
      data-component="UomTag"
      className={classNames(
        'text-right flex-1 flex',
        'px-2 py-1 rounded inline-flex text-xxs m-px',
        props.classType
          ? props.classType
          : 'bg-app-panel group-hover:text-menu-text group-hover:bg-app-panel-dark/50',
        props.className,
      )}
    >
      <span
        data-type="key"
        className="opacity-50 ltr:mr-1 rtl:ml-1 flex-1 text-left"
      >
        {props.uom}
      </span>

      <span data-type="value" className="flex-1 text-right">
        {props.value > 1000000 ? '1M+' : formatInteger(props.value)}
      </span>
    </span>
  );
};

export default UomTag;
