import {
  AllocationAllowReplacement,
  AllocationItemPriority,
  AllocationLocationPriority,
  AllocationPriorityDirection,
  AllocationSettingsFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  toDateFromLocaleStringDate,
  toLocaleDateTimeString,
} from '../../../common/dateTimeHelper';
import OrderedList, {
  OrderedListItem,
  OrderedListProps,
} from '../../../components/actions/OrderedList';
import { InputGroupList } from '../../../components/inputs/InputGroupList';
import InputNumber from '../../../components/inputs/InputNumber';
import DateRangePicker from '../../../components/inputs/calendar/DateRangePicker';
import SectionOptional from '../../../components/layout/SectionOptional';
import useUpdateSimulation from '../../hooks/useUpdateSimulation';
import { simulationOrderSetSummary } from '../../store/simulation.layout.state';
import { simulationCurrent } from '../../store/simulation.state';

const AllocationRunSettings: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [sim, updateSim] = useRecoilState(simulationCurrent);
  const updateSimulation = useUpdateSimulation();
  const orderSetSummary = useRecoilValue(simulationOrderSetSummary);

  function updateAllocationSettings(
    patch: Partial<AllocationSettingsFragment>,
  ) {
    updateSim(current => ({
      ...current,
      allocationSettings: {
        ...(current.allocationSettings ?? {}),
        ...patch,
      },
    }));

    updateSimulation({
      allocationSettings: {
        ...(sim.allocationSettings ?? {}),
        ...patch,
      },
    });
  }

  const hasDateRange = !_.isNil(orderSetSummary?.dateRange);
  const range = [
    orderSetSummary?.dateRange?.from,
    orderSetSummary?.dateRange?.to,
  ];
  const fromRaw = sim.allocationSettings?.orderSetFilter?.orderDate?.from;
  const from = fromRaw ? toDateFromLocaleStringDate(fromRaw) : new Date();
  const toRaw = sim.allocationSettings?.orderSetFilter?.orderDate?.to;
  const to = toRaw ? toDateFromLocaleStringDate(toRaw) : new Date();

  const prioritySettings = sim.allocationSettings?.prioritySettings;

  const locationPriorityOptions: Record<AllocationLocationPriority, string> = {
    [AllocationLocationPriority.DISTANCE_FROM_START]: t`Distance`,
    [AllocationLocationPriority.LOCATION_LEVEL]: t`Level`,
    [AllocationLocationPriority.LOCATION_ORDER]: t`Location order`,
    [AllocationLocationPriority.LOCATION_VOLUME]: t`Location Volume`,
  };

  function updateLocationPriority(
    items: OrderedListItem<AllocationLocationPriority>[],
  ) {
    updateAllocationSettings({
      prioritySettings: {
        ...sim.allocationSettings?.prioritySettings,
        locationPriority: _.map(items, i => ({
          priority: i.type,
          direction: AllocationPriorityDirection.DEFAULT,
        })),
      },
    });
  }

  const locationSortOrderProps: OrderedListProps<AllocationLocationPriority> = {
    current: _.map(prioritySettings?.locationPriority, p => ({
      type: p.priority,
      direction: p.direction,
    })),
    options: locationPriorityOptions,
    onChange: updateLocationPriority,
  };

  const itemsPriorityOptions: Record<AllocationItemPriority, string> = {
    [AllocationItemPriority.ORDER_LINE_COUNT]: t`ABC`,
    [AllocationItemPriority.ESTIMATED_REPLENISHMENT_COUNT]: t`Replenishment count`,
    [AllocationItemPriority.REMAINING_REQUIRED_VOLUME]: t`Ordered volume`,
  };

  function updateItemPriority(
    items: OrderedListItem<AllocationItemPriority>[],
  ) {
    updateAllocationSettings({
      prioritySettings: {
        ...sim.allocationSettings?.prioritySettings,
        itemPriority: _.map(items, i => ({
          priority: i.type,
          direction: AllocationPriorityDirection.DEFAULT,
        })),
      },
    });
  }

  const itemsSortOrderProps: OrderedListProps<AllocationItemPriority> = {
    current: _.map(prioritySettings?.itemPriority, p => ({
      type: p.priority,
      direction: p.direction,
    })),
    options: itemsPriorityOptions,
    onChange: updateItemPriority,
  };

  return (
    <>
      <InputGroupList className="space-y-2 p-1 lg:p-2 xl:p-4">
        <h4 className="text-menu-active py-2 text-base">{t`Allocation Settings`}</h4>

        {/* location-size-constraints */}
        <SectionOptional
          id={'allocation-assignment-policy-constraints'}
          title={t`Storage policy Constraints`}
          value={
            sim.allocationSettings?.assignmentPolicyConstraint?.disabled ===
            false
          }
          onChange={enabled =>
            updateAllocationSettings({
              assignmentPolicyConstraint: {
                ...sim.allocationSettings?.assignmentPolicyConstraint,
                disabled: !enabled,
              },
            })
          }
        >
          <SectionOptional
            id={'allocation-assignment-policy-constraints-compliant'}
            title={t`Relocate non-compliant`}
            value={
              sim.allocationSettings?.assignmentPolicyConstraint
                ?.makeCompliant === true
            }
            onChange={enabled =>
              updateAllocationSettings({
                assignmentPolicyConstraint: {
                  ...sim.allocationSettings?.assignmentPolicyConstraint,
                  makeCompliant: enabled,
                },
              })
            }
          ></SectionOptional>
        </SectionOptional>

        {/* location-size-constraints */}
        <SectionOptional
          id={'allocation-location-size-constraints'}
          title={t`Location Size Constraints`}
          value={
            sim.allocationSettings?.locationSizeConstraint?.disabled === false
          }
          onChange={enabled =>
            updateAllocationSettings({
              locationSizeConstraint: {
                ...sim.allocationSettings?.locationSizeConstraint,
                disabled: !enabled,
              },
            })
          }
        >
          <SectionOptional
            id={'allocation-location-size-constraints-compliant'}
            title={t`Relocate non-compliant`}
            value={
              sim.allocationSettings?.locationSizeConstraint?.makeCompliant ===
              true
            }
            onChange={enabled =>
              updateAllocationSettings({
                locationSizeConstraint: {
                  ...sim.allocationSettings?.locationSizeConstraint,
                  makeCompliant: enabled,
                },
              })
            }
          ></SectionOptional>
        </SectionOptional>

        {/* bay-width-constraints */}
        <SectionOptional
          id={'allocation-bay-width-constraints'}
          title={t`Bay Width Constraints`}
          value={sim.allocationSettings?.bayWidthConstraint?.disabled === false}
          onChange={enabled =>
            updateAllocationSettings({
              bayWidthConstraint: {
                ...sim.allocationSettings?.bayWidthConstraint,
                disabled: !enabled,
              },
            })
          }
        >
          <SectionOptional
            id={'allocation-bay-width-constraints-compliant'}
            title={t`Relocate non-compliant`}
            value={
              sim.allocationSettings?.bayWidthConstraint?.makeCompliant === true
            }
            onChange={enabled =>
              updateAllocationSettings({
                bayWidthConstraint: {
                  ...sim.allocationSettings?.bayWidthConstraint,
                  makeCompliant: enabled,
                },
              })
            }
          ></SectionOptional>
        </SectionOptional>

        {hasDateRange && (
          <SectionOptional
            id={'allocation-limit-by-date'}
            title={t`Limit date`}
            value={!_.isNil(sim.allocationSettings?.orderSetFilter?.orderDate)}
            onChange={enabled =>
              updateAllocationSettings({
                orderSetFilter: {
                  orderDate: enabled
                    ? {
                        from: range[0],
                        to: range[1],
                      }
                    : null,
                },
              })
            }
          >
            <DateRangePicker
              value={[from, to]}
              minValue={
                range?.[0] ? toDateFromLocaleStringDate(range?.[0]) : null
              }
              maxValue={
                range?.[1] ? toDateFromLocaleStringDate(range?.[1]) : null
              }
              onChange={v =>
                updateAllocationSettings({
                  orderSetFilter: {
                    orderDate: {
                      from: toLocaleDateTimeString(v[0]),
                      to: toLocaleDateTimeString(v[1]),
                    },
                  },
                })
              }
              onResetClick={() =>
                updateAllocationSettings({
                  orderSetFilter: {
                    orderDate: {
                      from: range[0],
                      to: range[1],
                    },
                  },
                })
              }
              showTimeMenu={true}
              showDateMenu={true}
              headerMode
            />
          </SectionOptional>
        )}

        {/* LIMIT ALLOCATED ASSIGNMENTS*/}
        <SectionOptional
          id={'allocation-limit-by-allocated-assignments'}
          title={t`Limit allocated assignment`}
          value={
            !_.isNil(
              sim.allocationSettings?.limitSettings?.maxAllocatedAssignments,
            )
          }
          onChange={enabled =>
            updateAllocationSettings({
              limitSettings: {
                ...sim.allocationSettings?.limitSettings,
                maxAllocatedAssignments: enabled ? 1 : null,
              },
            })
          }
        >
          <InputNumber
            title={t`Max Allocated per item`}
            value={
              sim.allocationSettings?.limitSettings?.maxAllocatedAssignments
            }
            range={[0, 1000]}
            onChange={v =>
              updateAllocationSettings({
                limitSettings: {
                  ...sim.allocationSettings?.limitSettings,
                  maxAllocatedAssignments: v,
                },
              })
            }
          />
        </SectionOptional>

        {/* LIMIT PICKABLE ASSIGNMENTS*/}
        <SectionOptional
          id={'allocation-limit-by-pickable-assignments'}
          title={t`Limit total assignment`}
          value={
            !_.isNil(
              sim.allocationSettings?.limitSettings?.maxPickableAssignments,
            )
          }
          onChange={enabled =>
            updateAllocationSettings({
              limitSettings: {
                ...sim.allocationSettings?.limitSettings,
                maxPickableAssignments: enabled ? 1 : null,
              },
            })
          }
        >
          <InputNumber
            title={t`Max pickable assignments per item`}
            value={
              sim.allocationSettings?.limitSettings?.maxPickableAssignments
            }
            range={[0, 1000]}
            onChange={v =>
              updateAllocationSettings({
                limitSettings: {
                  ...sim.allocationSettings?.limitSettings,
                  maxPickableAssignments: v,
                },
              })
            }
          />
        </SectionOptional>

        {/* ALLOCATED VOLUME*/}
        <SectionOptional
          id={'allocation-limit-by-allocated-volume'}
          title={t`Limit by allocation volume`}
          value={
            !_.isNil(sim.allocationSettings?.limitSettings?.maxAllocatedVolume)
          }
          onChange={enabled =>
            updateAllocationSettings({
              limitSettings: {
                ...sim.allocationSettings?.limitSettings,
                maxAllocatedVolume: enabled ? 1000 : null,
              },
            })
          }
        >
          <InputNumber
            title={t`Max allocation volume`}
            value={sim.allocationSettings?.limitSettings?.maxAllocatedVolume}
            range={[0, 1000000]}
            onChange={v =>
              updateAllocationSettings({
                limitSettings: {
                  ...sim.allocationSettings?.limitSettings,
                  maxAllocatedVolume: v,
                },
              })
            }
          />
        </SectionOptional>
        {/* PICKABLE VOLUME*/}
        <SectionOptional
          id={'allocation-limit-by-pickable-volume'}
          title={t`Limit by pickable volume`}
          value={
            !_.isNil(sim.allocationSettings?.limitSettings?.maxPickableVolume)
          }
          onChange={enabled =>
            updateAllocationSettings({
              limitSettings: {
                ...sim.allocationSettings?.limitSettings,
                maxPickableVolume: enabled ? 1000 : null,
              },
            })
          }
        >
          <InputNumber
            title={t`Max pickable volume`}
            value={sim.allocationSettings?.limitSettings?.maxPickableVolume}
            range={[0, 1000000]}
            onChange={v =>
              updateAllocationSettings({
                limitSettings: {
                  ...sim.allocationSettings?.limitSettings,
                  maxPickableVolume: v,
                },
              })
            }
          />
        </SectionOptional>

        {/* MAX ALLOCATION PER ROUND */}
        <SectionOptional
          id={'allocation-per-round'}
          title={t`Specify allocation per round`}
          value={
            !_.isNil(
              sim.allocationSettings?.roundSettings?.maxAssignmentsPerRound,
            )
          }
          onChange={enabled =>
            updateAllocationSettings({
              roundSettings: {
                ...sim.allocationSettings?.roundSettings,
                maxAssignmentsPerRound: enabled ? 1 : null,
              },
            })
          }
        >
          <InputNumber
            title={t`Max allocation per round`}
            value={
              sim.allocationSettings?.roundSettings?.maxAssignmentsPerRound
            }
            range={[0, 1000]}
            onChange={v =>
              updateAllocationSettings({
                roundSettings: {
                  ...sim.allocationSettings?.roundSettings,
                  maxAssignmentsPerRound: v,
                },
              })
            }
          />
        </SectionOptional>

        <SectionOptional
          id={'allocation-multiplexing'}
          title={t`Allow multiplexing`}
          value={
            sim.allocationSettings?.multiplexingSettings?.allow ===
            AllocationAllowReplacement.ALWAYS
          }
          onChange={enabled =>
            updateAllocationSettings({
              multiplexingSettings: {
                ...sim.allocationSettings?.multiplexingSettings,
                allow: enabled ? AllocationAllowReplacement.ALWAYS : null,
              },
            })
          }
        />

        <SectionOptional
          id={'allocation-replacement'}
          title={t`Allow replacement`}
          value={
            sim.allocationSettings?.replacementSettings?.allow ===
            AllocationAllowReplacement.ALWAYS
          }
          onChange={enabled =>
            updateAllocationSettings({
              replacementSettings: {
                ...sim.allocationSettings?.replacementSettings,
                allow: enabled ? AllocationAllowReplacement.ALWAYS : null,
              },
            })
          }
        />

        <p className="text-menu-active py-2 text-base">{t`Items order`}</p>
        <OrderedList {...itemsSortOrderProps} />
        <p className="text-menu-active py-2 text-base">{t`Location order`}</p>
        <OrderedList {...locationSortOrderProps} />
      </InputGroupList>
    </>
  );
};

export default AllocationRunSettings;
