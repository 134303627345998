import { StackingPriority } from '@warebee/shared/engine-model';
import classNames from 'classnames';
import _ from 'lodash';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedValueWithUnit } from '../../../common/formatHelper';
import CounterLabel from '../../../components/events/CounterLabel';
import LegendItem from '../../../components/events/LegendItem';
import StackingPolicyCategoryTag from '../../../stackingPolicy/StackingPolicyCategoryTag';
import ItemTag from '../../tags/ItemTag';
import {
  ContainerStatsMini,
  IssueMiniInline,
  StatMiniInline,
} from './StatInline';

type PicklistTitleProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string | React.ReactNode;
  titleAriaLabel?: string;
  titleTag?: string;
  isFirst?: boolean;
  isLast?: boolean;
  isClosed?: boolean;
  isAgent?: boolean;
  counter?: React.ReactNode;
  indicator?: React.ReactNode;
  indicatorEnergy?: React.ReactNode;
  indicatorConnectivity?: React.ReactNode;
  locationTag?: React.ReactNode;
  icon?: React.FC<HTMLAttributes<Element>>;
  counterSize?: 'sm' | 'lg' | 'date' | 'timedetail' | 'time';
  stackingPriority?: StackingPriority;
  stats?: {
    title: string;
    titleAlt?: string;
    value: FormattedValueWithUnit | string;
    valueRaw?: number;
    valueThreshold?: number;
  }[];
  issues?: { title: string; value: FormattedValueWithUnit | string }[];
  labelValueType?: 'itemId' | 'locationId' | 'orderId';
  labelValueFilters?: Record<string, string>;
  color?: [string, string];
  isSticky?: boolean;
  container?: string;
};

const EventListItemHeader: React.FC<PicklistTitleProps> = props => {
  const { t } = useTranslation('simulation');
  const counterSize = props.counterSize ?? 'sm';
  const IconItem = props.icon;
  const [bgColor, textColor] = props.color ?? [];
  const isTitleString = typeof props.title === 'string';

  return (
    <div
      data-component="EventListItemHeader"
      aria-label="EventTitle"
      className={classNames(
        'group',
        'relative',
        'flex items-center w-full',
        'ltr:pr-2 rtl:pl-2',
        {
          'h-20': props.isFirst || props.isLast,
          'sticky top-0': props.isSticky,
          'sticky top-0 !h-14': counterSize === 'date',
        },
        props.className,
      )}
    >
      {props.isFirst && (
        <LegendItem
          bgColor={bgColor}
          textColor={textColor}
          legendSize="Start"
        />
      )}

      {!props.isFirst && !props.isLast && (
        <LegendItem bgColor={bgColor} textColor={textColor} legendSize="Full" />
      )}

      {props.isLast && (
        <LegendItem bgColor={bgColor} textColor={textColor} legendSize="End" />
      )}

      <CounterLabel
        bgColor={bgColor}
        textColor={textColor}
        counterSize={counterSize}
        IconItem={IconItem}
      >
        {props.counter}
      </CounterLabel>

      <div
        className={classNames(
          'flex flex-col flex-1',
          'py-2',
          'w-full',
          props.isFirst || props.isLast
            ? 'ltr:pl-14 rtl:pr-14'
            : 'ltr:pl-12 rtl:pr-12',
        )}
      >
        <div
          data-component="ItemTitle"
          className={classNames(
            'flex-1',
            props.indicatorEnergy ? 'flex items-center' : '',
            props.indicatorConnectivity ? 'flex items-center' : '',
            !props.isFirst && !props.isLast && !props.isAgent ? 'mb-2' : '',
            props.isAgent ? 'text-sm' : 'text-base',
            'text-menu-text select-text',
          )}
          aria-label={props.titleAriaLabel}
          title={props.titleAriaLabel}
        >
          {props.labelValueType === 'itemId' ? (
            <>
              {isTitleString && (
                <ItemTag
                  title={props.title as string}
                  filters={props.labelValueFilters}
                />
              )}
            </>
          ) : (
            <span className={classNames('flex-1 truncate')}>{props.title}</span>
          )}
          {props.indicatorEnergy}
          {props.indicatorConnectivity}
        </div>
        {props.indicator}
        {props.locationTag}

        {(!_.isEmpty(props.stats) || props.stats?.length > 0) && (
          <ContainerStatsMini>
            {_.chain(props.stats)
              .filter(stat => stat.value !== undefined && stat.value !== null)
              .map(stat => (
                <StatMiniInline
                  // className={classNames('ltr:ml-1 rtl:mr-1')}
                  key={`stat-${stat.title}`}
                  title={stat.title}
                  titleAlt={stat.titleAlt}
                  hasIssue={stat.valueRaw > stat.valueThreshold}
                  value={
                    typeof stat.value === 'object'
                      ? stat.value?.fullString
                      : stat.value
                  }
                />
              ))
              .value()}
          </ContainerStatsMini>
        )}

        {!_.isNil(props.stackingPriority) && (
          <div
            data-component="minStatContainer"
            className={classNames(
              'flex flex-col',
              'space-y-0.5 md:space-y-0 md:flex-row flex-1',
              'ltr:pr-1 rtl:pl-1',
              'text-menu',
              'mt-4',
            )}
          >
            <StackingPolicyCategoryTag
              stackingPriority={props.stackingPriority}
            />
          </div>
        )}

        {!_.isNil(props.container) && (
          <div
            data-component="minStatContainer"
            className={classNames(
              'flex flex-col md:flex-row flex-1',
              'space-y-0.5 md:space-y-0',
              'ltr:pr-1 rtl:pl-1',
              'text-menu-100',
              'mt-4',
            )}
          >
            <span
              className={classNames(
                'flex',
                'text-center',
                'px-2 py-0.5',
                'rounded',
                'text-xxs',
                'whitespace-nowrap',
                'm-0.5',
                'w-full',
                'bg-app-panel-dark/60',
              )}
            >
              {t`Container:`} {props.container}
            </span>
          </div>
        )}
        {!_.isEmpty(props.issues) && (
          <div
            data-component="minStatContainer"
            className={classNames(
              'flex flex-col',
              'space-y-0.5 md:space-y-0 md:flex-row flex-1',
              'ltr:pr-1 rtl:pl-1',
              'text-menu-100',
              'mt-4',
            )}
          >
            {_.map(props.issues, issue => (
              <IssueMiniInline
                className="ltr:ml-1 rtl:mr-1"
                key={`issue-${issue.title}`}
                title={issue.title}
                value={
                  typeof issue.value === 'object'
                    ? issue.value?.fullString
                    : issue.value
                }
              />
            ))}
          </div>
        )}
      </div>
      {props.children}
    </div>
  );
};

export default EventListItemHeader;
