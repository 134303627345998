import {
  AisleFeatureMetaFragment,
  FindLocationsStatsFilterQuery,
  FindLocationsStatsFilterQueryVariables,
  LayoutLocationsSummaryFragment,
  LayoutLocationsSummaryKeyFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export const locationCapacityIds = ['h130', 'h100'] as const;
export type LocationCapacityId = (typeof locationCapacityIds)[number];
export type LocationCapacityMap = {
  [K in LocationCapacityId]: number;
};

export type LocationsStatsExportJobParams = ExportJobParams<
  FindLocationsStatsFilterQueryVariables,
  LocationsStatsConverterConfig
>;

export const locationsStatsExportJobParams: LocationsStatsExportJobParams = {
  converterId: 'LOCATIONS_STATS',
  query: null,
  config: null,
  variables: null,
  filename: 'locations-stats.csv',
};

export type LocationsStatsDataRow = Pick<
  LayoutLocationsSummaryFragment,
  'locationCount'
> &
  LayoutLocationsSummaryKeyFragment &
  LocationCapacityMap & {
    aisleTitle: string;
    accessAisleTitle: string;
    aisleAndSide: string;
  };

export type LocationsStatsDataColumn = keyof LocationsStatsDataRow;

export type LocationsStatsConverterConfig = {
  columnsConfig: ColumnConfigBase<LocationsStatsDataRow>[];
  dictionaries: {
    aisleTitleMap: Record<string, AisleFeatureMetaFragment>;
  };
  searchValues: Partial<Record<LocationsStatsDataColumn, any>>;
  sortColumnField: LocationsStatsDataColumn;
};

const createLocStatRow = (
  locStats: LayoutLocationsSummaryFragment,
  aisleTitleMap: Record<string, AisleFeatureMetaFragment>,
): LocationsStatsDataRow => {
  const key = locStats.key;
  const uoms = _.reduce(
    locStats.uoms,
    (acc, u) => ({
      ...acc,
      [u.id]: u.uomCount,
    }),
    {} as LocationCapacityMap,
  );
  return {
    aisleTitle: aisleTitleMap?.[key.aisleId]?.title ?? key.aisleId,
    accessAisleTitle:
      (key.accessAisleId && aisleTitleMap?.[key.accessAisleId]?.title) ??
      aisleTitleMap?.[key.aisleId]?.title,
    aisleAndSide: `${aisleTitleMap?.[key.aisleId]?.title} ${
      key?.locationSide ?? ''
    }`,
    locationCount: locStats.locationCount,
    ...key,
    ...uoms,
  };
};

export function getLocationsStatsTableRows(
  data: LayoutLocationsSummaryFragment[],
  config: LocationsStatsConverterConfig,
): LocationsStatsDataRow[] {
  const { searchValues, sortColumnField } = config;
  const aisleTitleMap = config.dictionaries.aisleTitleMap ?? {};

  return _(data)
    .flatMap(row => createLocStatRow(row, aisleTitleMap))
    .filter(row =>
      _.every(
        searchValues,
        (value, field) =>
          _.isEmpty(value) ||
          (row?.[field] as string | boolean | number)
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(value?.toString()?.toLowerCase() ?? '') > -1,
      ),
    )
    .sortBy([sortColumnField])
    .value();
}

export const LOCATIONS_STATS_EXPORT_CONVERTER: ExportConverter<
  FindLocationsStatsFilterQuery,
  LocationsStatsConverterConfig
> = (input, config) => {
  const data = getLocationsStatsTableRows(
    input.layout?.locationsByFilter?.summary,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
