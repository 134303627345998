import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SetterOrUpdater } from 'recoil';
import { TwTheme } from '../../../Tw';
import {
  HeatmapBucket,
  getHeatmapBucketTitle,
} from '../../common/heatmap.helper';
import ButtonToolbar from '../../components/actions/ButtonToolbar';
import { Container } from '../../components/layout/ContainerFlex';
import TitleToolbarContainer from '../../containers/TitleToolbarContainer';
import { HeatmapFilter } from '../store/simulation.types';
import { LegendItem } from './LegendItem';

const locationColor = TwTheme.extend.colors.location;

export type HeatmapLegendBucketsProps = {
  title: string;
  buckets: HeatmapBucket[];
  metric: any; //HeatmapMetricDescriptor<string, Record<string, any>>;
  hasDisabledOption?: boolean;
  hasEmptyOption?: boolean;
  heatmapFilter: HeatmapFilter;
  setHeatmapFilter: SetterOrUpdater<HeatmapFilter>;
};

const HeatmapLegendBuckets: React.FC<HeatmapLegendBucketsProps> = props => {
  const { t } = useTranslation('simulation');
  const { heatmapFilter, setHeatmapFilter } = props;

  const debouncedClick = useCallback(
    _.debounce(
      (
        id: string,
        value: boolean,
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        if (evt.detail === 2) {
          forceSelectSingleBucket(id);
        } else {
          updateBucketFilter(id, value);
        }
      },
      400,
    ),
    [props.heatmapFilter, props.buckets],
  );

  const updateBucketFilter = (id: string, value: boolean) => {
    props.setHeatmapFilter(heatmapFilter => ({
      ...heatmapFilter,
      hiddenBuckets: {
        ...heatmapFilter.hiddenBuckets,
        [id]: value,
      },
    }));
  };

  const forceSelectSingleBucket = (id: string) => {
    const hiddenBuckets = _.reduce(
      props.buckets,
      (acc, b) => ({
        ...acc,
        [b.id]: b.id !== id,
      }),
      {},
    );
    props.setHeatmapFilter(heatmapFilter => ({
      ...heatmapFilter,
      hiddenBuckets,
    }));
  };

  return (
    <TitleToolbarContainer
      id="panel-toolbar-heatmap-hit-count"
      title={props.title}
      collapsible
      hasScroll
      classNameContainerBodyChildren="max-h-[88vh]"
    >
      <Container col>
        {_(props.buckets)
          .sortBy(b => -b.index)
          .map(bucket => {
            const isHidden =
              props.heatmapFilter.hiddenBuckets[bucket.id] === true;

            const title = getHeatmapBucketTitle(
              bucket,
              props.metric,
              t`Unknown`,
            );
            return (
              <ButtonToolbar
                key={`legend-item-${bucket.id}`}
                titleTrace={`Legend: Item`}
              >
                <LegendItem
                  classNameText={classNames(
                    'max-w-ch-20 truncate text-ellipsis',
                  )}
                  color={bucket.color}
                  text={title}
                  labelRaw={title}
                  isHidden={isHidden}
                  onClick={evt => {
                    debouncedClick(bucket.id, !isHidden, evt);
                  }}
                />
              </ButtonToolbar>
            );
          })
          .value()}

        {(props.hasEmptyOption || props.hasDisabledOption) && (
          <hr className={classNames('border-menu/50 mb-1 mt-1')} />
        )}

        {props.hasEmptyOption && (
          <ButtonToolbar titleTrace={`Toggle: Empty visits`}>
            <LegendItem
              key={`legend-item-empty`}
              text={t`Empty`}
              color={locationColor.DEFAULT}
              isHidden={heatmapFilter?.hideEmpty}
              onClick={() =>
                setHeatmapFilter({
                  ...heatmapFilter,
                  hideEmpty: !heatmapFilter.hideEmpty,
                })
              }
            />
          </ButtonToolbar>
        )}

        {props.hasDisabledOption && (
          <ButtonToolbar titleTrace={`Toggle: Disabled`}>
            <LegendItem
              key={`legend-item-disabled`}
              text={t`Disabled`}
              color={locationColor.disabled}
              isHidden={heatmapFilter?.hideDisabled}
              onClick={() =>
                setHeatmapFilter({
                  ...heatmapFilter,
                  hideDisabled: !heatmapFilter.hideDisabled,
                })
              }
            />
          </ButtonToolbar>
        )}
      </Container>
    </TitleToolbarContainer>
  );
};

export default HeatmapLegendBuckets;
