import { ItemSetDataTableRow } from '@warebee/shared/export-converter';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import useFormatter from '../common/useFormatter';
import { ColumnConfig } from '../components/DatasetTable';
import FormattedValue from '../components/FormattedValue';
import { getItemSetSchema } from '../import/itemSet/schema/itemSetSchema';
import ItemTag from '../simulation/tags/ItemTag';

function useItemSetDataTableConfig(): ColumnConfig<ItemSetDataTableRow>[] {
  const { t: tImporter } = useTranslation('importer');
  const { t } = useTranslation('dataset');
  const formatter = useFormatter();
  const schema = _.keyBy(getItemSetSchema(tImporter).fields, f => f.name);

  const columnsConfigRaw: ColumnConfig<ItemSetDataTableRow>[] = [
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
      hasFilter: true,
      isHeader: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      hasFilter: true,
      isHeader: true,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
    {
      field: 'skuGroup',
      title: t`Item Group`,
      hasFilter: true,
      // isHeader: true,
    },
    { field: 'uom', title: t`UOM`, hasFilter: true },
    {
      field: 'unitsPerLowestUom',
      title: t`Unit Per Lower UOM`,
      hasFilter: true,
    },
    { field: 'lowerUom', title: t`Lower UOM`, hasFilter: true },
    {
      field: 'netWeight',
      title: t`Weight`,
      hasFilter: true,
      render: (v: number) => (
        <FormattedValue value={formatter.formatWeight(v)} />
      ),
    },
    {
      field: 'length',
      title: t`Length`,
      hasFilter: true,
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v, 10)} />
      ),
    },
    {
      field: 'width',
      title: t`Width`,
      hasFilter: true,
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v, 10)} />
      ),
    },
    {
      field: 'height',
      title: t`Height`,
      hasFilter: true,
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v, 10)} />
      ),
    },
    {
      field: 'volume',
      title: t`Volume`,
      hasFilter: true,
      render: (v: number) => (
        <FormattedValue value={formatter.formatVolume(v)} />
      ),
    },
    { field: 'name', title: t`Item Name`, hasFilter: false },
    { field: 'description', title: t`Item Description`, hasFilter: false },
    { field: 'ean', title: t`EAN`, hasFilter: false },
    { field: 'upc', title: t`UPC`, hasFilter: false },
    { field: 'subGroup', title: t`Item sub-group`, hasFilter: true },
    { field: 'transportClass', title: t`Transport Class`, hasFilter: true },
    { field: 'stockCategory', title: t`Stock category`, hasFilter: true },
    { field: 'storageClass', title: t`Storage Category`, hasFilter: true },
    { field: 'pollutionClass', title: t`Pollution Class`, hasFilter: true },
  ];

  const columnsConfig = columnsConfigRaw.map(f => ({
    ...f,
    title: f.title ?? schema[f.field]?.title,
  }));

  return columnsConfig;
}
export default useItemSetDataTableConfig;
