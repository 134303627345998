import classNames from 'classnames';
import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getQualitativeColor } from '../../common/color.helper';
import {
  toHumanReadableTime,
  toHumanReadableTimeDetailed,
} from '../../common/dateTimeHelper';
import {
  FormattedValueWithUnit,
  formatDateTime,
  formatInteger,
} from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import { parseJSON } from '../../common/utils';
import ButtonLocateLocation from '../../components/ButtonLocateLocation';
import CounterLabel from '../../components/events/CounterLabel';
import LegendItem from '../../components/events/LegendItem';
import * as Icon from '../../components/icons';
import { StatListItem } from '../../components/stats/StatListItem';
import EventSection from '../../containers/EventSection';
import EventListItemHeader from '../../simulation/panels/route/EventListItemHeader';
import { actualityJobTimingMode } from '../store/actuality.jobs.state';
import { ActualityAnalyzedEvent } from '../store/datasetQueries/actualityAnalyzedEvents';
import AnalyzeStackedLine from './AnalyzeStakedLine';

type TimeIssuesProps = {
  issues: {
    title: string;
    value: string | FormattedValueWithUnit;
  }[];
};

const TimeIssuesDisplay: React.FC<TimeIssuesProps> = ({ issues }) => {
  if (issues.length === 0) return null;

  return (
    <div
      className={classNames(
        'bg-barber-pole bg-barber-size bg-menu-active/10 flex w-full',
      )}
    >
      <LegendItem legendSize="Full" legendType="Warn" />
      <CounterLabel
        counterType="Warn"
        counterSize="sm"
        IconItem={Icon.TriangleInfo}
      ></CounterLabel>

      {issues.map((issue, index) => (
        <div
          key={index}
          className={classNames(
            'flex w-full flex-1 flex-col py-2 ltr:pl-12 rtl:pr-12',
            `text-menu-active`,
          )}
        >
          <span>{issue.title}</span>
          <span>
            {typeof issue.value === 'string'
              ? issue.value
              : issue.value.fullString}
          </span>
        </div>
      ))}
    </div>
  );
};

const ISSUE_DURATION_DIFF_SEC = 120;

export type FeedActivityPicklistLineDetailsProps = PropsWithChildren & {
  index: number;
  line: ActualityAnalyzedEvent;
  prevLine?: ActualityAnalyzedEvent;
};

const FeedActivityPicklistLineDetails: React.FC<
  FeedActivityPicklistLineDetailsProps
> = props => {
  const { t } = useTranslation('feed');
  const formatter = useFormatter();
  const { line, prevLine } = props;

  const timingMode = useRecoilValue(actualityJobTimingMode);

  // console.log('line:', line);
  // console.log(
  //   'line.scans[0].previousStartTime:',
  //   line.scans[0].previousStartTime,
  // );

  // const previousStartTimeDate = line.scans[0].previousStartTime
  //   ? new Date(line.scans[0].previousStartTime)
  //   : null;

  // const previousStartTime = line.scans[0].previousStartTime
  //   ? previousStartTimeDate.getTime()
  //   : null;

  const eventTime = line.eventEndTime;
  const receivedTime = line.eventStartTime;

  const datesAreDifferent = (date1?: Date, date2?: Date): boolean => {
    if (!date1 || !date2) return false;
    return date1.getTime() !== date2.getTime();
  };

  // Determine if we should show start/end times based on existence and differences
  const showStartTime = false;
  // startTimeDate &&
  // (!endTimeDate || datesAreDifferent(startTimeDate, endTimeDate));
  const showEndTime = true;
  // endTimeDate &&
  // (!startTimeDate || datesAreDifferent(startTimeDate, endTimeDate));

  const durationOriginalRaw = _.isNil(props.prevLine)
    ? null
    : (line.eventEndTime.getTime() - prevLine.eventEndTime.getTime()) / 1000;

  const durationAnalyzedRaw = line.duration ?? 0;

  const durationOriginal = formatter.formatTimeSpan(durationOriginalRaw, 1);
  const durationAnalyzed = formatter.formatTimeSpan(durationAnalyzedRaw, 1);

  const hasAnalyze = !_.isNil(line.jobDate);

  const durationDiffRaw = hasAnalyze
    ? durationOriginalRaw - durationAnalyzedRaw
    : 0;

  let timeCounterDisplay;
  switch (timingMode) {
    case 'relative':
      if (durationOriginal === null) {
        timeCounterDisplay = t`Start`;
      } else {
        const timeValueNumber = durationOriginal.raw;
        const timeValue = durationOriginal.fullString;
        if (timeValueNumber === 0) {
          timeCounterDisplay = '—';
        } else {
          const sign = Math.sign(timeValueNumber) >= 0 ? '+' : '';
          timeCounterDisplay = `${sign}${timeValue}`;
        }
      }
      break;
    case 'absolute':
      timeCounterDisplay = toHumanReadableTime(eventTime);
      break;
    case 'detailed':
      timeCounterDisplay = toHumanReadableTimeDetailed(eventTime);
      break;
    case 'steps':
      timeCounterDisplay = line.jobLineSequence;
      break;
  }

  const agent = line.agentId;
  const agentColors = getQualitativeColor(agent, 'dimension');

  const stats = [
    {
      title: t`UOM`,
      value: props.line.uom ?? '—',
    },
    {
      title: t`Qty`,
      value: formatInteger(props.line.quantity),
    },
  ];

  const issues: {
    title: string;
    value: FormattedValueWithUnit | string;
  }[] = [];

  const analyzedDuration = formatter.formatTimeSpan(durationAnalyzedRaw, 1);

  const failReasons = parseJSON(line.reasons);
  const hasFailReason = !_.isEmpty(failReasons);

  const isComparable = hasAnalyze && !_.isNil(prevLine) && !hasFailReason;

  if (hasFailReason) {
    issues.push({
      title: t`Not analysed`,
      value: failReasons.join(', '),
    });
  }

  if (isComparable && durationDiffRaw > ISSUE_DURATION_DIFF_SEC) {
    issues.push({
      title: t`Duration difference:`,
      value: `${durationOriginal.fullString} vs ${durationAnalyzed.fullString}`,
    });
  }

  return (
    <EventSection
      id={`feed-event-${line.jobId}-${line.jobLineSequence}`}
      fullWidth
      inPanelView
      collapsible
      isSelected={false}
      className={issues && 'flex-col'}
      titleContainer={
        <>
          <TimeIssuesDisplay issues={issues} />

          <EventListItemHeader
            // icon={getAgentIcon('picker-manual')}
            title={line.sku}
            titleAriaLabel={t`Item`}
            counter={<span className="text-minimal">{timeCounterDisplay}</span>}
            counterSize={timingMode === 'detailed' ? 'timedetail' : 'time'}
            stats={stats}
            // issues={issues}
            color={agentColors}
            labelValueType="itemId"
            locationTag={
              <ButtonLocateLocation
                className="mb-2"
                locationId={props.line.locationId}
                icon={Icon.Show}
              />
            }
          ></EventListItemHeader>
        </>
      }
    >
      {timingMode === 'detailed' && (
        <div
          className={classNames(
            'relative',
            'w-full',
            'my-0.5',
            'py-2',
            'ltr:pl-12 ltr:pr-4 rtl:pl-4 rtl:pr-12',
            'bg-app-panel-dark',
          )}
        >
          <LegendItem legendSize="Full" legendType="Info" />

          {/* {previousStartTimeDate && (
            <StatListItem
              title={`Start Prev.`}
              value={formatDateTime(previousStartTimeDate)}
            />
          )}
          {line.durationPrevLine > 0 && (
            <StatListItem
              title={`Duration (Prev)`}
              value={durationPrevLine.value}
              unitOfMeasure={durationPrevLine.unit}
            />
          )} */}
          {analyzedDuration.raw > 0 && (
            <StatListItem
              title={`Standard Time`}
              value={analyzedDuration.value}
              unitOfMeasure={analyzedDuration.unit}
            />
          )}
          {showStartTime && (
            <StatListItem
              title={`Start Time`}
              value={formatDateTime(receivedTime)}
            />
          )}
          {line.duration > 0 && (
            <StatListItem
              title={`Duration`}
              value={line.duration}
              unitOfMeasure={`m`}
            />
          )}
          {showEndTime && (
            <StatListItem
              title={`End Time`}
              value={formatDateTime(eventTime)}
            />
          )}
        </div>
      )}
      <AnalyzeStackedLine {...line} />

      {props.children}
    </EventSection>
  );
};
export default FeedActivityPicklistLineDetails;
