import { t } from 'i18next';
import { TwTheme } from '../../../Tw';

const eventGroupsColor = TwTheme.extend.colors.eventGroups;

export type AnalyzeStackedLineProps = {
  duration?: number;
  durationTravellingStartEnd?: number;
  durationHandling?: number;
  durationPicking?: number;
  durationTravellingVertical?: number;
  durationTravelling?: number;
  durationReordering?: number;
};

const AnalyzeStackedLine: React.FC<AnalyzeStackedLineProps> = props => {
  if (!(props.duration > 1)) return null;

  const startEndShare =
    ((props.durationTravellingStartEnd / props.duration) * 100) % 100;
  const travellingShare =
    ((props.durationTravelling / props.duration) * 100) % 100;
  const liftingShare =
    ((props.durationTravellingVertical / props.duration) * 100) % 100;
  const handlingShare = ((props.durationHandling / props.duration) * 100) % 100;

  const pickingShare = ((props.durationPicking / props.duration) * 100) % 100;
  const reorderingShare =
    ((props.durationReordering / props.duration) * 100) % 100;

  return (
    <div
      data-component="AnalyzeStackedLine"
      className="flex h-3 flex-row rounded-sm"
    >
      <div
        title={t`Travelling (Start/End)`}
        style={{
          width: `${startEndShare}%`,
          backgroundColor: eventGroupsColor.travellingEndpoint,
        }}
      />
      <div
        title={t`Travelling`}
        style={{
          width: `${travellingShare}%`,
          backgroundColor: eventGroupsColor.travellingHorizontal,
        }}
      />
      <div
        title={t`Travelling (Up/Down)`}
        style={{
          width: `${liftingShare}%`,
          backgroundColor: eventGroupsColor.travellingVertical,
        }}
      />
      <div
        title={t`Handling`}
        style={{
          width: `${handlingShare}%`,
          backgroundColor: eventGroupsColor.handling,
        }}
      />
      <div
        title={t`Picking`}
        style={{
          width: `${pickingShare}%`,
          backgroundColor: eventGroupsColor.picking,
        }}
      />
      <div
        title={t`Re-Ordering`}
        style={{
          width: `${reorderingShare}%`,
          backgroundColor: eventGroupsColor.reordering,
        }}
      />
    </div>
  );
};
export default AnalyzeStackedLine;
