import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import useFormatter from '../../common/useFormatter';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import * as Icon from '../../components/icons';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { feedDisabledAgentStatsFields } from '../store/feed.state';
import { FeedAgentStatsField } from '../store/feed.types';
import { getFeedAgentsStatsDesc } from '../store/feedStats.default';

const StatManagementComponent = () => {
  const { t } = useTranslation('feed');
  const formatter = useFormatter();
  const [disabledStats, setDisabledStats] = useRecoilState(
    feedDisabledAgentStatsFields,
  );
  const statItems = getFeedAgentsStatsDesc(t, formatter); // Generate stat items with current translations

  const disabledStatsSet = new Set(disabledStats);
  const handleToggle = (id: FeedAgentStatsField) => {
    setDisabledStats(
      disabledStatsSet.has(id)
        ? _.filter(disabledStats, s => s != id)
        : [...disabledStats, id],
    );
  };

  return (
    <>
      <ScreenTitle
        title={t`Feature Visibility`}
        subtitle={t`Feed`}
        isSticky
        helpNavTo={'actuality/actuality-kpi'}
        icon={Icon.ObjectSettings}
      />
      <ul
        data-component="StatManagementComponent"
        className={classNames('my-4 space-y-6 p-4')}
      >
        {statItems.map(({ id, title, category }) => {
          const isDisabled = disabledStatsSet.has(id); // Determine if the current stat item is disabled
          return (
            <div className={classNames('flex')} key={`${category}-${id}`}>
              <label className={classNames('flex-1 opacity-80')}>{title}</label>
              <ButtonSwitchMulti
                autoSize
                options={[{ label: t('off') }, { label: t('on') }]}
                selectedIndex={isDisabled ? 0 : 1}
                onClick={() => handleToggle(id)}
              />
            </div>
          );
        })}
      </ul>
    </>
  );
};

export default StatManagementComponent;
