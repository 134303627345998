import classNames from 'classnames';
import React from 'react';
import { analyticsAgent } from '../../AnalyticTracker';
import { cn } from '../../common/utils';
import { ButtonType } from '../_ComponentsThemeStyles';
import { Loader } from '../loaders/Loader';

export type ButtonSwitchMultiOption = {
  label?: string;
  description?: string;
  icon?: React.ReactNode;
  action?: React.ReactNode;
  iconLeft?: boolean;
  iconRight?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
};

export type ButtonSwitchMultiProps = {
  className?: string;
  classNameLabel?: string;
  classNameThumb?: string;
  classNameContainer?: string;
  autoSize?: boolean;
  truncate?: boolean;
  options: ButtonSwitchMultiOption[];
  selectedIndex: number;
  buttonType?: keyof typeof ButtonType | string;
  onClick: (index: number) => void;
  disabled?: boolean;
};

export default function ButtonSwitchMulti(props: ButtonSwitchMultiProps) {
  return (
    <fieldset
      data-component="ButtonSwitchMulti"
      className={cn(
        'relative flex',
        {
          'text-xxs lg:text-xs':
            props.autoSize && props.buttonType !== 'header',
        },
        {
          'text-xxs lg:text-sm':
            props.autoSize && props.buttonType === 'header',
        },
        { 'text-xxs lg:text-xs': !props.autoSize },
        props.className,
      )}
      data-state="auto"
    >
      <div
        className={cn(
          'flex flex-1 items-center justify-center',
          'rounded-full',
          { 'h-6': !props.autoSize },
          props.buttonType === 'secondary'
            ? 'bg-app-panel-dark text-app-header-text' //border border-menu-active
            : props.buttonType === 'header'
              ? 'bg-app-header text-app-header-text' ///70 border border-app-panel-dark
              : props.buttonType === 'minimal'
                ? 'bg-app-background'
                : props.buttonType === 'switchon'
                  ? 'bg-app-background/75'
                  : 'bg-menu-active text-menu-active-text hover:bg-menu-active/50 hover:text-menu-active-text', //default
          { 'bg-app-header/85 text-app-header-text': props.disabled },
          props.classNameContainer,
        )}
      >
        {props.options.map(
          (option, index) =>
            (option.label || option.icon) && (
              <label
                key={`option-${option.label}`}
                data-label={`button-switch-multi-${option.label || ''}`}
                aria-label={`button-switch-multi-${option.label || ''}`}
                title={option.label ?? ''}
                className={cn(
                  'relative flex items-center',
                  props.disabled ? '' : 'cursor-pointer',
                  'whitespace-nowrap',
                  { truncate: props.truncate },
                  {
                    'flex-1 p-px lg:p-0.5':
                      props.autoSize && props.buttonType !== 'header',
                  },
                  {
                    'flex-1 p-px':
                      props.autoSize && props.buttonType === 'header',
                  },
                  props.classNameThumb,
                )}
                onClick={e => {
                  e.stopPropagation();
                  console.log(
                    'onClick',
                    `Button: SwitchMulti [${option.label}]`,
                  );
                  if (props.disabled) return;
                  props.onClick(index);
                  try {
                    analyticsAgent?.track(
                      `Button: SwitchMulti [${option.label}]`,
                    );
                  } catch (ex) {
                    console.debug('analyticsAgent Error:', ex);
                  }
                }}
              >
                <div
                  data-component={`ButtonSwitchMultiThumb-${props.buttonType}`}
                  aria-label="thumb"
                  className={classNames(
                    'right-0 top-0',
                    'flex items-center text-center',
                    'rounded-full',
                    'transform transition-all duration-300 ease-in-out',
                    { 'ltr:pr-4 rtl:pl-4': option.iconLeft },
                    { 'ltr:pl-4 rtl:pr-4': option.iconRight },
                    { truncate: props.truncate },
                    index === props.selectedIndex
                      ? props.buttonType === 'secondary' ||
                        (props.buttonType === 'header' && !props.disabled)
                        ? 'bg-menu-header text-menu-active-text'
                        : props.buttonType === 'minimal' && !props.disabled
                          ? 'bg-menu-secondary/90 text-menu-text/90 hover:text-menu-text hover:bg-menu-secondary'
                          : props.buttonType === 'switchon' && !props.disabled
                            ? 'bg-menu-active text-menu-active-text'
                            : 'bg-app-panel text-menu-text hover:!bg-app-panel'
                      : props.buttonType === 'secondary' && !props.disabled
                        ? 'text-menu-text/90 hover:bg-menu-active/50 hover:text-menu-active-text'
                        : (props.buttonType === 'minimal' ||
                              props.buttonType === 'switchon' ||
                              props.buttonType === 'header') &&
                            !props.disabled
                          ? 'hover:bg-menu-active/75 hover:text-menu-active-text'
                          : props.disabled
                            ? 'text-menu-text/30'
                            : 'hover:bg-menu-header hover:text-menu-active-text',
                    props.autoSize && props.buttonType !== 'header'
                      ? 'flex-1 p-px lg:p-0.5 xl:p-1'
                      : props.autoSize && props.buttonType === 'header'
                        ? 'flex-1 p-px'
                        : 'h-5 w-5',
                    props.classNameLabel,
                  )}
                >
                  {option.action ? (
                    option.action
                  ) : (
                    <span
                      className={cn(
                        'flex-1',
                        { 'flex items-center': option.isLoading },
                        'min-w-8 whitespace-nowrap',
                        { truncate: props.truncate },
                        {
                          'lg:p-0.5':
                            props.autoSize && props.buttonType !== 'header',
                        },
                        {
                          'px-1 py-0.5 lg:px-1 lg:py-0.5 xl:px-2': !option.icon,
                        },
                      )}
                    >
                      {option.isLoading && (
                        <Loader
                          size={16}
                          stroke={6}
                          type={'loading-original'}
                          className={cn('ltr:mr-1 rtl:ml-1')}
                        />
                      )}
                      {option.icon ?? option.label}
                    </span>
                  )}
                </div>
                <span
                  className={cn(
                    'text-menu-text/60 sr-only uppercase opacity-50 transition-opacity',
                    props.disabled ? 'cursor-default' : 'cursor-pointer',
                  )}
                >
                  {option.isLoading && (
                    <Loader
                      size={16}
                      stroke={6}
                      type={'loading-original'}
                      className={cn('ltr:mr-1 rtl:ml-1')}
                    />
                  )}
                  {option.label}
                </span>
              </label>
            ),
        )}
      </div>
    </fieldset>
  );
}
