import { AllocationRunResultRequirementGroupBy } from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { getSKUKey } from '../../../metrics/analyzeProduct/analyzeProductMetric.helper';
import {
  AllocationMetric,
  AllocationMetricDescriptor,
  AllocationSummaryGroupByDescriptor,
  AllocationSummaryGroupByType,
} from './allocation.types';

export function getAllocationSummaryGroutByOptions(
  t: TFunction<'simulation'>,
): Record<AllocationSummaryGroupByType, AllocationSummaryGroupByDescriptor> {
  const options: AllocationSummaryGroupByDescriptor[] = [
    {
      type: 'consignee',
      title: t('Consignee', { ns: 'simulation' }),
      groupBy: [AllocationRunResultRequirementGroupBy.CONSIGNEE],
      keyColumns: ['consignee'],
      getMetricValue: row => row.consignee,
      heatmapMetric: 'consignee',
    },
    {
      type: 'item',
      title: t('Item', { ns: 'simulation' }),
      groupBy: [
        AllocationRunResultRequirementGroupBy.CONSIGNEE,
        AllocationRunResultRequirementGroupBy.SKU,
      ],
      keyColumns: ['consignee', 'sku'],
      getMetricValue: row =>
        getSKUKey({ consignee: row.consignee, sku: row.sku }),
      heatmapMetric: 'consignee-sku',
    },
    {
      type: 'sku-group',
      title: t('Item Group', { ns: 'simulation' }),
      groupBy: [AllocationRunResultRequirementGroupBy.SKU_GROUP],
      keyColumns: ['skuGroup'],
      getMetricValue: row => row.skuGroup,
      heatmapMetric: 'sku-group',
    },
    {
      type: 'sub-group',
      title: t('Sub Group', { ns: 'simulation' }),
      groupBy: [AllocationRunResultRequirementGroupBy.SUBGROUP],
      keyColumns: ['subGroup'],
      getMetricValue: row => row.subGroup,
      heatmapMetric: 'sub-group',
    },
  ];
  return _.keyBy(options, o => o.type) as Record<
    AllocationSummaryGroupByType,
    AllocationSummaryGroupByDescriptor
  >;
}

export function getAllocationMetricDescriptors(
  t: TFunction<'simulation'>,
): Record<AllocationMetric, AllocationMetricDescriptor> {
  const descriptors: AllocationMetricDescriptor[] = [
    {
      type: 'all',
      title: t`All`,
      columns: [],
    },
    {
      type: 'required',
      title: t`Ordered`,
      columns: ['requiredQty'],
    },
    {
      type: 'assigned',
      title: t`In Stock`,
      columns: ['initiallyPickableQty'],
    },
    {
      type: 'allocated',
      title: t`Allocate (Gap)`,
      columns: ['allocatedQty'],
    },
    {
      type: 'deallocated',
      title: t`Deallocated`,
      columns: ['deallocatedQty'],
    },
    {
      type: 'unpickable',
      title: t`Issues`,
      columns: ['unpickableQty'],
    },
  ];
  return _.keyBy(descriptors, d => d.type) as Record<
    AllocationMetric,
    AllocationMetricDescriptor
  >;
}
// const AllocationSummarySummaryKeysMap: Record<
//   AllocationSummaryGroupBy,
//   keyof AllocationSummarySummaryKeyFragment
// > = {
//   CONSIGNEE: 'consignee',
//   SKU: 'sku',
//   ITEM_ID: 'itemId',
//   ORDER_LINE_RULE_ID: 'orderLineRuleId',
//   POSSIBLE_LOCATION_MASK: 'possibleLocationMask',
//   REQUIRED_LOCATION_MASK: 'requiredLocationMask',
// };

// export function getAllocationSummaryKeyTitle(
//   groupBy: AllocationSummaryGroupBy[],
//   key: AllocationSummarySummaryKeyFragment,
// ): string {
//   return _(groupBy)
//     .map(group => key[AllocationSummarySummaryKeysMap[group]])
//     .join(' ');
// }
