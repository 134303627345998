import {
  QueryDatasetDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  ActivityFeedConverterConfig,
  ActivityFeedExportJobParams,
  activityFeedExportJobParams,
  getActivityFeedTableRows,
} from '@warebee/shared/export-converter';
import { ActivityFeedEventDataset } from '@warebee/shared/import-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { containsFilter } from '../common/filterHelper';
import { AsyncLoadStatus, DatasetDataViewProps } from '../common/types';
import DatasetTable from '../components/DatasetTable';
import { getFeedTotalRowsQuery } from '../feed/store/datasetQueries/feedDataRows';
import { FeedFilterBy } from '../feed/store/datasetQueries/queryBuilder/feedQueryBuilder';
import { warehouseSelectedId } from '../store/warehouse.state';
import useLoadActivityFeedData from './hooks/useLoadActivityFeedData';
import {
  datasetObjectData,
  datasetObjectDataLoadStatus,
  datasetObjectDataState,
  datasetObjectDocumentId,
} from './store/datasetObject.state';
import useActivityFeedDataTableConfig from './useActivityFeedDataTableConfig';

const ActivityFeedDataTable: React.FC<
  DatasetDataViewProps<ActivityFeedEventDataset>
> = props => {
  const { t } = useTranslation('dataset');
  const whId = useRecoilValue(warehouseSelectedId);
  const datasetId = useRecoilValue(datasetObjectDocumentId);
  const data = useRecoilValue(datasetObjectData);
  const status = useRecoilValue(datasetObjectDataLoadStatus);
  const [dataState, setDataState] = useRecoilState(datasetObjectDataState);
  const [runExportCSV] = useRunExportJobMutation();

  const [loadCallback, reset] = useLoadActivityFeedData();
  const columnsConfig = useActivityFeedDataTableConfig();

  useEffect(() => {
    callDataLoad(dataState.searchValues);
  }, [props.id, dataState.searchValues]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  function callDataLoad(
    searchValues: Record<string, string>,
    page = { isAppend: false, skip: 0 },
  ) {
    if (_.isNil(props.id)) return;

    const filterBy: FeedFilterBy = {
      locationId: containsFilter(searchValues['locationId']),
      sku: containsFilter(searchValues['sku']),
      consignee: containsFilter(searchValues['consignee']),
      jobId: containsFilter(searchValues['jobId']),
      importJobId: containsFilter(searchValues['importJobId']),
    };

    loadCallback({
      datasetId,
      isAppend: page.isAppend,
      page: {
        skip: page.skip,
      },
      filterBy,
    });
  }

  function onLoadNext() {
    callDataLoad(dataState.searchValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  const converterConfig: ActivityFeedConverterConfig = {
    columnsConfig,
  };

  async function startExportCSV() {
    const query = getFeedTotalRowsQuery({
      datasetId,
    }).compile();
    const variables: ActivityFeedExportJobParams = {
      ...activityFeedExportJobParams,
      query: QueryDatasetDocument.loc.source.body,
      config: converterConfig,
      variables: {
        warehouseId: whId,
        query: query.sql,
        params: query.parameters,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const flattenData = getActivityFeedTableRows(
    data?.content as ActivityFeedEventDataset[],
    converterConfig,
  );

  const isLoading = status === AsyncLoadStatus.Loading;
  const itemsCount = data?.content?.length || 0;
  const totalCount = data?.totalCount ?? 0;

  if (props.customRenders) {
    _.forEach(props.customRenders, (renderer, key) => {
      const cfg = _.find(columnsConfig, c => c.field === key);
      if (!_.isNil(cfg)) {
        cfg.render = renderer;
      }
    });
  }

  return (
    <DatasetTable
      id={props.id}
      data={flattenData}
      columnsConfig={columnsConfig}
      keyFields={['eventId']}
      hasCounter
      hideScreenTitle={props.hideScreenTitle}
      subtitle={t`Activities`}
      title={props.title}
      isSticky
      onLoadNext={onLoadNext}
      onSearch={v => {
        setDataState({ ...dataState, searchValues: v });
      }}
      totalCount={totalCount}
      searchValues={dataState.searchValues}
      isLoading={isLoading}
      onStartExportClick={startExportCSV}
    />
  );
};

export default ActivityFeedDataTable;
