import {
  LayoutLevelLocationFragment,
  LayoutLocationWithMatchingFiltersFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { TwTheme } from '../../../Tw';
import {
  getBestContrastColor,
  getQualitativeColor,
  manipulateColor,
} from '../../common/color.helper';
import useGetNamedColor from '../../common/useGetNamedColor';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import { loadBalancingPolicyLocationByLevel } from '../../loadBalancingPolicy/loadBalancingPolicy.state';
import { swapPolicyLevelLocationByRule } from '../../swapPolicy/swapPolicy.state';
import { AP_FALLBACK_RULE_ID } from '../store/assignmentPolicy.default';
import {
  assignmentPolicyDocument,
  assignmentPolicyLocationByLevel,
  assignmentPolicyRule,
  assignmentPolicySelectedIdentity,
} from '../store/assignmentPolicy.state';
import { pickingPolicyLevelLocationByRule } from '../store/pickingPolicy/pickingPolicy.state';
import {
  simulationOptimisationPolicyTabKey,
  simulationWizardSelectedStepId,
} from '../store/simulation.wizard.state';
import { STACKING_POLICY_DEFAULT_RULE_ID } from '../store/stackingPolicy/stackingPolicy.default';
import {
  stackingPolicyLocationByLevel,
  stackingPolicySelectedIdentity,
} from '../store/stackingPolicy/stackingPolicy.state';
import { StackingPolicyItem } from '../store/stackingPolicy/stackingPolicy.types';

const locationColor = TwTheme.extend.colors.location;
const opacity = 0.4;

const SimulationPolicyLocationLayer: React.FC = () => {
  const stepId = useRecoilValue(simulationWizardSelectedStepId);
  const getNamedColor = useGetNamedColor();
  const optimisationTab = useRecoilValue(simulationOptimisationPolicyTabKey);
  const pickingPolicyLocationLoadable = useRecoilValueLoadable(
    pickingPolicyLevelLocationByRule,
  );
  const swapPolicyLocationLoadable = useRecoilValueLoadable(
    swapPolicyLevelLocationByRule,
  );
  const balancingPolicyLocationLoadable = useRecoilValueLoadable(
    loadBalancingPolicyLocationByLevel,
  );

  const assignmentPolicyLocationLoadable = useRecoilValueLoadable(
    assignmentPolicyLocationByLevel,
  );

  const stackingPolicyLocationLoadable = useRecoilValueLoadable(
    stackingPolicyLocationByLevel,
  );
  const assignmentPolicy = useRecoilValue(assignmentPolicyDocument);
  const assignmentPolicyFallbackRule = useRecoilValue(
    assignmentPolicyRule(AP_FALLBACK_RULE_ID),
  );
  const assignmentPolicySelected = useRecoilValue(
    assignmentPolicySelectedIdentity,
  );
  const stackingPolicySelected = useRecoilValue(stackingPolicySelectedIdentity);

  let getColor: (loc: LayoutLevelLocationFragment) => [string, string] | null;
  let getLabel: (loc: LayoutLevelLocationFragment) => string;
  let getGradientColors: (loc: LayoutLevelLocationFragment) => string[];

  if (
    stepId === 'policy-picking' &&
    pickingPolicyLocationLoadable.state === 'hasValue'
  ) {
    getColor = (loc: LayoutLevelLocationFragment) =>
      pickingPolicyLocationLoadable.contents?.has(loc.locationId)
        ? [locationColor.selected, getBestContrastColor(locationColor.selected)]
        : null;
  }

  if (
    stepId === 'optimise' &&
    optimisationTab === 'tab-optimisation-policy-swap' &&
    swapPolicyLocationLoadable.state === 'hasValue'
  ) {
    getColor = (loc: LayoutLevelLocationFragment) =>
      swapPolicyLocationLoadable.contents?.has(loc.locationId)
        ? [locationColor.selected, null]
        : null;
  }

  if (
    stepId === 'optimise' &&
    optimisationTab === 'tab-optimisation-policy-load-balancing' &&
    balancingPolicyLocationLoadable.state === 'hasValue'
  ) {
    const balancingSummary = balancingPolicyLocationLoadable.contents;
    if (!_.isEmpty(balancingSummary?.zones)) {
      const zoneDictionary = _.reduce(
        balancingSummary?.zones,
        (zones, z) => ({
          ...zones,
          ..._.reduce(
            z.locationIds,
            (acc, l) => ({ ...acc, [l]: z.zoneId }),
            {},
          ),
        }),
        {},
      );

      getColor = (loc: LayoutLevelLocationFragment) =>
        _.isNil(zoneDictionary[loc.locationId])
          ? null
          : getQualitativeColor(zoneDictionary[loc.locationId], 'policy');
    }
  }

  if (
    stepId === 'policy-storage' &&
    assignmentPolicyLocationLoadable.state === 'hasValue'
  ) {
    const locationMap: Record<
      string,
      LayoutLocationWithMatchingFiltersFragment
    > = assignmentPolicyLocationLoadable.contents;

    getGradientColors = (loc: LayoutLevelLocationFragment): string[] => {
      const ruleIndexes = locationMap[loc.locationId]?.allMatchingFilters;

      if (_.isEmpty(ruleIndexes)) {
        // fallback color

        // const ruleColor = getNamedColor(
        //   assignmentPolicyFallbackRule.title,
        // )?.[0];
        const ruleColor = locationColor.DEFAULT;

        const isSelected =
          AP_FALLBACK_RULE_ID === assignmentPolicySelected?.ruleId;
        const applyNonSelectedColor =
          !_.isNil(assignmentPolicySelected?.ruleId) && !isSelected;

        return [
          applyNonSelectedColor
            ? manipulateColor(ruleColor, {
                desaturate: true,
              })
            : ruleColor,
        ];
      }

      const colors = _(ruleIndexes)
        .map(i => {
          const rule = assignmentPolicy?.rules[i];
          const ruleColor = getNamedColor(
            rule?.title ?? AP_FALLBACK_RULE_ID,
          )?.[0];
          const isSelected = rule?.id === assignmentPolicySelected?.ruleId;
          const applyNonSelectedColor =
            !_.isNil(assignmentPolicySelected?.ruleId) && !isSelected;
          // return applyOpacity ? getColorDarker(ruleColor) : ruleColor;
          return applyNonSelectedColor
            ? manipulateColor(ruleColor, {
                // darkness: 0.5,
                desaturate: true,
                // saturation: -1,
              })
            : ruleColor;
        })
        .compact()
        .value();
      return _.isEmpty(colors) ? null : colors;
    };
  }

  if (
    stepId === 'policy-stacking' &&
    stackingPolicyLocationLoadable.state === 'hasValue'
  ) {
    const locationMap: Record<string, StackingPolicyItem[]> =
      stackingPolicyLocationLoadable.contents;

    getGradientColors = (loc: LayoutLevelLocationFragment): string[] => {
      let colors = _(locationMap[loc.locationId])
        .map(item => item.color)
        .compact()
        .value();

      const isSelected = _.some(
        locationMap[loc.locationId],
        item =>
          (item.policyRule?.id ?? STACKING_POLICY_DEFAULT_RULE_ID) ===
          stackingPolicySelected?.ruleId,
      );

      const needApplyOpacity =
        !_.isNil(stackingPolicySelected?.ruleId) && !isSelected;

      if (needApplyOpacity) {
        colors = _.map(colors, c =>
          manipulateColor(c, { darkness: 0.5, saturation: 0 }),
        );
      }
      return _.isEmpty(colors) ? null : colors;
    };
  }

  if (_.isNil(getColor) && _.isNil(getGradientColors)) return null;
  return (
    <LocationLayer getColor={getColor} getGradientColors={getGradientColors} />
  );
};

export default SimulationPolicyLocationLayer;
