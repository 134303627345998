import classNames from 'classnames';
import _ from 'lodash';
import { useState } from 'react';

export type StatItemPieProps = {
  label?: string;
  value?: string | number;
};

export const StatItemPieContainer = ({ label, value }: StatItemPieProps) => {
  return (
    <div
      data-component="StatItemPieContainer"
      className="max-w-28 xl:max-w-32 w-28 xl:w-32"
    >
      <label className="uppercase text-minimal xl:text-xs">{label}</label>
      <div className="text-base md:text-sm lg:text-xl xl:text-2xl">{value}</div>
    </div>
  );
};

export type StatCenterPieProps = {
  statLabelTop?: string;
  statLabelBottom?: string;
  statLabelMiddle?: string;
  statValueTop?: string | number;
  statValueBottom?: string | number;
  statValueMiddle?: string | number;
};

export const StatCenterPie = ({
  statLabelTop,
  statValueTop,
  statLabelMiddle,
  statValueMiddle,
  statLabelBottom,
  statValueBottom,
}: StatCenterPieProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      data-component="StatCenterPie"
      className={classNames(
        'absolute top-0 ltr:left-0 rtl:right-0',
        'flex flex-col items-center justify-center flex-1 w-full h-full',
        'text-center isolate',
        'divide-y divide-opacity-50 divide-solid divide-menu-400',
      )}
    >
      {statValueTop && (
        <StatItemPieContainer label={statLabelTop} value={statValueTop} />
      )}
      {statValueMiddle && (
        <StatItemPieContainer label={statLabelMiddle} value={statValueMiddle} />
      )}
      {statValueBottom && (
        <StatItemPieContainer label={statLabelBottom} value={statValueBottom} />
      )}
    </div>
  );
};
