import {
  BatchJobStatus,
  useRemoveImportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import { ImportJobsDataRow } from '@warebee/shared/export-converter';
import classNames from 'classnames';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  formatDateTime,
  formatInteger,
  formatShortenedTimespan,
} from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import useFormatter from '../common/useFormatter';
import { ColumnConfig } from '../components/DatasetTable';
import { Button } from '../components/actions/Button';
import CopyToClipboardButton from '../components/actions/CopyToClipboardButton';
import * as Icon from '../components/icons';
import LinkTraced from '../components/nav/LinkTraced';
import {
  datasetObjectDataState,
  datasetObjectDocumentId,
  datasetObjectSelectedMenuItemId,
} from '../datasetObject/store/datasetObject.state';
import Tag from '../helpContext/Tag';
import { importTypePath } from '../import/store/import.helper';
import EmptyValueTag from '../simulation/tags/EmptyValueTag';
import { warehouseSelectedId } from '../store/warehouse.state';
import { importJobsData } from './store/importJobs.state';

type JobStatusTagProps = {
  job: ImportJobsDataRow;
};

const JobStatusTag = (props: JobStatusTagProps) => {
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const navigate = useNavigate();
  const classType = classNames(
    {
      'border border-state-draft text-menu-text':
        props.job.status === BatchJobStatus.CREATED,
    },
    {
      'text-menu-active-text bg-state-analyzed':
        props.job.status === BatchJobStatus.CALCULATING,
    },
    {
      'text-menu-active-text bg-state-optimized':
        props.job.status === BatchJobStatus.READY,
    },
    {
      'text-menu-text bg-alerts-error':
        props.job.status === BatchJobStatus.FAILED ||
        props.job.status === BatchJobStatus.TERMINATED,
    },
  );

  return (
    <div className="flex items-center">
      <Button
        full
        buttonSize={'xs'}
        buttonType="secondary"
        hasIconAfter
        buttonIcon={
          <Icon.Reload className={classNames('h-4 w-3 fill-current')} />
        }
        className="rounded text-xs"
        onPress={() => {
          navigate(
            `/wh/i/${warehouseId}/import/${importTypePath[props.job.importType]}/${props.job.id}`,
          );
        }}
      />
      <Link
        to={`/wh/i/${warehouseId}/import/${importTypePath[props.job.status]}/${props.job.id}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => {}}
      >
        <Tag classType={classType}>{props.job.status}</Tag>
      </Link>
    </div>
  );
};

const RemoveJobButton = (props: { id: string }) => {
  const { t } = useTranslation('dataset');
  const [importJobs, setImportJobs] = useRecoilState(importJobsData);
  const [removeJob] = useRemoveImportJobMutation();
  const [status, setStatus] = useState<AsyncLoadStatus>(AsyncLoadStatus.None);

  async function remove() {
    try {
      setStatus(AsyncLoadStatus.Loading);
      const { data, errors } = await removeJob({
        variables: {
          id: props.id,
          options: {
            removeJob: true,
            removeInputFile: true,
            removeImportedData: true,
          },
        },
      });
      if (errors) {
        setStatus(AsyncLoadStatus.Error);
        return;
      }

      //success
      setStatus(AsyncLoadStatus.Ok);
      setImportJobs({
        totalCount: importJobs.totalCount - 1,
        content: _.filter(importJobs.content, j => j.id !== props.id),
      });
    } catch (ex) {
      console.error(ex);
      setStatus(AsyncLoadStatus.Error);
    }
  }
  const label =
    status === AsyncLoadStatus.Loading
      ? t`Processing...`
      : status === AsyncLoadStatus.Error
        ? t`Error`
        : t`Remove`;

  return (
    <Button
      className="rounded"
      buttonSize="xs"
      buttonType="delete"
      label={label}
      onPress={remove}
      hasIconAfter={
        <Icon.CircleX className={classNames('h-5 w-5 fill-current')} />
      }
    />
  );
};

const ViewDataButton = (props: { id: string; title?: string }) => {
  const { t } = useTranslation('dataset');
  const setFilter = useSetRecoilState(datasetObjectDataState);
  const documentId = useRecoilValue(datasetObjectDocumentId);
  const setStepId = useSetRecoilState(
    datasetObjectSelectedMenuItemId(documentId),
  );

  async function showData() {
    setFilter(state => ({
      ...state,
      searchValues: {
        importJobId: props.id,
      },
    }));
    setStepId('dataset-object-menu-start');
  }

  return (
    <Button
      label={props.title ? props.title : t`Data`}
      className={classNames('rounded', 'text-xs')}
      buttonSize="xs"
      buttonType="secondary"
      onPress={showData}
      hasIconBefore={
        props.title && (
          <Icon.ShowTable className={classNames('h-5 w-5 fill-current')} />
        )
      }
      hasIconAfter={
        !props.title && (
          <Icon.ShowTable className={classNames('h-5 w-5 fill-current')} />
        )
      }
    />
  );
};

export type ImportJobsTableConfigParams = {
  withTargetColumns?: boolean;
};

function useImportJobsTableConfig(
  params: ImportJobsTableConfigParams,
): ColumnConfig<ImportJobsDataRow>[] {
  const { t } = useTranslation('dataset');

  const formatted = useFormatter();

  const cols: ColumnConfig<ImportJobsDataRow>[] = [
    {
      field: 'status',
      title: t(`Status`, { ns: 'simulation' }),
      render: (v, job) => <JobStatusTag job={job} />,
      isHeader: true,
    },
    {
      field: 'sourceProcessType',
      title: t`Data Source`,
      render: (v: string) => <Tag className="text-xs">{v}</Tag>,
    },

    // {
    //   field: 'createdAt',
    //   title: t(`Created`, { ns: 'simulation' }),
    //   render: (v: number) => (
    //     <span className="text-sm">{formatDateTime(new Date(v))}</span>
    //   ),
    // },

    {
      field: 'completedAt',
      title: t(`Date`, { ns: 'simulation' }),
      // render: (v: number) => v && formatDateTime(new Date(v)),
      render: (_, row) => {
        if (row.completedAt && row.createdAt) {
          const duration = row.completedAt - row.createdAt;
          return (
            <div className="space-y-1">
              <div className="mb-1 text-xs" title={`Created at`}>
                {formatDateTime(new Date(row.createdAt))}
              </div>
              <div className="text-xs" title={`Completed`}>
                {formatDateTime(new Date(row.completedAt))}
              </div>
              <div className="text-right text-xs opacity-50">
                ({formatShortenedTimespan(duration)})
              </div>
            </div>
          );
        }
        return <EmptyValueTag />;
      },
    },
    // {
    //   field: 'completedAt',
    //   title: t(`Duration`, { ns: 'simulation' }),
    //   render: (_, row) => {
    //     if (row.completedAt && row.createdAt) {
    //       const duration = row.completedAt - row.createdAt;
    //       return (
    //         <span className="text-xxs opacity-50">
    //           {formatShortenedTimespan(duration)}
    //         </span>
    //       );
    //     }
    //     return <EmptyValueTag />;
    //   },
    // },
    // {
    //   field: 'inputLineCount',
    //   title: t`Lines`,
    // },
    {
      field: 'importedLineCount',
      title: t`Imported`,
      render: (value, row) => {
        if (value === null || value === 0 || isNaN(value)) {
          return (
            <div className="flex w-full items-center">
              <EmptyValueTag />
            </div>
          );
        }

        const difference = row.inputLineCount - row.importedLineCount;
        const percentage =
          row.inputLineCount > 0
            ? ((row.importedLineCount / row.inputLineCount) * 100).toFixed(1)
            : '0.00';

        let iconStatus;
        if (value === 0 || value === null || isNaN(value)) {
          iconStatus = (
            <Icon.TriangleInfo
              className={
                'text-compliance-nonCompliant mx-1 h-3 w-3 fill-current'
              }
            />
          );
        } else if (parseFloat(percentage) === 100) {
          iconStatus = (
            <Icon.CircleOk
              className={'text-compliance-compliant mx-1 h-3 w-3 fill-current'}
            />
          );
        } else if (parseFloat(percentage) <= 80) {
          iconStatus = (
            <Icon.TriangleInfo
              className={
                'text-compliance-nonCompliant mx-1 h-3 w-3 fill-current'
              }
            />
          );
        } else {
          iconStatus = (
            <Icon.CircleOk
              className={'text-heatmap_compliance-10 mx-1 h-3 w-3 fill-current'}
            />
          );
        }

        return (
          <div className="flex items-center">
            {iconStatus}
            <div className="flex flex-col items-start">
              <div>
                {difference === 0 ? (
                  <>
                    <span className="">
                      {formatInteger(row.inputLineCount)}
                    </span>
                  </>
                ) : (
                  <>
                    {formatInteger(row.importedLineCount)}
                    <span className="text-xxs mx-1 opacity-50">{`of`}</span>
                    {formatInteger(row.inputLineCount)}
                  </>
                )}
              </div>
              {difference > 0 && (
                <div className="space-x-1">
                  <span className="text-xxs opacity-30">({difference})</span>
                  <span className="text-xxs opacity-50">({percentage}%)</span>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'errorCount',
      title: t`Errors`,
      render: (value, row) => {
        return (
          <div className="flex w-full items-center">
            {value ? value : <EmptyValueTag />}
          </div>
        );
      },
    },
    {
      field: 'sanitizedFailureReason',
      title: t`Issues`,
      render: (value, row) => {
        return (
          <div className="flex w-full items-center text-xs">
            {value ? value : <EmptyValueTag />}
          </div>
        );
      },
    },

    {
      field: 'filename',
      title: t`Filename`,
      hasFilter: true,
      render: (filename: string, file: ImportJobsDataRow) => (
        <div className="bg-app-panel flex w-full items-center rounded px-2 py-0.5">
          <LinkTraced
            rel="noopener noreferrer"
            className={classNames(
              'group',
              'cursor-pointer',
              'flex items-center',
              'flex-grow',
              'hover:text-menu-active',
            )}
            to={file.downloadUrl}
            target="_blank"
          >
            <Icon.FiletypeAny
              className={classNames(
                'h-4 w-4',
                'fill-current',
                'group-hover:text-menu-active',
              )}
            />
            <span
              className={classNames(
                'ltr:mx-2 rtl:mx-2',
                'flex-1',
                'group-hover:text-menu-active',
                'text-xs',
              )}
            >
              {filename}
            </span>
          </LinkTraced>
          <CopyToClipboardButton hasIconSmall value={filename} />
        </div>
      ),
    },
    params.withTargetColumns
      ? {
          field: 'targetTitle',
          title: t(`Target title`, { ns: 'simulation' }),
          render: id => <ViewDataButton id={id} title={id} />,
        }
      : null,
    {
      field: 'id',
      title: t`ID`,
      render: (v: string) => (
        <CopyToClipboardButton truncate title={v} value={v} />
      ),
    },
    params.withTargetColumns
      ? {
          field: 'targetId',
          title: t(`Target Id`, { ns: 'simulation' }),
          render: (v: string) => (
            <CopyToClipboardButton truncate title={v} value={v} />
          ),
        }
      : null,
    {
      field: 'transformation',
      title: t`Transformation`,
      render: (v: string, row) =>
        row.transformationEnabled ? (
          <CopyToClipboardButton title={'Select ...'} value={v} />
        ) : null,
    },
    {
      field: 'id',
      title: t`Data`,
      render: id => <ViewDataButton id={id} />,
      hiddenInExport: true,
    },
    {
      field: 'id',
      title: t`Action`,
      render: id => <RemoveJobButton id={id} />,
      hiddenInExport: true,
    },
    // {
    //   field: 'downloadUrl',
    //   title: t`Link`,
    //   // render: id => <RemoveJobButton id={id} />,
    //   hiddenInExport: true,
    // },
  ];

  return _.compact(cols);
}

export default useImportJobsTableConfig;
