import _ from 'lodash';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { cn } from '../../common/utils';
import InboxZero from '../../components/InboxZero';
import TitleSection from '../../components/layout/TitleSection';
import { StatListItem } from '../../components/stats/StatListItem';
import PanelContainer from '../../containers/PanelContainer';
import ItemTag from '../../simulation/tags/ItemTag';
import useActualityHeatmapItems from '../hooks/useActualityHeatmapItems';
import { feedLatestLocationEvents } from '../store/feed.state';
import FeedEventsList from './FeedEventsList';

export type FeedLocationItemsProps = {
  locationId: string;
};

const FeedLocationItems: React.FC<FeedLocationItemsProps> = props => {
  const { t } = useTranslation('feed');
  const { getLocationItems } = useActualityHeatmapItems();
  const events = useRecoilValue(feedLatestLocationEvents);

  const items = getLocationItems(props.locationId);
  const sectionTitle = t`Item Info`;
  const hasItems = !_.isEmpty(items);

  if (!hasItems) {
    return (
      <InboxZero message={t`Empty location`} message_helper={`(No Items)`} />
    );
  }

  return (
    <PanelContainer
      id="panel-feed-stats-item"
      title={sectionTitle}
      collapsible
      hasPadding={false}
      className={cn('flex h-full flex-1 flex-col')}
      // isAccordion
      classNameChildren="flex flex-col h-full"
    >
      <TitleSection inPanelView title={t`Item`} />

      {_.map(items, item => (
        <Fragment key={item.skuKey}>
          <div data-component="ItemData" className={cn('py-1')}>
            <ItemTag
              title={item.itemRanked?.sku ?? item.skuKey}
              filters={{
                consignee: item.itemRanked?.consignee,
                sku: item.itemRanked?.sku,
              }}
            />
            
            <StatListItem
              title={item.metricDescriptor.title}
              value={item.heatmapValue}
              valueStyle={{
                color: item.bucket?.color,
                borderColor: item.bucket?.color,
                borderWidth: item.bucket?.color ? 1 : 0,
              }}
            />
          </div>
          <div data-component="ItemDataEventsHistory" className={cn('flex-1')}>
            <FeedEventsList events={events} groupingOption="byHour" />
          </div>
        </Fragment>
      ))}
    </PanelContainer>
  );
};

export default FeedLocationItems;
