import { useApolloClient } from '@apollo/client';
import {
  FindLocationsStatsFilterDocument,
  FindLocationsStatsFilterQuery,
  FindLocationsStatsFilterQueryVariables,
  LocationFilterUnionInput,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../common/types';
import {
  policyFilteredLocationsStatsLoadStatus,
  policyFilteredLocationStats,
  policyLocationsStatsDimension,
} from '../simulation/store/policyFilter.state';
import {
  simulationAssignmentId,
  simulationLayoutId,
} from '../simulation/store/simulation.state';
import {
  getLocationCapacitySpec,
  getLocationsStatsDimensions,
  locationStatsFixedDimensions,
} from '../simulation/tableViews/locationsStatsTable.config';
import { errorAppender } from '../store/error.state';

export type LoadLocationsStatsByRuleParams = {
  includeMatching?: LocationFilterUnionInput;
  excludeMatching?: LocationFilterUnionInput[];
  skipDefaultDimensions?: boolean;
};

function useLoadLocationsStatsByRule() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Location stats by rule.`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadLocationsStatsByRuleParams) => {
        set(policyFilteredLocationsStatsLoadStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadLocationsStatsByRuleParams) => {
        //const simulationId = await snapshot.getPromise(simulationCurrentId);
        const layoutId = await snapshot.getPromise(simulationLayoutId);
        const assignmentId = await snapshot.getPromise(simulationAssignmentId);
        const dimensionId = await snapshot.getPromise(
          policyLocationsStatsDimension,
        );

        // prevent to call request with assignment related drill downs when there is no assignment selected
        if (
          _.isNil(assignmentId) &&
          (dimensionId === 'ASSIGNED' || dimensionId === 'CONSIGNEE')
        ) {
          set(policyFilteredLocationStats, null);
          set(policyFilteredLocationsStatsLoadStatus, AsyncLoadStatus.Ok);
          return;
        }

        const { includeMatching, excludeMatching } = params;

        // const filter: ItemsFilter = {
        //   consigneeContains: searchValues['consignee'],
        //   skuContains: searchValues['sku'],
        //   skuGroupContains: searchValues['skuGroup'],
        // };

        function handleError(details, stack) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(policyFilteredLocationsStatsLoadStatus, AsyncLoadStatus.Error);
        }

        const buildInDimensions = params.skipDefaultDimensions
          ? []
          : locationStatsFixedDimensions;

        const dimensions = getLocationsStatsDimensions();
        const query = client.watchQuery<
          FindLocationsStatsFilterQuery,
          FindLocationsStatsFilterQueryVariables
        >({
          query: FindLocationsStatsFilterDocument,
          variables: {
            layoutId,
            input: {
              includeMatching,
              excludeMatching,
            },
            summary: {
              assignmentId,
              groupBy: [
                ...dimensions[dimensionId].groupBy,
                ...buildInDimensions,
              ],
              uoms: _.map(getLocationCapacitySpec(), spec =>
                _.omit(spec, 'title'),
              ),
            },
          },
        });

        const queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            set(
              policyFilteredLocationStats,
              data?.layout?.locationsByFilter?.summary,
            );
            set(policyFilteredLocationsStatsLoadStatus, AsyncLoadStatus.Ok);
          },
          error => {
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadLocationsStatsByRuleParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadLocationsStatsByRule;
