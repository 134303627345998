import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../../common/useFormatter';
import InboxZero from '../../../components/InboxZero';
import { Container } from '../../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../../containers/SidebarContainer';
import { updatePolicyFilterValues } from '../../../policyFilters/policyFilter.helper';
import useLoadItemFilterValues from '../../../policyFilters/useLoadItemFilterValues';
import {
  deallocationPolicyRule,
  deallocationPolicySelectedIdentity,
} from '../../store/allocationPolicy/allocationPolicy.state';
import { getProductFilterConfigCommon } from '../../store/assignmentPolicy.default';
import DeallocatePolicyProductFilterEditor from './DeallocatePolicyProductFilterEditor';

const DeallocatePolicyEditorSidebar: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const selectedIdentity = useRecoilValue(deallocationPolicySelectedIdentity);
  const rule = useRecoilValue(deallocationPolicyRule(selectedIdentity?.ruleId));
  const [loadItemValues, cancelLoadItemValues] = useLoadItemFilterValues();

  const showProductFilters = !_.isNil(selectedIdentity?.ruleId);
  const productFiltersConfig = getProductFilterConfigCommon(t, formatter);

  useEffect(() => {
    if (selectedIdentity?.filterId) {
      updatePolicyFilterValues({
        filterConfig: productFiltersConfig,
        selectedFilterId: selectedIdentity.filterId,
        policyFilterUnion: rule.itemsMatch,
        load: loadItemValues,
        cancel: cancelLoadItemValues,
      });
    }
  }, [selectedIdentity?.filterId]);

  if (!showProductFilters) {
    return (
      <InboxZero
        selfCenter
        center
        message={t`No Policy filter group selected`}
        message_helper={t`Click on an Edit button in Policy Rule to change filters`}
      />
    );
  }

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        subtitle={t`Filter Options for`}
        title={`${rule.title}`}
        isSticky
      />

      {_.map(productFiltersConfig, configItem => (
        <DeallocatePolicyProductFilterEditor
          key={`policy-filter-editor-${configItem.type}`}
          filterConfig={configItem}
          startLoadData={loadItemValues}
          cancelLoadData={cancelLoadItemValues}
        />
      ))}
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-policy-deallocate-editor',
    title: t`Filter Policy Params`,
    loadingMessage: t`Loading Policy...`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <DeallocatePolicyEditorSidebar />
    </SidebarContainer>
  );
};
