import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { ContainerCol } from '../components/layout/ContainerFlex';
import ItemSetDataTable from './ItemSetDataTable';
import ItemSetFilterTag from './ItemSetFilterTag';
import { itemSetDocument } from './store/itemSet.state';

const ItemSetDataView: React.FC = () => {
  const { t } = useTranslation('dataset');
  const itemSet = useRecoilValue(itemSetDocument);

  return (
    <ContainerCol
      overflow
      componentName="ItemSetDefaultView"
      className={`border-app-panel-dark relative flex-1 overflow-hidden ltr:border-l rtl:border-r`}
    >
      <PanelHeader
        title={t`Items Data Table`}
        filterPlaceholder={<ItemSetFilterTag />}
      />
      <ItemSetDataTable id={itemSet?.id} title={itemSet?.title} />
    </ContainerCol>
  );
};

export default ItemSetDataView;
