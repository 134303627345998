import { sql } from 'kysely';
import _ from 'lodash';
import { toLocaleDateTimeDBString } from '../../../../common/dateTimeHelper';
import { datasetQueryBuilder } from './datasetQueryBuilder';
import { ActivityFeedQueryBuilderParams } from './feedEventsQueryBuilders';

/**
 *  Create AT (Analytic Table) contains jobs, aggregated from activity feed events
 *
 */

export function getActivityFeedJobsBuilder(
  params: ActivityFeedQueryBuilderParams,
) {
  const start = params.from
    ? toLocaleDateTimeDBString(params.from)
    : new Date();
  const end = params.to ? toLocaleDateTimeDBString(params.to) : new Date();

  const hasAgentTypeFilter = !_.isEmpty(params.disabledAgentTypes);
  const hasProcessTypeFilter = !_.isEmpty(params.disabledProcessTypes);
  const hasAgentFilter = !_.isNil(params.agentId);

  let builder = datasetQueryBuilder

    .with(
      cte => cte('jobIds'),
      db =>
        db
          .selectFrom('___activity_feed_iceberg___')
          .select(({ fn }) => [
            `jobId as targetJobId`,
            'agentId as targetAgentId',
            fn.count<number>('eventId').as('timeFramedEventsCount'),
            fn.sum<number>('quantity').as('timeFramedUomCount'),
          ])
          .where('eventEndTime', '>=', sql`cast( ${start} as timestamp)`)
          .where('eventEndTime', '<=', sql`cast( ${end} as timestamp)`)
          .where('datasetObjectId', '=', params.datasetId)
          .groupBy(['jobId', 'agentId'])
          .$if(hasAgentTypeFilter, db =>
            db.where('agentType', 'not in', params.disabledAgentTypes),
          )
          .$if(hasProcessTypeFilter, db =>
            db.where('eventProcessType', 'not in', params.disabledProcessTypes),
          )
          .$if(hasAgentFilter, db => db.where('agentId', '=', params.agentId)),
    )
    .with(
      cte => cte('af'),
      db =>
        db
          .selectFrom('___activity_feed_iceberg___')
          .innerJoin('jobIds', join =>
            join
              .onRef('jobId', '=', 'targetJobId')
              .onRef('agentId', '=', 'targetAgentId'),
          )
          .selectAll()
          .select(({ fn }) => [
            sql<Date>`
            lag(${sql.ref('eventEndTime')}, 1) 
            OVER(
              PARTITION BY ${sql.ref('agentId')}
              ORDER BY ${sql.ref('eventEndTime')} ASC
            )`.as('previousEndTime'),
            sql<number>`row_number() over (
              PARTITION BY ${sql.ref('jobId')}
              ORDER BY ${sql.ref('eventEndTime')} ASC)`.as('jobLineSequence'),
            sql<string>`${sql.ref('consignee')} ||'-'|| ${sql.ref('sku')} `.as(
              'skuKey',
            ),
            sql<string>`${sql.ref('jobId')} || ${sql.ref('agentId')} `.as(
              'jobKey',
            ),
          ])
          .$if(hasAgentTypeFilter, db =>
            db.where('agentType', 'not in', params.disabledAgentTypes),
          )
          .$if(hasProcessTypeFilter, db =>
            db.where('eventProcessType', 'not in', params.disabledProcessTypes),
          )
          .$if(hasAgentFilter, db => db.where('agentId', '=', params.agentId)),
    )
    .with(
      cte => cte('afj'),
      db => {
        return db
          .selectFrom('af')
          .select(({ fn }) => [
            'jobKey',
            'jobId',
            'agentId',
            'agentType',
            'eventProcessType',
            fn.count<number>('eventId').as('lines'),
            fn.sum<number>('quantity').as('uomCount'),
            fn.min('eventEndTime').as('minEventTime'),
            fn.min('previousEndTime').as('minPreviousEndTime'),
            fn.max('eventEndTime').as('maxEventTime'),
            fn.min('timeFramedEventsCount').as('timeFramedEventsCount'),
            fn.min('timeFramedUomCount').as('timeFramedUomCount'),
          ])
          .groupBy([
            'jobKey',
            'agentId',
            'jobId',
            'agentType',
            'eventProcessType',
          ]);
      },
    );
  return builder;
}
