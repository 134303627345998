import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { Container } from '../../../components/layout/ContainerFlex';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../../containers/SidebarContainer';
import useSelectSimulationWizardStep from '../../hooks/useSelectSimulationWizardStep';
import ComplianceVolumePanel from '../../panels/compliance/ComplianceVolumePanel';
import ActionBarNextStep from '../ActionBarNextStep';

const ComplianceVolumeSidebarContent: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectStep = useSelectSimulationWizardStep();

  return (
    <>
      <Container col>
        <Suspense
          fallback={<LoadingIndicator selfCenter message={t`Loading...`} />}
        >
          <ComplianceVolumePanel />
        </Suspense>
      </Container>
      <ActionBarNextStep />
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-compliance-volume',
    title: t`Occupancy and Volume`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <ComplianceVolumeSidebarContent />
    </SidebarContainer>
  );
};
