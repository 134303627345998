import { Container } from '../../components/layout/ContainerFlex';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HelperLearnMore } from '../../components/layout/HelperLearnMore';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { HelperTemplate } from '../helper/HelperTemplate';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import ActionBarNextStep from './ActionBarNextStep';

const SimulationSidebarDataset: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectStep = useSelectSimulationWizardStep();

  return (
    <>
      <Container col hasOverflowY hasExtraPadding>
        <HelperTemplate
          title={t`About Simulation Dataset`}
          subtitle={t`Getting Started`}
          hasLearnMore={false}
          hasPadding
        >
          {t`Let's Get Started with the new Slotting Simulation Dataset and Policies`}
          <HelperLearnMore title={t`Learn More...`} />
        </HelperTemplate>
      </Container>
      <ActionBarNextStep title={t`Start`} />
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-dataset',
    title: t`Dataset`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SimulationSidebarDataset />
    </SidebarContainer>
  );
};
