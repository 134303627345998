import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../../components/actions/ButtonSwitchMulti';
import useUserPermissionFeatures from '../../permissions/roles/hooks/useUserPermissionFeatures';
import {
  actualityMainViewType,
  actualityShowDatasetAsTable,
} from '../store/actuality.state';
import { ActualityMainViewType } from '../store/actuality.types';

const FeedLayoutViewSwitch: React.FC = () => {
  const { t } = useTranslation('feed');
  const [viewAs, setViewAs] = useRecoilState(actualityMainViewType);
  const setShowDatasetTable = useSetRecoilState(actualityShowDatasetAsTable);
  const visibleFeatures = useUserPermissionFeatures();

  const options: (ButtonSwitchMultiOption & { id: ActualityMainViewType })[] = [
    { label: t('Map'), id: 'layout' },
    { label: t('Data'), id: 'table' },
    { label: t('Timeline'), id: 'timeline' },
  ];

  const handleOptionClick = (index: number) => {
    const selectedId = options[index].id;
    setViewAs(selectedId);

    if (selectedId === 'table') {
      setShowDatasetTable('feed');
    } else {
      setShowDatasetTable(null);
    }
  };

  const allowedOptions = options.filter(
    option => visibleFeatures.find(f => f.id === option.id)?.visible,
  );

  return (
    <ButtonSwitchMulti
      autoSize
      className="mx-3"
      classNameLabel="text-xs py-1 px-2"
      buttonType="minimal"
      selectedIndex={_.findIndex(allowedOptions, o => o.id === viewAs)}
      onClick={handleOptionClick}
      options={allowedOptions}
    />
  );
};

export default FeedLayoutViewSwitch;
