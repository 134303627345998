import { DatasetQueryConverterConfig } from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { containsFilter } from '../../common/filterHelper';
import { AsyncLoadStatus, DatasetDataViewProps } from '../../common/types';
import DatasetTable from '../../components/DatasetTable';
import useLoadActualityHeatmapTableData from '../hooks/useLoadActualityHeatmapTableData';
import {
  actualityHeatmapBuilderParams,
  actualityHeatmapTableData,
  actualityHeatmapTableDataLoadStatus,
  actualityHeatmapTableDataState,
  actualityHeatmapTableTotalCount,
} from '../store/actuality.heatmap.state';
import { ActualityHqDataRow } from '../store/datasetQueries/actualityHqDataRows';
import { ActualityHqFilterBy } from '../store/datasetQueries/actualityQueryBuilder';
import useActualityHeatmapDataTableConfig from './useActualityHeatmapDataTableConfig';

const ActualityHeatmapDataTable: React.FC<
  DatasetDataViewProps<ActualityHqDataRow>
> = props => {
  const { t } = useTranslation('dataset');
  // const whId = useRecoilValue(warehouseSelectedId);
  // const datasetId = useRecoilValue(datasetObjectDocumentId);
  const data = useRecoilValue(actualityHeatmapTableData);
  const totalCount = useRecoilValue(actualityHeatmapTableTotalCount);
  const status = useRecoilValue(actualityHeatmapTableDataLoadStatus);
  const queryBuilderParams = useRecoilValue(actualityHeatmapBuilderParams);
  const [dataState, setDataState] = useRecoilState(
    actualityHeatmapTableDataState,
  );

  const [loadCallback, reset, exportCSV] = useLoadActualityHeatmapTableData();
  const columnsConfig = useActualityHeatmapDataTableConfig();

  useEffect(() => {
    callDataLoad(dataState.searchValues);
  }, [props.id, dataState.searchValues, queryBuilderParams]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  function callDataLoad(
    searchValues: Record<string, string>,
    page = { isAppend: false, skip: 0 },
  ) {
    if (_.isNil(props.id)) return;

    const filterBy: ActualityHqFilterBy = _.reduce(
      _.entries(searchValues),
      (acc, [key, value]) => {
        return {
          ...acc,
          [key]: containsFilter(value),
        };
      },
      {} as ActualityHqFilterBy,
    );
    // {
    //   locationId: containsFilter(searchValues['locationId']),
    //   sku: containsFilter(searchValues['sku']),
    //   consignee: containsFilter(searchValues['consignee']),
    //   jobId: containsFilter(searchValues['jobId']),
    //   importJobId: containsFilter(searchValues['importJobId']),
    // }

    loadCallback({
      ...queryBuilderParams,
      isAppend: page.isAppend,
      page: {
        skip: page.skip,
      },
      filterBy,
    });
  }

  function onLoadNext() {
    callDataLoad(dataState.searchValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  const converterConfig: DatasetQueryConverterConfig = {
    columnsConfig: columnsConfig as any,
  };

  async function startExportCSV() {
    return await exportCSV({
      ...queryBuilderParams,
      page: null,
      converterConfig,
    });
  }

  const isLoading = status === AsyncLoadStatus.Loading;
  const itemsCount = data?.length || 0;

  return (
    <DatasetTable
      id={props.id}
      data={data}
      columnsConfig={columnsConfig}
      keyFields={['locationId', 'skuKey']}
      hasCounter
      hideScreenTitle={props.hideScreenTitle}
      subtitle={t`Activities`}
      title={props.title}
      isSticky
      onLoadNext={onLoadNext}
      onSearch={v => {
        setDataState({ ...dataState, searchValues: v });
      }}
      totalCount={totalCount}
      searchValues={dataState.searchValues}
      isLoading={isLoading}
      onStartExportClick={startExportCSV}
    />
  );
};

export default ActualityHeatmapDataTable;
