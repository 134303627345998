import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Container } from '../../components/layout/ContainerFlex';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import ActualityHeatmapLegendPanel from '../panels/ActualityHeatmapLegendPanel';

const ActualityHeatmapBucketsSidebar: React.FC = () => {
  const { t } = useTranslation('feed');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-feed-heatmap',
    title: t`Warehouse Stats`,
    size: 'xs',
  };

  return (
    <SidebarContainer {...containerProps}>
      <Container
        col
        data-component="ActualityHeatmapBucketsSidebar"
        className="relative"
      >
        {/* <Container col> */}
        <Suspense
          fallback={
            <LoadingIndicator absolute message={t`Loading Data`} selfCenter />
          }
        >
          <ActualityHeatmapLegendPanel />
        </Suspense>
      </Container>
      {/* </Container> */}
    </SidebarContainer>
  );
};
export default ActualityHeatmapBucketsSidebar;
