import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

export type modeStyle = {
  fill?: boolean;
  stroke?: boolean;
};

export const ClassIcon = {
  xs: 'w-3 h-3',
  sm: 'w-4 h-4',
  md: 'w-5 h-5',
  lg: 'w-6 h-6',
  xl: 'w-7 h-7',
  '2xl': 'w-8 h-8',
};

export const ClassType = (fill: boolean, stroke: boolean) => {
  return {
    default: fill
      ? 'bg-menu-active text-menu-active-text'
      : 'border border-menu-active text-menu-active',
    locked: fill
      ? 'bg-menu-active text-menu-active-text'
      : 'bg-app-panel/30 border border-menu-active/20 text-menu-active uppercase',
    warn: fill
      ? 'bg-menu-active text-menu-active-text'
      : 'border border-menu-active text-menu-active',
    error: fill
      ? 'bg-menu-active text-menu-active-text'
      : 'border border-menu-active text-menu-active',
  };
};

export type StatusTagProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  type?: keyof typeof ClassType | string;
  modeStyle?: 'fill' | 'stroke';
  icon?: React.FC<HTMLAttributes<Element>>;
  iconClass?: keyof typeof ClassIcon | string;
  onPress?: () => void;
};

export const StatusTag = ({
  className,
  type = 'default',
  title,
  children,
  modeStyle = 'fill',
  icon,
  iconClass = 'sm',
  onPress,
}: StatusTagProps) => {
  const fillStyle = modeStyle === 'fill';
  const strokeStyle = modeStyle === 'stroke';
  const typeClass = ClassType(fillStyle, strokeStyle)[type];
  const iconSize = ClassIcon[iconClass];
  const Icon = icon;

  return (
    <div
      data-component="StatusTag"
      aria-label={`status-${title}`}
      className={classNames(
        'p-2 py-0.5 text-xs rounded',
        'flex items-center',
        typeClass,
        className,
      )}
      onClick={onPress}
    >
      {icon && <Icon className={classNames('fill-current', iconSize)} />}
      <div className={classNames('mx-1')}>
        <span>{title}</span>
      </div>
      {children}
    </div>
  );
};
