import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenHeader from './ScreenHeader';
import { Spacer } from './layout/Spacer';
import NavBreadcrumbs, { NavBreadcrumbsItem } from './nav/NavBreadcrumbs';

export type AppHeaderProps = PropsWithChildren & {
  warehouseId: string;
  backPath?: string;
  breadcrumbItems: NavBreadcrumbsItem[];
  isLoading?: boolean;
};

const AppHeaderData: React.FC<AppHeaderProps> = props => {
  const { t } = useTranslation('app');

  return (
    <ScreenHeader
      backPath={props.backPath ? props.backPath : `/wh/i/${props.warehouseId}/`}
      logo
      subtitle={t`WareBee`}
    >
      <NavBreadcrumbs
        items={props.breadcrumbItems}
        isLoading={props.isLoading}
      />
      <Spacer flexspace />
      {props.children}
    </ScreenHeader>
  );
};

export default React.memo(AppHeaderData);
