import {
  StackingPolicyRuleSubcategoriesFragment,
  StackingPolicyRuleSubcategoryType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formatInteger } from '../common/formatHelper';
import { getDefaultMeasure, getMeasureTitle } from '../common/measureHelper';
import { Button } from '../components/actions/Button';
import ButtonSwitchMulti from '../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../components/actions/DropdownSelector';
import * as Icon from '../components/icons';
import InboxZero from '../components/InboxZero';
import InputNumber from '../components/inputs/InputNumber';
import { Container } from '../components/layout/ContainerFlex';
import TitleSection from '../components/layout/TitleSection';
import { ActionBar } from '../components/nav/ActionBar';
import { Table } from '../components/table/Table';
import { TableBody } from '../components/table/TableBody';
import { TableHead } from '../components/table/TableHead';
import { TableRow } from '../components/table/TableRow';
import { TableTd } from '../components/table/TableTd';
import { TableTh } from '../components/table/TableTh';
import { STACKING_POLICY_DEFAULT_RULE_ID } from '../simulation/store/stackingPolicy/stackingPolicy.default';
import { getStackingPolicySubcategoryTypeDescriptors } from '../simulation/store/stackingPolicy/stackingPolicy.helper';
import {
  stackingPolicyFallbackRule,
  stackingPolicyRule,
  stackingPolicySelectedIdentity,
} from '../simulation/store/stackingPolicy/stackingPolicy.state';
import { warehouseMeasurementSystem } from '../store/warehouse.state';

const subcategoriesOptions = ['none', 'step', 'boundaries'] as const;
type SubcategoriesOption = (typeof subcategoriesOptions)[number];

type StackingPolicySubcategoriesEditorProps = {
  subcategories: StackingPolicyRuleSubcategoriesFragment;
  onChange: (subcategories: StackingPolicyRuleSubcategoriesFragment) => void;
};

const StackingPolicySubcategoriesEditorBase: React.FC<
  StackingPolicySubcategoriesEditorProps
> = (props: StackingPolicySubcategoriesEditorProps) => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('simulation');

  const measurementSystem = useRecoilValue(warehouseMeasurementSystem);

  const weightUnits = getMeasureTitle(
    getDefaultMeasure(measurementSystem, 'weight'),
    tMeasures,
  );
  const categoriesSettings = props.subcategories;

  const [subCatTypeLocal, setSubCatTypeLocal] =
    useState<StackingPolicyRuleSubcategoryType>(
      categoriesSettings?.type ??
        StackingPolicyRuleSubcategoryType.BASE_UOM_WEIGHT,
    );
  const [stepLocal, setStepLocal] = useState<number>(
    categoriesSettings?.step ?? 1,
  );
  const [boundariesLocal, setBoundariesLocal] = useState<number[]>(
    categoriesSettings?.boundaries ?? [2],
  );
  const [boundary, setBoundary] = useState(0);

  useEffect(() => {
    if (!_.isNil(props.subcategories?.boundaries)) {
      setBoundariesLocal(props.subcategories?.boundaries);
    }

    if (!_.isNil(props.subcategories?.step)) {
      setStepLocal(props.subcategories?.step);
    }

    if (!_.isNil(props.subcategories?.type)) {
      setSubCatTypeLocal(props.subcategories?.type);
    }
  }, [props.subcategories]);

  let selectedOption: SubcategoriesOption = 'none';
  if (!_.isNil(categoriesSettings?.step)) {
    selectedOption = 'step';
  }
  if (!_.isNil(categoriesSettings?.boundaries)) {
    selectedOption = 'boundaries';
  }

  const selectedIndex = _.findIndex(
    subcategoriesOptions,
    o => o === selectedOption,
  );

  const subcategoriesOptionsTitles: Record<SubcategoriesOption, string> = {
    none: t`None`,
    step: t`By Interval`,
    boundaries: t`By Range`,
  };

  function updateType(value: StackingPolicyRuleSubcategoryType) {
    setSubCatTypeLocal(value);
    props.onChange({
      ...categoriesSettings,
      type: value,
    });
  }

  function addBoundary(boundary: number, isDelete = false) {
    const newBoundaries = _.filter(
      categoriesSettings?.boundaries,
      v => v !== boundary,
    );
    if (!isDelete) {
      newBoundaries.push(boundary);
      newBoundaries.sort((a, b) => a - b);
    }
    setBoundariesLocal(newBoundaries);
    props.onChange({
      type: subCatTypeLocal,
      boundaries: newBoundaries,
    });
  }

  function selectSubcategoriesOption(index: number) {
    const option = subcategoriesOptions[index];
    let subcategories: StackingPolicyRuleSubcategoriesFragment = null;
    switch (option) {
      case 'step':
        subcategories = {
          type: subCatTypeLocal,
          step: stepLocal,
        };
        break;
      case 'boundaries':
        subcategories = {
          type: subCatTypeLocal,
          boundaries: boundariesLocal,
        };
        break;
    }
    props.onChange(subcategories);
  }

  const typeDescriptors = getStackingPolicySubcategoryTypeDescriptors(t);
  return (
    <div data-component="StackingPolicySubcategoriesEditor">
      <TitleSection
        id={`stacking-policy-subcategories-editor`}
        title={t`Weight Categories`}
        inSidebarView
        hasScreenTitle
      >
        <ActionBar>
          <ButtonSwitchMulti
            buttonType="minimal"
            autoSize
            className="text-xs mx-2 my-2 flex-1"
            selectedIndex={selectedIndex}
            onClick={selectSubcategoriesOption}
            options={_.map(subcategoriesOptions, o => ({
              label: subcategoriesOptionsTitles[o],
            }))}
          />
        </ActionBar>

        {selectedOption === 'none' && (
          <Container col>
            <InboxZero message={`No Weight Categories`} />
          </Container>
        )}

        {selectedOption === 'step' && (
          <Container col>
            {/* <HelperMessage hasPadding isSuggestion hasBefore hasBackground>
              {t`#TODO:  Fixed step `}
            </HelperMessage> */}
            <ActionBar className="p-1 px-2">
              <DropdownSelector
                label={t`By Type`}
                classNameLabel="text-xs"
                widthFull
                panelModeMultiline
                value={subCatTypeLocal}
                values={_.keys(typeDescriptors)}
                renderValue={v => typeDescriptors[v].title}
                onChange={updateType}
              />
            </ActionBar>
            <TitleSection inPanelView title={t`Intervals`} />

            <ActionBar className="p-1 px-2">
              <InputNumber
                className="min-w-6 ltr:mr-1"
                inputClassName="rounded text-sm h-6"
                title={t(`Weight ({{weightUnits}})`, { weightUnits })}
                value={stepLocal}
                range={[0, null]}
                debounceInterval={100}
                onChange={step => {
                  setStepLocal(step);
                  props.onChange({
                    type: subCatTypeLocal,
                    step,
                  });
                }}
              />
            </ActionBar>
          </Container>
        )}

        {selectedOption === 'boundaries' && (
          <Container col>
            {/* <div className="p-default">
              <HelperMessage hasPadding isSuggestion hasBefore hasBackground>
                {t`#TODO:  Boundaries `}
              </HelperMessage>
            </div> */}
            <ActionBar className="p-1 px-2 mb-4">
              <DropdownSelector
                label={t`By Type`}
                classNameLabel="text-xs"
                panelModeMultiline
                widthFull
                value={subCatTypeLocal}
                values={_.keys(typeDescriptors)}
                renderValue={v => typeDescriptors[v].title}
                onChange={updateType}
              />
            </ActionBar>

            <TitleSection inPanelView title={t`Ranges`} />
            <ActionBar className="p-1 px-2">
              <InputNumber
                value={boundary}
                range={[0, null]}
                onChange={setBoundary}
                className="min-w-6 ltr:mr-1"
                inputClassName="rounded text-sm h-6"
                title={t(`Weight ({{weightUnits}})`, { weightUnits })}
              />
              <Button
                label={t`Add`}
                buttonType="primary"
                buttonSize="xxs"
                className="rounded"
                hasIconBefore
                buttonIcon={
                  <Icon.CirclePlus className={`w-4 h-4 fill-current`} />
                }
                onPress={() => addBoundary(boundary)}
              />
            </ActionBar>

            <Table isSticky isSortable isHoverable>
              <TableHead>
                <TableRow>
                  <TableTh isWide value={t`Range`} selectable={true} />
                  <TableTh />
                </TableRow>
              </TableHead>

              <TableBody>
                {_.map(categoriesSettings?.boundaries, v => {
                  return (
                    <TableRow key={`row-${v}`} rowLineList rowHover>
                      <TableTd>{formatInteger(v)}</TableTd>
                      <TableTd>
                        <Button
                          label={t`Delete`}
                          buttonSize="xs"
                          buttonType={'secondary'}
                          className="rounded"
                          hasIconAfter
                          buttonIcon={
                            <Icon.CircleX className={`w-5 h-5 fill-current`} />
                          }
                          onPress={() => addBoundary(v, true)}
                        />
                      </TableTd>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Container>
        )}
      </TitleSection>
    </div>
  );
};

const StackingPolicyFallbackSubcategoriesEditor: React.FC = () => {
  const [fallbackRule, updateFallbackRule] = useRecoilState(
    stackingPolicyFallbackRule,
  );
  function onChange(subcategories: StackingPolicyRuleSubcategoriesFragment) {
    updateFallbackRule({
      ...fallbackRule,
      subcategories,
    });
  }

  return (
    <StackingPolicySubcategoriesEditorBase
      subcategories={fallbackRule?.subcategories}
      onChange={onChange}
    />
  );
};

const StackingPolicyRuleSubcategoriesEditor: React.FC<{
  ruleId: string;
}> = props => {
  const [rule, updateRule] = useRecoilState(stackingPolicyRule(props.ruleId));
  function onChange(subcategories: StackingPolicyRuleSubcategoriesFragment) {
    updateRule({
      ...rule,
      subcategories,
    });
  }

  return (
    <StackingPolicySubcategoriesEditorBase
      subcategories={rule?.subcategories}
      onChange={onChange}
    />
  );
};

const StackingPolicySubcategoriesEditor: React.FC = () => {
  const selectedIdentity = useRecoilValue(stackingPolicySelectedIdentity);
  if (
    _.isNil(selectedIdentity.ruleId) ||
    !selectedIdentity.isSubcategoriesFilterSelected
  ) {
    return null;
  }
  if (selectedIdentity.ruleId === STACKING_POLICY_DEFAULT_RULE_ID) {
    return <StackingPolicyFallbackSubcategoriesEditor />;
  }

  return (
    <StackingPolicyRuleSubcategoriesEditor ruleId={selectedIdentity.ruleId} />
  );
};
export default StackingPolicySubcategoriesEditor;
