import { useApolloClient } from '@apollo/client';
import {
  CreateOptimizationRunDocument,
  CreateOptimizationRunMutation,
  CreateOptimizationRunMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { OptimizationRunSourceType } from '@warebee/shared/engine-model';
import { FetchResult } from 'apollo-link';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { OptimizeJobEngine } from '../../../../../../libs/frontend/data-access-api-graphql/src/generated/generated';
import {
  optimisationResult,
  optimisationResultStatus,
} from '../../simulation/store/optimisation.state';
import { errorAppender } from '../../store/error.state';

export type RunOptimizationParams = {
  type: OptimizationRunSourceType;
  id: string;
  seed?: number;
  steps?: number;
  engine?: OptimizeJobEngine;
  nodes?: number;
};

function useRunOptimization() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');

  const runOptimization = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: RunOptimizationParams) => {
        let response: FetchResult<CreateOptimizationRunMutation>;
        try {
          response = await client.mutate<
            CreateOptimizationRunMutation,
            CreateOptimizationRunMutationVariables
          >({
            mutation: CreateOptimizationRunDocument,
            variables: {
              input: {
                steps: params.steps ?? 10,
                seed: params.seed,
                engine: params.engine,
                nodes: params.nodes,
                source: {
                  type: params.type,
                  id: params.id,
                },
              },
            },
          });
          console.log('[runOptimization]  ID:', params.id);
          console.log('[runOptimization]  Type:', params.type);
          console.log('[runOptimization] Seed:', params.seed);
          console.log('[runOptimization] Steps:', params.steps);
          console.log('[runOptimization] Engine:', params.engine);
          console.log('[runOptimization] Nodes:', params.nodes);
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: t`Cannot run optimisation`,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: t`Cannot run optimisation`,
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          return;
        }
        const optimizeRunResult = response.data.createOptimizationRun;
        set(optimisationResult, optimizeRunResult);
        set(optimisationResultStatus, optimizeRunResult);
      },
  );

  return async (params: RunOptimizationParams) => {
    await runOptimization(params);
  };
}

export default useRunOptimization;
