import _ from 'lodash';
import { atom, selectorFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import {
  COLLAPSIBLE_OPEN_PREFIX,
  CollapsibleId,
  CollapsibleState,
  CollapsibleStateMap,
  getDefaultCollapsibleState,
} from './collapsible.default';

const { persistAtom } = recoilPersist({
  key: 'warebee-collapsible-panels-1.0.1',
  storage: localStorage,
});

export const collapsibleStateAtom = atom<CollapsibleStateMap>({
  key: 'warebee-collapsible-state-inner',
  default: getDefaultCollapsibleState(),
  effects: [persistAtom],
});

export const collapsibleState = selectorFamily<CollapsibleState, CollapsibleId>(
  {
    key: 'warebee-collapsible-state',
    get:
      (id: CollapsibleId) =>
      ({ get }) => {
        return (
          get(collapsibleStateAtom)[id] || {
            isCollapsed: !_.some(
              COLLAPSIBLE_OPEN_PREFIX,
              prefix => id?.startsWith(prefix),
            ),
          }
        );
      },

    set:
      (id: CollapsibleId) =>
      ({ get, set }, value: CollapsibleState) => {
        const current = get(collapsibleStateAtom);
        set(collapsibleStateAtom, { ...current, [id]: value });
      },
  },
);

// export const collapsibleFilterState = atom<CollapsibleFilterStateMap>({
//   key: 'warebee-collapsible-filter-state',
//   default: {},
// });
